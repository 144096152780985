import { CreateUETEMTResponse } from '@/api/uet';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Modal, Select, useUpdate } from '@maxtropy/components';
import {
  apiV2ElectricityAccountListPost,
  apiV2EmtConfigBindAccountsPost,
  apiV2EmtConfigInfoPost,
  apiV2MediumAccountRelateMediumAccountListPost,
  V2ElectricityAccountListPostResponse,
  V2EmtConfigInfoPostResponse,
  V2MediumAccountRelateMediumAccountListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Col, Form, MenuProps, Row } from 'antd';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';

export interface IRelatedAccounts {
  eMTBasicInfo?: CreateUETEMTResponse;
}

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export type ElectricityAccountItem = Exclude<V2ElectricityAccountListPostResponse['list'], undefined>[number];
export type EnergyMediumAccountItem = Exclude<
  V2MediumAccountRelateMediumAccountListPostResponse['list'],
  undefined
>[number];

const RelatedAccounts: FC<IRelatedAccounts> = props => {
  const { eMTBasicInfo } = props;
  const [form] = Form.useForm();

  const [accountModalOpen, setAccountModalOpen] = useState<boolean>(false);
  const [energyMediumAccountList, setEnergyMediumAccountList] = useState<EnergyMediumAccountItem[]>();
  const [elecAccountList, setElecAccountList] = useState<ElectricityAccountItem[]>();
  const [params, setParams] = useState<any>();
  const [updateState, updateFn] = useUpdate();
  const [accountData, setAccountData] = useState<V2EmtConfigInfoPostResponse>();

  useEffect(() => {
    if (eMTBasicInfo && eMTBasicInfo.id) {
      apiV2EmtConfigInfoPost({ id: eMTBasicInfo.id.toString() }).then(res => {
        setAccountData(res);
      });
    }
  }, [updateState, eMTBasicInfo]);

  useEffect(() => {
    if (accountData) {
      form.setFieldsValue({
        elecAccount: accountData.electricityAccountVo
          ? [
              {
                elecAccountId: accountData.electricityAccountVo?.id,
              },
            ]
          : undefined,
        energyMediumAccount: accountData.mediumAccountVos?.map(item => {
          return {
            energyMediumAccountId: item.id + '-' + item.energyMediumSceneId,
          };
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);

  useEffect(() => {
    if (params) {
      apiV2EmtConfigBindAccountsPost(params).then(_ => {
        updateFn();
        setAccountModalOpen(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // 查询工质账户列表
  useEffect(() => {
    apiV2MediumAccountRelateMediumAccountListPost().then(res => {
      setEnergyMediumAccountList(res.list);
    });
  }, []);
  // 查询用电账户列表
  useEffect(() => {
    apiV2ElectricityAccountListPost().then(res => {
      setElecAccountList(res?.list ?? []);
    });
  }, []);

  // 工质账户列表options
  const energyMediumAccountListOptions = useMemo(() => {
    return (energyMediumAccountList ?? []).map(i => ({
      label: i.accountNumber + '-' + i.energyMediumSceneName,
      value: i.id + '-' + i.energyMediumSceneId,
    }));
  }, [energyMediumAccountList]);

  // 用电账户列表options
  const elecAccountListOptions = useMemo(() => {
    return (elecAccountList ?? []).map(i => ({
      label: i.number,
      value: i.id,
    }));
  }, [elecAccountList]);

  const addElecAccount = () => {
    const elecAccountList = form.getFieldValue('elecAccount') || [];
    if (elecAccountList.length < 1) {
      const nextList = elecAccountList.concat(undefined);
      form.setFieldsValue({
        elecAccount: nextList,
      });
    } else {
      message.error('只能添加一个用电账户');
    }
  };
  const addEnergyMediumAccount = () => {
    const energyMediumAccountList = form.getFieldValue('energyMediumAccount') || [];
    const nextList = energyMediumAccountList.concat(undefined);
    form.setFieldsValue({
      energyMediumAccount: nextList,
    });
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button type="link" style={{ color: 'var(--mx-dropdown-menu-color)' }} onClick={() => addElecAccount()}>
          用电账户
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button type="link" style={{ color: 'var(--mx-dropdown-menu-color)' }} onClick={() => addEnergyMediumAccount()}>
          能源介质账户
        </Button>
      ),
    },
  ];

  const onFinish = (values: any) => {
    const params = {
      emtId: eMTBasicInfo?.id,
      electricAccountId:
        !values.elecAccount || values.elecAccount.length === 0 ? undefined : values.elecAccount[0].elecAccountId,
      energyMediumAccountList:
        !values.energyMediumAccount || values.energyMediumAccount.length === 0
          ? undefined
          : values.energyMediumAccount.map((item: any) => {
              return {
                sceneId: Number(item.energyMediumAccountId.split('-')[1]),
                accountId: Number(item.energyMediumAccountId.split('-')[0]),
              };
            }),
    };
    setParams(params);
  };

  return (
    <>
      <Button type="primary" onClick={() => setAccountModalOpen(true)}>
        账户设置
      </Button>
      {accountData && (
        <>
          <Row style={{ margin: 12, padding: 6 }}>
            {accountData.electricityAccountVo && (
              <Col span={8}>
                <div className={styles.label}>用电账户：</div>
                <div className={styles.value}>{accountData.electricityAccountVo.number}</div>
              </Col>
            )}
            {accountData.mediumAccountVos?.map(item => (
              <Col span={8} key={item.id}>
                <div className={styles.label}>能源介质账户：</div>
                <div className={styles.value}>{item.accountNumber}</div>
              </Col>
            ))}
          </Row>
        </>
      )}
      <Modal
        open={accountModalOpen}
        title="账户设置"
        width={600}
        destroyOnClose
        onOk={() => {
          form.submit();
        }}
        okText="保存"
        onCancel={() => {
          setAccountModalOpen(false);
        }}
      >
        <Dropdown menu={{ items }} placement="bottom" type="primary">
          关联账户
        </Dropdown>
        <Form form={form} onFinish={onFinish} style={{ marginTop: 24 }} autoComplete="off">
          <Form.List name="elecAccount">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={16} key={key} className={styles.formSpaceContent}>
                    <Col span={22}>
                      <Form.Item {...restField} {...formItemLayout} label="用电账户" name={[name, 'elecAccountId']}>
                        <Select
                          placeholder="请选择用电账户"
                          showSearch
                          optionFilterProp="label"
                          options={elecAccountListOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <MinusCircleOutlined
                        style={{ color: '#4A90E2', cursor: 'pointer', paddingTop: 8 }}
                        onClick={() => remove(name)}
                      />
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
          <Form.List name="energyMediumAccount">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={16} key={key} className={styles.formSpaceContent}>
                    <Col span={22}>
                      <Form.Item
                        {...restField}
                        {...formItemLayout}
                        label="能源介质账户"
                        name={[name, 'energyMediumAccountId']}
                        rules={[{ required: true, message: '请选择能源介质账户' }]}
                      >
                        <Select
                          placeholder="请选择能源介质账户"
                          showSearch
                          optionFilterProp="label"
                          options={energyMediumAccountListOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <MinusCircleOutlined
                        style={{ color: '#4A90E2', cursor: 'pointer', paddingTop: 8 }}
                        onClick={() => remove(name)}
                      />
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default RelatedAccounts;
