import { fetch } from '@maxtropy/components';

export interface ModelResponse {
  name: string;
  deviceTypeId: string;
  createSource: string;
  createByUserId: string;
}

export function getModel(id: number): Promise<{ list: ModelResponse[] }> {
  return fetch(
    `/api/v2/model/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
