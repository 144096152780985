import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Descriptions, Divider, Row, Space } from 'antd';
import React, { Key, useCallback, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import {
  BSAResProps,
  checkBsaUnique,
  createAllUet,
  createUetBsa,
  createUetMasterSlaveBsa,
  DeviceListByMcidProps,
  getBsaInfo,
  getDeviceListByOuId,
  MasterFcsProps,
  PcsStructureType,
  Status,
  statusFormat,
  UetInfoProps,
  updateBsa,
} from '../../../../api/uet';
import {
  AllFcsListProps,
  FCSLinkBSAType,
  FcsListProps,
  getFCSByMasterGatewayId,
  getFcsByOuBsaVersion,
  getFcsInfoByHardwarePcsStruct,
} from '../../../../api/fcs';
import { Link, useParams } from 'react-router-dom';
import { useUpdate, Button, Form, message, Modal, Input, InputNumber, Select } from '@maxtropy/components';
import { useQuery } from '../../../../utils/utils';
import { queryOuTypeList } from '../../../../api/ou';
import FixedBottomButton from '@/components/FixedBottomButton';
import { CircuitProps, getCircuitDetail, getCircuitList } from '@/api/circuit';

interface BSAProps {
  info?: UetInfoProps;
  isDetail?: boolean;
}

interface ListProps {
  // toVoidProdLoadList?: LoadTransformerDetailDtosProps[]; // 作废后查询负载
  isToVoid?: boolean; // 是否作废
  isEditing?: boolean; // 是否是编辑态
  bsa: BSAResProps;
  allFcsList?: FcsListProps[];
  fcsList?: AllFcsListProps[]; // 返回的fcsList
  fcsId?: number; // 老的fcsId
  fcsInfoExceptMasterAndSlave?: AllFcsListProps; // 老的fcs对象
  masterFcsId?: number; // 关联的主控FCSid
  fcsInfoMaster?: AllFcsListProps; // 关联的主控FCS对象
  slaveFcsIds?: number[]; // 关联的从控FCSids
  slaveFcsList?: AllFcsListProps[]; // 关联的从控FCS列表对象
  circuitList?: CircuitProps[];
  circuitId?: number;
  currentCircuit?: CircuitProps;
  fcs?: FcsListProps;
  customerCode: string;
  ouSerialNumber: string;
  meterDevice?: DeviceListByMcidProps;
  meterDeviceId?: number; // 电表id
  pvMeterDeviceId?: number; // 光伏电表
  upstreamTransformerMeterDeviceIds?: number[]; // 上端变压器电表id
  meterDeviceList?: DeviceListByMcidProps[]; // 查询的所有的电表
  pvMeterDeviceList?: DeviceListByMcidProps[]; // 查询所有的光伏电表
  upstreamTransformerMeterDeviceList?: DeviceListByMcidProps[]; // 上端变压器电表
}
interface KeyValues {
  [key: string]: string;
}

export const getObjectKey = (object: KeyValues, value?: string) => {
  if (!value) return '';
  let key = Object.keys(object).find(key => object[key] === value) ?? '';
  return key;
};
export enum HardWareVersion {
  HA1 = 'HA1',
  HA2 = 'HA2',
  MP1 = 'MP1',
  HA11 = 'HA1_1',
  SH1 = 'SH1',
  YJ1 = 'YJ1',
  JN1 = 'JN1',
  JN2 = 'JN2',
  ZG1 = 'ZG1', // 这个FM/1.0版本比较特殊, 没有从控
}

export const hardWareValueDisplay: KeyValues = {
  [HardWareVersion.HA1]: 'HS1.1',
  [HardWareVersion.HA2]: 'HS1.2',
  [HardWareVersion.MP1]: 'HS1.3',
  [HardWareVersion.HA11]: 'HS1.4',
  [HardWareVersion.SH1]: 'HS1.5',
  [HardWareVersion.YJ1]: 'HS1.6',
  [HardWareVersion.JN1]: 'HS1.7',
  [HardWareVersion.JN2]: 'HS1.8',
  [HardWareVersion.ZG1]: 'HS1.9',
};
export const hardWareLabelDisplay: KeyValues = {
  [hardWareValueDisplay[HardWareVersion.HA1]]: '单PCS-利思PCS-手动并离网',
  [hardWareValueDisplay[HardWareVersion.HA2]]: '单PCS-利思PCS-半自动并离网',
  [hardWareValueDisplay[HardWareVersion.MP1]]: '多PCS通用结构无离网结构',
  [hardWareValueDisplay[HardWareVersion.HA11]]: '英博单PCS+炬能协能BMS',
  [hardWareValueDisplay[HardWareVersion.SH1]]: '盛弘单PCS+协能定制电表200kWh标准柜',
  [hardWareValueDisplay[HardWareVersion.YJ1]]: '盛弘多PCS+云尖协能BMS',
  [hardWareValueDisplay[HardWareVersion.JN1]]: '英博多PCS+炬能协能BMS',
  [hardWareValueDisplay[HardWareVersion.JN2]]: '盛弘多PCS+炬能协能BMS',
  [hardWareValueDisplay[HardWareVersion.ZG1]]: '智光PCS+智光BMS',
};
// 单pcs的硬件版本
export const hardWareEnumArray = [
  HardWareVersion.HA1,
  HardWareVersion.HA2,
  HardWareVersion.HA11,
  HardWareVersion.SH1,
] as string[];

// 多pcs版本, 但没有从控
export const hardWareEnumArrayWithoutControl = [HardWareVersion.ZG1] as string[];

// 主控软件版本
export const masterFcsList = ['FC/1.0', 'FC/1.1', 'FC/1.2', 'FG/1.0', 'FI/1.0', 'FK/1.0'];

const BSA: React.FC<BSAProps> = ({ info, isDetail }) => {
  const [form] = Form.useForm();
  const [eachForm] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [visible, setVisible] = useState(false);
  const [uetBsaList, setUetBsaList] = useState<ListProps[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const btnDisabled = isDetail || !edit;
  const [x, forceUpdate] = useUpdate();
  const [extraMessage, setExtraMessage] = useState<string>();
  const [slaveFcsList, setSlaveFcsList] = useState<MasterFcsProps[]>([]); // 从控FCS列表
  // const [editItemStatus, setEditItemStatus] = useState<{ editState: boolean; index: string | number }>(); // 设置每一个阵列的编辑态
  const [findMasterInfo, setFindMasterInfo] = useState<AllFcsListProps>();
  const [voidSoftwareInfo, setVoidSoftwareInfo] = useState<AllFcsListProps>();

  const editSaveInfoListRef = useRef<ListProps[]>();

  const { data: ouList } = useQuery(
    useCallback(async () => {
      const res = await queryOuTypeList();
      const id = res.find(i => i.key === 'MICRONET')?.id;
      return info?.ous.filter(i => i.ouTypeId === id);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );

  useEffect(() => {
    if (info) {
      getBsaInfo(info.id).then(async res => {
        editSaveInfoListRef.current = await Promise.all(
          (res.list ?? []).map(async i => {
            return {
              ...i,
              isEditing: false,
              circuitId: i.circuit?.id,
              fcsInfoExceptMasterAndSlave: i.fcsList
                ? i.fcsList.find(item => item.linkType === FCSLinkBSAType.EXCEPTMASTERORSLAVE)
                : undefined,
              fcsId: i.fcsList
                ? i.fcsList.find(item => item.linkType === FCSLinkBSAType.EXCEPTMASTERORSLAVE)?.id
                : undefined,
              fcsInfoMaster: i.fcsList ? i.fcsList.find(item => item.linkType === FCSLinkBSAType.MASTER) : undefined,
              masterFcsId: i.fcsList ? i.fcsList.find(item => item.linkType === FCSLinkBSAType.MASTER)?.id : undefined,

              slaveFcsIds: i.fcsList
                ? (i.fcsList.filter(item => item.linkType === FCSLinkBSAType.SLAVE).map(i => i.id) as number[])
                : undefined,
              slaveFcsList: i.fcsList ? i.fcsList.filter(item => item.linkType === FCSLinkBSAType.SLAVE) : undefined,
              isToVoid: i.fcsList && i.fcsList.length > 0 ? false : true,
              fcsList:
                i.fcsList && i.fcsList.length > 0
                  ? i.fcsList
                  : await getFcsByOuBsaVersion({
                      ouId: i.bsa.ouId,
                      softwareVersionList: await getFcsInfoByHardwarePcsStruct({
                        hardwareVersion: i.bsa.hardwareVersion,
                        pcsStruct: hardWareEnumArray.includes(i.bsa.hardwareVersionEnum ?? '')
                          ? PcsStructureType.Single
                          : PcsStructureType.Muti,
                      }).then(r => r.list),
                    }).then(res => {
                      return res.list;
                    }),
              meterDeviceId: i.meterDevice?.id, // BSA关联的可选的计量电表
              pvMeterDeviceId: i.pvMeterDevice?.id ?? undefined,
              upstreamTransformerMeterDeviceIds: i.upstreamTransformerMeterDevice?.map(item => item.id),
              meterDeviceList: await getDeviceListByOuId([i.bsa.ouId]), // 查询所有电表list
              pvMeterDeviceList: await getDeviceListByOuId([i.bsa.ouId]),
              upstreamTransformerMeterDeviceList: await getDeviceListByOuId([i.bsa.ouId]),
              circuitList: i.circuit ? [] : (await getCircuitList({ uetId: id })).list ?? [], // 作废之后重新查询回路
              currentCircuit: i.circuit,
            };
          })
        );
        setUetBsaList(editSaveInfoListRef.current);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, x]);

  // 3位小数点
  const limitDecimalsF = (value: any) => {
    let reg = /^(-)*(\d+)\.(\d\d\d).*$/;
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '').replace(reg, '$1$2.$3');
  };
  // 截断3位小数
  const limitDecimalsPThree = (value: any) => {
    let reg = /^(-)*(\d+)\.(\d\d\d).*$/;
    return value.replace(/￥\s?|(,*)/g, '').replace(reg, '$1$2.$3');
  };

  // 弹窗保存的时候
  const onFinish = (v: {
    ouId: number;
    name: string;
    hardwareVersion: string;
    installedCapacity: number;
    ratedPower: number;
    pcsStruct: PcsStructureType;
  }) => {
    checkBsaUnique(v.name).then(async res => {
      if (!res.flag) {
        form.setFields([
          {
            name: 'name',
            errors: ['储能阵列名称重复！'],
          },
        ]);
      } else {
        setVisible(false);
        form.resetFields();
        setExtraMessage(undefined);
        let fcsRes: FcsListProps[] = [];
        let circuits: CircuitProps[] = [];
        let softwareVersionListTemp: string[] = [];
        let meterDevice: DeviceListByMcidProps[] = [];
        // 通过硬件版本和结构查询Fcs的软件版本
        await getFcsInfoByHardwarePcsStruct({
          hardwareVersion: v.hardwareVersion,
          pcsStruct: hardWareEnumArray.includes(getObjectKey(hardWareValueDisplay, v.hardwareVersion))
            ? PcsStructureType.Single
            : PcsStructureType.Muti,
        })
          .onError(error => {
            const { errorMessage } = error?.cause;
            message.error(errorMessage);
            throw error;
          })
          .then(async res => {
            // setSoftwareVersionList(res);
            softwareVersionListTemp = res.list;
          });
        // FCS
        await getFcsByOuBsaVersion({ ouId: v.ouId, softwareVersionList: softwareVersionListTemp })
          .onError(error => {
            const { errorMessage } = error?.cause;
            message.error(errorMessage);
            throw error;
          })
          .then(result => (fcsRes = result.list));
        // 查询回路列表
        await getCircuitList({ uetId: id })
          .onError(error => {
            const { errorMessage } = error?.cause;
            message.error(errorMessage);
            throw error;
          })
          .then(res => (circuits = res.list ?? []));

        // 查询计量电表
        meterDevice = await getDeviceListByOuId([v.ouId]);

        setUetBsaList([
          ...uetBsaList,
          {
            ...v,
            allFcsList: fcsRes,
            circuitList: circuits,
            meterDeviceList: meterDevice,
            pvMeterDeviceList: meterDevice,
            upstreamTransformerMeterDeviceList: meterDevice,
            bsa: {
              name: v.name,
              mcid: info?.mcid,
              customerName: info?.customerName,
              ouId: v.ouId,
              ouName: info?.ous.find(i => i.id === v.ouId)?.name,
              hardwareVersion: v.hardwareVersion,
              hardwareName: hardWareLabelDisplay[v.hardwareVersion],
              hardwareVersionEnum: getObjectKey(hardWareValueDisplay, v.hardwareVersion),
              installedCapacity: v.installedCapacity,
              ratedPower: v.ratedPower,
              pcsStruct: hardWareEnumArray.includes(getObjectKey(hardWareValueDisplay, v.hardwareVersion))
                ? PcsStructureType.Single
                : PcsStructureType.Muti,
              softwareVersion: softwareVersionListTemp.join(), // fcs软件版本list
            },
            customerCode: info?.customerCode ?? '--',
            ouSerialNumber: info?.ous.find(i => i.id === v.ouId)?.serialNumber ?? '--',
          },
        ]);
      }
    });
  };

  // 保存提交调用接口
  const preFetch = (v: ListProps, index: number) => {
    // 选择FCS和负载变压器之后
    const params = {
      ouId: v.bsa.ouId,
      name: v.bsa.name,
      circuitId: v.circuitId,
      bsaId: v.bsa.id,
      hardwareVersion: v.bsa.hardwareVersion,
      installedCapacity: v.bsa.installedCapacity,
      ratedPower: v.bsa.ratedPower,
      pcsStruct: hardWareEnumArray.includes(v.bsa.hardwareVersionEnum ?? '')
        ? PcsStructureType.Single
        : PcsStructureType.Muti,
      // softwareVersion: v.bsa.softwareVersion,
      fcsId: v.fcsId,
      masterFcsId: v.masterFcsId,
      slaveFcsIds: v.slaveFcsIds,
      meterId: v.meterDeviceId,
      pvMeterId: v.pvMeterDeviceId,
      upstreamTransformerMeterIds: v.upstreamTransformerMeterDeviceIds,
    };
    // 如果没有主从关系(单pcs或没有从控)
    if (
      v.bsa.hardwareVersionEnum &&
      [...hardWareEnumArray, ...hardWareEnumArrayWithoutControl].includes(v.bsa.hardwareVersionEnum)
    ) {
      // 先判断有无选择fcs和变压器
      if (!(v.fcsId && v.circuitId)) {
        message.error('请选择关联FCS和负载变压器！');
      } else if (!v.meterDeviceId) {
        message.error('请选择计量电表！');
      } else {
        if (v.bsa.id) {
          updateBsa({
            uetId: id!,
            fcsId: v.fcsId,
            bsaId: v.bsa.id,
            installedCapacity: v.bsa.installedCapacity,
            ratedPower: v.bsa.ratedPower,
            name: v.bsa.name,
            meterId: v.meterDeviceId,
            circuitId: v.circuitId,
            pvMeterId: v.pvMeterDeviceId,
            upstreamTransformerMeterIds: v.upstreamTransformerMeterDeviceIds,
          }).then(({ result: res }) => {
            if (res) {
              message.success('保存成功！');
              uetBsaList.splice(index, 1, {
                ...uetBsaList[index],
                fcsId: v.fcsId,
                isEditing: false,
                isToVoid: false,
              });
              editSaveInfoListRef.current = [...uetBsaList]; // 记录最新的数据，以便取消的缓存
              setUetBsaList([...uetBsaList]);
            }
          });
        } else {
          createUetBsa({
            uetId: id!,
            ...params,
          }).then(res => {
            if (res) {
              message.success('保存成功！');
              uetBsaList.splice(index, 1, {
                ...uetBsaList[index],
                ...res,
                bsa: { ...res.bsa, softwareVersion: v.bsa.softwareVersion },
                currentCircuit: res.circuit,
                circuitId: res.circuit.id,
                meterDeviceId: res.meterDevice.id, // BSA关联的可选的计量电表
                pvMeterDeviceId: res.pvMeterDevice?.id ?? undefined,
                upstreamTransformerMeterDeviceIds: res.upstreamTransformerMeterDevice?.map(item => item.id),
                isEditing: false,
                isToVoid: false,
              });
              editSaveInfoListRef.current = [...uetBsaList]; // 记录最新的数据，以便取消的缓存
              setUetBsaList([...uetBsaList]);
            }
          });
        }
      }
      // 有主从关系
    } else {
      if (!v.masterFcsId || !(v.slaveFcsIds && v.slaveFcsIds.length > 0) || !v.circuitId) {
        message.error('请选择关联主从FCS和负载变压器！');
      } else if (!v.meterDeviceId) {
        message.error('请选择计量电表！');
      } else {
        if (v.bsa.id) {
          updateBsa({
            uetId: id!,
            masterFcsId: v.masterFcsId,
            slaveFcsIds: v.slaveFcsIds,
            bsaId: v.bsa.id,
            installedCapacity: v.bsa.installedCapacity,
            ratedPower: v.bsa.ratedPower,
            name: v.bsa.name,
            meterId: v.meterDeviceId,
            circuitId: v.circuitId,
            pvMeterId: v.pvMeterDeviceId,
            upstreamTransformerMeterIds: v.upstreamTransformerMeterDeviceIds,
          }).then(({ result: res }) => {
            if (res) {
              message.success('保存成功！');
              uetBsaList.splice(index, 1, {
                ...uetBsaList[index],
                masterFcsId: v.masterFcsId,
                slaveFcsIds: v.slaveFcsIds,
                isEditing: false,
                isToVoid: false,
              });
              console.log(uetBsaList);
              editSaveInfoListRef.current = [...uetBsaList]; // 记录最新的数据，以便取消的缓存
              setUetBsaList([...uetBsaList]);
            }
          });
        } else {
          createUetMasterSlaveBsa({
            uetId: id!,
            ...params,
          }).then(res => {
            if (res) {
              message.success('保存成功！');
              uetBsaList.splice(index, 1, {
                ...uetBsaList[index],
                ...res,
                bsa: res.bsa,
                currentCircuit: res.circuit,
                circuitId: res.circuit.id,
                masterFcsId: res.fcsList.find(item => item.linkType === FCSLinkBSAType.MASTER)?.id,
                slaveFcsIds: res.fcsList
                  .filter(item => item.linkType === FCSLinkBSAType.SLAVE)
                  .map(i => i.id) as number[],
                meterDeviceId: res.meterDevice.id, // BSA关联的可选的计量电表
                pvMeterDeviceId: res.pvMeterDevice?.id ?? undefined,
                upstreamTransformerMeterDeviceIds:
                  res.upstreamTransformerMeterDevice?.map(item => item.id) ?? undefined,
                isEditing: false,
                isToVoid: false,
              });
              editSaveInfoListRef.current = [...uetBsaList]; // 记录最新的数据，以便取消的缓存
              setUetBsaList([...uetBsaList]);
            }
          });
        }
      }
    }
  };

  // 左下角保存的时候
  const onSubmit = async (v: ListProps, index: number) => {
    let findIsSameName = editSaveInfoListRef.current?.[index]
      ? editSaveInfoListRef.current?.[index].bsa.name
      : v.bsa.name;
    if (v.bsa.name === findIsSameName) {
      preFetch(v, index);
    } else {
      checkBsaUnique(v.bsa.name).then(async res => {
        if (!res.flag) {
          message.error('名字重复！');
          return;
        } else {
          preFetch(v, index);
        }
      });
    }
  };

  // 选择fcs的时候
  const onSelectFcs = (v: number, index: number) => {
    let find = editSaveInfoListRef.current?.[index];
    if (find) {
      let findVoidedFcsInfo = find.fcsList?.find(i => i.id === v);
      findVoidedFcsInfo && setVoidSoftwareInfo(findVoidedFcsInfo);
    }
    setUetBsaList(list => {
      const newList = [...list];
      const current = newList[index];
      newList.splice(index, 1, {
        ...current,
        fcsId: v,
      });
      return newList;
    });
  };

  // 选择主控FCS时，查询从控FCS
  const onSelectMasterFcs = (find: FcsListProps | AllFcsListProps, v: number, index: number) => {
    // 查询从控FCS
    getFCSByMasterGatewayId(find.gatewayId).then(res => {
      if (res) {
        setUetBsaList(list => {
          const newList = [...list];
          const current = newList[index];
          // 在同一组网下就清空，否则不清空从控fcs
          if (find.networkId && slaveFcsList && slaveFcsList.length > 0) {
            if (slaveFcsList[0].networkId === find.networkId) {
              newList.splice(index, 1, {
                ...current,
                masterFcsId: v,
              });
            } else {
              newList.splice(index, 1, {
                ...current,
                masterFcsId: v,
                slaveFcsIds: [],
              });
            }
          } else {
            newList.splice(index, 1, {
              ...current,
              masterFcsId: v,
              slaveFcsIds: [],
            });
          }
          return newList;
        });
        setSlaveFcsList(res.list);
      }
    });
  };

  // 选择从控FCS
  const onSelectslaveFcs = (v: number[], index: number) => {
    // setUetBsaList(uetBsaList.map((d, i) => ({ ...d, slaveFcsIds: i === index ? v : undefined })));
    setUetBsaList(list => {
      const newList = [...list];
      const current = newList[index];
      newList.splice(index, 1, {
        ...current,
        slaveFcsIds: v,
      });
      return newList;
    });
  };

  // 计量电表/光伏计量电表等选择
  const onSelectDevice = (
    v: any,
    index: number,
    key: 'upstreamTransformerMeterDeviceIds' | 'pvMeterDeviceId' | 'meterDeviceId'
  ) => {
    uetBsaList[index][key] = v;
    setUetBsaList([...uetBsaList]);
  };
  const onBsaEditChange = (key: string, value: string | number, index: number) => {
    setUetBsaList(list => {
      const newList = [...list];
      const current = newList[index];
      newList.splice(index, 1, {
        ...current,
        bsa: {
          ...current.bsa,
          [key]: value,
        },
      });
      return newList;
    });
  };

  // 选择变压器的时候 来源于uet中创建的回路
  const onSelectLoadTransformerId = async (v: number, index: number) => {
    let circuitDetail = await getCircuitDetail(v);
    setUetBsaList(list => {
      const newList = [...list];
      const current = newList[index];
      const currentCircuit = current.circuitList?.find(i => i.id === v);
      newList.splice(index, 1, {
        ...current,
        currentCircuit: { ...circuitDetail },
        circuitId: currentCircuit?.id,
      });
      return newList;
    });
  };

  // 点击左下角编辑
  const setEachEdit = (index: number) => {
    setUetBsaList(list => {
      const newList = [...list];
      const current = newList[index];
      newList.splice(index, 1, {
        ...current,
        isEditing: true,
      });
      editSaveInfoListRef.current = newList;
      return newList;
    });
  };

  // 点击取消
  const onDelete = (index: number, bsaId?: number) => {
    setVoidSoftwareInfo(undefined); // 清掉软件版本extra
    setFindMasterInfo(undefined); // 清掉软件版本extra
    if (bsaId) {
      // console.log('取消bsaId', bsaId);
      // console.log('editSaveInfoListRef.current!', editSaveInfoListRef.current!);
      setUetBsaList(list => {
        const newList = [...editSaveInfoListRef.current!];
        const newList1 = [...list];
        const current = newList[index];
        newList1.splice(index, 1, {
          ...current,
          isEditing: false,
        });
        editSaveInfoListRef.current = newList1;
        return newList1;
      });
    } else {
      uetBsaList.splice(index, 1);
      setUetBsaList([...uetBsaList]);
    }
  };

  const onSave = () => {
    const unfinished = uetBsaList.find(i => !i.bsa.id);
    if (unfinished || uetBsaList.map(i => i.isEditing).includes(true)) {
      message.error('有未保存的阵列！');
    } else {
      createAllUet(id!);
      setEdit(false);
    }
  };
  // 生产负载,总计量表dom
  const renderFunc = (data?: CircuitProps) => {
    if (!data) return <></>;

    const { gaugeHost, load } = data;
    return (
      <Row>
        {gaugeHost && (
          <Col span={8}>
            <div>总计量表</div>
            <div className={styles.area}>
              <div className={styles.line}>设备编号: {gaugeHost.code}</div>
              <div className={styles.line}>设备名称: {gaugeHost.name}</div>
            </div>
          </Col>
        )}
        {load && (
          <Col span={12}>
            <div>生产负载表</div>
            <div className={styles.area}>
              <div className={styles.line}>设备编号: {load.code}</div>
              <div className={styles.line}>设备名称: {load.name}</div>
            </div>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <>
      <Button type="primary" disabled={btnDisabled} onClick={() => setVisible(true)}>
        <PlusOutlined />
        新建储能站
      </Button>
      {uetBsaList.map((i, index) => {
        let softwareVersion =
          i.fcsInfoExceptMasterAndSlave?.softwareVersion ??
          i.bsa?.softwareVersion ??
          voidSoftwareInfo?.softwareVersion ??
          '-';
        let tempSoftwareInfo = i.allFcsList?.find(item => item.softwareVersion === softwareVersion);
        let softwareName =
          i.fcsInfoExceptMasterAndSlave?.softwareName ??
          i.bsa?.softwareName ??
          voidSoftwareInfo?.softwareName ??
          tempSoftwareInfo?.softwareName ??
          '';

        // 从控
        let flowControl = i.fcsInfoMaster?.softwareVersion ?? findMasterInfo?.softwareVersion ?? '-';
        let flowControlName = i.fcsInfoMaster?.softwareName ?? findMasterInfo?.softwareName ?? '';

        return (
          <Form layout="vertical" className={styles.top} key={i.bsa.id} form={eachForm}>
            <Row>
              <Col span={3}>
                <Form.Item label="储能站编号">
                  <span>{i.bsa?.serialNumber ?? '--'}</span>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="储能站名称">
                  {!!!i.bsa.id || i.isEditing ? (
                    <Input
                      maxLength={10}
                      style={{ width: '80%' }}
                      value={i.bsa.name}
                      onChange={e => onBsaEditChange('name', e.target.value, index)}
                    />
                  ) : (
                    <span>{i.bsa.name ?? '--'}</span>
                  )}
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="储能站状态">
                  <span className={i.bsa.status !== Status.ENABLE ? styles.red : ''}>
                    {(i.bsa.status !== undefined && statusFormat[i.bsa.status]) ?? '--'}
                  </span>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="组织编号">
                  <span>{i.customerCode ?? '--'}</span>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="组织名称">
                  <span>{i.bsa.customerName ?? '--'}</span>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="运营单元编号">
                  <span>{i.ouSerialNumber ?? '--'}</span>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="运营单元">
                  <span>{i.bsa.ouName ?? '--'}</span>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="硬件结构版本">
                  <span>{`${i.bsa.hardwareVersion ?? '-'}-${i.bsa.hardwareName ?? ''}`}</span>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="装机容量">
                  {!!!i.bsa.id || i.isEditing ? (
                    <InputNumber
                      style={{ width: '80%' }}
                      placeholder="请输入"
                      formatter={limitDecimalsF}
                      parser={limitDecimalsPThree}
                      max={99999999.999}
                      min={0.001}
                      addonAfter="MWh"
                      step="1"
                      value={i.bsa.installedCapacity}
                      onChange={value => onBsaEditChange('installedCapacity', value as Key, index)}
                      // precision={3}
                    />
                  ) : (
                    <span>{i.bsa.installedCapacity ?? '--'}MWh</span>
                  )}
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="额定功率">
                  {!!!i.bsa.id || i.isEditing ? (
                    <InputNumber
                      style={{ width: '80%' }}
                      placeholder="请输入"
                      formatter={limitDecimalsF}
                      parser={limitDecimalsPThree}
                      max={99999999.999}
                      min={0.001}
                      addonAfter="MW"
                      step="1"
                      value={i.bsa.ratedPower}
                      onChange={value => onBsaEditChange('ratedPower', value as Key, index)}
                    />
                  ) : (
                    <span>{i.bsa.ratedPower ?? '--'}MW</span>
                  )}
                </Form.Item>
              </Col>
              <Divider />
              {[...hardWareEnumArray, ...hardWareEnumArrayWithoutControl].includes(i.bsa.hardwareVersionEnum ?? '') ? (
                <Col span={24}>
                  <Form.Item
                    label="关联FCS"
                    required
                    extra={`FCS软件版本：${softwareVersion}${softwareName ? `-${softwareName}` : ''}`}
                    rules={[{ required: true, message: '请选择FCS' }]}
                  >
                    {i.isToVoid ? (
                      <Select
                        placeholder="请选择"
                        style={{ width: 300 }}
                        disabled={!!!i.bsa.id ? false : !i.isEditing}
                        onSelect={(v: number) => onSelectFcs(v, index)}
                        value={i.fcsId ?? []}
                      >
                        {i.fcsList?.map(m => (
                          <Select.Option value={m.id} key={m.id}>
                            {m.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : i.fcsList && i.fcsList.length !== 0 ? (
                      <Button type="link">
                        <Link
                          target="_blank"
                          to={`/device/fcs/list/detail/${
                            i.fcsList.find(item => item.linkType === FCSLinkBSAType.EXCEPTMASTERORSLAVE)?.id ?? i.fcsId
                          }`}
                        >
                          {i.fcsList.find(item => item.linkType === FCSLinkBSAType.EXCEPTMASTERORSLAVE)?.name ??
                            i.fcsList.find(item => item.id === i.fcsId)?.name}
                        </Link>
                      </Button>
                    ) : (
                      <Select
                        placeholder="请选择"
                        style={{ width: 300 }}
                        disabled={!!!i.bsa.id ? false : !i.isEditing}
                        onSelect={(v: number) => onSelectFcs(v, index)}
                        value={i.fcsId ?? []}
                      >
                        {i.allFcsList?.map(m => (
                          <Select.Option value={m.id} key={m.id}>
                            {m.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              ) : (
                <div>
                  <Col span={24}>
                    <Form.Item
                      label="关联主控FCS"
                      required
                      extra={`FCS软件版本：${flowControl}${flowControlName ? `-${flowControlName}` : ''}`}
                      rules={[{ required: true, message: '请选择FCS' }]}
                    >
                      {i.isToVoid ? (
                        <Select
                          placeholder="请选择"
                          style={{ width: 300 }}
                          showSearch
                          optionFilterProp="label"
                          disabled={!!!i.bsa.id ? false : !i.isEditing}
                          onSelect={(v: number) => {
                            let find = i.fcsList?.find(item => item.id === v);

                            find && setFindMasterInfo(find); // 为了下拉主控的时候显示软件版本
                            find && onSelectMasterFcs(find, v, index);
                          }}
                          value={i.masterFcsId ?? []}
                          options={i.fcsList
                            ?.filter(i => masterFcsList.includes(i?.softwareVersion ?? ''))
                            .map(item => ({
                              label: item.name,
                              value: item.id,
                            }))}
                        />
                      ) : i.fcsList && i.fcsList.length !== 0 ? (
                        <Button type="link">
                          <Link
                            target="_blank"
                            to={`/device/fcs/list/detail/${
                              i.fcsList.find(item => item.linkType === FCSLinkBSAType.MASTER)?.id ?? i.masterFcsId
                            }`}
                          >
                            {i.fcsList.find(item => item.linkType === FCSLinkBSAType.MASTER)?.name ??
                              i.fcsList.find(item => item.id === i.masterFcsId)?.name}
                          </Link>
                        </Button>
                      ) : (
                        <Select
                          placeholder="请选择"
                          style={{ width: 300 }}
                          showSearch
                          optionFilterProp="label"
                          disabled={!!!i.bsa.id ? false : !i.isEditing}
                          onSelect={(v: number) => {
                            let find = i.allFcsList?.find(item => item.id === v);
                            find && setFindMasterInfo(find); // 为了下拉主控的时候显示软件版本
                            find && onSelectMasterFcs(find, v, index);
                          }}
                          value={i.masterFcsId ?? []}
                          options={i.allFcsList
                            ?.filter(i => masterFcsList.includes(i?.softwareVersion ?? ''))
                            .map(item => ({
                              label: item.name,
                              value: item.id,
                            }))}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="关联从控FCS"
                      required
                      extra={`注意：FCS选择后，不可以修改，请仔细检查。`}
                      rules={[{ required: true, message: '请选择从控FCS' }]}
                    >
                      {i.isToVoid ? (
                        <Select
                          mode="multiple"
                          placeholder={!i.masterFcsId ? '请先选择主控FCS' : '请选择'}
                          style={{ width: 300 }}
                          showSearch
                          optionFilterProp="label"
                          disabled={!i.masterFcsId ? true : !!!i.bsa.id ? false : !i.isEditing && !i.masterFcsId}
                          onChange={(v: number[]) => onSelectslaveFcs(v, index)}
                          value={i.slaveFcsIds}
                          options={slaveFcsList.map(item => ({
                            label: `【${item.name}】软件版本：${item.softwareVersion}`,
                            value: item.id,
                          }))}
                        />
                      ) : i.fcsList ? (
                        i.slaveFcsList?.length === 0 ? (
                          <div style={{ display: 'flex' }}>
                            {i.fcsList
                              .filter(m => (i.slaveFcsIds ?? []).includes(m.id!))
                              .map(item => (
                                <div style={{ marginRight: '16px' }}>
                                  <Button type="link">
                                    <Link target="_blank" to={`/device/fcs/list/detail/${item.id}`}>
                                      {item.name}
                                    </Link>
                                  </Button>
                                  <br />
                                  <span>FCS软件版本：{`${item.softwareVersion}-${item.softwareName}`}</span>
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div style={{ display: 'flex' }}>
                            {i.fcsList
                              .filter(i => i.linkType === FCSLinkBSAType.SLAVE)
                              .map(item => (
                                <div style={{ marginRight: '16px' }}>
                                  <Button type="link">
                                    <Link target="_blank" to={`/device/fcs/list/detail/${item.id}`}>
                                      {item.name}
                                    </Link>
                                  </Button>
                                  <br />
                                  <span>FCS软件版本：{`${item.softwareVersion}-${item.softwareName}`}</span>
                                </div>
                              ))}
                          </div>
                        )
                      ) : (
                        <Select
                          mode="multiple"
                          placeholder={!i.masterFcsId ? '请先选择主控FCS' : '请选择'}
                          style={{ width: 300 }}
                          showSearch
                          optionFilterProp="label"
                          disabled={!i.masterFcsId ? true : !!!i.bsa.id ? false : !i.isEditing && !i.masterFcsId}
                          onChange={(v: number[]) => onSelectslaveFcs(v, index)}
                          value={i.slaveFcsIds}
                          options={slaveFcsList.map(item => ({
                            label: `【${item.name}】软件版本：${item.softwareVersion}`,
                            value: item.id,
                          }))}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </div>
              )}
              <Col span={24}></Col>
              <Col>
                <Form.Item label="计量电表" required>
                  <Select
                    placeholder="请输入并选择"
                    showSearch
                    optionFilterProp="label"
                    style={{ width: 300 }}
                    disabled={!i.bsa.id ? false : !i.isEditing}
                    onChange={(v: number) => onSelectDevice(v, index, 'meterDeviceId')}
                    value={i.meterDeviceId}
                    options={i.meterDeviceList?.map(item => ({
                      label: item.name,
                      value: item.id,
                      disabled: [...(i.upstreamTransformerMeterDeviceIds ?? []), i.pvMeterDeviceId].includes(item.id),
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col>
                <Form.Item label="光伏电表">
                  <Select
                    placeholder="请输入并选择"
                    showSearch
                    optionFilterProp="label"
                    style={{ width: 300 }}
                    disabled={!i.bsa.id ? false : !i.isEditing}
                    onChange={(v: number) => onSelectDevice(v, index, 'pvMeterDeviceId')}
                    value={i.pvMeterDeviceId}
                    options={i.pvMeterDeviceList?.map(item => ({
                      label: item.name,
                      value: item.id,
                      disabled: [...(i.upstreamTransformerMeterDeviceIds ?? []), i.meterDeviceId].includes(item.id),
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col>
                <Form.Item label="上端变压器电表">
                  <Select
                    placeholder="请输入并选择"
                    showSearch
                    mode="multiple"
                    optionFilterProp="label"
                    style={{ width: 300 }}
                    disabled={!i.bsa.id ? false : !i.isEditing}
                    onChange={(v: number[]) => onSelectDevice(v, index, 'upstreamTransformerMeterDeviceIds')}
                    value={i.upstreamTransformerMeterDeviceIds}
                    options={i.upstreamTransformerMeterDeviceList?.map(item => ({
                      label: item.name,
                      value: item.id,
                      disabled: [i.meterDeviceId, i.pvMeterDeviceId].includes(item.id),
                    }))}
                  />
                </Form.Item>
              </Col>
              <Divider />
              <Col span={24}>
                <Form.Item
                  label="负载变压器"
                  required
                  rules={[{ required: true, message: '请选择负载变压器' }]}
                  extra={
                    <p className={styles.extra}>
                      <InfoCircleOutlined />
                      注意，储能阵列必须选择一个在【微网电能拓扑】页签配置过的回路，否则不能完成相关配置。如果您没有进行相关配置，请先前往拓扑基础设定将【微网电能拓扑】开关选项选中，然后在【微网电能拓扑】页签设置回路，设置完成后再进行储能阵列的相关配置。请注意：如果该回路没有容量、目标负载、目标负载率以及总计量表和生产负载表其中之一，储能站的策略可能不会生效
                    </p>
                  }
                >
                  {!i.circuitId ? (
                    <Select
                      placeholder="请选择"
                      disabled={!!!i.bsa.id ? false : !i.isEditing}
                      style={{ width: 300 }}
                      onSelect={(v: number) => onSelectLoadTransformerId(v, index)}
                    >
                      {i.circuitList?.map(d => (
                        <Select.Option value={d.id} key={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : i.bsa.id ? (
                    <span>{i.currentCircuit?.name}</span>
                  ) : (
                    <Select
                      placeholder="请选择"
                      style={{ width: 300 }}
                      disabled={!!i.bsa.id}
                      onSelect={(v: number) => onSelectLoadTransformerId(v, index)}
                    >
                      {i.circuitList?.map(d => (
                        <Select.Option value={d.id} key={d.id}>
                          {d.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {i.currentCircuit && (
                <Col span={24}>
                  <Descriptions
                    column={4}
                    layout="vertical"
                    colon={false}
                    className={styles.des}
                    style={{ background: 'transparent' }}
                  >
                    <Descriptions.Item label="容量">{i.currentCircuit?.capacity}kVA</Descriptions.Item>
                    <Descriptions.Item label="目标负载">{i.currentCircuit?.targetLoad ?? '--'}kWh</Descriptions.Item>
                    <Descriptions.Item label="目标负载率">
                      {i.currentCircuit.targetLoad
                        ? ((i.currentCircuit.targetLoad / i.currentCircuit.capacity!) * 100).toFixed(2)
                        : '--'}
                      %
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              )}
            </Row>
            {renderFunc(i.currentCircuit)}

            {edit ? (
              <Space style={{ marginRight: '3px' }}>
                <Button type="primary" disabled={i.isEditing || !!!i.bsa.id} onClick={() => setEachEdit(index)}>
                  编辑
                </Button>
              </Space>
            ) : (
              <></>
            )}
            {(i.isEditing || !i.bsa.id) && (
              <Space>
                <Button type="primary" onClick={() => onSubmit(i, index)}>
                  保存
                </Button>
                <Button onClick={() => onDelete(index, i.bsa.id)}>取消</Button>
              </Space>
            )}
          </Form>
        );
      })}
      <Modal
        title="新建储能站"
        open={visible}
        destroyOnClose
        onCancel={() => {
          setVisible(false);
          form.resetFields();
          setExtraMessage(undefined);
        }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select placeholder="请选择">
              {ouList?.map(i => (
                <Select.Option value={i.id} key={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="储能阵列名称"
            name="name"
            rules={[
              { required: true, message: '请输入储能阵列名称' },
              { max: 10, message: '最多10个字符' },
              { pattern: /^[A-Za-z0-9\u4e00-\u9fa5-]*$/, message: '只支持汉字、字母、数字、-' },
            ]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item
            label="硬件结构版本"
            name="hardwareVersion"
            rules={[{ required: true, message: '请选择硬件结构版本' }]}
            extra={extraMessage}
          >
            <Select
              placeholder="请选择"
              onChange={v =>
                hardWareEnumArray.includes(getObjectKey(hardWareValueDisplay, v))
                  ? setExtraMessage('支持PCS数量：单PCS')
                  : setExtraMessage('支持PCS数量：多PCS')
              }
            >
              {Object.values(hardWareValueDisplay).map(i => (
                <Select.Option value={i} key={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="装机容量" name="installedCapacity" rules={[{ required: true, message: '请输入装机容量' }]}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              formatter={limitDecimalsF}
              parser={limitDecimalsPThree}
              max={99999999.999}
              min={0.001}
              addonAfter="MWh"
              step="1"
              // precision={3}
            />
          </Form.Item>
          <Form.Item label="额定功率" name="ratedPower" rules={[{ required: true, message: '请输入额定功率' }]}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              formatter={limitDecimalsF}
              parser={limitDecimalsPThree}
              max={99999999.999}
              min={0.001}
              addonAfter="MW"
              step="1"
              // precision={3}
            />
          </Form.Item>
        </Form>
      </Modal>
      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                  onOk: () => {
                    forceUpdate();
                    setEdit(false);
                  },
                });
              }}
            >
              取消
            </Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>
    </>
  );
};

export default BSA;
