import { fetch, PromiseWithErrorHandler } from '@maxtropy/components';
import { EdgeDeviceTemplatePoint } from '../components/EdgeDevicePointInfo';
import { MockingbirdDeviceProps } from '../components/DataAcquisitionForm/types';
import { DriveType, IotProtocolType } from '../types';

interface PointLockBody {
  tenantMcid?: string;
  deviceTypeId: number;
  iotProtocol: number;
  driveType: number;
  deviceIds: number[];
  pointType?: number;
  hasProperty?: boolean;
  dataPropertyIds?: number[];
  parameter?: string[];
  points: EdgeDeviceTemplatePoint[];
  serial: string;
}

export const batchAddPointLock = (body: PointLockBody) =>
  fetch<{ serial: string }>(
    '/api/v2/batch/edgeDevice/point/add/lock',
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'x-tenant-mcid': body.tenantMcid ?? '',
      },
    },
    true
  );

export const bathAddPoint = (body: FormData) => {
  const headers: Record<string, string> = {
    'Content-Type': 'multipart/form-data',
  };
  const tenantMcid = body.get('tenantMcid');
  if (tenantMcid) {
    headers['x-tenant-mcid'] = (body.get('tenantMcid') ?? '') as string;
  }
  return fetch<{ key: string }>(
    `/api/v2/batch/edgeDevice/point/add/input`,
    {
      method: 'POST',
      body: body,
      headers,
    },
    true
  );
};

interface UpdateLockBody extends MockingbirdDeviceProps {
  tenantMcid?: string;
  deviceTypeId: number;
  iotProtocol: IotProtocolType;
  deviceIds: number[];
  serial: string;
  driveType: DriveType;
  updateItems: string[];
}

export const batchDriveTypeFormLock = (body: UpdateLockBody, tenantMcid: string) =>
  fetch<{ serial: string }>(
    '/api/v2/batch/edgeDevice/update/lock',
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'x-tenant-mcid': tenantMcid,
      },
    },
    true
  );

export const bathUpdateDriveTypeForm = (body: FormData) => {
  const headers: Record<string, string> = {
    'Content-Type': 'multipart/form-data',
  };
  const tenantMcid = body.get('tenantMcid');
  if (tenantMcid) {
    headers['x-tenant-mcid'] = (body.get('tenantMcid') ?? '') as string;
  }
  return fetch<{ key: string }>(
    `/api/v2/batch/edgeDevice/update/input`,
    {
      method: 'POST',
      body: body,
      headers,
    },
    true
  );
};

export const batchUpdatePointLock = (body: PointLockBody) =>
  fetch<{ serial: string }>(
    '/api/v2/batch/edgeDevice/point/update/lock',
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'x-tenant-mcid': body.tenantMcid ?? '',
      },
    },
    true
  );

export const bathUpdatePoint = (body: FormData) => {
  const headers: Record<string, string> = {
    'Content-Type': 'multipart/form-data',
  };
  const tenantMcid = body.get('tenantMcid');
  if (tenantMcid) {
    headers['x-tenant-mcid'] = (body.get('tenantMcid') ?? '') as string;
  }
  return fetch<{ key: string }>(
    `/api/v2/batch/edgeDevice/point/update/input`,
    {
      method: 'POST',
      body: body,
      headers,
    },
    true
  );
};

export const bathDeletePoint = (body: FormData) => {
  const headers: Record<string, string> = {
    'Content-Type': 'multipart/form-data',
  };
  const tenantMcid = body.get('tenantMcid');
  if (tenantMcid) {
    headers['x-tenant-mcid'] = (body.get('tenantMcid') ?? '') as string;
  }

  return fetch<{ key: string }>(
    `/api/v2/batch/edgeDevice/point/delete`,
    {
      method: 'POST',
      body: body,
      headers,
    },
    true
  );
};

export const getBatchDeviceResult = (key: string): PromiseWithErrorHandler<{ hasResult: boolean }> =>
  fetch(
    `/api/v2/batch/edgeDevice/result`,
    {
      method: 'POST',
      body: JSON.stringify({ key }),
    },
    true
  );
