import { UetInfoProps } from '@/api/uet';
import FixedBottomButton from '@/components/FixedBottomButton';
import { PlusOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, Modal, Table, useTenantPermissions, useUpdate } from '@maxtropy/components';
import { Space } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PermissionsType } from '@/common/permissionsConst';
import { useRequest } from 'ahooks';
import { apiV2WindPowerStationListPost, V2WindPowerStationListPostResponse } from '@maxtropy/device-customer-apis-v2';
import WindPowerPlantNewModal from './WindPowerPlantNewModal';

const otherColumns = [
  {
    title: '风电站名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联回路',
    dataIndex: 'circuitName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '风力发电机组',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export type ItemProps = Exclude<V2WindPowerStationListPostResponse['list'], undefined>[number];

interface Props {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const WindPowerPlant: FC<Props> = props => {
  const { info, isDetail } = props;
  const { id, key: routerKey } = useParams<{ id: string; key: string }>();
  const [update, forceUpdate] = useUpdate();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const btnDisabled = isDetail || !edit;
  const [currentRowInfo, setCurrentRowInfo] = useState<ItemProps>();

  const { data: list, loading } = useRequest(
    async () => {
      const res = await apiV2WindPowerStationListPost({
        id: Number(id),
      });
      return res.list;
    },
    {
      ready: !!id && !!info?.mcid,
      refreshDeps: [id, info?.mcid, update],
    }
  );

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      ellipsis: { showTitle: true },
      render: (record: any) => (
        <Button
          type="link"
          disabled={btnDisabled}
          onClick={() => {
            setCurrentRowInfo(record);
            setModalVisible(true);
          }}
        >
          编辑风电站
        </Button>
      ),
    },
  ];

  const onCancel = () => {
    Modal.confirm({
      title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
      onOk: () => {
        forceUpdate();
        setEdit(false);
      },
    });
  };

  return (
    <>
      <Button type="primary" onClick={() => setModalVisible(true)} disabled={btnDisabled}>
        <PlusOutlined />
        新建风电站
      </Button>

      <Table
        loading={loading}
        style={{ marginTop: 12 }}
        rowKey="id"
        dataSource={list}
        columns={columns}
        pagination={false}
      />
      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={() => setEdit(false)}>
              保存
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>
      {modalVisible && (
        <WindPowerPlantNewModal
          modalVisible={modalVisible}
          setModalVisible={val => setModalVisible(val)}
          ouList={info?.ous}
          currentRowInfo={currentRowInfo}
          setCurrentRowInfo={val => setCurrentRowInfo(val)}
          forceUpdate={() => forceUpdate()}
        />
      )}
    </>
  );
};

export default WindPowerPlant;
