import { Divider } from 'antd';
import { Dayjs } from 'dayjs';
import React, { FC, useMemo } from 'react';
import Chart from '../Chart';
import { getEnergyMediumLossChartOption } from '../Chart/lossChartOptions';
import { LossDataPostItemResponse } from '../LossDuringTransAndDisProcess';
import { MediumIndicatorDisplayUnit } from '@/api/energyMedium';

export interface ILossEcharts {
  item?: LossDataPostItemResponse;
  statisticsDataLoading: boolean;
  processId?: React.Key;
  rangeDate?: [Dayjs, Dayjs];
  mediumIndicatorDisplayUnitData?: MediumIndicatorDisplayUnit;
}

const LossEcharts: FC<ILossEcharts> = props => {
  const { item, mediumIndicatorDisplayUnitData, statisticsDataLoading } = props;

  const option = useMemo(
    () =>
      getEnergyMediumLossChartOption(
        item?.processLossViewVoToIns,
        item?.energyMediumIndicatorId,
        mediumIndicatorDisplayUnitData,
        item?.energyMediumName
      ),
    // selectedLabel,
    // selectedIndicatorId,
    // selectedAverageValue,
    // outPutIndicatorId,
    // chartData,
    // mediumIndicatorDisplayUnitData
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [item]
  );

  return (
    <>
      <div style={{ marginTop: 20 }}>
        <Chart height={450} loading={statisticsDataLoading} chartData={item?.processLossViewVoToIns} option={option} />
      </div>

      <Divider style={{ marginTop: 24 }} />
    </>
  );
};

export default LossEcharts;
