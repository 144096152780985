import { Form, Input, InputNumber, Modal, Select } from '@maxtropy/components';
import React, { FC, useEffect, useMemo } from 'react';
import { ItemProps } from '.';
import { OuInfoProps } from '@/api/ou';
import { useRequest } from 'ahooks';
import {
  apiV2CircuitListPost,
  apiV2WindPowerStationCreatePost,
  apiV2WindPowerStationDevicesPost,
  apiV2WindPowerStationUpdatePost,
} from '@maxtropy/device-customer-apis-v2';
import { useParams } from 'react-router-dom';
import { useWatch } from 'antd/es/form/Form';

const formLayout = {
  labelCol: { flex: '120px' },
};

export interface IWindPowerPlantNewModal {
  modalVisible?: boolean;
  currentRowInfo?: ItemProps;
  ouList?: OuInfoProps[];
  setModalVisible: (v: boolean) => void;
  setCurrentRowInfo: (v: undefined) => void;
  forceUpdate: () => void;
}

const WindPowerPlantNewModal: FC<IWindPowerPlantNewModal> = props => {
  const { modalVisible, currentRowInfo, ouList, setModalVisible, setCurrentRowInfo, forceUpdate } = props;
  const [form] = Form.useForm();
  const ouId = useWatch('ouId', form);
  const { id } = useParams<{ id: string }>(); // 获取uetid

  const requestApi = useMemo(() => {
    return currentRowInfo ? apiV2WindPowerStationUpdatePost : apiV2WindPowerStationCreatePost;
  }, [currentRowInfo]);
  const { runAsync, loading } = useRequest(params => requestApi(params), {
    manual: true,
  });

  // 回路list
  const { data: circuitListData } = useRequest(
    async () => {
      return await apiV2CircuitListPost({
        ouId,
        uetId: id,
      });
    },
    {
      ready: !!modalVisible && !!ouId && !!id,
      refreshDeps: [modalVisible, ouId, id],
    }
  );

  // 根据ouId查询风电机组设备
  const { data: deviceListData } = useRequest(
    async () => {
      return await apiV2WindPowerStationDevicesPost({
        id: ouId,
      });
    },
    {
      ready: !!modalVisible && !!ouId,
      refreshDeps: [modalVisible, ouId],
    }
  );

  // 回路options
  const circuitListOptions = useMemo(() => {
    if (circuitListData && circuitListData.list) {
      return circuitListData.list.map(i => ({ label: i.name, value: i.id }));
    }
  }, [circuitListData]);

  // 风电机组设备options
  const deviceListOptions = useMemo(() => {
    if (deviceListData && deviceListData.list) {
      return deviceListData.list.map(i => ({ label: i.name, value: i.id }));
    }
  }, [deviceListData]);

  useEffect(() => {
    if (currentRowInfo && modalVisible) {
      console.log('currentRowInfo', currentRowInfo);
      form.setFieldsValue({
        ...currentRowInfo,
      });
    }
  }, [currentRowInfo, form, modalVisible]);

  const onOk = () => {
    form.validateFields().then(values => {
      const params = {
        ...values,
        uetId: +id!,
      };
      if (currentRowInfo) params.id = currentRowInfo.id;

      runAsync(params).then(() => {
        form.resetFields();
        setModalVisible(false);
        setCurrentRowInfo(undefined);
        forceUpdate();
      });
    });
  };

  const onCancel = () => {
    form.resetFields();
    setModalVisible(false);
    setCurrentRowInfo(undefined);
  };

  const title = useMemo(() => {
    return currentRowInfo ? '编辑风电站' : '新建风电站';
  }, [currentRowInfo]);

  return (
    <>
      <Modal open={modalVisible} confirmLoading={loading} title={title} onCancel={onCancel} onOk={onOk} destroyOnClose>
        <Form form={form} {...formLayout} style={{ marginTop: 24 }}>
          <Form.Item label="运营单元" name="ouId" rules={[{ required: true, message: '请选择运营单元' }]}>
            <Select
              disabled={!!currentRowInfo}
              optionFilterProp="label"
              showSearch
              placeholder="请选择"
              onChange={() =>
                form.setFieldsValue({
                  circuitId: undefined,
                  deviceId: undefined,
                })
              }
              options={(ouList ?? []).map(item => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="风电站名称"
            name="name"
            rules={[
              { required: true, message: '请输入风电站名称' },
              { max: 30, message: '最多输入30个字符' },
            ]}
          >
            <Input placeholder="请输入" allowClear />
          </Form.Item>

          <Form.Item label="关联回路" name="circuitId" rules={[{ required: true, message: '请选择关联回路' }]}>
            <Select placeholder="请选择" options={circuitListOptions} />
          </Form.Item>

          <Form.Item label="风力发电机组" name="deviceId" rules={[{ required: true, message: '请选择风力发电机组' }]}>
            <Select placeholder="请选择" options={deviceListOptions} />
          </Form.Item>

          <Form.Item
            label="碳排放因子"
            name="carbonEmissionFactor"
            rules={[{ required: true, message: '请输入碳排放因子' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              max={999999999.99}
              min={0}
              precision={2}
              addonAfter="kWh/kgCO2e"
            />
          </Form.Item>
          <Form.Item label="装机容量" name="installedCapacity" rules={[{ required: true, message: '请输入装机容量' }]}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              max={999999999.99}
              min={0}
              precision={2}
              addonAfter="kW"
            />
          </Form.Item>
          <Form.Item label="标准煤耗" name="standardCoalFactor" rules={[{ required: true, message: '请输入标准煤耗' }]}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="请输入"
              max={999999999.99}
              min={0}
              addonAfter="kgce/kWh"
              precision={2}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default WindPowerPlantNewModal;
