import { RadioChangeEvent, Space } from 'antd';
import React, { createContext, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import { getEnergyMediumList } from '@/api/plustekSankey';
import { Button, DatePicker, Key, Radio } from '@maxtropy/components';
import { EnergyMediumIconDisplay, EnergyMediumSceneIds } from '@/pages/ProductionEnergyConsumptionBoard/chartConfigs';
import styles from './index.module.scss';
import { DateType, DateTypeDayjs, DateTypeDisplay } from '@/pages/WaterUseOverview/utils';
import dayjs, { Dayjs } from 'dayjs';
import TotalPie from './components/TotalPie';
import UnitRank from './components/UnitRank';
import {
  apiV2EnergyMediumListByUnitLevel1Post,
  apiV2EnergyUnitOverviewAggregatedKgcePost,
  apiV2EnergyUnitOverviewAggregatedPost,
} from '@maxtropy/device-customer-apis-v2';
import FooterTip from '../FooterTip';

export interface IEnergyAnalysisOverview {
  groupId: number | undefined;
}

const { RangePicker } = DatePicker;

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

const EnergyAnalysisOverview: FC<IEnergyAnalysisOverview> = props => {
  const { groupId } = props;
  const [mediumId, setMediumId] = useState<Key>();
  const [dates, setDates] = useState<RangeValue>(null);
  const [timeResolution, setTimeResolution] = useState<DateType>(DateType.DAY);
  // 日期选择器默认选择当天
  const [value, setValue] = useState<RangeValue>([dayjs(), dayjs()]);
  const [displayValue, setDisplayValue] = useState<any>(dayjs());
  const [rangeTime, setRangeTime] = useState<RangeValue>();
  const timer = useRef<any>();

  // 切换groupId重置mediumId
  useEffect(() => {
    setMediumId(undefined);
    if (groupId) {
      timer.current = setTimeout(() => {
        setMediumId('total');
      }, 0);
    }
    return () => clearTimeout(timer.current);
  }, [groupId]);

  // format startDate endDate
  useEffect(() => {
    if (timeResolution === DateType.CUSTOM) {
      setRangeTime([value?.[0]?.startOf('D') ?? null, value?.[1]?.endOf('D') ?? null]);
    } else {
      const startTime = displayValue?.startOf(DateTypeDayjs[timeResolution]);
      const endTime = dayjs().isBefore(displayValue?.endOf(DateTypeDayjs[timeResolution]))
        ? dayjs()
        : displayValue?.endOf(DateTypeDayjs[timeResolution]);
      setRangeTime([startTime, endTime]);
    }
  }, [value, timeResolution, displayValue]);

  // 调用接口查询总数据
  const { data } = useRequest(
    async () => {
      if (mediumId) {
        if (mediumId === 'total') {
          return await apiV2EnergyUnitOverviewAggregatedKgcePost({
            energyUnitGroupId: groupId,
            startDate: rangeTime?.[0]?.format('YYYY-MM-DD'),
            endDate: rangeTime?.[1]?.format('YYYY-MM-DD'),
          });
        } else {
          return await apiV2EnergyUnitOverviewAggregatedPost({
            energyMediumId: mediumId,
            energyUnitGroupId: groupId,
            startDate: rangeTime?.[0]?.format('YYYY-MM-DD'),
            endDate: rangeTime?.[1]?.format('YYYY-MM-DD'),
          });
        }
      }
    },
    {
      ready: !!rangeTime,
      refreshDeps: [rangeTime, mediumId],
    }
  );

  const { data: mediumList } = useRequest(
    async () => {
      const res = await apiV2EnergyMediumListByUnitLevel1Post({ energyUnitGroupId: groupId! });
      return res.list;
    },
    {
      ready: !!groupId,
      refreshDeps: [groupId],
    }
  );

  // 能源介质+综合能耗
  const mediumOptions = useMemo(() => {
    const res: {
      name?: string;
      id?: Key;
    }[] = (mediumList ?? []).map(item => ({
      name: item.name,
      id: item.id,
      // sceneId: item.sceneId,
    }));
    res.unshift({
      name: '综合能耗',
      id: 'total',
    });
    return res;
  }, [mediumList]);

  const onRadioChange = (e: RadioChangeEvent) => {
    const value: DateType = e.target.value;
    setTimeResolution(value);
    if (value !== DateType.CUSTOM) {
      setDisplayValue(dayjs());
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  // 时间跨度为31天
  const customDisabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 31;
    const tooEarly = current && current > dayjs().endOf('day');
    return !!tooEarly || !!tooLate;
  };

  return (
    <div className={styles.wrapper}>
      <Space style={{ padding: '20px 12px' }} size="small" wrap>
        {mediumOptions.map(medium => (
          <Button
            key={medium.id}
            style={{ paddingLeft: 12, paddingRight: 16 }}
            onClick={() => {
              setMediumId(medium.id!);
            }}
            className={`${mediumId}` === `${medium.id}` ? styles.titleButtonChecked : styles.titleButtonUnChecked}
          >
            <Space size="small" align="center">
              {/* <img
                width={24}
                src={
                  mediumId === medium.value
                    ? EnergyMediumIconDisplay[medium.value as EnergyMediumSceneIds].active
                    : EnergyMediumIconDisplay[medium.value as EnergyMediumSceneIds].passive
                }
                alt=""
              /> */}
              <span>{medium.name}</span>
            </Space>
          </Button>
        ))}
      </Space>
      {/* 日期选择器 */}
      <Space size={8} style={{ padding: '0px 12px' }}>
        <Radio.Group onChange={onRadioChange} value={timeResolution} buttonStyle="solid">
          <Radio.Button value={DateType.DAY}>{DateTypeDisplay[DateType.DAY]}</Radio.Button>
          <Radio.Button value={DateType.MONTH}>{DateTypeDisplay[DateType.MONTH]}</Radio.Button>
          <Radio.Button value={DateType.YEAR}>{DateTypeDisplay[DateType.YEAR]}</Radio.Button>
          <Radio.Button value={DateType.CUSTOM}>{DateTypeDisplay[DateType.CUSTOM]}</Radio.Button>
        </Radio.Group>
        {timeResolution === DateType.DAY && (
          <DatePicker
            style={{ width: '250px' }}
            value={displayValue}
            disabledDate={disabledDate}
            onChange={setDisplayValue}
            allowClear={false}
          />
        )}
        {timeResolution === DateType.MONTH && (
          <DatePicker
            picker="month"
            style={{ width: '250px' }}
            value={displayValue}
            disabledDate={disabledDate}
            onChange={setDisplayValue}
            allowClear={false}
          />
        )}
        {timeResolution === DateType.YEAR && (
          <DatePicker
            picker="year"
            style={{ width: '250px' }}
            value={displayValue}
            disabledDate={disabledDate}
            onChange={setDisplayValue}
            allowClear={false}
          />
        )}
        {timeResolution === DateType.CUSTOM && (
          <RangePicker
            style={{ width: '250px' }}
            value={dates || value}
            disabledDate={customDisabledDate}
            onCalendarChange={val => {
              setDates(val);
            }}
            onChange={val => {
              setValue(val);
            }}
            onOpenChange={onOpenChange}
            allowClear={false}
          />
        )}
      </Space>
      <div>
        <TotalPie data={data} mediumId={mediumId} />
      </div>
      <div>
        <UnitRank data={data} mediumId={mediumId} />
      </div>
      <FooterTip dates={dates} />
    </div>
  );
};

export default EnergyAnalysisOverview;
