import { Button, Form, Input, Modal, PopConfirm, Select, useAsync } from '@maxtropy/components';
import React, { FC, useEffect, useMemo } from 'react';
import { useRequest } from 'ahooks';
import {
  apiV2ExternalDetailPost,
  apiV2ExternalDeviceListPost,
  apiV2ExternalPlatformDeviceListPost,
  apiV2ExternalPlatformDevicePropertyListPost,
  apiV2ExternalProtocolListPost,
} from '@maxtropy/device-customer-apis-v2';
import { Cascader, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { dataTypeList, findPathById, processValue, ProtocolDataType } from '../../utils';
import { formatOptionData } from '@/shared/components/CascadingMultipleSelector/utils';
import { getDeviceTypeTree } from '@/api/deviceType';
import { DefaultOptionType } from 'antd/es/cascader';
import UnitSelect from '../UnitSelect';
import { isNil } from 'lodash-es';

export interface formSubmitProps {
  protocol: string;
  deviceId: number;
  thirdPartyUniqueIdentifier: string;
  deviceTypeId: number[];
  propertyBindItems: any[];
}

export interface ICreateOrEditDeviceModal {
  editId?: number;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: formSubmitProps) => Promise<void>;
}

const keyValueRegex = /^(\w+:\w+)(;\w+:\w+)*$/;

const CreateOrEditDeviceModal: FC<ICreateOrEditDeviceModal> = props => {
  const { editId, visible, onCancel, onSubmit } = props;
  const [form] = Form.useForm();

  const protocol = Form.useWatch('protocol', form);
  const deviceTypeId = Form.useWatch('deviceTypeId', form);
  const deviceId = Form.useWatch('deviceId', form);
  const propertyBindItems = Form.useWatch('propertyBindItems', form);

  const deviceTypeData = useAsync(getDeviceTypeTree);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  // 编辑查询详情
  const { data: detail } = useRequest(
    () => {
      return apiV2ExternalDetailPost({
        id: editId,
      });
    },
    {
      ready: !!editId && visible,
      refreshDeps: [editId, visible],
    }
  );

  // 编辑的时候表达赋值
  useEffect(() => {
    if (!detail) return;
    const deviceTypeIdPath = findPathById(formatOptionData(deviceTypeData), detail.deviceTypeId);
    form.setFieldsValue({
      protocol: detail.protocol,
      thirdPartyUniqueIdentifier: detail.thirdPartyUniqueIdentifier,
      deviceTypeId: deviceTypeIdPath,
      deviceId: detail.deviceId,
      propertyBindItems: (detail.propertyBindItems ?? []).map(item => ({
        ...item,
        valueMaps: item.valueMaps ? convertArrayToString(item.valueMaps) : undefined,
      })),
    });
  }, [detail, deviceTypeData]);

  // 转换
  const convertArrayToString = (arr?: any[]) => {
    return (arr ?? [])
      .map(item => `${item.originalValue}:${item.convertedValue}`) // 拼接 key:value
      .join(';'); // 用分号连接
  };

  // 协议列表
  const { data: protocolList } = useRequest(
    async () => {
      const res = await apiV2ExternalProtocolListPost({});
      return res?.list ?? [];
    },
    { ready: visible }
  );

  const isSelect = useMemo(() => {
    if (!!protocol && protocolList?.length) {
      return protocolList?.find(i => i.name === protocol)?.protocolDataType === ProtocolDataType.SELECT;
    }
    return false;
  }, [protocol, protocolList]);

  // 查询可选设备列表
  const { data: deviceList } = useRequest(
    async () => {
      const res = await apiV2ExternalDeviceListPost({ protocol });
      return res?.list ?? [];
    },
    { ready: visible && isSelect, refreshDeps: [visible, isSelect] }
  );

  // 查询三方平台设备列表
  const { data: platformDeviceList } = useRequest(
    async () => {
      const res = await apiV2ExternalPlatformDeviceListPost({
        deviceTypeIds: processValue(deviceTypeId),
        protocol,
      });
      return res?.list ?? [];
    },
    { ready: visible && !!deviceTypeId && !!protocol, refreshDeps: [visible, deviceTypeId, protocol] }
  );

  // 平台设备数采点
  const { data: platformPropertyList } = useRequest(
    async () => {
      const res = await apiV2ExternalPlatformDevicePropertyListPost({ id: deviceId });
      return res?.list ?? [];
    },
    { ready: visible && !!deviceId, refreshDeps: [visible, deviceId] }
  );

  const onOK = async () => {
    const values = await form.validateFields();
    onSubmit(values);
  };

  const disabledItem = useMemo(() => {
    return (propertyBindItems ?? []).map((item: any) => item?.dataPropertyId).filter((i?: number) => !isNil(i));
  }, [propertyBindItems]);

  return (
    <Modal
      contentClassName="modal-form-content"
      size="large"
      destroyOnClose={true}
      open={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={onOK}
      title={editId ? '编辑' : '新增'}
      maskClosable={false}
    >
      <Form form={form} labelAlign="left">
        <Form.Item name="protocol" label="协议" rules={[{ required: true, message: '请选择协议' }]}>
          <Select
            allowClear
            placeholder="请选择协议"
            showSearch
            optionFilterProp="label"
            style={{ width: '100%' }}
            options={(protocolList ?? []).map(v => ({
              value: v.name,
              label: v.name,
            }))}
            onChange={() => form.setFieldValue('thirdPartyUniqueIdentifier', undefined)}
          />
        </Form.Item>
        <Form.Item
          name="thirdPartyUniqueIdentifier"
          label="三方设备编码"
          rules={[{ required: true, message: '请选择三方设备编码' }]}
        >
          {protocolList?.find(i => i.name === protocol)?.protocolDataType === ProtocolDataType.SELECT ? (
            <Select
              options={(deviceList ?? []).map(item => ({
                value: item.code,
                label: item.name,
              }))}
              placeholder="请选择"
            />
          ) : (
            <Input placeholder="请输入" maxLength={50} />
          )}
        </Form.Item>
        <Form.Item name="deviceTypeId" label="所属类目" rules={[{ required: true, message: '请选择所属类目' }]}>
          <Cascader
            options={formatOptionData(deviceTypeData)}
            allowClear={false}
            fieldNames={{ children: 'child' }}
            showSearch={{ filter }}
            placeholder={'请选择所属类目'}
            onChange={() => form.setFieldValue('deviceId', undefined)}
          />
        </Form.Item>
        <Form.Item name="deviceId" label="平台设备" rules={[{ required: true, message: '请选择平台设备' }]}>
          <Select
            options={(platformDeviceList ?? []).map(item => ({
              value: item.id,
              label: item.name,
              disabled: item.disabled,
            }))}
            placeholder="请选择"
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>

        <Form.Item label="数据点关联" required>
          <Form.List name="propertyBindItems" layout="vertical">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row key={index} gutter={16} style={{ marginRight: -32 }}>
                    <Col span={7}>
                      <Form.Item label={index === 0 ? '三方设备数采点编码' : ''} key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'thirdPartyPropertyCode']}
                          rules={[
                            {
                              required: true,
                              message: '请输入',
                            },
                            { min: 1, message: '至少输入1个字符' },
                            { max: 50, message: '最多输入50个字符' },
                          ]}
                          noStyle
                        >
                          <Input placeholder="请输入" />
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={index === 0 ? '平台设备数采点' : ''} key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'dataPropertyId']}
                          rules={[
                            {
                              required: true,
                              message: '请选择',
                            },
                          ]}
                          noStyle
                        >
                          <Select
                            options={(platformPropertyList ?? []).map(item => ({
                              value: item.id,
                              label: item.id + '-' + item.name,
                              disabled: disabledItem.includes(item.id),
                            }))}
                            showSearch
                            optionFilterProp="label"
                            placeholder="请选择"
                            onChange={() =>
                              form.setFieldValue(['propertyBindItems', name, 'physicalUnitId'], undefined)
                            }
                          />
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item label={index === 0 ? '数采类型' : ''} key={key}>
                        <Form.Item
                          {...restField}
                          name={[name, 'dataType']}
                          rules={[
                            {
                              required: true,
                              message: '请选择',
                            },
                          ]}
                          noStyle
                        >
                          <Select options={dataTypeList} placeholder="请选择" />
                        </Form.Item>
                      </Form.Item>
                    </Col>

                    <Form.Item
                      noStyle
                      dependencies={[
                        ['propertyBindItems', name, 'dataType'],
                        ['propertyBindItems', name, 'dataPropertyId'],
                      ]}
                    >
                      {({ getFieldValue }) => {
                        const type = getFieldValue(['propertyBindItems', name, 'dataType']);
                        const dataPropertyId = getFieldValue(['propertyBindItems', name, 'dataPropertyId']);
                        return type === 1 ? (
                          <Col span={4}>
                            <Form.Item label={index === 0 ? '单位' : ''} key={key}>
                              <Form.Item
                                {...restField}
                                name={[name, 'physicalUnitId']}
                                rules={[
                                  {
                                    required: true,
                                    message: '请选择',
                                  },
                                ]}
                                noStyle
                              >
                                <UnitSelect dataPropertyId={dataPropertyId} />
                              </Form.Item>
                            </Form.Item>
                          </Col>
                        ) : (
                          <Col span={4}>
                            <Form.Item label={index === 0 ? '单位' : ''} key={key}>
                              <Form.Item
                                {...restField}
                                name={[name, 'valueMaps']}
                                rules={[
                                  {
                                    required: true,
                                    message: '请输入',
                                  },
                                  { pattern: keyValueRegex, message: '格式不正确，应为key:value;key:value' },
                                ]}
                                noStyle
                              >
                                <Input.TextArea placeholder="请输入" />
                              </Form.Item>
                            </Form.Item>
                          </Col>
                        );
                      }}
                    </Form.Item>

                    <Col span={2}>
                      <Form.Item label={index === 0 ? '操作' : ''} required={false} key={key}>
                        <PopConfirm
                          title="确定删除该行数据？"
                          onConfirm={() => {
                            remove(name);
                          }}
                        >
                          <Button style={{ width: 'auto', paddingLeft: 0 }} type="link">
                            删除
                          </Button>
                        </PopConfirm>
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Row gutter={8}>
                  <Col span={23}>
                    <Button type="dashed" ghost icon={<PlusOutlined />} onClick={() => add()}>
                      新增
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrEditDeviceModal;
