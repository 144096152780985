import { Radio, DatePicker, Form } from '@maxtropy/components';
import { useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';
import { DatePickerProps, Space, Cascader } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { formateCommon } from '../..';
import { apiV2ProductionBaseCenterHierarchyPost } from '@maxtropy/device-customer-apis-v2';
interface Iprops {
  form: any;
  setChoseWorkCenterIds: (val: number[]) => void;
  choseWorkerCenterIds?: number[];
  currentDateType: string;
  setCurrentDateType: (val: string) => void;
  setCurrentDate: (val: string) => void;
  setStartDate: (val: string) => void;
  setEndDate: (val: string) => void;
  setChoseProductionBaseId: (val: number) => void;
  setBaseCenterHierarchyOptions: (val: baseCenterTypes[]) => void;
  BaseCenterHierarchyOptions?: any;
  currentDate: string;
  choseproductionBaseId?: number;
  isFull: boolean;
}
export interface baseCenterTypes {
  name?: string;
  disabled?: boolean;
  workCenterHierarchy?: workCenterHierarchyType[];
  id?: number;
  code?: string;
  iconKey?: string;
  sequence?: number;
}
export interface workCenterHierarchyType {
  name?: string;
  id?: number;
  code?: string;
  iconKey?: string;
  sequence?: number;
  productionBaseId?: number;
}
const WorkCenterTop: React.FC<Iprops> = ({
  form,
  currentDateType,
  setCurrentDateType,
  setCurrentDate,
  setStartDate,
  setEndDate,
  currentDate,
  setChoseProductionBaseId,
  choseproductionBaseId,
  choseWorkerCenterIds,
  setChoseWorkCenterIds,
  setBaseCenterHierarchyOptions,
  BaseCenterHierarchyOptions,
  isFull,
}) => {
  // const tabButtonGroupRef = useRef<TabButtonGroupRef>();
  const [datePickerValueMonth, setDatePickerValueMonth] = useState<Dayjs>(dayjs(dayjs(), 'YYYY-MM'));

  const memoryData = useRef<Map<string, dayjs.Dayjs>>(new Map());

  memoryData.current.set(currentDateType, dayjs(currentDate, formateCommon[currentDateType]));

  useEffect(() => {
    apiV2ProductionBaseCenterHierarchyPost({}).then(res => {
      const mappedData = res.list?.map(item => ({
        ...item,
        name: `${item.code}-${item.name}`,
        disabled: isNil(item.workCenterHierarchy) ? true : false,
        workCenterHierarchy: (item?.workCenterHierarchy ?? [])?.map(hierarchyItem => ({
          ...hierarchyItem,
          name: `${hierarchyItem.code}-${hierarchyItem.name}`,
        })),
      }));
      setBaseCenterHierarchyOptions(mappedData as baseCenterTypes[]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 级联框生产基地
  useEffect(() => {
    if (!isFull) {
      if (
        BaseCenterHierarchyOptions &&
        BaseCenterHierarchyOptions.length > 0 &&
        choseWorkerCenterIds &&
        choseWorkerCenterIds.length === 0
      ) {
        const findData = BaseCenterHierarchyOptions.find(
          (i: baseCenterTypes) => i.id === BaseCenterHierarchyOptions[0].id
        );
        if (isNil(findData) || isNil(findData?.workCenterHierarchy)) return;
        const resultMap = findData.workCenterHierarchy.map((item: workCenterHierarchyType) => [
          BaseCenterHierarchyOptions[0].id,
          item.id,
        ]);
        form.setFieldsValue({
          workCenterIds: resultMap,
        });
        const setFilterSelected = Array.from(new Set(resultMap.map((item: any) => item[1] as number)));
        setChoseProductionBaseId(BaseCenterHierarchyOptions[0].id);
        setChoseWorkCenterIds(setFilterSelected as number[]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BaseCenterHierarchyOptions, choseWorkerCenterIds]);

  const getTimestamps = (inputDate: string): string[] => {
    if (currentDateType === 'M') {
      const startDate = dayjs(inputDate).startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('month').format('YYYY-MM-DD');
      return [startDate.toString(), endDate.toString()];
    } else if (currentDateType === 'D') {
      const startDate = dayjs(inputDate).startOf('day').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('day').format('YYYY-MM-DD');
      return [startDate.toString(), endDate.toString()];
    } else if (currentDateType === 'Y') {
      const startDate = dayjs(inputDate).startOf('year').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('year').format('YYYY-MM-DD');
      return [startDate.toString(), endDate.toString()];
    } else {
      return ['0', '0'];
    }
  };

  useEffect(() => {
    const timeStamps = getTimestamps(currentDate);
    setStartDate(timeStamps[0].toString());
    setEndDate(timeStamps[1].toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setCurrentDate(dateString as string);

    date && memoryData.current.set(currentDateType, date);
    if (date && currentDateType === 'M') setDatePickerValueMonth(date);
  };
  return (
    <div className={styles.topSelect}>
      <Space size={48}>
        <Form.Item name="workCenterIds" label="生产基地" className={styles.workCenter}>
          <Cascader
            options={BaseCenterHierarchyOptions}
            multiple
            getPopupContainer={triggerNode => triggerNode.parentNode}
            style={{ width: 300 }}
            allowClear={false}
            maxTagCount="responsive"
            showSearch
            onChange={(value, workCenterOptions) => {
              const productionBaseId = Array.from(new Set(workCenterOptions.map((item: any) => item[0].id))).pop();
              const filterSelected = value.filter(subArray => subArray[0] === productionBaseId);
              const setFilterSelected = Array.from(new Set(filterSelected.map(item => item[1] as number)));
              if (choseproductionBaseId !== productionBaseId) {
                form.setFieldsValue({ workCenterIds: filterSelected });
              }
              setChoseWorkCenterIds(setFilterSelected);
              setChoseProductionBaseId(productionBaseId);
            }}
            changeOnSelect
            showCheckedStrategy={'SHOW_CHILD'}
            fieldNames={{ label: `name`, value: 'id', children: 'workCenterHierarchy' }}
            placeholder="请选择工作中心"
          />
        </Form.Item>

        <Space size={10}>
          <Radio.Group
            onChange={(e: any) => {
              setCurrentDateType(e.target.value);
              // url_date = undefined  存在地址栏传参的话;
              let memeryDate = memoryData.current.get(e.target.value);
              setCurrentDate(
                memeryDate
                  ? dayjs(memeryDate).format(formateCommon[e.target.value])
                  : dayjs().format(formateCommon[e.target.value])
              );
              if (e.target.value === 'M') setDatePickerValueMonth(dayjs(dayjs(), 'YYYY-MM'));
            }}
            defaultValue={currentDateType || 'M'}
            buttonStyle="solid"
          >
            <Radio.Button value="D">按日</Radio.Button>
            <Radio.Button value="M">按月</Radio.Button>
            <Radio.Button value="Y">按年</Radio.Button>
          </Radio.Group>
          <div>
            {currentDateType === 'D' && (
              <DatePicker
                style={{ width: 300 }}
                allowClear={false}
                getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                defaultValue={memoryData.current.get(currentDateType) ?? dayjs(dayjs(), 'YYYY-MM-DD')}
                onChange={onChange}
              />
            )}
            {currentDateType === 'M' && (
              <DatePicker
                allowClear={false}
                style={{ width: 300 }}
                getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                value={memoryData.current.get(currentDateType) ?? datePickerValueMonth}
                onChange={onChange}
                picker="month"
              />
            )}
            {currentDateType === 'Y' && (
              <DatePicker
                allowClear={false}
                getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
                style={{ width: 300 }}
                defaultValue={memoryData.current.get(currentDateType) ?? dayjs(dayjs(), 'YYYY')}
                onChange={onChange}
                picker="year"
              />
            )}
          </div>
        </Space>
      </Space>
    </div>
  );
};
export default WorkCenterTop;
