import React, { FC, createContext, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import {
  CreateUETEMTProcessReq,
  CreateUETEMTProcessResponse,
  CreateUETEMTResponse,
  EnergyWorkingProcessType,
  checkUetEMTProcessNameUnique,
  checkUetEMTProcessPermissioned,
  delEMTprocess,
} from '@/api/uet';
import { Button, Form, Input, Modal } from '@maxtropy/components';
import { findfilterArr } from './utils';
import ShowItemInfo from './components/ShowItemInfo';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EditItemInfo from './components/EditItemInfo';
import {
  V2UetEmtProcessListPostResponse,
  apiV2UetEmtProcessDetailPost,
  apiV2UetSaveProcessPost,
  apiV2UetUpdateProcessPost,
} from '@maxtropy/device-customer-apis-v2';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

export const createdFormLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const ShareDataContext = createContext<ShareDataProps>({});

export interface InOutFieldsLengthProps {
  key: string;
  number: number;
}

interface ShareDataProps {
  processType?: EnergyWorkingProcessType;
  setProcessType?: React.Dispatch<React.SetStateAction<EnergyWorkingProcessType>>;

  energyMediumIds?: number[];
  setEnergyMediumIds?: React.Dispatch<React.SetStateAction<number[]>>;

  selectedOuIds?: number[];
  setSelectedOuIds?: React.Dispatch<React.SetStateAction<number[]>>;

  inOutFieldsLength?: InOutFieldsLengthProps[];
  setInOutFieldsLength?: React.Dispatch<React.SetStateAction<InOutFieldsLengthProps[]>>;

  tabsActiveKey?: string;
  setTabsActiveKey?: React.Dispatch<React.SetStateAction<string>>;

  setImgValue?: React.Dispatch<React.SetStateAction<string | undefined>>;
  imgValue?: string;

  createdProcesses?: CreateUETEMTProcessResponse[];
  setCreatedProcesses?: React.Dispatch<React.SetStateAction<CreateUETEMTProcessResponse[]>>;

  setOptions?: React.Dispatch<React.SetStateAction<any[] | undefined>>; // 引子类型
}

export interface IAddProcess {
  eMTBasicInfo?: CreateUETEMTResponse;
  // callOptions?: (options?: any[]) => void;
  setCreatedProcessNumber: React.Dispatch<React.SetStateAction<CreateUETEMTProcessResponse[]>>;
  editedProcesses: V2UetEmtProcessListPostResponse['processVos'];
  isEdit?: boolean;
}

const AddProcess: FC<IAddProcess> = props => {
  const { eMTBasicInfo, setCreatedProcessNumber, editedProcesses, isEdit } = props;

  const [searchForm] = Form.useForm(); // 搜索的表单
  const [topoNameOrEnterExitName, setTopoNameOrEnterExitName] = useState<string>(); // 前端模糊查询
  const processListRef = useRef<CreateUETEMTProcessResponse[]>([]); // 前端做查询的temp

  const [processForm] = Form.useForm(); // 节点的表单
  const [saveState, setSaveState] = useState(true); // 记录保存状态，作用：只能编辑或者创建完成后才能进行继续编辑或添加
  const [isAddProcess, setIsAddProcess] = useState<boolean>(false); // 是否是添加节点状态
  const [createdProcesses, setCreatedProcesses] = useState<CreateUETEMTProcessResponse[]>([]); // 创建完成或正在编辑的节点

  const [imgValue, setImgValue] = useState<string>(); // 图片
  const [selectedOuIds, setSelectedOuIds] = useState<number[]>([]); // 保存所选择的ouIds
  const [energyMediumIds, setEnergyMediumIds] = useState<number[]>([]); // 保存能源介质ids
  const [processType, setProcessType] = useState<EnergyWorkingProcessType>(
    EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION
  ); // 保存节点类型，用于查询制取处理节点中节点输入所有的能源介质
  const [inOutFieldsLength, setInOutFieldsLength] = useState<InOutFieldsLengthProps[]>([]); // 节点输入输出的number计数
  const [tabsActiveKey, setTabsActiveKey] = useState<string>('1'); // 选择输出还是输入

  const [options, setOptions] = useState<any[]>(); // 引自类型

  // 监听节点数，用在上层保存时的校验
  useEffect(() => {
    setCreatedProcessNumber(createdProcesses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdProcesses]);

  // 节点列表展示是否有编辑权限  查询staff是否有能源介质拓扑下的节点的权限
  useEffect(() => {
    if (isEdit && editedProcesses && eMTBasicInfo) {
      checkUetEMTProcessPermissioned(String(eMTBasicInfo.id)).then(res => {
        const editedProcessesTransCreated = editedProcesses.map(item => {
          return {
            ...item,
            // id: item.id,
            processEntryVos: [...(item.processEntrySimpleVos ?? [])],
            processExitVos: [...(item.processExitSimpleVos ?? [])],
            processNodeVos: [...(item.processNodeSimpleVo ? [item.processNodeSimpleVo] : [])],
            isEdit: false,
            hasPermission: res[item.id!],
          };
        });
        processListRef.current = editedProcessesTransCreated;
        setCreatedProcesses(editedProcessesTransCreated);
      });
    }
  }, [isEdit, editedProcesses, eMTBasicInfo]);

  // 模糊查询
  useEffect(() => {
    setCreatedProcesses(processListRef.current); // 重置所有
    if (topoNameOrEnterExitName) {
      setCreatedProcesses(list => {
        let newList: CreateUETEMTProcessResponse[] = JSON.parse(JSON.stringify(list));
        return findfilterArr(newList, topoNameOrEnterExitName);
      });
    }
  }, [topoNameOrEnterExitName]);

  // 删除节点
  const deleteProcess = (id: number) => {
    if (eMTBasicInfo && eMTBasicInfo.uetId && eMTBasicInfo.id) {
      Modal.confirm({
        title: `确认删除节点【${createdProcesses.find(i => i.id === id)?.name}】？`,
        icon: <ExclamationCircleOutlined />,
        content: '删除后不可恢复，且会清除关联信息',
        okText: '删除',
        onOk: () => {
          delEMTprocess(id).then(_ => {
            // 删除
            setCreatedProcesses(list => {
              let newList = [...list];
              const findIndex = newList.findIndex(i => i.id === id);
              newList.splice(findIndex, 1);
              return [...newList];
            });
          });
        },
      });
    }
  };

  // 点击编辑节点
  const onEditProcess = (id: number, findCurrentEdit: CreateUETEMTProcessResponse) => {
    if (!saveState) {
      Modal.error({
        content: `请先保存正在编辑或创建的节点！`,
      });
    } else {
      // 如果是编辑得查询详情
      if (isEdit) {
        apiV2UetEmtProcessDetailPost({ id: String(findCurrentEdit.id) }).then(res => {
          setImgValue(res.pictureKey);

          // 可优化
          processForm.setFieldsValue({
            addProcess: [
              {
                ...findCurrentEdit,
                // type: findCurrentEdit.type,
                // name: findCurrentEdit.name,
                energyMediumIds: findCurrentEdit.energyMediumVos?.map(i => i.id),
                ouIds: findCurrentEdit.ouSimpleVos?.map(i => i.id),
                // hasConversion: findCurrentEdit.hasConversion,
                // picture: findCurrentEdit.picture,
                // pictureKey: findCurrentEdit.pictureKey,
                processEntryCreateRequests: res.processEntryVos?.map(i => {
                  return {
                    ...i,
                    refId: i.refFlag ? i?.refName : i.refId,
                    deviceVos: i.deviceOuVos?.map(i => i),
                  };
                }),
                processExitCreateRequests: res.processExitVos?.map(i => {
                  return {
                    ...i,
                    deviceVos: i.deviceOuVos?.map(i => i),
                  };
                }),
                processNodeCreateRequests: (res.processNodeVo ? [res.processNodeVo] : []).map(i => {
                  return {
                    ...i,
                    deviceVos: i.deviceOuVos?.map(i => i),
                  };
                }),
              },
            ],
          });

          setCreatedProcesses(list => {
            let newList = [...list];
            const findIndex = newList.findIndex(i => i.id === id);
            const find = newList.find(i => i.id === id);
            newList.splice(findIndex, 1, {
              ...find,
              ...res,
              isEdit: true, // 设置成编辑态
            });
            return newList;
          });
          setSaveState(false);

          setInOutFieldsLength([
            {
              key: '0in',
              number: findCurrentEdit.processEntryVos?.length ?? 0,
            },
            {
              key: '0out',
              number: findCurrentEdit.processExitVos?.length ?? 0,
            },
            {
              key: '0inside',
              number: findCurrentEdit.processNodeVos?.length ?? 0,
            },
          ]); // 计数清除
          setEnergyMediumIds((findCurrentEdit.energyMediumVos ?? []).map(i => i.id!));
          setSelectedOuIds((findCurrentEdit.ouSimpleVos ?? []).map(i => i.id!));
          setProcessType(findCurrentEdit.type!); // 默认为分配节点
          setTabsActiveKey('1');
        });
      } else {
        setImgValue(findCurrentEdit.pictureKey);
        processForm.setFieldsValue({
          addProcess: [
            {
              ...findCurrentEdit,
              // type: findCurrentEdit.type,
              // name: findCurrentEdit.name,
              energyMediumIds: findCurrentEdit.energyMediumVos?.map(i => i.id),
              ouIds: findCurrentEdit.ouSimpleVos?.map(i => i.id),
              // hasConversion: findCurrentEdit.hasConversion,
              // picture: findCurrentEdit.picture,
              // pictureKey: findCurrentEdit.pictureKey,
              processEntryCreateRequests: findCurrentEdit.processEntryVos?.map(i => {
                return {
                  ...i,
                  deviceVos: i.deviceOuVos?.map(i => i),
                };
              }),
              processExitCreateRequests: findCurrentEdit.processExitVos?.map(i => {
                return {
                  ...i,
                  deviceVos: i.deviceOuVos?.map(i => i),
                };
              }),
              processNodeCreateRequests: (findCurrentEdit.processNodeVo ? [findCurrentEdit.processNodeVo] : []).map(
                i => {
                  return {
                    ...i,
                    deviceVos: i.deviceOuVos?.map(i => i),
                  };
                }
              ),
            },
          ],
        });
        setCreatedProcesses(list => {
          let newList = [...list];
          const findIndex = newList.findIndex(i => i.id === id);
          const findCurrent = newList.find(i => i.id === id);
          if (findCurrent) {
            newList.splice(findIndex, 1, {
              ...findCurrent,
              isEdit: true, // 设置成编辑态
            });
          }
          return newList;
        });
        setSaveState(false); // 设置正在编辑状态
        setInOutFieldsLength([
          {
            key: '0in',
            number: findCurrentEdit.processEntryVos?.length ?? 0,
          },
          {
            key: '0out',
            number: findCurrentEdit.processExitVos?.length ?? 0,
          },
          {
            key: '0inside',
            number: findCurrentEdit.processNodeVos?.length ?? 0,
          },
        ]); // 计数器计时
        setEnergyMediumIds((findCurrentEdit.energyMediumVos ?? []).map(i => i.id!)); // 能源介质
        setSelectedOuIds((findCurrentEdit.ouSimpleVos ?? []).map(i => i.id!)); // 所选ouIds
        setProcessType(findCurrentEdit.type!); // 节点类型
      }
    }
  };

  // 初始化
  const initState = () => {
    setSaveState(true); // 保存后设置无编辑态
    setInOutFieldsLength([]); // 计数清除
    setEnergyMediumIds([]); // 能源介质清掉
    setSelectedOuIds([]); // 清除所选ouIds
    setTabsActiveKey('1'); // 使得tab为1
    setProcessType(EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION); // 默认为分配节点
    setIsAddProcess(false); // 设置没有list在编辑态，因为考虑有新增，所以没有用到isEdit
  };

  // 点击保存
  const onProcessFormFinish = async (removeFunc?: Function, index?: number | number[]) => {
    const v: { addProcess: CreateUETEMTProcessReq[] } = await processForm.validateFields();
    if (!v.addProcess[0].processEntryCreateRequests?.length || !v.addProcess[0].processExitCreateRequests?.length) {
      Modal.error({
        content: `至少添加一个输入和一个输出！`,
      });
    } else {
      // 格式化device
      const processEntryCreateRequests = v.addProcess[0].processEntryCreateRequests.map(item => {
        const refType = options?.find(k => k.value === item.refId)?.type;
        const params = {
          ...item,
          type: refType,
          refName:
            typeof item.refId === 'string' ? (item?.refId as string).replace(`${eMTBasicInfo?.name}--`, '') : undefined,
          deviceIds: item.deviceVos?.map(i => i.id),
        };
        if (typeof item?.refId === 'string') {
          delete params.refId;
        }
        return params;
      });
      const processExitCreateRequests = v.addProcess[0].processExitCreateRequests.map(item => {
        return {
          ...item,
          deviceIds: item.deviceVos?.map(i => i.id),
        };
      });
      const processNodeCreateRequests = (v.addProcess[0]?.processNodeCreateRequests ?? []).map(item => {
        return {
          ...item,
          deviceIds: item.deviceVos?.map(i => i.id),
        };
      });
      const findEditProcess = createdProcesses.find(i => i.isEdit); // 是否有编辑的节点
      if (!findEditProcess) {
        if (eMTBasicInfo && eMTBasicInfo.uetId && eMTBasicInfo.id) {
          // 先检查名称唯一性
          checkUetEMTProcessNameUnique(String(eMTBasicInfo?.id), v.addProcess[0].name).then(res => {
            if (res) {
              Modal.error({
                content: `节点名称重复！`,
              });
            } else {
              // 创建
              apiV2UetSaveProcessPost({
                emtId: String(eMTBasicInfo.id),
                type: v.addProcess[0].type,
                name: v.addProcess[0].name,
                energyMediumIds: v.addProcess[0].energyMediumIds,
                ouIds: v.addProcess[0].ouIds,
                picture: v.addProcess[0].picture,
                pictureKey: v.addProcess[0].pictureKey,
                hasConversion: v.addProcess[0].hasConversion,
                processEntryCreateRequests,
                processExitCreateRequests,
                processNodeCreateRequestVo:
                  processNodeCreateRequests.length > 0 ? processNodeCreateRequests[0] : undefined,
              }).then(res => {
                setCreatedProcesses(list => {
                  processListRef.current = [...list];
                  return [...list, { ...res, isEdit: false, hasPermission: true }];
                });
                processForm.resetFields();
                if (removeFunc) {
                  removeFunc(index);
                }
                initState();
              });
            }
          });
        }
      } else {
        // 编辑
        if (eMTBasicInfo && eMTBasicInfo.uetId && eMTBasicInfo.id) {
          if (v.addProcess[0].name !== findEditProcess.name) {
            checkUetEMTProcessNameUnique(String(eMTBasicInfo?.id), v.addProcess[0].name).then(res => {
              if (res) {
                Modal.error({
                  content: `节点名称重复！`,
                });
              } else {
                updateUetProcess(
                  v,
                  eMTBasicInfo,
                  findEditProcess,
                  processEntryCreateRequests,
                  processExitCreateRequests,
                  processNodeCreateRequests,
                  removeFunc,
                  index
                );
              }
            });
          } else {
            updateUetProcess(
              v,
              eMTBasicInfo,
              findEditProcess,
              processEntryCreateRequests,
              processExitCreateRequests,
              processNodeCreateRequests,
              removeFunc,
              index
            );
          }
        }
      }
    }
  };

  // 修改节点调用接口
  const updateUetProcess = (
    v: {
      addProcess: CreateUETEMTProcessReq[];
    },
    eMTBasicInfo: CreateUETEMTResponse,
    findEditProcess: CreateUETEMTProcessResponse,
    processEntryCreateRequests: any,
    processExitCreateRequests: any,
    processNodeCreateRequests: any,
    removeFunc?: Function,
    index?: number | number[]
  ) => {
    apiV2UetUpdateProcessPost({
      id: findEditProcess!.id,
      emtId: String(eMTBasicInfo.id),
      type: v.addProcess[0].type,
      name: v.addProcess[0].name,
      energyMediumIds: v.addProcess[0].energyMediumIds,
      ouIds: v.addProcess[0].ouIds,
      hasConversion: v.addProcess[0].hasConversion,
      picture: v.addProcess[0].picture,
      pictureKey: v.addProcess[0].pictureKey,
      processEntryUpdateRequests: processEntryCreateRequests,
      processExitUpdateRequests: processExitCreateRequests,
      processNodeUpdateRequest: processNodeCreateRequests.length > 0 ? processNodeCreateRequests[0] : undefined,
    }).then(res => {
      const findEditProcessIndex = createdProcesses.findIndex(i => i.isEdit); // 是否有编辑的节点
      const findEditProcess = createdProcesses.find(i => i.isEdit); // 是否有编辑的节点
      setCreatedProcesses(list => {
        let resList = JSON.parse(JSON.stringify(list));
        resList.splice(findEditProcessIndex, 1, {
          ...findEditProcess,
          ...res,
          isEdit: false,
        });
        processListRef.current = resList;
        return resList;
      });
      processForm.resetFields();
      if (removeFunc) {
        removeFunc(index);
      }
      initState();
    });
  };

  const filters = (
    <Form className={styles.formStyle} form={searchForm} labelAlign={'left'}>
      <Form.Item name="NameOrEnterExitName" className={styles.itemFormSty}>
        <Input.Search
          className={styles.search}
          allowClear
          placeholder="输入节点名称或入口、出口名称搜索"
          onSearch={(value: any) => setTopoNameOrEnterExitName(value)}
          onChange={(e: any) => {
            if (e.type === 'click') {
              setTopoNameOrEnterExitName(undefined);
            }
          }}
          onBlur={(e: any) => {
            if (e.target.value === '') {
              setTopoNameOrEnterExitName(undefined);
            } else {
              setTopoNameOrEnterExitName(e.target.value);
            }
          }}
          onPressEnter={(e: any) => setTopoNameOrEnterExitName(e.target.value)}
        />
      </Form.Item>
    </Form>
  );

  return (
    <>
      <div className={styles.filterPosition}>{eMTBasicInfo?.code && filters}</div>
      <div style={{ marginTop: 50 }}>
        <ShareDataContext.Provider
          value={{
            processType,
            setProcessType,
            energyMediumIds,
            setEnergyMediumIds,
            selectedOuIds,
            setSelectedOuIds,
            inOutFieldsLength,
            setInOutFieldsLength,
            tabsActiveKey,
            setTabsActiveKey,
            setImgValue,
            imgValue,
            createdProcesses,
            setCreatedProcesses,
            setOptions,
          }}
        >
          <Form layout="horizontal" form={processForm} {...formLayout}>
            <Form.List name="addProcess">
              {(fields, { add, remove }) => (
                <>
                  {eMTBasicInfo?.code && (
                    <div style={{ marginBottom: 12, position: 'absolute', top: 6, left: 34 }}>
                      <Button
                        onClick={async () => {
                          if (saveState || createdProcesses.length === 0) {
                            setIsAddProcess(true);
                            await add();
                            const div = document.getElementsByClassName('ant-spin-container')[0];
                            const div1 = document.getElementById('handelDocID');
                            div1?.scrollTo({ top: div.clientHeight, behavior: 'smooth' });
                            setSaveState?.(false);
                          } else {
                            Modal.error({
                              content: `请先保存正在编辑或创建的节点！`,
                            });
                          }
                        }}
                        type="primary"
                      >
                        添加节点
                      </Button>
                    </div>
                  )}

                  {(createdProcesses ?? []).map((item, index) =>
                    // 不是编辑展示
                    !item.isEdit ? (
                      <ShowItemInfo
                        key={item.id}
                        item={item}
                        isEdit={isEdit}
                        onEditProcess={onEditProcess}
                        deleteProcess={deleteProcess}
                      />
                    ) : (
                      fields.map((field, index) => {
                        return (
                          <EditItemInfo
                            key={field.key}
                            eMTBasicInfo={eMTBasicInfo}
                            processForm={processForm}
                            field={field}
                            remove={remove}
                            onProcessFormFinish={onProcessFormFinish}
                            initState={initState}
                          />
                        );
                      })
                    )
                  )}

                  {/* 新增节点 */}
                  {isAddProcess &&
                    fields.map((field, index) => {
                      return (
                        <EditItemInfo
                          key={field.key}
                          isNew={true}
                          eMTBasicInfo={eMTBasicInfo}
                          onProcessFormFinish={onProcessFormFinish}
                          processForm={processForm}
                          field={field}
                          remove={remove}
                          initState={initState}
                        />
                      );
                    })}
                </>
              )}
            </Form.List>
          </Form>
        </ShareDataContext.Provider>
      </div>
    </>
  );
};

export default AddProcess;
