import { fetch } from '@maxtropy/components';
import { Key } from 'react';
import qs from 'qs';
import { StatisticsPartition } from '@/pages/WaterDashBoard/components/DateSwitch';

export enum DataType {
  IVFR = 101, // 瞬时体积流量
  IMFR = 102, // 瞬时质量流量
  AVFR = 103, // 累积体积流量
  AMFR = 104, // 累积质量流量
  PA = 105, // 压力
  T = 106, // 温度
  E = 107, // 电量
  POWER = 108, // 功率
  AHEAT = 109, // 累积热量
  D = 110, // 密度
  RH = 111, // 相对湿度
  AH = 112, // 绝对湿度
  LEVEL = 113, // 液位
  PURITY = 114, // 纯度
}

// 单位配置
export const DataTypeNameDisplay = {
  [DataType.IVFR]: '瞬时体积流量',
  [DataType.IMFR]: '瞬时质量流量',
  [DataType.AVFR]: '累积体积流量',
  [DataType.AMFR]: '累积质量流量',
  [DataType.PA]: '压力',
  [DataType.T]: '温度',
  [DataType.E]: '电量',
  [DataType.POWER]: '功率',
  [DataType.AHEAT]: '累积热量',
  [DataType.D]: '密度',
  [DataType.RH]: '相对湿度',
  [DataType.AH]: '绝对湿度',
  [DataType.LEVEL]: '液位',
  [DataType.PURITY]: '纯度',
};

// 单位配置
export const DataTypeUitDisplay = {
  [DataType.IVFR]: 'm³/h',
  [DataType.IMFR]: 'kg/s',
  [DataType.AVFR]: 'm³',
  [DataType.AMFR]: 'kg',
  [DataType.PA]: 'kPa',
  [DataType.T]: '℃',
  [DataType.E]: 'kWh',
  [DataType.POWER]: 'kW',
  [DataType.AHEAT]: 'MJ',
  [DataType.D]: 'kg/m³',
};

export interface EnergyMediumTopologyTreeProps {
  id?: number;
  name?: string;
  processTreeVos?: ProcessTreeProps[];
}

export interface ProcessEntryOrExitTreeVos {
  id?: number;
  name?: string;
}

export interface ProcessTreeProps {
  id?: number;
  name?: string;
  processEntryTreeVos?: ProcessEntryOrExitTreeVos[];
  processExitTreeVos?: ProcessEntryOrExitTreeVos[];
}

// 区分过程输出输入
export enum EnergyMediumTreeFormatType {
  ENTRY = 0,
  EXIT = 1,
  NODEINSIDE = 2,
  PROCESS = 3,
  TOPO = 4,
}

export const EnergyMediumTreeFormatTypeDisplay = {
  [EnergyMediumTreeFormatType.ENTRY]: '输入',
  [EnergyMediumTreeFormatType.EXIT]: '输出',
  [EnergyMediumTreeFormatType.NODEINSIDE]: '节点内',
  [EnergyMediumTreeFormatType.PROCESS]: '节点',
  [EnergyMediumTreeFormatType.TOPO]: '拓扑',
};

// 用于拓扑树的Tag颜色
export const EnergyMediumTreeFormatTypeDisplayLabelColor = {
  [EnergyMediumTreeFormatType.ENTRY]: '#4A90E2',
  [EnergyMediumTreeFormatType.EXIT]: '#52C41A',
  [EnergyMediumTreeFormatType.NODEINSIDE]: '#FAAD14',
  [EnergyMediumTreeFormatType.PROCESS]: '#FAAD14',
  [EnergyMediumTreeFormatType.TOPO]: '#fff',
};

export interface getEnergyMediumTopologyTreeParams {
  sceneId: Key;
}

export interface ProcessEntryOrExitMonitoringIndicatorsReq {
  processEntryOrExitId: Key;
  type: EnergyMediumTreeFormatType;
}

export interface ProcessEntryOrExitMonitoringIndicatorsRes {
  indicatorId?: number; //指标id
  indicatorName?: string; //指标名称
  type?: number; //指标类型：1.累积量;2.瞬时量
  typeName?: string; // 指标类型名称
  basePhysicalQuantityId?: number; //指标基础物理量id
  basePhysicalQuantityName?: string; //物理量name
  dataPropertyId?: number; //数据属性id
  dataPropertyName?: string; //设备的数据属性名称
  deviceId?: number; //设备id
  deviceName?: string; //设备名称
}

// 获取能源介质拓扑树中的过程输入/输出中配置的指标
export const getProcessEntryOrExitMonitoringIndicators = (query: ProcessEntryOrExitMonitoringIndicatorsReq) =>
  fetch<{ list: ProcessEntryOrExitMonitoringIndicatorsRes[] }>(
    `/api/v2/mediumIndicatorDashboard/getProcessEntryOrExitMonitoringIndicators`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

export interface MediumIndicatorStatisticsReq {
  processEntryOrExitId?: Key; //过程输入/输出id
  processId?: Key; // 过程id
  type?: EnergyMediumTreeFormatType; //过程输入/输出类型
  indicatorId?: Key; //指标id
  timeResolution?: StatisticsPartition; //时间颗粒度
  from: number; //开始时间（毫秒时间戳）
  to: number; //结束时间（毫秒时间戳）
}

export interface AllDataProps {
  year?: number; // 年份
  processId?: number; //所属能源介质拓扑过程id
  entryExitId?: number; //输入输出id
  type?: number; //类型(0. 输入;1. 输出)
  indicatorId?: number; //指标id
  ts?: number; //时间戳
  max?: number; //区间内的最大值
  min?: number; //区间内的最小值
  avg?: number; //区间内的平均值
  sum?: number; //区间内的总和
  num?: number; //区间内的数据个数
  resolution?: string; //时间颗粒度 1M :按1min统计 15M :按15min统计 30M :按半小时统计 H :按小时统计 D :按日统计 M :按月统计 Y :按年统计
  updateTime?: string;
}

export interface MediumIndicatorStatisticsRes {
  indicatorId?: Key; //指标id
  processId?: Key; // 过程id
  sceneId?: Key; // 场景id
  entryExitId?: number;
  type?: number;
  data?: AllDataProps[];
}

// 获取指标的统计数据
export const getMediumIndicatorStatistics = (query: MediumIndicatorStatisticsReq) =>
  fetch<MediumIndicatorStatisticsRes>(
    `/api/v2/mediumIndicatorDashboard/getMediumIndicatorStatistics`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

export interface MediumIndicatorProcessStatisticsReq {
  processId?: Key; //过程id
  mediumId?: Key; //工质id
  indicatorId?: Key; //指标id
  timeResolution?: StatisticsPartition; //时间颗粒度
  from: number; //开始时间（毫秒时间戳）
  to: number; //结束时间（毫秒时间戳）
  processEntryExitType?: EnergyMediumTreeFormatType; // 过程输入/输出类型
}

export interface ProcessEntryOrExitStatisticsRes {
  energyMediumId?: number; //工质id
  sceneId?: number; //场景id
  indicatorId?: number; //对应指标id
  energyMediumName?: string; //工质名称
  value?: number; //值
}

export interface MediumIndicatorProcessStatisticsRes {
  processEntryStatistics?: ProcessEntryOrExitStatisticsRes[];
  processExitStatistics?: ProcessEntryOrExitStatisticsRes[];
  processNodeStatistics?: ProcessEntryOrExitStatisticsRes;
}

// 获取工质指标看板过程中的卡片统计数据
export const getMediumIndicatorProcessStatistics = (
  query: Omit<MediumIndicatorProcessStatisticsReq, 'mediumId' | 'indicatorId'>
) =>
  fetch<MediumIndicatorProcessStatisticsRes>(
    `/api/v2/mediumIndicatorDashboard/getMediumIndicatorProcessStatistics`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

// 获取工质指标看板过程中的柱状图统计数据
export const getMediumIndicatorProcessPointData = (query: MediumIndicatorProcessStatisticsReq) =>
  fetch<MediumIndicatorStatisticsRes>(
    `/api/v2/mediumIndicatorDashboard/getMediumIndicatorProcessPointData`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );

// 场景id枚举
export enum EnergyMediumSceneIds {
  WATER_SUPPLY_AND_DRAINAGE = 500, // 给排水/用气
  GAS_SUPPLY_AND_CONSUMPTION = 501, // 供气/用气
  HOT_STEAM = 502, // 热蒸汽
  COMPRESSED_AIR = 503, // 压缩空气
  INDUSTRIAL_GASES = 504, // 工业气体
  HEATING_VENTILATION_AND_AIR_CONDITIONING = 505, // 供暖通风与空气调节
  INDUSTRIAL_LIQUIDS = 506, // 工业液体
  ELEC = 'elec', // 电能
}

export const EnergyMediumSceneIdsColorDisplay = {
  [EnergyMediumSceneIds.WATER_SUPPLY_AND_DRAINAGE]: '#53DEF4',
  [EnergyMediumSceneIds.GAS_SUPPLY_AND_CONSUMPTION]: '#F4C345',
  [EnergyMediumSceneIds.HOT_STEAM]: '#F48C45',
  [EnergyMediumSceneIds.COMPRESSED_AIR]: '#234CD0',
  [EnergyMediumSceneIds.INDUSTRIAL_GASES]: '#6844EA',
  [EnergyMediumSceneIds.HEATING_VENTILATION_AND_AIR_CONDITIONING]: '#51BC1C',
  [EnergyMediumSceneIds.INDUSTRIAL_LIQUIDS]: '#F44577',
  [EnergyMediumSceneIds.ELEC]: '#198BF4',
};

export interface MediumIndicatorConfigProps {
  /**
   * 能源/工质拓扑id
   */
  energyMediumTopologyId: number;
  /**
   * 报警开关
   */
  alarmSwitch?: 0 | 1;
  /**
   * 用量统计开关
   */
  usageCountSwitch?: 0 | 1;
  /**
   * 过程id
   */
  processIds?: number[];
}

export type MediumIndicatorDisplayUnit = {
  /**
   * 工质指标id
   */
  energyMediumIndicatorId?: number;
  /**
   * 工质指标展示单位id
   */
  displayPhysicalUnitId?: number;
  /**
   * 工质指标展示单位名称
   */
  displayPhysicalUnitGeneralName?: string;
}[];
