import React, { useContext, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import { isEmpty, isNil } from 'lodash-es';
import { DataPointType, DataProperty, DataPropertyType } from '../../../../../types';
import { EdgeDeviceTemplatePoint } from './index';
import { PointContext } from '../../../../EdgeDevicePointInfo/contextTypes';
import { useDataProperties } from '../../../../EdgeDevicePointInfo/mockingbird/Point/hooks';
import FormSubmit from '../../../../EdgeDevicePointInfo/mockingbird/FormSubmit';
import { checkNum, identifierValidatorBuilder } from '../../../../EdgeDevicePointInfo/mockingbird/Point/entity';
import { BatchEdgeContent } from '../../../contentTypes';
import { PointOperateType } from '../../../interface';
import { Input, Modal, Radio, Select, message, InputNumber, ShowInput, Tooltip } from '@maxtropy/components';
import { FilterModeDisplay, FilterMode } from '@/shared/const';
import { validatorValueRange } from '@/shared/utils/utils';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export interface PointFormDialogProps {
  row?: Partial<EdgeDeviceTemplatePoint>;
  onCancel: () => void;
  onOk: (values: any) => void;
  usedProperties?: number[];
  usedIdentifier?: string[];
  dataPropertiesAll?: DataProperty[];
  alterMode?: boolean;
}

const PointFormDialog: React.FC<PointFormDialogProps> = props => {
  const { row, dataPropertiesAll, usedProperties, usedIdentifier, onCancel, onOk, alterMode } = props;
  const { setRow } = useContext(PointContext);
  const { baseForm } = useContext(BatchEdgeContent);
  const { pointOperateType } = baseForm?.getFieldsValue();

  const [form] = Form.useForm();
  const valueFilterMode = Form.useWatch('valueFilterMode', form);

  const { dataProperties, dataPropertyId, setDataPropertyId, units, dataPropertyType } = useDataProperties(
    dataPropertiesAll,
    usedProperties
  );

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units) && row) {
      form.setFieldsValue({
        physicalUnitId: units?.find(i => i.id === row.physicalUnitId)?.id ?? units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName:
          units?.find(i => i.id === row.physicalUnitId)?.generalName ?? units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units, row]);

  const submit = async (shouldContinue?: boolean) => {
    const values = await form.validateFields();
    if (shouldContinue) {
      await onOk(values);
      form.resetFields();
      setRow?.({
        ...values,
        pointType: DataPointType.BASE_POINT,
        dataPropertyId: undefined,
        physicalUnitId: undefined,
        identifier: undefined,
      });
    } else {
      await onOk(values);
      onCancel();
    }
  };

  return (
    <Modal
      open={row?.pointType === DataPointType.BASE_POINT}
      onCancel={onCancel}
      title={`${pointOperateType === PointOperateType.UPDATE ? '编辑' : '添加'}采集点`}
      centered={true}
      maskClosable={false}
      footer={<FormSubmit submit={submit} cancel={onCancel} alterMode={alterMode} />}
    >
      <Form {...formLayout} form={form} labelWrap={true} initialValues={row} onFinish={onOk}>
        {alterMode ? <Form.Item noStyle name="dataPropertyId" /> : <Form.Item noStyle name="dataPropertyName" />}
        <Form.Item noStyle name="physicalUnitGeneralName" />

        {alterMode ? (
          <Form.Item noStyle name="hasProperty" />
        ) : (
          <Form.Item
            name="hasProperty"
            label="采集点类型"
            initialValue={true}
            rules={[{ required: true, message: '请选择' }]}
          >
            <Radio.Group disabled={!isNil(row?.id)}>
              <Radio value={true}>建模采集点</Radio>
              <Radio value={false}>非建模采集点</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        {alterMode ? (
          <>
            <Form.Item
              name={row?.hasProperty ? 'dataPropertyName' : 'identifier'}
              label={row?.hasProperty ? '数据属性' : '非建模点标识符'}
            >
              <ShowInput />
            </Form.Item>
            {dataPropertyType === DataPropertyType.YC && (
              <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                <Select
                  onChange={value => {
                    form.setFieldsValue({
                      physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                    });
                  }}
                  placeholder="请选择"
                >
                  {units?.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.generalName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </>
        ) : (
          <Form.Item noStyle dependencies={['hasProperty']}>
            {({ getFieldValue }) =>
              getFieldValue('hasProperty') ? (
                <>
                  <Form.Item
                    name="dataPropertyId"
                    label="数据属性"
                    rules={[{ required: true, message: '请选择数据属性' }]}
                  >
                    <Select
                      onChange={value => {
                        form.setFieldsValue({
                          dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                        });
                        setDataPropertyId(value as number);
                        form.setFieldsValue({
                          physicalUnitId: undefined,
                          physicalUnitGeneralName: '',
                        });
                      }}
                      placeholder="请输入/选择"
                      showSearch
                      filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataProperties?.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {dataPropertyType === DataPropertyType.YC && (
                    <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                      <Select
                        onChange={value => {
                          form.setFieldsValue({
                            physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                          });
                        }}
                        placeholder="请选择"
                      >
                        {units?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.generalName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              ) : (
                <Form.Item
                  required
                  name="identifier"
                  label="非建模采集点标识符"
                  rules={[
                    {
                      validator: identifierValidatorBuilder(usedIdentifier),
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              )
            }
          </Form.Item>
        )}

        <Form.Item
          name="writable"
          label="是否可写"
          initialValue={false}
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group>
            <Radio value={true}>是</Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item required label="公共地址">
          <Input placeholder="excel模版导入" disabled />
        </Form.Item>

        <Form.Item required label="信息体地址">
          <Input placeholder="excel模版导入" disabled />
        </Form.Item>

        <Form.Item
          required
          label="乘积系数k"
          tooltip={{
            title: 'y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数',
            icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
          }}
        >
          <Input placeholder="excel模版导入" disabled />
        </Form.Item>
        <Form.Item
          required
          label="偏移系数b"
          tooltip={{
            title: 'y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数',
            icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
          }}
        >
          <Input placeholder="excel模版导入" disabled />
        </Form.Item>
        <Form.Item
          name="valueFilterMode"
          label="数值过滤模式"
          initialValue={0}
          wrapperCol={{ span: 18 }}
          rules={[{ required: true, message: '请选择' }]}
          shouldUpdate
        >
          <Radio.Group
            onChange={v => {
              if (v.target?.value === FilterMode.GROWTHREASONABLERANGE) {
                message.warning('新网关版本在3.7.30版本以上');
              }
            }}
          >
            {Object.entries(FilterModeDisplay).map(([k, v]) => (
              <Radio key={k} value={Number(k)}>
                {v}
                {Number(k) === FilterMode.GROWTHREASONABLERANGE && (
                  <Tooltip title="注意针对累积量">
                    <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', fontSize: 12, marginLeft: '4px' }} />
                  </Tooltip>
                )}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {valueFilterMode === 1 && (
          <Form.Item
            label="数值合理区间"
            name="valueRange"
            dependencies={['valueFloor', 'valueCeiling']}
            rules={[
              form => ({
                validator: () => validatorValueRange(form),
              }),
            ]}
            tooltip={{
              title: '区间外数值将被直接滤除，视为无效数值',
              icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
            }}
          >
            <Row gutter={8}>
              <Col span={11}>
                <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={2}>--</Col>
              <Col span={11}>
                <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        )}
        {valueFilterMode === 2 && (
          <>
            <Form.Item
              label="增长值合理区间"
              name="valueRange"
              required={true}
              dependencies={['valueFloor', 'valueCeiling']}
              rules={[
                form => ({
                  validator: () => validatorValueRange(form),
                }),
              ]}
              tooltip={{
                title: (
                  <div>
                    <div>新网关版本在3.7.30版本以上</div>
                    <div>网关采集相邻数据的差值对应的合理范围；（设备的采集频率）</div>
                  </div>
                ),
                icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
              }}
            >
              <Row gutter={8}>
                <Col span={9}>
                  <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={2}>--</Col>
                <Col span={9}>
                  <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={3}>/min</Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="valueFilterCnt"
              label="边缘侧异常数据过滤个数"
              initialValue={5}
              rules={[{ required: true, message: '请输入' }]}
            >
              <InputNumber
                placeholder="请输入"
                min={1}
                max={20}
                precision={0}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
            <Form.Item
              label="云端增长值合理区间"
              name="cloudValueRange"
              dependencies={['cloudValueFloor', 'cloudValueCeiling']}
              tooltip={{
                title: '设备原始数据里1分钟里数据增长值的合理范围',
                icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
              }}
            >
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item name="cloudValueFloor" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={2}>--</Col>
                <Col span={8}>
                  <Form.Item name="cloudValueCeiling" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={5}>{units?.find(i => i.default)?.generalName ?? '-'}/min</Col>
              </Row>
            </Form.Item>
            <Form.Item name="cloudValueFilterCnt" label="云端异常数据过滤个数" initialValue={5}>
              <InputNumber
                placeholder="请输入"
                min={1}
                max={1000}
                precision={0}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </>
        )}

        {!alterMode && (
          <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '长度不能超过50个字符' }]}>
            <Input placeholder="请输入备注" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default PointFormDialog;
