import { Radio, DatePicker, Button, Empty, useBreadcrumbRoutes, Wrapper, Key, Form } from '@maxtropy/components';
import { DatePickerProps, Divider, Row, Space, Spin, Cascader } from 'antd';
import ReactEcharts from 'echarts-for-react';
import { useRequest } from 'ahooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { colorCategory, colorCategoryEdge, colors, energyDisplay } from './colors';
import BannerInfo, { totalUseTransformUnit, transformUnit } from './BannerInfo';
import {
  apiV2LeanEnergyFlowAnalysisBannerPost,
  apiV2LeanEnergyFlowAnalysisDetailDataPost,
  apiV2LeanEnergyFlowAnalysisFlowDetailProductionBasePost,
  apiV2LeanEnergyFlowAnalysisFlowChartPost,
  V2LeanEnergyFlowAnalysisDetailDataPostResponse,
  apiV2LeanEnergyFlowAnalysisMomDataPost,
  V2LeanEnergyFlowAnalysisFlowDetailProductionBasePostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { apiV2ProductionBaseCenterHierarchyPost } from '@maxtropy/device-customer-apis-v2';
import { isNil } from 'lodash-es';
import EnergyFlowDetailsModal from './components/EnergyFlowDetailsModal';
import { TypeConfig } from './components/EnergyFlowDetailsModal';
import { baseCenterTypes, concatMessage, ProductionBaseType, edgesType, pointsType } from './utils';
import MediumSwipper from './components/MediumSwipper';

export interface workCenterHierarchyType {
  name?: string;
  id?: number;
  code?: string;
  iconKey?: string;
  sequence?: number;
  productionBaseId?: number;
}

export const getNodeMaxDepth = (nodes: any[]) => {
  const all_depth = nodes.map(i => i.depth);
  return Math.max(...all_depth);
};
const createEle = (color: string) => {
  return `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>`;
};

const formateCommon: Record<string, string> = {
  D: 'YYYY-MM-DD',
  M: 'YYYY-MM-DD',
  Y: 'YYYY-MM-DD',
};
//node用于传递节点数据
//link  边数据
//currentSelectedIds 表示当前选中的节点的 ID。
//labelTransformInfo 是一个映射对象，用于将节点名称转换成标签显示名称的信息。
const getChartOption = (
  nodes: any[],
  links: any[],
  currentSelectedIds: number[],
  labelTransformInfo: Map<string, string>
) => {
  return {
    label: {
      formatter: (labelInfo: any) => {
        return labelTransformInfo.get(labelInfo.name) ?? labelInfo.name;
      },
    },

    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
      backgroundColor: '#000',
      borderColor: 'transparent',
      textStyle: {
        color: 'rgba(255,255,255,0.65)',
        fontSize: 12,
      },
      formatter: (data: any) => {
        if (data.dataType === 'edge') {
          console.log(data.data);
          return `${labelTransformInfo.get(data.data.source)} -> ${labelTransformInfo.get(data.data.target)}  ${
            data.data?.energyMediumName ?? '-'
          } : ${transformUnit(data.data.trueValue, data.data.unit)}`;
        } else {
          let ponitName = data.data.nameBak;
          // 只选择了某一个介质, 即非综合能耗
          if (currentSelectedIds.length === 1) {
            let childData = data.data?.list?.[0];
            if (!childData) return '';
            // 非生产基地显示占比
            let proportion = ponitName === 'PRODUCTION_BASE' ? '' : `占比: ${childData.proportion ?? '-'}%`;
            return `${childData.name ?? '-'} : ${transformUnit(childData.value, childData.unit)} ${proportion}`;
          }
          // 排除ENERGY_CONSUMPTION
          if (!(ponitName in energyDisplay)) {
            return `${labelTransformInfo.get(data.data.name)} : ${transformUnit(data.data.trueValue, data.data.unit)}`;
          }

          let childs = data.data.list
            .sort((a: any, b: any) => b.proportion - a.proportion)
            .map(
              (item: any) =>
                `${createEle(colorCategory[item.energyMediumId])} <span style="display:inline-block;width:70px;">${
                  item.name + ':'
                }</span> <span style="display:inline-block;width:90px;">${transformUnit(
                  item.value,
                  item.unit
                )}</span> 占比: ${item.proportion ?? '-'}%`
            )
            .join('<br/>');
          return (
            `${energyDisplay[ponitName]}<br/>
              <div style="padding: 4px 0;">
                总用能 
                <span style="color: #00adff;">
                  ${totalUseTransformUnit(data.data.value)}
                </span>
              </div>` + childs
          );
        }
      },
    },
    series: [
      {
        type: 'sankey',
        left: 0,
        right: 0,
        layoutIterations: 0, // 解决能流图衔接问题
        nodeAlign: 'justify',
        label: {
          color: '#fff',
          fontSize: 10,
        },
        labelLayout: {
          hideOverlap: true,
        },
        data: nodes?.map(i => {
          return {
            ...i,
            label: {
              position: i.depth === getNodeMaxDepth(nodes) ? 'left' : 'right',
            },
          };
        }),
        links: links,
        lineStyle: {
          color: 'source',
          curveness: 0.5,
        },
      },
    ],
  };
};
const ENERGYID = 100; // 100 为综合能耗

const EnergyFlowAnalysis = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [currentDateType, setCurrentDateType] = useState<string>('M');
  const [currentDate, setCurrentDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [currentSelectedIds, setCurrentSelectedIds] = useState<number[]>([]);
  const [datePickerValueMonth, setDatePickerValueMonth] = useState<Dayjs>(dayjs(dayjs(), 'YYYY-MM'));
  const [urlSearchParams] = useSearchParams();
  let url_date = urlSearchParams.get('date') ?? undefined;
  const [BaseCenterHierarchyOptions, setBaseCenterHierarchyOptions] = useState<baseCenterTypes[]>([]);
  const [choseproductionBaseId, setChoseProductionBaseId] = useState<number>();
  const [choseWorkerCenterIds, setChoseWorkCenterIds] = useState<number[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedMediumType, setSelectedMediumType] = useState<Key>('综合能耗');
  const [selectedBasePhysicalQuantityId, setSelectedBasePhysicalQuantityId] = useState<number>(0); // 基础物理量
  const memoryData = useRef<Map<string, dayjs.Dayjs>>(new Map());
  const [message, setMessage] = useState<string>('-');
  const [type, setType] = useState<keyof TypeConfig>('productionBase');
  const [detailDataOutSide, setDetailDataOutSide] = useState<V2LeanEnergyFlowAnalysisDetailDataPostResponse>({});
  const [maxEnergy, setMaxEnergy] = useState<any>({});
  const [tableData, setTableData] = useState<V2LeanEnergyFlowAnalysisFlowDetailProductionBasePostResponse['list']>([]);

  useEffect(() => {
    if (!!currentDate && choseWorkerCenterIds.length > 0) {
      const detailParams = {
        energyMediumId: currentSelectedIds.length === 0 ? undefined : currentSelectedIds[0],
        date: currentDate,
        resolution: currentDateType,
      };
      apiV2LeanEnergyFlowAnalysisFlowDetailProductionBasePost({
        ...detailParams,
        workCenterIds: choseWorkerCenterIds,
      }).then(res => {
        findMaxAmount(res.list);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedIds, currentDate, choseWorkerCenterIds, currentDateType]);

  // 级联框生产基地
  useEffect(() => {
    if (
      BaseCenterHierarchyOptions &&
      BaseCenterHierarchyOptions.length > 0 &&
      choseWorkerCenterIds &&
      choseWorkerCenterIds.length === 0
    ) {
      const findData = BaseCenterHierarchyOptions.find(
        (i: baseCenterTypes) => i.id === BaseCenterHierarchyOptions[0].id
      );
      if (isNil(findData) || isNil(findData?.workCenterHierarchy)) return;
      const resultMap = findData.workCenterHierarchy.map((item: workCenterHierarchyType) => [
        BaseCenterHierarchyOptions[0].id,
        item.id,
      ]);
      form.setFieldsValue({
        workCenterIds: resultMap,
      });
      const setFilterSelected = Array.from(new Set(resultMap.map((item: any) => item[1] as number)));
      setChoseProductionBaseId(BaseCenterHierarchyOptions[0].id);
      setChoseWorkCenterIds(setFilterSelected as number[]);

      const childrenNames = findData.workCenterHierarchy
        .map((child: workCenterHierarchyType) => child?.name)
        .join('、');
      setMessage(`${BaseCenterHierarchyOptions[0].name}下${childrenNames}工作中心`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BaseCenterHierarchyOptions, choseWorkerCenterIds]);

  useEffect(() => {
    if (url_date) {
      setCurrentDateType('M');
      setCurrentDate(dayjs(url_date).format('YYYY-MM-DD'));
      setDatePickerValueMonth(dayjs(url_date, 'YYYY-MM'));
    }
  }, [url_date]);

  useEffect(() => {
    apiV2ProductionBaseCenterHierarchyPost({}).then(res => {
      const mappedData = res.list?.map(item => ({
        ...item,
        name: `${item.code}-${item.name}`,
        disabled: isNil(item.workCenterHierarchy) ? true : false,
        workCenterHierarchy: (item?.workCenterHierarchy ?? [])?.map(hierarchyItem => ({
          ...hierarchyItem,
          name: `${hierarchyItem.code}-${hierarchyItem.name}`,
        })),
      }));
      setBaseCenterHierarchyOptions(mappedData as baseCenterTypes[]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: detailData } = useRequest(
    () => {
      return apiV2LeanEnergyFlowAnalysisBannerPost({
        productionBaseId: choseproductionBaseId,
        workCenterIds: choseWorkerCenterIds,
        date: currentDate,
        resolution: currentDateType,
      });
    },
    {
      ready: !!choseproductionBaseId && !!currentDate && choseWorkerCenterIds.length > 0,
      refreshDeps: [choseproductionBaseId, currentDate, choseWorkerCenterIds, currentDateType],
    }
  );

  const { data: bannerInfo } = useRequest(
    () => {
      return apiV2LeanEnergyFlowAnalysisMomDataPost({
        productionBaseId: choseproductionBaseId,
        workCenterIds: choseWorkerCenterIds,
        date: currentDate,
        resolution: currentDateType,
      });
    },
    {
      ready: !!choseproductionBaseId && !!currentDate && choseWorkerCenterIds.length > 0,
      refreshDeps: [choseproductionBaseId, currentDate, choseWorkerCenterIds, currentDateType],
    }
  );

  useEffect(() => {
    if (isNil(currentSelectedIds) || !currentDateType || choseWorkerCenterIds.length === 0 || !bannerInfo) return;
    apiV2LeanEnergyFlowAnalysisDetailDataPost({
      energyMediumId: ENERGYID,
      date: currentDate,
      resolution: currentDateType,
      productionBaseId: choseproductionBaseId,
      workCenterIds: choseWorkerCenterIds,
    }).then(res => {
      setDetailDataOutSide(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerInfo, choseWorkerCenterIds, currentDateType, currentSelectedIds]);

  const btnList = useMemo(() => {
    let arr = bannerInfo?.list ? [...bannerInfo.list] : null;
    return arr;
  }, [bannerInfo]);

  const { data: flowChartData, loading } = useRequest(
    () => {
      return apiV2LeanEnergyFlowAnalysisFlowChartPost({
        productionBaseId: choseproductionBaseId,
        workCenterIds: choseWorkerCenterIds,
        date: currentDate,
        resolution: currentDateType,
        energyMediumId: currentSelectedIds && currentSelectedIds.length === 0 ? ENERGYID : currentSelectedIds[0],
        basePhysicalQuantityId: selectedBasePhysicalQuantityId,
      });
    },
    {
      ready: choseWorkerCenterIds.length > 0 && !!currentDate,
      refreshDeps: [choseWorkerCenterIds, currentDate, currentDateType, currentSelectedIds],
    }
  );
  /**
   * echart中显示的数据是value --> tceValue(转换后的标准煤值)
   * tooltip中显示的数据是trueValue --> value(真实值)
   *
   */
  const labelTransformInfo = useMemo(() => {
    let nodesObj = new Map();
    flowChartData?.points?.forEach((item: pointsType) => {
      nodesObj.set(item.id, item.name);
    });
    return nodesObj;
  }, [flowChartData]);
  const nodes = useMemo(() => {
    return (
      flowChartData?.points?.map((item: pointsType) => {
        let nameBak = item.id?.includes('ENERGY_UNIT') ? 'ENERGY_UNIT' : item.id?.replace(`${item.initId ?? ''}`, '');
        return {
          name: item.id, // name值不唯一, 故不能取值name
          value: item.tceValue,
          trueValue: item.value,
          unit: item.unit,
          list: item.list,
          nameBak: nameBak,
          depth: (item.level ?? 1) - 1, //以0开始
          // WORK_PROCEDURE203 --> WORK_PROCEDURE
          itemStyle: {
            color: item.id?.includes('ENERGY_CONSUMPTION') ? colorCategory[item.energyMediumId!] : colors[nameBak!],
          },
        };
      }) ?? []
    );
  }, [flowChartData]);
  const links = useMemo(() => {
    return (
      flowChartData?.edges?.map((item: edgesType) => {
        return {
          ...item,
          source: item.sourceId,
          target: item.targetId,
          value: item.tceValue,
          trueValue: item.value,
          energyMediumName: item.energyMediumName,
          lineStyle: {
            color: colorCategoryEdge[item.energyMediumId!],
            opacity: 0.8,
          },
        };
      }) ?? []
    );
  }, [flowChartData]);

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setCurrentDate(dayjs(dateString as string).format('YYYY-MM-DD'));
    date && memoryData.current.set(currentDateType, date);
    if (date && currentDateType === 'M') setDatePickerValueMonth(date);
  };

  const findMaxAmount = (data: any) => {
    const maxEnergy = Math.max(...data.map((item: any) => item.energyAmount));
    const maxEnergyObject = data.find((item: any) => item.energyAmount === maxEnergy);
    setMaxEnergy(maxEnergyObject);
  };

  // const onEvents = {
  //   click: (params: any) => {
  //     console.log(params);
  //     setOpen(true);
  //   },
  // };

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapperStyles}>
      <div className={styles.search_box}>
        <Form form={form} isOrigin>
          <Row justify="space-between">
            <Form.Item style={{ marginBottom: 0 }} name="workCenterIds" label="生产基地" className={styles.workCenter}>
              <Cascader
                options={BaseCenterHierarchyOptions}
                multiple
                getPopupContainer={triggerNode => triggerNode.parentNode}
                style={{ width: 300 }}
                allowClear={false}
                maxTagCount="responsive"
                showSearch
                onChange={(value, workCenterOptions) => {
                  const productionBaseId = Array.from(new Set(workCenterOptions.map((item: any) => item[0].id))).pop();
                  console.log(productionBaseId);
                  const filterSelected = value.filter(subArray => subArray[0] === productionBaseId);
                  const setFilterSelected = Array.from(new Set(filterSelected.map(item => item[1] as number)));
                  if (choseproductionBaseId !== productionBaseId) {
                    form.setFieldsValue({ workCenterIds: filterSelected });
                  }
                  setChoseWorkCenterIds(setFilterSelected);
                  setChoseProductionBaseId(productionBaseId);
                  function filterArray(arr: any) {
                    const lastCode = arr[arr.length - 1][0].code;
                    return arr.filter((subArray: ProductionBaseType[]) => subArray[0].code === lastCode);
                  }
                  const resultArray = filterArray(workCenterOptions);
                  const message = concatMessage(resultArray as ProductionBaseType[][]);
                  setMessage(message);
                }}
                changeOnSelect
                showCheckedStrategy={'SHOW_CHILD'}
                fieldNames={{ label: `name`, value: 'id', children: 'workCenterHierarchy' }}
                placeholder="请选择工作中心"
              />
            </Form.Item>
          </Row>
        </Form>
      </div>
      <div>
        <Divider style={{ margin: '18px 0' }} />
      </div>
      <div className={styles.time_box}>
        <Space size={10}>
          <Radio.Group
            onChange={e => {
              setCurrentDateType(e.target.value);
              url_date = undefined;
              let memeryDate = memoryData.current.get(e.target.value);
              setCurrentDate(
                memeryDate
                  ? dayjs(memeryDate).format(formateCommon[e.target.value])
                  : dayjs().format(formateCommon[e.target.value])
              );
              if (e.target.value === 'M') setDatePickerValueMonth(dayjs(dayjs(), 'YYYY-MM'));
            }}
            defaultValue="M"
            buttonStyle="solid"
          >
            <Radio.Button value="D">按日</Radio.Button>
            <Radio.Button value="M">按月</Radio.Button>
            <Radio.Button value="Y">按年</Radio.Button>
          </Radio.Group>
          <div>
            {currentDateType === 'D' && (
              <DatePicker
                style={{ width: 227 }}
                allowClear={false}
                defaultValue={memoryData.current.get(currentDateType) ?? dayjs(dayjs(), 'YYYY-MM-DD')}
                onChange={onChange}
              />
            )}
            {currentDateType === 'M' && (
              <DatePicker
                style={{ width: 227 }}
                allowClear={false}
                value={memoryData.current.get(currentDateType) ?? datePickerValueMonth}
                onChange={onChange}
                picker="month"
              />
            )}
            {currentDateType === 'Y' && (
              <DatePicker
                allowClear={false}
                style={{ width: 227 }}
                defaultValue={memoryData.current.get(currentDateType) ?? dayjs(dayjs(), 'YYYY')}
                onChange={onChange}
                picker="year"
              />
            )}
          </div>
        </Space>

        <Button
          type="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          能流详情
        </Button>
      </div>
      {choseWorkerCenterIds && (
        <div className={styles.tips_box}>
          <BannerInfo
            currentDateType={currentDateType}
            currentDate={currentDate}
            detailData={detailData}
            maxEnergy={maxEnergy}
            detailDataOutSide={detailDataOutSide}
            message={message}
          />
        </div>
      )}
      <Spin spinning={loading}>
        {choseWorkerCenterIds && bannerInfo && btnList && btnList.length > 0 ? (
          <div className={styles.echart_box}>
            <div className={styles.tabs}>
              <MediumSwipper
                setCurrentSelectedIds={setCurrentSelectedIds}
                list={btnList}
                selectedMediumType={selectedMediumType}
                selectedBasePhysicalQuantityId={selectedBasePhysicalQuantityId}
                setSelectedMediumType={setSelectedMediumType}
                setSelectedBasePhysicalQuantityId={setSelectedBasePhysicalQuantityId}
              />
            </div>
            <ReactEcharts
              style={{ height: `calc(100vh - 445px)`, minHeight: '485px', overflowX: 'hidden', width: '100%' }}
              option={getChartOption(nodes, links, currentSelectedIds, labelTransformInfo)}
              notMerge
              // onEvents={onEvents}
              lazyUpdate={false}
            />
          </div>
        ) : (
          <div className={styles.empty_box}>
            <Empty />
          </div>
        )}
      </Spin>
      {open && (
        <EnergyFlowDetailsModal
          choseproductionBaseId={choseproductionBaseId}
          setTableData={setTableData}
          tableData={tableData}
          currentSelectedIds={currentSelectedIds}
          open={open}
          setOpen={setOpen}
          currentDate={currentDate}
          currentDateType={currentDateType}
          choseWorkerCenterIds={choseWorkerCenterIds}
          selectedMediumType={selectedMediumType}
          selectedBasePhysicalQuantityId={selectedBasePhysicalQuantityId}
          setType={setType}
          type={type}
        />
      )}
    </Wrapper>
  );
};
export default EnergyFlowAnalysis;
