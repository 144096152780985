export interface PageResponse<T> {
  page: number;
  size: number;
  total: number;
  list: Array<T>;
}

export interface PageRequest {
  page: number;
  size: number;
}
// 抄表导入日志接口SearchParams 的校验
export interface PageRequestDates {
  page: string;
  size: string;
}

export enum OuStatus {
  DISABLE = 2,
  ENABLE = 1,
}

export const OuStatusDisplay = {
  [OuStatus.DISABLE]: '作废',
  [OuStatus.ENABLE]: '启用',
};

export enum OuStaffType {
  MEMBER,
  ADMINISTRATOR,
}

export const OuStaffTypeDisplay = {
  [OuStaffType.MEMBER]: '成员',
  [OuStaffType.ADMINISTRATOR]: '管理员',
};

export enum FilterMode {
  NOCONFIG = 0,
  NUMBERREASONABLERANGE,
  GROWTHREASONABLERANGE,
}

export const FilterModeDisplay = {
  [FilterMode.NOCONFIG]: '无',
  [FilterMode.NUMBERREASONABLERANGE]: '数值合理区间',
  [FilterMode.GROWTHREASONABLERANGE]: '增长值合理区间',
};
