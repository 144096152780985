import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Space } from 'antd';
import { Button, Modal, Steps } from '@maxtropy/components';
import RangeContent from '@/pages/CarbonEmissionReporting/Reporting/RangeEmissions/RangeContent';
import { EmissionsRange, RangeDataTypes } from '@/pages/CarbonEmissionReporting/Reporting/RangeEmissions/types';
import styles from './index.module.scss';
import qs from 'qs';
import { V2CarbonEmissionFillingDetailPostResponse } from '@maxtropy/device-customer-apis-v2';

interface Props {
  fillInDetails?: V2CarbonEmissionFillingDetailPostResponse;
  update: () => void;
}
const RangeEmissions: React.FC<Props> = ({ fillInDetails, update }) => {
  const [current, setCurrent] = useState(0);
  const [emissionFillingId, setEmissionFillingId] = useState<number>();
  const [urlSearchParams] = useSearchParams();
  let url_accountingUnitId = urlSearchParams.get('accountingUnitId') ?? undefined;

  const navigete = useNavigate();

  const [rangeData, setRangeData] = useState<RangeDataTypes>({
    range1: null,
    range2: null,
    range3: null,
  });

  const steps = useMemo(() => {
    const steps = [];
    for (let i = 1; i <= 3; i++) {
      const rangeKey = `range${i}`;
      if (rangeData[rangeKey]?.length! > 0) {
        steps.push({
          title: `范围${i}排放`,
          content: (
            // @ts-ignore 一个用V2，一个用自己写的types
            <RangeContent rangeData={rangeData[rangeKey]} emissionFillingId={emissionFillingId} update={update} />
          ),
        });
      }
    }
    return steps;
  }, [emissionFillingId, rangeData, update]);

  const items = steps.map(item => ({ key: item.title, title: item.title }));

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleBeforeLeave = () => {
    Modal.confirm({
      title: '确认是否离开?',
      content: '离开页面将会丢失未保存的数据',
      okText: '离开',
      cancelText: '取消',
      onOk: () => {
        navigete(
          `/leanCarbonManagement/carbonEmissionInventory/carbonEmissionReporting?${qs.stringify({
            accountingUnitId: url_accountingUnitId,
          })}`
        );
      },
    });
  };

  useEffect(() => {
    if (!fillInDetails) return;
    const { guideClassificationList, id } = fillInDetails;
    const rangeData = {
      range1: guideClassificationList?.filter(item => item.range === EmissionsRange.RangeOne),
      range2: guideClassificationList?.filter(item => item.range === EmissionsRange.RangeTwo),
      range3: guideClassificationList?.filter(item => item.range === EmissionsRange.RangeThree),
    };
    setEmissionFillingId(id);
    setRangeData(rangeData);
  }, [fillInDetails]);

  return (
    <>
      <Steps current={current} items={items} className={styles.steps} />

      <div>{steps[current]?.content}</div>

      {/* 按钮区域 */}
      <Space size={8}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            下一页
          </Button>
        )}
        {current > 0 && <Button onClick={() => prev()}>上一页</Button>}
        <Button type="default" onClick={() => handleBeforeLeave()}>
          关闭
        </Button>
      </Space>
    </>
  );
};

export default RangeEmissions;
