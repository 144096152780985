import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { Col, Form, Row } from 'antd';
import { isEmpty, isNil } from 'lodash-es';
import {
  ActionType,
  DataPointType,
  DataProperty,
  DataPropertyType,
  VALUE_TYPE,
  ModbusPointBase,
  BYTE_LENGTH,
} from '../../../../../types';
import { PointContext } from '../../../../EdgeDevicePointInfo/contextTypes';
import { useDataProperties } from '../../../../EdgeDevicePointInfo/mockingbird/Point/hooks';
import FormSubmit from '../../../../EdgeDevicePointInfo/mockingbird/FormSubmit';
import {
  byteLengthFormatter,
  checkNum,
  identifierValidatorBuilder,
} from '../../../../EdgeDevicePointInfo/mockingbird/Point/entity';
import { BatchEdgeContent } from '../../../contentTypes';
import { PointOperateType } from '../../../interface';
import { Input, Modal, Radio, Select, message, InputNumber, ShowInput, Tooltip } from '@maxtropy/components';
import { FilterMode, FilterModeDisplay } from '@/shared/const';
import { validatorValueRange } from '@/shared/utils/utils';
import {
  getDefaultHandleModeAndValueType,
  getHandleModeMapping,
  getValueTypeMapping,
} from '@/shared/components/EdgeDevicePointInfo/mockingbird/Point/s7Point/CreateForm';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export interface FormModbusPoint extends ModbusPointBase {
  actionType?: ActionType;
  id?: number;
  pointType?: DataPointType;
  hasProperty?: boolean;
  writable?: boolean;
  dataPropertyId?: number;
  physicalUnitId?: number;
  dataPropertyName?: string;
  physicalUnitGeneralName?: string;
  identifier?: string;
  length?: any;
}

export interface PointFormDialogProps {
  row?: FormModbusPoint;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
  onCancel: () => void;
  onOk: (values: any) => void;
  alterMode?: boolean;
}

const PointFormDialog: React.FC<PointFormDialogProps> = props => {
  const { row, dataPropertiesAll, usedProperties, usedIdentifier, onCancel, onOk, alterMode } = props;
  const { setRow } = useContext(PointContext);
  const { baseForm } = useContext(BatchEdgeContent);
  const { pointOperateType } = baseForm?.getFieldsValue();

  const { dataProperties, dataPropertyId, setDataPropertyId, units, dataPropertyType } = useDataProperties(
    dataPropertiesAll,
    usedProperties
  );

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const initStatusRef = useRef(true);

  const valueFilterMode = Form.useWatch('valueFilterMode', form);
  const length = Form.useWatch('length', form);

  const initFormValues = useCallback(
    (
      length: BYTE_LENGTH | undefined,
      handleMode: number | undefined,
      valueType: VALUE_TYPE | undefined,
      rest: object = {}
    ) => {
      Promise.resolve()
        .then(() => setFieldsValue(Object.assign({ length }, {})))
        .then(() =>
          setFieldsValue(
            Object.assign(
              {
                ...rest,
              },
              !isNil(valueType) ? { valueType } : {},
              !isNil(handleMode) ? { handleMode } : {}
            )
          )
        )
        .finally(() => {
          initStatusRef.current = false;
        });
    },
    [setFieldsValue]
  );

  // 初始化
  useEffect(() => {
    if (row && !isNil(row.length)) {
      // 编辑 和 保存并继续添加
      const { length, handleMode, valueType, ...rest } = row;
      initFormValues(length, handleMode, valueType, rest);
    } else {
      // 新建
      const { handleMode, valueType } = getDefaultHandleModeAndValueType(undefined);
      initFormValues(undefined, handleMode, valueType, row);
    }
  }, [row]);

  useEffect(() => {
    if (row) {
      setDataPropertyId(row.dataPropertyId);
    }
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  useEffect(() => {
    if (dataPropertyType === DataPropertyType.YC && !isEmpty(units) && row) {
      form.setFieldsValue({
        physicalUnitId: units?.find(i => i.id === row.physicalUnitId)?.id ?? units?.find(unit => unit.default)?.id,
        physicalUnitGeneralName:
          units?.find(i => i.id === row.physicalUnitId)?.generalName ?? units?.find(unit => unit.default)?.generalName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPropertyId, dataPropertyType, units, row]);

  const onValuesChange = (changedValues: { [key: string]: any }) => {
    const { length } = changedValues;

    if (length !== undefined) {
      form.setFieldsValue(getDefaultHandleModeAndValueType(length));
    }
  };

  const submit = async (shouldContinue?: boolean) => {
    const values = await form.validateFields();
    const submitValues = {
      ...values,
    };
    if (shouldContinue) {
      await onOk(submitValues);
      form.resetFields();
      setRow?.({
        ...values,
        pointType: DataPointType.BASE_POINT,
        dataPropertyId: undefined,
        physicalUnitId: undefined,
        identifier: undefined,
      });
    } else {
      await onOk(submitValues);
      onCancel();
    }
  };

  return (
    <Modal
      open={row?.pointType === DataPointType.BASE_POINT}
      onCancel={onCancel}
      title={`${pointOperateType === PointOperateType.UPDATE ? '编辑' : '添加'}采集点`}
      centered={true}
      maskClosable={false}
      footer={<FormSubmit submit={submit} cancel={onCancel} alterMode={alterMode} />}
    >
      <div style={{ height: 600, overflow: 'auto' }}>
        <Form {...formLayout} form={form} labelWrap={true} onValuesChange={onValuesChange}>
          {alterMode ? <Form.Item noStyle name="dataPropertyId" /> : <Form.Item noStyle name="dataPropertyName" />}
          <Form.Item noStyle name="physicalUnitGeneralName" />

          {alterMode ? (
            <Form.Item noStyle name="hasProperty" />
          ) : (
            <Form.Item
              name="hasProperty"
              label="采集点类型"
              initialValue={true}
              rules={[{ required: true, message: '请选择' }]}
            >
              <Radio.Group disabled={!isNil(row?.id)}>
                <Radio value={true}>建模采集点</Radio>
                <Radio value={false}>非建模采集点</Radio>
              </Radio.Group>
            </Form.Item>
          )}

          {alterMode ? (
            <>
              <Form.Item
                name={row?.hasProperty ? 'dataPropertyName' : 'identifier'}
                label={row?.hasProperty ? '数据属性' : '非建模点标识符'}
              >
                <ShowInput />
              </Form.Item>
              {dataPropertyType === DataPropertyType.YC && (
                <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                  <Select
                    onChange={value => {
                      form.setFieldsValue({
                        physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                      });
                    }}
                    placeholder="请选择"
                  >
                    {units?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.generalName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </>
          ) : (
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => prevValues.hasProperty !== curValues.hasProperty}
            >
              {({ getFieldValue }) =>
                getFieldValue('hasProperty') ? (
                  <>
                    <Form.Item
                      name="dataPropertyId"
                      label="数据属性"
                      rules={[{ required: true, message: '请选择数据属性' }]}
                    >
                      <Select
                        onChange={value => {
                          form.setFieldsValue({
                            dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                          });
                          setDataPropertyId(value as number);
                          form.setFieldsValue({
                            physicalUnitId: undefined,
                            physicalUnitGeneralName: '',
                          });
                        }}
                        placeholder="请输入/选择"
                        showSearch
                        filterOption={(input, option) =>
                          (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                      >
                        {dataProperties?.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {dataPropertyType === DataPropertyType.YC && (
                      <Form.Item name="physicalUnitId" label="单位" rules={[{ required: true, message: '请选择单位' }]}>
                        <Select
                          onChange={value => {
                            form.setFieldsValue({
                              physicalUnitGeneralName: units?.find(item => item.id === value)?.generalName,
                            });
                          }}
                          placeholder="请选择"
                        >
                          {units?.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.generalName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </>
                ) : (
                  <Form.Item
                    required
                    name="identifier"
                    label="非建模采集点标识符"
                    rules={[
                      {
                        validator: identifierValidatorBuilder(usedIdentifier),
                      },
                    ]}
                  >
                    <Input placeholder="请输入" />
                  </Form.Item>
                )
              }
            </Form.Item>
          )}

          <Form.Item
            name="writable"
            label="是否可写"
            initialValue={false}
            rules={[{ required: true, message: '请选择' }]}
          >
            <Radio.Group>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            required
            label="地址"
            tooltip={{
              overlayStyle: { maxWidth: 560 },
              icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
              title: (
                <div style={{ color: 'var(--mx-text-secondary-color)' }}>
                  <div style={{ color: 'var(--mx-warning-color)' }}>数据块数据格式（最常用）:</div>
                  <div>
                    <span style={{ color: 'var(--mx-text-base-color)' }}>第一种：</span>DBx.DBXy.z =&gt;
                    x是DB块编号，y是字节，z是位 =&gt; 结果是布尔值
                    <br />
                    <span style={{ color: 'var(--mx-text-base-color)' }}>第二种：</span>DBx.DBbc =&gt;
                    x是DB块编号，b是数据格式，c是数据位置, <br />
                    &nbsp;b为：B（字节）、W（字=2字节，一般存储短整数）、D（双字=4字节，可以存储整数或浮点数）
                    <br />
                    <span style={{ color: 'var(--mx-text-base-color)' }}>示例：</span>
                    <br />
                    DB0.DBX0.0：数据块0第0字节0位
                    <br />
                    DB2.DBD4：数据块2第4双字（4字节）
                    <br />
                  </div>
                  <div style={{ color: 'var(--mx-warning-color)' }}>存储区格式（较少用）:</div>
                  <div>
                    <span style={{ color: 'var(--mx-text-base-color)' }}>第一种：</span>xy.z =&gt;
                    x是存储区格式，y是字节，z是位 ={'>'} 结果是布尔值
                    <br />
                    <span style={{ color: 'var(--mx-text-base-color)' }}>第二种：</span>xbc ={'>'}{' '}
                    x是存储区格式，b是数据格式，c是数据位置
                    <br />
                    x为：I（输入区），Q（输出区），M（位存储区）
                    <br />
                    b为：B（字节）、W（字=2字节，一般存储短整数）、D（双字=4字节，可以存储整数或浮点数）
                    <br />
                    <span style={{ color: 'var(--mx-text-base-color)' }}>示例：</span>
                    <br />
                    I0.0： 输入区0字节0位
                    <br />
                    MW100：位存储区第100字（2字节）
                    <br />
                  </div>
                </div>
              ),
            }}
          >
            <Input placeholder="excel模版导入" disabled />
          </Form.Item>
          <Form.Item name="length" label="字节长度" rules={[{ required: true, message: '请选择' }]}>
            <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}>
              <Option value={BYTE_LENGTH.ZERO}>{byteLengthFormatter[BYTE_LENGTH.ZERO]}</Option>
              <Option value={BYTE_LENGTH.ONE}>{byteLengthFormatter[BYTE_LENGTH.ONE]}</Option>
              <Option value={BYTE_LENGTH.TWO}>{byteLengthFormatter[BYTE_LENGTH.TWO]}</Option>
              <Option value={BYTE_LENGTH.FOUR}>{byteLengthFormatter[BYTE_LENGTH.FOUR]}</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle dependencies={['length']}>
            {() => {
              const formatter = getHandleModeMapping(length);

              return (
                length !== 0 &&
                length !== 1 && (
                  <Form.Item name="handleMode" label="处理方式" rules={[{ required: true, message: '请选择' }]}>
                    <Select
                      placeholder="请选择"
                      getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                    >
                      {Object.entries(formatter || {}).map(([value, key]) => {
                        return (
                          <Option key={value} value={Number(value)}>
                            {key}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )
              );
            }}
          </Form.Item>
          <Form.Item noStyle dependencies={['length']}>
            {() => {
              const formatter = getValueTypeMapping(length);
              return (
                <Form.Item name="valueType" label="解码类型" rules={[{ required: true, message: '请选择' }]}>
                  <Select placeholder="请选择" getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}>
                    {Object.entries(formatter || {}).map(([value, key]) => {
                      return (
                        <Option key={value} value={Number(value)}>
                          {key}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              return (
                <>
                  <Form.Item
                    required
                    label="乘积系数k"
                    tooltip={{
                      title: 'y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数',
                      icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                    }}
                  >
                    <Input placeholder="excel模版导入" disabled />
                  </Form.Item>
                  <Form.Item
                    required
                    label="偏移系数b"
                    tooltip={{
                      title: 'y=kx+b，x为直采数据，y为输出数据，k为乘积系数，b为偏移系数',
                      icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                    }}
                  >
                    <Input placeholder="excel模版导入" disabled />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            name="valueFilterMode"
            label="数值过滤模式"
            initialValue={0}
            wrapperCol={{ span: 18 }}
            rules={[{ required: true, message: '请选择' }]}
            shouldUpdate
          >
            <Radio.Group
              onChange={v => {
                if (v.target?.value === FilterMode.GROWTHREASONABLERANGE) {
                  message.warning('新网关版本在3.7.30版本以上');
                }
              }}
            >
              {Object.entries(FilterModeDisplay).map(([k, v]) => (
                <Radio key={k} value={Number(k)}>
                  {v}
                  {Number(k) === FilterMode.GROWTHREASONABLERANGE && (
                    <Tooltip title="注意针对累积量">
                      <InfoCircleOutlined
                        style={{ color: 'var(--mx-warning-color)', fontSize: 12, marginLeft: '4px' }}
                      />
                    </Tooltip>
                  )}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {valueFilterMode === 1 && (
            <Form.Item
              label="数值合理区间"
              name="valueRange"
              dependencies={['valueFloor', 'valueCeiling']}
              rules={[
                form => ({
                  validator: () => validatorValueRange(form),
                }),
              ]}
              tooltip={{
                title: '区间外数值将被直接滤除，视为无效数值',
                icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
              }}
            >
              <Row gutter={8}>
                <Col span={11}>
                  <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={2}>--</Col>
                <Col span={11}>
                  <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          )}
          {valueFilterMode === 2 && (
            <>
              <Form.Item
                label="增长值合理区间"
                required={true}
                name="valueRange"
                dependencies={['valueFloor', 'valueCeiling']}
                rules={[
                  form => ({
                    validator: () => validatorValueRange(form),
                  }),
                ]}
                tooltip={{
                  title: (
                    <div>
                      <div>新网关版本在3.7.30版本以上</div>
                      <div>网关采集相邻数据的差值对应的合理范围；（设备的采集频率）</div>
                    </div>
                  ),
                  icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                }}
              >
                <Row gutter={8}>
                  <Col span={9}>
                    <Form.Item name="valueFloor" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>--</Col>
                  <Col span={9}>
                    <Form.Item name="valueCeiling" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={3}>/min</Col>
                </Row>
              </Form.Item>
              <Form.Item
                name="valueFilterCnt"
                label="边缘侧异常数据过滤个数"
                initialValue={5}
                rules={[{ required: true, message: '请输入' }]}
              >
                <InputNumber
                  placeholder="请输入"
                  min={1}
                  max={20}
                  precision={0}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
              <Form.Item
                label="云端增长值合理区间"
                name="cloudValueRange"
                dependencies={['cloudValueFloor', 'cloudValueCeiling']}
                tooltip={{
                  title: '设备原始数据里1分钟里数据增长值的合理范围',
                  icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />,
                }}
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <Form.Item name="cloudValueFloor" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={2}>--</Col>
                  <Col span={8}>
                    <Form.Item name="cloudValueCeiling" noStyle rules={[{ validator: checkNum }]}>
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>{units?.find(i => i.default)?.generalName ?? '-'}/min</Col>
                </Row>
              </Form.Item>
              <Form.Item name="cloudValueFilterCnt" label="云端异常数据过滤个数" initialValue={5}>
                <InputNumber
                  placeholder="请输入"
                  min={1}
                  max={1000}
                  precision={0}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
            </>
          )}
          {!alterMode && (
            <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '长度不能超过50个字符' }]}>
              <Input placeholder="请输入备注" />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default PointFormDialog;
