import {
  DeleteOutlined,
  DeploymentUnitOutlined,
  GlobalOutlined,
  PlusOutlined,
  ThunderboltFilled,
} from '@ant-design/icons';
import { Col, Divider, Form, Row, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { apiV2ElectricityAccountListByCustomerMcidPost } from '@maxtropy/device-customer-apis-v2';
import styles from '../index.module.scss';
import { loadTransformers } from '../columns';

import { Button, Modal, Select, InputNumber, useAsync } from '@maxtropy/components';

export interface changeTargetLoadProps {
  value: any;
  key: 'targetLoad' | 'targetLoadRate' | 'electricityAccountId' | 'electricityAccountNumber';
  data: loadTransformers;
}

interface ListProps {
  values: loadTransformers[];
  addAction?: (value: loadTransformers) => void;
  deleteAction?: (value: loadTransformers) => void;
  changeTargetLoad: (v: changeTargetLoadProps) => void;
  disabled?: boolean;
  mcid: string;
  setLevel: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsModalEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalEditInfo?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const List: React.FC<ListProps> = ({
  setModalEditInfo,
  setIsModalEdit,
  values,
  addAction,
  deleteAction,
  changeTargetLoad,
  disabled,
  mcid,
  setLevel,
}) => {
  const [editingTransformer, setEditingTransformer] = useState<loadTransformers>();
  const [form] = Form.useForm();

  const accounts = useAsync(
    useCallback(
      () => apiV2ElectricityAccountListByCustomerMcidPost({ customerMcid: mcid, containsInvalid: false }),
      [mcid]
    )
  );

  return (
    <>
      {editingTransformer && (
        <Modal
          open
          width={540}
          title="选择进线户号"
          onOk={() => {
            form.submit();
          }}
          onCancel={() => {
            setEditingTransformer(undefined);
          }}
        >
          <Form
            form={form}
            onFinish={values => {
              changeTargetLoad({
                value: {
                  electricityAccountId: values.electricityAccountId,
                  electricityAccountNumber: accounts?.list?.find(item => item.id === values.electricityAccountId)
                    ?.number!,
                },
                key: 'electricityAccountId',
                data: editingTransformer,
              });
              setEditingTransformer(undefined);
            }}
          >
            <Form.Item
              rules={[{ required: true, message: '请选择' }]}
              initialValue={editingTransformer.electricityAccountId}
              label={'进线户号'}
              name={'electricityAccountId'}
            >
              <Select placeholder={'请选择'}>
                {accounts?.list?.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {values.map(i => (
        <>
          {i.level === '1' && (
            <div className={styles.top}>
              <Space>
                <div>
                  <GlobalOutlined className={styles.icon} />
                  网络：<span className={styles.red}>{i.level}</span>
                </div>
                <div>
                  <DeploymentUnitOutlined className={styles.icon} />
                  节点编号：<span className={styles.red}>{i.sequence}#</span>
                </div>
                <div>
                  <ThunderboltFilled className={styles.icon} />
                  2级子网络变压器个数: <span className={styles.red}>1</span>
                </div>
              </Space>
              <Space>
                <Button type="dashed" disabled={disabled} className={styles.defaultBtn} onClick={() => addAction!(i)}>
                  <PlusOutlined />
                  添加2级网络变压器
                </Button>
                <Button type="dashed" disabled={disabled} className={styles.deleteBtn} onClick={() => deleteAction!(i)}>
                  <DeleteOutlined />
                  删除
                </Button>
              </Space>
            </div>
          )}
          <Form layout="vertical">
            <Row gutter={[16, 16]} className={styles.row}>
              <Col span={2}>
                <Form.Item label="层级">{i.level}级</Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="网格内编号">#{i.sequence}</Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="设备编号">{i.code ?? '--'}</Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="设备名称">{i.name ?? '--'}</Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="所属类目">{i.typeName ?? '--'}</Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="容量">{i.capacity ?? '--'}kVA</Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="目标负载">
                  <InputNumber
                    addonAfter="kW"
                    value={i.targetLoad}
                    min={0}
                    max={i.capacity}
                    disabled={disabled}
                    onChange={v => changeTargetLoad({ value: v, key: 'targetLoad', data: i })}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="目标负载率">
                  <InputNumber
                    addonAfter="%"
                    value={i.targetLoadRate}
                    min={0}
                    max={100}
                    disabled={disabled}
                    onChange={v => changeTargetLoad({ value: v, key: 'targetLoadRate', data: i })}
                  />
                </Form.Item>
              </Col>
              <Divider dashed style={{ margin: '-10px 0 10px' }} />
              <Col span={4}>
                <Form.Item label="总负载电表设备编号">{i.totalLoadDeviceCode ?? '--'}</Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="设备名称">{i.totalLoadDeviceName ?? '--'}</Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="生产负载电表设备编号">{i.productionLoadDeviceCode ?? '--'}</Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="设备名称">{i.productionLoadDeviceName ?? '--'}</Form.Item>
              </Col>
              {i.level === '0' && (
                <>
                  <Col span={3}>
                    <Form.Item label="进线户号">{i.electricityAccountNumber ?? '--'}</Form.Item>
                  </Col>
                  <Col offset={4} span={3}>
                    <Button disabled={disabled} type={'primary'} onClick={() => setEditingTransformer(i)}>
                      关联进线户号
                    </Button>
                    {/* <br />
                    <br />
                    <Button
                      disabled={disabled}
                      type={'primary'}
                      onClick={() => {
                        setLevel('0');
                        setModalEditInfo?.(i.deviceId);
                        setIsModalEdit?.(true);
                      }}
                    >
                      编辑
                    </Button> */}
                  </Col>
                </>
              )}
              {/* {i.level === '1' && (
                <>
                  <Col span={3}></Col>
                  <Col offset={4} span={3}>
                    <Button
                      disabled={disabled}
                      type={'primary'}
                      onClick={() => {
                        setLevel('1');
                        setModalEditInfo?.(i.deviceId);
                        setIsModalEdit?.(true);
                      }}
                    >
                      编辑
                    </Button>
                  </Col>
                </>
              )} */}
            </Row>
          </Form>
        </>
      ))}
    </>
  );
};

export default List;
