import { FC, useEffect, useMemo, useState } from 'react';
import { Checkbox, ShowInput } from '@maxtropy/components';
import { Col, Form, Row, Space } from 'antd';
import {
  DataProperty,
  getReportStrategyDeviceDataProperty,
  ReportStrategyDevicePageResponse,
} from '../../../../api/reportStrategy';
import { ReportStrategyStatisticalTypeEnum } from '@/shared/types';

export interface CheckDataPropertyProps {
  data?: ReportStrategyDevicePageResponse;
  value?: DataProperty[];
  statisticalType?: ReportStrategyStatisticalTypeEnum;
  onChange?: (value: DataProperty[]) => void;
}

const CheckDataProperty: FC<CheckDataPropertyProps> = props => {
  const { data, value, onChange, statisticalType } = props;

  const [form] = Form.useForm();

  const [checkData, setCheckData] = useState<DataProperty[]>([]);

  const [indeterminate, setIndeterminate] = useState(false);

  const [checkAll, setCheckAll] = useState(false);

  const plainOptions = useMemo(() => {
    return (checkData ?? []).map(i => ({
      label: i.name,
      value: i.id,
    }));
  }, [checkData]);

  useEffect(() => {
    if ((value ?? []).length === 0) {
      setIndeterminate(false);
      setCheckAll(false);
      return;
    }
    if ((value ?? []).length) {
      setIndeterminate((value ?? []).length < plainOptions.length);
      setCheckAll((value ?? []).length === plainOptions.length);
    }
  }, [value, checkData, plainOptions]);

  useEffect(() => {
    if (data && Number.isInteger(data.id) && statisticalType) {
      getReportStrategyDeviceDataProperty(data.id!, statisticalType).then(res => {
        setCheckData(res.deviceDataPropertyLinkList);
      });
    }
  }, [data, statisticalType]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        code: data.code,
        name: data?.name,
        deviceType: data.deviceType,
        ouName: data.ouName,
        tagList: (data.tagList ?? []).join(',') || undefined,
      });
    }
  }, [data, form]);

  const onLocalChange = (checkedValue: any[]) => {
    const newValue = checkData.filter(i => checkedValue.includes(i.id!));
    onChange?.(newValue);
  };

  const onCheckAllChange = (checked: boolean) => {
    checked ? onChange?.(checkData) : onChange?.([]);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Form autoComplete="off" form={form}>
        <Form.Item label="设备编号" name="code">
          <ShowInput />
        </Form.Item>
        <Form.Item label="设备名称" name="name">
          <ShowInput />
        </Form.Item>
        <Form.Item label="所属类目" name="deviceType">
          <ShowInput />
        </Form.Item>
        <Form.Item label="运营单元" name="ouName">
          <ShowInput />
        </Form.Item>
        <Form.Item label="设备标签" name="tagList">
          <ShowInput />
        </Form.Item>
      </Form>
      {(plainOptions ?? []).length > 0 && (
        <Checkbox
          indeterminate={indeterminate}
          onChange={e => {
            onCheckAllChange(e.target.checked);
          }}
          checked={checkAll}
        >
          全选
        </Checkbox>
      )}
      <Checkbox.Group style={{ width: '100%' }} value={(value ?? []).map(i => i.id!)} onChange={onLocalChange}>
        <Row style={{ width: '100%' }}>
          {plainOptions.map(i => (
            <Col key={i.value} span={6}>
              <Checkbox value={i.value}>{i.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Space>
  );
};

export default CheckDataProperty;
