import { AlarmChannel } from '@/shared/types';
import { fetch } from '@maxtropy/components';
import { AlarmLevel } from '@/shared/types';
import { PageRequest, PageResponse } from './page';

export interface AlarmLogRequest extends PageRequest {
  ouIds?: number[];
  deviceCode?: string;
  deviceName?: string;
  levels?: number;
  state?: number;
  startTime?: number;
  endTime?: number;
  deviceAssetCode?: string;
  deviceTypeId?: number[];
  physicalModelId?: number[];
  sortValues?: any[];
}

// 资产类型枚举
export enum deviceAssetTypeEmun {
  ENERGYSTORAGEARRAY = 1, // 储能阵列
  PVSTATION = 2, // 光伏站
  NETENERGY = 3, // 微网能源资产
  CHARGING = 4, // 充电站
  TOPOLOGY = 5, // 能源介质拓扑
  NEW_ENERGYSTORAGEARRAY = 6, // 新储能阵列
  GAS_PREPARATION_STATION = 7, // 气体制备站
  HVAC = 8, // 暖通空调
}

export interface deviceAssetsResponse {
  id: number;
  deviceAssetCode: string; // 设备资产编号
  createTime: string;
  deviceAssetId: number;
  deviceAssetType: deviceAssetTypeEmun;
  deviceId: number;
  updateTime: string;
}

export interface AlarmLogResponse {
  id: number;
  alarmName?: string;
  alarmLevel?: AlarmLevel;
  alarmTime?: string;
  recoveryTime?: string;
  readTime?: string;
  tenantCode?: string;
  tenantName?: string;
  deviceId?: string;
  deviceCode?: string;
  deviceName?: string;
  // 清流告警工单 id
  ruleLogWorkOrderId?: number;
  deviceAssets?: deviceAssetsResponse[]; // 能源资产信息
  alarmCode?: string;
  ous?: { id: number }[];
}

export interface NewRuleWithEnergyUnitServerAlarm {
  ruleName: string;
  alarmLevel: number;
  ouId: number;
  energyUnitGroupId: number;
  energyUnitIds: number[];
  alarmType: number;
  meterTypeIds: [];
  dayUpperLimitValue: any;
  weekUpperLimitValue: any;
  monthUpperLimitValue: any;
  openEarlyWarning?: boolean;
  daysOfMonthForEarlyWarning?: number[];
}
export interface UseEnergyUnitAlarmList extends PageRequest {
  alarmObjectName?: string;
  alarmLevels?: number[];
  startDate?: any;
  endDate?: string;
}

export function getAlarmLogList(
  query: AlarmLogRequest
): Promise<PageResponse<AlarmLogResponse> & { useSearchAfter: boolean; sortValues: any[] }> {
  return fetch(
    `/api/v2/rule/alarm/log/page/v2`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}
export interface createAlarmProps {
  name: string;
  ruleLogId: string;
}
// 创建报警工单
export function createAlarmWorkOrder(data: createAlarmProps) {
  return fetch(
    `/api/v2/rule/alarm/log/workOrder`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  );
}

// 查询报警工单
export interface WorkOrderDetailProps {
  name?: string;
  ruleName?: string;
  deviceName?: string;
  alarmLevel?: AlarmLevel;
  alarmTime?: any;
}
export function getAlarmWorkOrderDetail(id: string) {
  return fetch<WorkOrderDetailProps>(
    `/api/v2/rule/alarm/log/getWorkOrder`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
export interface AlarmLogDetailResponse extends AlarmLogResponse {
  ouId?: number;
  ouName?: string;
  ruleId?: number;
  ruleCode?: string;
  ruleName?: string;
  // 报警状态 0 恢复, 1 未恢复
  state?: 0 | 1;
  // 网关离线报警编号, 无代表不是网关离线报警
  edgeGatewayNo?: string | null;
  channel?: AlarmChannel;
}

export function getAlarmLogDetail(id: string): Promise<AlarmLogDetailResponse> {
  return fetch(
    `/api/v2/rule/alarm/log/details`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function readAlarmLog(id: string | number) {
  return fetch<{ flag: boolean }>(
    `/api/v2/rule/alarm/log/readAlarmLog`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface AlarmLogRuleRelatedPropertiesResponse {
  dataPropertyId: number;
  dataPropertyName: string;
  // 报警值 / 触发值
  alarmValue: number;
  recoverValue: number;
  realtimeValue: number;
  // 单位名称
  generalName: string;
  // 是否是录波数据
  isSthf: boolean;
}

export interface RelatedPropertyDetail {
  id: number;
  type: number;
  name: string;
  measurementType: number;
  // 单位名称
  generalName: string;
  enumValue: any;
}

// 获取报警记录关联属性以及属性值
export function getAlarmLogRuleRelatedProperties(
  id: string | number
): Promise<{ list: AlarmLogRuleRelatedPropertiesResponse[] }> {
  return fetch(
    `/api/v2/rule/alarm/log/rule/related/property-value/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取规则关联属性列表, 不存在属性值
export function getRuleRelatedPropertyList(id: string | number) {
  return fetch<{ list: RelatedPropertyDetail[] }>(
    `/api/v2/rule/alarm/log/related/property/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 高频录波响应
export interface AlarmLogPointSTFHData {
  ts: number;
  value: number;
}

// 高频录波数据
export function getAlarmLogPointSTFHList(
  alarmId: string | number,
  dataPropertyId: string | number
): Promise<{ list: AlarmLogPointSTFHData[] }> {
  return fetch(
    `/api/v2/rule/alarm/log/point-sthf-list`,
    {
      method: 'POST',
      body: JSON.stringify({ id: alarmId, dataPropertyId }),
    },
    true
  );
}

export interface OuResponse {
  id: number;
  name: string;
}

export function getOuList() {
  return fetch<{ list: OuResponse[] }>(
    `/api/v2/rule/alarm/log/ouListByRootMcid`,
    {
      method: 'POST',
    },
    true
  ).then(res => res?.list ?? []);
}

export function getOuListMember() {
  return fetch<{ list: OuResponse[] }>(
    `/api/v2/rule/alarm/log/ouListByRootMcid/member`,
    {
      method: 'POST',
    },
    true
  ).then(res => res?.list ?? []);
}

// 获取平台网关类目的 id 值
export function getPlatformGatewayId() {
  return fetch<{ id: number }>(
    '/api/v2/deviceType/platform/gateway/id',
    {
      method: 'POST',
    },
    true
  );
}
