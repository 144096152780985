import { MediumIndicatorDisplayUnit } from '@/api/energyMedium';
import { V2EnergyCompareGetConversionEfficiencyStaticsPostResponse } from '@maxtropy/device-customer-apis-v2';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { isNil } from 'lodash-es';

const icons = [
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiM4QkQ0RUEiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNCNkU3N0YiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNGRjk4NkEiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNGRjZEMkMiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjYycHgiIGhlaWdodD0iMzdweCIgdmlld0JveD0iMCAwIDYyIDM3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA0NC4xICg0MTQ1NSkgLSBodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2ggLS0+CiAgICA8dGl0bGU+R3JvdXA8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiM0QTkwRTIiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIwIiB5PSIwIiB3aWR0aD0iNjIiIGhlaWdodD0iMjYiPjwvcmVjdD4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlRyaWFuZ2xlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyOS41MDAwMDAsIDMxLjAwMDAwMCkgc2NhbGUoMSwgLTEpIHRyYW5zbGF0ZSgtMjkuNTAwMDAwLCAtMzEuMDAwMDAwKSAiIHBvaW50cz0iMjkuNSAyNSA0MCAzNyAxOSAzNyI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+',
];

const markPoint = (unit?: string, outPutUnit?: string) => {
  return {
    label: {
      show: true,
      offset: [0, -4],
    },
    emphasis: {
      show: true,
      offset: [0, -4],
    },
    symbolSize: [120, 26],
    symbolOffset: [0, -13],
    data: [
      {
        type: 'max',
        name: '最高',
        symbol: `image://${icons[3]}`,
        itemStyle: {
          color: '#FF4D4F',
        },
        label: {
          formatter: function (data: any) {
            return `最高：${data.value.toFixed(2)}${unit}/${outPutUnit}`;
          },
          color: '#FFFFFF',
        },
      },
      {
        type: 'min',
        name: '最低',
        symbol: `image://${icons[4]}`,
        itemStyle: {
          color: '#00ADFF',
        },
        label: {
          formatter: function (data: any) {
            return `最低：${data.value.toFixed(2)}${unit}/${outPutUnit}`;
          },
          color: '#FFFFFF',
        },
      },
    ],
    itemStyle: {
      normal: {
        color: '#d6a223',
      },
    },
  };
};

const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};

// tooltip 通用配置
export const getTooltip = ({ unit = '', outPutUnit = '' }: { unit?: string; outPutUnit?: string }) => ({
  trigger: 'axis',
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  textStyle: {
    color: '#fff',
  },
  formatter(items: any) {
    const { axisValue } = items[0];
    // 根据选择的颗粒度，展示不同的tooltip
    let str: string = dayjs(axisValue, 'x').format('MM-DD');
    items.forEach((item: any) => {
      const { seriesName, data, marker } = item;
      const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${unit}/${outPutUnit}` : '--';
      str += `<br> ${marker}${seriesName}: ${value}`;
    });
    return str;
  },
});

export const getNormalAxis = () => {
  return [
    {
      ...xAxis,
      type: 'time',
      minInterval: 60 * 1000 * 60 * 24,
      axisLabel: {
        formatter: function (e: number) {
          return dayjs(e, 'x').format('MM-DD');
        },
      },
    },
  ];
};

export const getChartOption = (unit: string, outPutUnit: string) => {
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 80,
      right: 50,
      top: 55,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
        // startValue: dayjs().startOf('d').format('MM-DD[\n]HH:mm'),
      },
    ],
    color: colors,
    tooltip: getTooltip({
      unit,
      outPutUnit,
    }),
    xAxis: getNormalAxis(),
    yAxis: {
      type: 'value',
      name: `${unit}/${outPutUnit}`,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
  };
};

export const getEnergyMediumChartOption = (
  selectedLabel?: string,
  selectedIndicatorId?: number,
  selectedAverageValue?: number,
  outPutIndicatorId?: number,
  chartData?: V2EnergyCompareGetConversionEfficiencyStaticsPostResponse['list'],
  mediumIndicatorDisplayUnitData?: MediumIndicatorDisplayUnit
) => {
  if (chartData && selectedLabel && selectedIndicatorId && outPutIndicatorId) {
    const unit = mediumIndicatorDisplayUnitData?.find(
      i => i.energyMediumIndicatorId === selectedIndicatorId
    )?.displayPhysicalUnitGeneralName;
    const outPutUnit = mediumIndicatorDisplayUnitData?.find(
      i => i.energyMediumIndicatorId === outPutIndicatorId
    )?.displayPhysicalUnitGeneralName;
    const option = unit && outPutUnit && getChartOption(unit, outPutUnit);
    const colors = ['#00ADFF', '#00ADFF'];

    return {
      ...option,
      series: [
        {
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: colors,
          markPoint: markPoint(unit, outPutUnit),
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(137,207,255,0.2)',
              },
              {
                offset: 1,
                color: 'rgba(81,161,255,0.1)',
              },
            ]),
          },
          name: selectedLabel,
          markLine: {
            silent: true,
            symbol: ['none', 'none'],
            lineStyle: {
              type: 'dashed',
              color: '#57FB8B',
            },
            data: [
              {
                label: {
                  show: true,
                  formatter: `均值: {c}${unit}/${outPutUnit}`,
                  position: 'insideEndTop',
                  color: '#59D744',
                },
                // yAxis: average(chartData?.map(i => i.sum)).toFixed(2),
                yAxis: !isNil(selectedAverageValue) ? selectedAverageValue?.toFixed(2) : '--',
              },
            ],
          },
          data: chartData?.map(a => {
            return [a.ts, a.sum];
          }),
        },
      ],
    };
  }
};
