import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, SelectProps, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Radio,
  Select,
  Modal,
  Form,
  Input,
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  FormTitle,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import {
  AlarmLevelDisplay,
  DiscreteRateInterval,
  DiscreteRateIntervalDisplay,
  RuleLogic,
  RuleLogicDisplay,
  AlarmLevel,
} from '../../types';
import styles from '../../index.module.scss';
import {
  apiV2OuListOwnedPost,
  apiV2ServeAlarmRuleAddPost,
  apiV2ServeAlarmRuleDetailPost,
  apiV2ServeAlarmRulePvStationListPost,
  apiV2ServeAlarmRuleUpdatePost,
} from '@maxtropy/device-customer-apis-v2';

interface Props {
  isEdit?: boolean;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = () => {
    apiV2ServeAlarmRulePvStationListPost({ name: value }).then(res => {
      if (currentValue === value) {
        const options = (res.list ?? []).map(item => ({ label: item.name, value: item.id, code: item.code }));
        callback(options);
      }
    });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};

const fetchPvStationId = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = () => {
    apiV2OuListOwnedPost({}).then(res => {
      if (currentValue === value) {
        const options = (res.list ?? []).map(item => ({ label: item.name, value: item.id }));
        callback(options);
      }
    });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};

const PvAlarmCreate: React.FC<Props> = ({ isEdit }) => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const [pvOption, setPvOption] = useState<SelectProps['options']>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const [pvStationId, setPvStationId] = useState<string>();
  const [ouOption, setOuOption] = useState<SelectProps['options']>([]);

  const onFinish = async (values: any) => {
    isEdit
      ? await apiV2ServeAlarmRuleUpdatePost({
          ...values,
          id: Number(id),
          alarmLevel: Number(values.alarmLevel),
          discreteRateInterval: Number(values.discreteRateInterval),
        })
      : await apiV2ServeAlarmRuleAddPost({ ...values, discreteRateInterval: Number(values.discreteRateInterval) });

    navigate(`/pvOps/server/alarm`);
  };

  const handlePvSearch = (newValue: string) => {
    if (newValue) {
      fetch(newValue, setPvOption);
    }
  };

  const handleOuSearch = (newValue: string) => {
    if (newValue) {
      fetchPvStationId(newValue, setOuOption);
    }
  };

  const getPvstationOptions = async (value: string) => {
    const res = await apiV2ServeAlarmRulePvStationListPost({ name: value });
    if (res) {
      const options = (res.list ?? []).map(item => ({ label: item.name, value: item.id, code: item.code }));
      setPvOption(options);
      return res;
    }
  };

  const getOuOptions = async () => {
    const res = await apiV2OuListOwnedPost({});

    const options = (res.list ?? []).map(item => ({ label: item.name, value: item.id }));
    setOuOption(options);
    return res.list ?? [];
  };

  useEffect(() => {
    getPvstationOptions('');
    getOuOptions();
  }, []);

  useEffect(() => {
    if (id) {
      apiV2ServeAlarmRuleDetailPost({ id }).then(res => {
        if (res) {
          setPvStationId(res.pvCode);
          form.setFieldsValue({
            ...res,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}光伏服务端报警` }];
  }, [isEdit]);

  return (
    <>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
        <FormTitle title={`${isEdit ? '编辑' : '新建'}光伏服务端报警`}></FormTitle>
        <SubContent className="mb-8">
          <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
            <Row>
              <Col span={8}>
                <Form.Item
                  label="规则名称"
                  name="name"
                  rules={[
                    { required: true, message: '请输入规则名称' },
                    { max: 20, message: '最多输入二十个字' },
                  ]}
                >
                  <Input placeholder="请输入"></Input>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="alarmLevel" label="报警等级" rules={[{ required: true, message: '请选择报警等级' }]}>
                  <Select placeholder="请选择">
                    <Select.Option value={AlarmLevel.Highest}>{AlarmLevelDisplay[AlarmLevel.Highest]}</Select.Option>
                    <Select.Option value={AlarmLevel.High}>{AlarmLevelDisplay[AlarmLevel.High]}</Select.Option>
                    <Select.Option value={AlarmLevel.Medium}>{AlarmLevelDisplay[AlarmLevel.Medium]}</Select.Option>
                    <Select.Option value={AlarmLevel.Low}>{AlarmLevelDisplay[AlarmLevel.Low]}</Select.Option>
                    <Select.Option value={AlarmLevel.Warning}>{AlarmLevelDisplay[AlarmLevel.Warning]}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="规则逻辑"
                  initialValue={isEdit ? null : RuleLogic.InverterDiscreteRate}
                  name="ruleLogic"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="请选择">
                    <Select.Option value={RuleLogic.InverterDiscreteRate}>
                      {RuleLogicDisplay[RuleLogic.InverterDiscreteRate]}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item label="光伏站" name="pvId" rules={[{ required: true, message: '请输入并选择光伏站' }]}>
                  <Select
                    placeholder="请输入并选择光伏站"
                    showSearch
                    options={pvOption}
                    allowClear={true}
                    key="id"
                    optionFilterProp="label"
                    onSearch={handlePvSearch}
                    onChange={(value, Option: any) => {
                      if (value === undefined) {
                        getPvstationOptions('');
                      }
                      setPvStationId(Option?.code);
                    }}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="光伏站编号">
                  <ShowInput value={pvStationId} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="推送运营单元"
                  name="ouId"
                  rules={[{ required: true, message: '请输入并选择运营单元' }]}
                >
                  <Select
                    placeholder="请输入并选择运营单元"
                    showSearch
                    options={ouOption}
                    allowClear
                    key="id"
                    optionFilterProp="label"
                    onSearch={handleOuSearch}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>

            <Col span={8}>
              <Form.Item
                label="离散率"
                name="discreteRateInterval"
                rules={[{ required: true, message: '请选择离散率' }]}
                tooltip={{
                  title: (
                    <div>
                      <div>异常: 离散率在20%及以上</div>
                      <div>较差: 离散率在20%~10%</div>
                      <div>一般: 离散率在10%~5%</div>
                      <div>优秀: 离散率在5%以下</div>
                    </div>
                  ),
                  icon: <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginLeft: 8 }} />,
                }}
              >
                <Radio.Group style={{ width: '100%' }}>
                  <Radio value={DiscreteRateInterval.UNWORK}>
                    {DiscreteRateIntervalDisplay[DiscreteRateInterval.UNWORK]}
                  </Radio>
                  <Radio value={DiscreteRateInterval.FiveToTen}>
                    {DiscreteRateIntervalDisplay[DiscreteRateInterval.FiveToTen]}
                  </Radio>
                  <Radio value={DiscreteRateInterval.TenToTwenty}>
                    {DiscreteRateIntervalDisplay[DiscreteRateInterval.TenToTwenty]}
                  </Radio>
                  <Radio value={DiscreteRateInterval.MoreThanTwenty}>
                    {DiscreteRateIntervalDisplay[DiscreteRateInterval.MoreThanTwenty]}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={8}></Col>
          </Form>
        </SubContent>

        <Space className="sticky-footer" size={8}>
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            保存
          </Button>
          <Button
            onClick={() => {
              Modal.confirm({
                title: <div>是否放弃所有未保存信息并返回列表？</div>,
                onOk: () => navigate(`/pvOps/server/alarm`),
              });
            }}
          >
            取消
          </Button>
        </Space>
      </Wrapper>
    </>
  );
};

export default PvAlarmCreate;
