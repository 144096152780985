import {
  EllipsisSpan,
  Paging,
  Table,
  useUpdate,
  Button,
  useSearchParams,
  Key,
  CustomFilter,
} from '@maxtropy/components';
import { Form, Input, Select, Space, Cascader } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { useHasPermission } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import { ActionType } from '@/pages/Account/utils';
import CreateForm from './CreateForm';
import {
  MediumAccountItem,
  WorkingSubstanceContractPageRes,
  WorkingSubstanceContractSearch,
  energyMediumSceneToMediumRes,
  getEnergyMediumSceneToMedium,
  getMediumAccountList,
  getWorkingSubstanceContractPage,
} from '@/api/workingSubstance';
import { isNil } from 'lodash-es';
import { PlusOutlined } from '@ant-design/icons';

const formatString = 'YYYY-MM-DD';

const columns = [
  {
    title: '合同编号',
    dataIndex: 'contractNumber',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '合同名称',
    dataIndex: 'name',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '关联账户',
    dataIndex: 'mediumAccountName',
    width: 150,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '适用能源介质',
    dataIndex: 'energyMediumName',
    width: 120,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '费率单位',
    dataIndex: 'physicalUnitName',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={isNil(v) ? undefined : `元/${v}`} />,
  },
  {
    title: '最新费率区间',
    dataIndex: 'startTime',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string, record: WorkingSubstanceContractPageRes) => (
      <EllipsisSpan
        value={
          isNil(v) && isNil(record.endTime)
            ? undefined
            : `${isNil(v) ? '--' : dayjs(v).format(formatString)} 至 ${
                isNil(record.endTime) ? '--' : dayjs(record.endTime).format(formatString)
              }`
        }
      />
    ),
  },
];

export interface SearchParams extends Omit<WorkingSubstanceContractSearch, 'page' | 'size'> {}

const WorkingSubstanceContract: FC = () => {
  const hasCREATEMEDIUMCONTRACT = useHasPermission(PermissionsType.B_CREATEMEDIUMCONTRACT); // 新增工质合同
  const hasRATEALLOCATION = useHasPermission(PermissionsType.B_RATEALLOCATION); // 费率配置
  const hasCONTRACTMANEDIT = useHasPermission(PermissionsType.B_CONTRACTMANEDIT); // 费率配置

  const [searchForm] = Form.useForm();
  const [updateState, updateFn] = useUpdate();

  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<WorkingSubstanceContractPageRes[]>([]);

  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [isShow, setIsShow] = useState<ActionType | undefined>();
  const [contractId, setContractId] = useState<Key>();

  const [mediumAccountList, setMediumAccountList] = useState<MediumAccountItem[]>([]); // 关联账户
  useEffect(() => {
    //得到staff权限下相关工质账户列表
    getMediumAccountList().then(res => setMediumAccountList(res.list));
  }, []);
  // 关联账户
  const mediumAccountOptions = useMemo(() => {
    return mediumAccountList.map(i => ({ label: i.accountNumber, value: i.id }));
  }, [mediumAccountList]);

  const [energyMediumSceneToMedium, setEnergyMediumSceneToMedium] = useState<energyMediumSceneToMediumRes[]>([]);
  useEffect(() => {
    getEnergyMediumSceneToMedium().then(setEnergyMediumSceneToMedium);
  }, []);
  const energyMediumSceneToMediumOptions = useMemo(() => {
    return energyMediumSceneToMedium.map(i => ({
      label: i.sceneName,
      value: i.id,
      children: i.energyMediums.map(item => ({
        label: item.energyName,
        value: item.id,
      })),
    }));
  }, [energyMediumSceneToMedium]);

  useEffect(() => {
    setLoading(true);
    const params = {
      ...searchParams,
      energyMediumSceneIds: ((searchParams?.energyMediumSceneIds as any) ?? []).map((item: any[]) => item[1]),
    };
    getWorkingSubstanceContractPage({
      ...params,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setData(res.list);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setTotalCount, searchParams, updateState]);

  const onFinish = (val: SearchParams) => {
    finish({ ...val });
  };
  const onReset = () => {
    reset();
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 100,
      render: (_: any, record: WorkingSubstanceContractPageRes) => (
        <Space size={16}>
          {hasCONTRACTMANEDIT && (
            <Button
              type="link"
              onClick={() => {
                setContractId(record.id);
                setIsShow(ActionType.UPDATE);
              }}
            >
              编辑
            </Button>
          )}
          {hasRATEALLOCATION && (
            <Button type="link">
              <Link
                to={`/operation/energy/contract/work-substance-rate/${record.id}?unit=${record.physicalUnitName}&precision=${record.precision}`}
              >
                费率配置
              </Link>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className={styles.filterWrapper}>
        <CustomFilter
          form={searchForm}
          onFinish={(val: SearchParams) => onFinish(val)}
          onReset={onReset}
          collapseOpen={false}
        >
          <Form.Item name="contractName" label="合同名称">
            <Input placeholder="请输入" />
          </Form.Item>

          <Form.Item name="contractNumber" label="合同编号">
            <Input placeholder="请输入" />
          </Form.Item>

          <Form.Item name="accountId" label="关联账户">
            <Select placeholder="请选择" options={mediumAccountOptions} />
          </Form.Item>

          <Form.Item name="energyMediumSceneIds" label="适用能源介质">
            <Cascader
              style={{ width: '100%' }}
              options={energyMediumSceneToMediumOptions}
              multiple
              maxTagCount="responsive"
              showCheckedStrategy={Cascader.SHOW_CHILD}
              placeholder="请选择"
            />
          </Form.Item>
        </CustomFilter>
      </div>
      <div className={styles.spaceStyle} />

      <div className={styles.content}>
        {hasCREATEMEDIUMCONTRACT && (
          <div className={styles.operationArea}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setContractId(undefined);
                setIsShow(ActionType.CREATE);
              }}
            >
              新增工质合同
            </Button>
          </div>
        )}

        <Table rowKey="id" sticky scroll={{ x: 1300 }} loading={loading} columns={buildColumns} dataSource={data} />
        <Paging pagingInfo={pagingInfo} />
      </div>
      {!!isShow && <CreateForm isShow={isShow} setIsShow={setIsShow} updateFn={updateFn} contractId={contractId} />}
    </>
  );
};

export default WorkingSubstanceContract;
