import {
  EnergyWorkingProcessType,
  EnergyWorkingProcessTypeDisplay,
  EnergyWorkingProcessTypeLabelColor,
} from '@/api/uet';
import { Divider, Spin } from 'antd';
import { Form, ShowInput, Tabs, Tag } from '@maxtropy/components';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';
import ViewEachProcessTabContent from './ViewEachProcessTabContent';
import { apiV2UetEmtProcessDetailPost, V2UetEmtProcessDetailPostResponse } from '@maxtropy/device-customer-apis-v2';

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface IItemProcessDetail {
  itemDetailId?: number;
}

const ItemProcessDetail: FC<IItemProcessDetail> = props => {
  const { itemDetailId } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, key: routerKey, energyId } = useParams<{ id: string; key: string; energyId: string }>();
  const [loading, setLoading] = useState<boolean>();
  const [uetEMTProcessDetail, setUetEMTProcessDetail] = useState<V2UetEmtProcessDetailPostResponse>(); // 每个折叠的详情

  useEffect(() => {
    if (id && energyId && itemDetailId) {
      setLoading(true);
      apiV2UetEmtProcessDetailPost({ id: String(itemDetailId) }).then(res => {
        setUetEMTProcessDetail(res);
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDetailId]);

  const tabs = (uetEMTProcessDetail?: V2UetEmtProcessDetailPostResponse) =>
    [
      {
        key: '2',
        label: <>输入({uetEMTProcessDetail?.processEntryVos?.length})</>,
        children: <ViewEachProcessTabContent uetEMTProcessDetail={uetEMTProcessDetail} currentKey={'2'} />,
      },
      {
        key: '4',
        label: <>节点内({uetEMTProcessDetail?.processNodeVo?.id ? 1 : 0})</>,
        children: <ViewEachProcessTabContent uetEMTProcessDetail={uetEMTProcessDetail} currentKey={'4'} />,
        show: !!uetEMTProcessDetail?.processNodeVo?.id,
      },
      {
        key: '3',
        label: <>输出({uetEMTProcessDetail?.processExitVos?.length})</>,
        children: <ViewEachProcessTabContent currentKey={'3'} uetEMTProcessDetail={uetEMTProcessDetail} />,
      },
    ].filter(item => item.show !== false);

  return (
    <div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: EnergyWorkingProcessTypeLabelColor[uetEMTProcessDetail?.type as EnergyWorkingProcessType],
            borderRadius: 4,
            padding: '9px 15px',
          }}
        >
          <div className={styles.TagStyle}>
            <div className={styles.nameSty}>{uetEMTProcessDetail?.name}</div>{' '}
            <Tag
              style={{
                color: EnergyWorkingProcessTypeLabelColor[uetEMTProcessDetail?.type as EnergyWorkingProcessType],
              }}
            >
              {EnergyWorkingProcessTypeDisplay[uetEMTProcessDetail?.type as EnergyWorkingProcessType]}
            </Tag>
          </div>
          <Divider className={styles.DividerStyle} />
          <Form className={styles.FormCardItemStyle} layout="horizontal" {...formLayout}>
            <Form.Item
              label={
                uetEMTProcessDetail?.type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE
                  ? '输出能源介质'
                  : uetEMTProcessDetail?.type === EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION
                  ? '输配能源介质'
                  : '存储能源介质'
              }
            >
              <ShowInput
                style={{ color: 'rgba(255, 255, 255, 0.85)' }}
                value={uetEMTProcessDetail?.energyMediumVos?.map(i => i.energyName).join()}
              />
            </Form.Item>
            <Divider className={styles.DividerStyle} />
            <Form.Item label="所属运营单元">
              <ShowInput
                style={{ color: 'rgba(255, 255, 255, 0.85)' }}
                value={uetEMTProcessDetail?.ouSimpleVos?.map(i => i.name).join()}
              />
            </Form.Item>
            {uetEMTProcessDetail?.type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE && (
              <>
                <Divider className={styles.DividerStyle} />
                <Form.Item label="是否发生转化">
                  <ShowInput
                    style={{ color: 'rgba(255, 255, 255, 0.85)' }}
                    value={uetEMTProcessDetail.hasConversion ? '是' : '否'}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
        <Tabs style={{ marginTop: 12 }} items={tabs(uetEMTProcessDetail)} />
      </Spin>
    </div>
  );
};

export default ItemProcessDetail;
