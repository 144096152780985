import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { Key } from 'react';
import {
  DataProperty,
  DeviceType,
  Operator,
  PricedisplayType,
  PricingMethod,
  TimeOfUseTariffType,
  TransactionMeansType,
  WorkDivisionEnum,
} from '@/shared/types';
import { Device } from './device';
import { SaleClientAccountInfo, TransactionType } from './sale';
import {
  ElectricitySaleContractSettlementMeterType,
  ElectricitySaleContractSettlementType,
  HasPreferentialAmountType,
  HasTimeOfUseElectricityPricePropsType,
  HasTimeOfUseElectricityPriceType,
  TwoPartSystemPowerConsumptionType,
} from './electricitySettlementRules';
import { ElectricityBillSearchParams } from '../pages/ElectricityBill';
import { Dayjs } from 'dayjs';
import { SaleElectricityMeterReadingRecordPageVo } from './saleElectricity';

export enum ElectricityAccountStatus {
  INVALID = 0,
  NORMAL = 1,
}

export const electricityAccountStatusDisplay: Record<ElectricityAccountStatus, string> = {
  [ElectricityAccountStatus.INVALID]: '作废',
  [ElectricityAccountStatus.NORMAL]: '正常',
};

export enum ElectricityType {
  BUSINESS = 0,
  INDUSTRY = 1,
}

export const ElectricityTypeDisplay = {
  [ElectricityType.BUSINESS]: '一般工商业',
  [ElectricityType.INDUSTRY]: '大工业',
};

export interface ElectricityAccountDeviceResponse extends Device {
  statisticsDate: string;
  dataPropertyId: number;
  dataPropertyName: string;
  accountDeviceLinkId: number;
  meterReadingRecordDataType: number;
  energyAdjustSort: HasTimeOfUseElectricityPricePropsType[];
}

export interface ElectricityAccountVo {
  id: number;
  number: string;
  regionCode: string;
  type: ElectricityType;
  transformerId: number;
  statisticsDate: string;
  status: ElectricityAccountStatus;
  createByUserId: string;
  updateByUserId: number;
  updateSource: Operator;
  createTime: string;
  updateTime: string;
  devices: ElectricityAccountDeviceResponse[];
  mcids?: string[];
  contract?: any;
}

export function getDeviceListByElectricityAccount(id: number) {
  return fetch<{ list: ElectricityAccountDeviceResponse[] }>(
    `/api/v2/electricity/account/device/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function getElectricityAccounList() {
  return fetch<{ list: ElectricityAccountVo[] }>(
    `/api/v2/electricity/account/list`,
    {
      method: 'POST',
    },
    true
  );
}

export function getElectricityAccount(id: Key) {
  return fetch<ElectricityAccountVo>(
    `/api/v2/electricity/account/info`,
    { method: 'POST', body: JSON.stringify({ id }) },
    true
  );
}

export interface AccountDeviceRequest extends PageRequest {
  code?: string;
  name?: string;
  electricityAccountId?: number;
}

export function getElectricityAccountDevice(request: AccountDeviceRequest) {
  return fetch<PageResponse<ElectricityAccountDeviceResponse>>(
    `/api/v2/electricity/account/bound-device/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface CreateElectricityAccountForm {
  number: string;
  regionCode: string;
  type: ElectricityType;
  mcids: string[];
  id: number;
}

export function createElectricityAccount(params: CreateElectricityAccountForm) {
  return fetch<void>(
    `/api/v2/electricity/account/add`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function updateElectricityAccount(params: CreateElectricityAccountForm) {
  return fetch<void>(
    `/api/v2/electricity/account/edit`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function invalidElectricityAccount(id: Key) {
  return fetch<void>(
    `/api/v2/electricity/account/void`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface Organization {
  adCode?: string;
  name: string;
  mcid: string;
}
// 根据当前用户的staff低敏感组织数据权限查询相关组织
export function getOrganizationWithCurrentStaff() {
  return fetch<{ list: Organization[] }>(
    `/api/v2/staff/data/authority`,
    {
      method: 'POST',
    },
    true
  );
}

export interface AreaItem {
  adcode: string;
  name: string;
  center: string;
  level: string;
  citycode: string;
  polyline: string | null;
  districts?: AreaItem[];
}

export function fetchAreaJson() {
  return fetch<AreaItem>(
    `/api/v2/location/getDistrict`,
    {
      method: 'POST',
      body: JSON.stringify({ subDistrict: 3 }),
    },
    true
  );
}

export function getDeviceTypeList() {
  return fetch<{ list: DeviceType[] }>(
    `/api/v2/electricity/account/deviceType/energyList`,
    {
      method: 'POST',
    },
    true
  );
}

export interface ElectricityDeviceListRequest extends PageRequest {
  codeOrName?: string;
  deviceTypeIds?: Array<number>;
  customerMcid?: string;
  electricityAccountId: number;
}

export function getElectricityDevicePage(params: ElectricityDeviceListRequest) {
  return fetch<PageResponse<Device>>(
    `/api/v2/electricity/account/device/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface ElectricityAccountDeviceBindRequest {
  deviceIds: number[];
  electricityAccountId: number;
  dataPropertyId?: number;
  recalculate: boolean;
  statisticsDate: string;
}

export function bindDevices(request: ElectricityAccountDeviceBindRequest) {
  return fetch<void>(
    `/api/v2/electricity/account/device/bind`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface ElectricityAccountDeviceUnbindRequest {
  deviceId: number;
  electricityAccountId: number;
}

export function unBindDevice(request: ElectricityAccountDeviceUnbindRequest) {
  return fetch<void>(
    `/api/v2/electricity/account/device/unbind`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface ElectricityAccountDeviceUpdateRequest {
  id: number;
  recalculate: boolean;
  statisticsDate: string;
}

export function deviceUpdateStatisticsDate(request: ElectricityAccountDeviceUpdateRequest) {
  return fetch<void>(
    `/api/v2/electricity/account/device/updateStatisticsDate`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

/** 合同相关 */

export enum ElectricityContractStatus {
  INVALID = 0,
  NORMAL = 1,
}

export interface ElectricityContractRequest {
  number: string;
  contractee: string;
  electricityAccountId: number;
  transactionMeans: TransactionMeansType;
  precision: number;
  startDate: string;
  endDate: string;
  id?: Key;
}

export interface ElectricityContractVo extends ElectricityContractRequest {
  id: number;
  tenantMcid: string;
  customerMcid: string;
  status: ElectricityContractStatus;
  electricityAccountName: string;
  createByUserId: string;
  updateByUserId: string;
  updateByUsername: string;
  updateSource: Operator;
  hasRate: boolean;
  createTime: string;
  updateTime: string;
  editable: boolean;
}

export function getElectricityContract(id: Key) {
  return fetch<ElectricityContractVo>(
    `/api/v2/electricity/contract/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function createElectricityContract(request: ElectricityContractRequest) {
  return fetch<void>(
    `/api/v2/electricity/contract/add`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function updateElectricityContract(request: ElectricityContractRequest) {
  return fetch<void>(
    `/api/v2/electricity/contract/edit`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function invalidElectricityContract(id: Key) {
  return fetch<void>(
    `/api/v2/electricity/contract/invalid`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface ElectricityContractAdjustEffectiveDateRequest
  extends Pick<ElectricityContractRequest, 'startDate' | 'endDate'> {
  electricityContractId?: Key;
}

export function adjustEffectiveDate(request: ElectricityContractAdjustEffectiveDateRequest) {
  return fetch<void>(
    `/api/v2/electricity/contract/adjust/effective-date`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

/** 费率 */

/** 电度电价 */

export interface RateElectricityPriceDetailVo {
  id?: number;
  type: TimeOfUseTariffType;
  startTime: string;
  endTime: string;
  price: string;
}

export interface RateElectricityPriceRequest {
  electricityContractId: number;
  displayType: PricedisplayType;
  startDate: string;
  endDate: string;
  details: RateElectricityPriceDetailVo[];
  recalculate: boolean;
  rateElectricityPriceId?: Key;
}

export interface RateElectricityPriceVo extends Omit<RateElectricityPriceRequest, 'recalculate'> {
  id: number;
}

export function getElectricRatePriceList(id: Key) {
  return fetch<{ list: RateElectricityPriceVo[] }>(
    `/api/v2/electricity/contract/contractId/price`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function createElectricRatePrice(request: RateElectricityPriceRequest[]) {
  return fetch<void>(
    `/api/v2/electricity/contract/price/add`,
    {
      method: 'POST',
      body: JSON.stringify({ request }),
    },
    true
  );
}

export function updateElectricRatePrice(request: RateElectricityPriceRequest) {
  return fetch<void>(
    `/api/v2/electricity/contract/price/edit`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function deleteElectricRatePrice(id: Key) {
  return fetch<RateElectricityPriceVo[]>(
    `/api/v2/electricity/contract/price/del`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

/** 基本电价 */

export interface RateBasicElectricityPriceRequest {
  electricityContractId: number;
  startDate: string;
  endDate: string;
  pricingMethod: PricingMethod;
  capacityUnitPrice?: string;
  agreedDemand?: number;
  demandUnitPrice?: string;
  penaltyCoefficient?: number;
  penaltyUnitPrice?: string;
  rateBasicElectricityPriceId?: Key;
}

export interface RateBasicElectricityPriceVo extends RateBasicElectricityPriceRequest {
  id: number;
  createTime: string;
  updateTime: string;
}

export function getBasicPriceList(id: Key) {
  return fetch<{ list: RateBasicElectricityPriceVo[] }>(
    `/api/v2/electricity/contract/contractId/basic-price`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function createBasicPrice(request: RateBasicElectricityPriceRequest[]) {
  return fetch<void>(
    `/api/v2/electricity/contract/basic-price/add`,
    {
      method: 'POST',
      body: JSON.stringify({ request }),
    },
    true
  );
}

export function updateBasicPrice(request: RateBasicElectricityPriceRequest) {
  return fetch<void>(
    `/api/v2/electricity/contract/basic-price/edit`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function deleteBasicPrice(id: Key) {
  return fetch<RateElectricityPriceVo[]>(
    `/api/v2/electricity/contract/basic-price/del`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

/** 附加电价 */

export interface RateAdditionalElectricityPriceRequest {
  electricityContractId: number;
  rateBasicElectricityPriceId?: Key;
  feeType: number;
  startDate: string;
  endDate: string;
  price: string;
}

export interface RateAdditionalElectricityPriceVo extends RateAdditionalElectricityPriceRequest {
  id: number;
  createTime: string;
  updateTime: string;
}

export function getAdditionalPriceList(id: Key) {
  return fetch<{ list: RateAdditionalElectricityPriceVo[] }>(
    `/api/v2/electricity/contract/contractId/additional-price`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function createAdditionalPrice(request: RateAdditionalElectricityPriceRequest[]) {
  return fetch<void>(
    `/api/v2/electricity/contract/additional-price/add`,
    {
      method: 'POST',
      body: JSON.stringify({ request }),
    },
    true
  );
}

export function updateAdditionalPrice(request: RateAdditionalElectricityPriceRequest) {
  return fetch<void>(
    `/api/v2/electricity/contract/additional-price/edit`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function deleteAdditionalPrice(id: Key) {
  return fetch<RateElectricityPriceVo[]>(
    `/api/v2/electricity/contract/additional-price/del`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface RegionAdditionalElectricityPriceTypeVo {
  id: number;
  regionCode: string;
  feeType: number;
  feeTypeName: string;
  transactionMeans: TransactionMeansType;
  electricityType: number;
}

export function getAdditionalPriceType(id: Key) {
  return fetch<{ list: RegionAdditionalElectricityPriceTypeVo[] }>(
    `/api/v2/electricity/contract/contractId/additional-price-type`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function getPriceDetails(electricityAccountId: number, statisticsDate: string) {
  return fetch<{ list: RateElectricityPriceDetailVo[] }>(
    `/api/v2/electricity/contract/getPriceDetails`,
    {
      method: 'POST',
      body: JSON.stringify({ electricityAccountId, statisticsDate }),
    },
    true
  );
}

export enum BillPaymentStatusEnum {
  UNPAID = 0,
  PARTIALLY_PAID = 1,
  PAID_DONE = 2,
}

export const BillPaymentStatusEnumDisplay = {
  [BillPaymentStatusEnum.UNPAID]: '未缴费',
  [BillPaymentStatusEnum.PARTIALLY_PAID]: '部分已缴',
  [BillPaymentStatusEnum.PAID_DONE]: '已缴完成',
};

export enum BillStatusEnum {
  EXCEPTION = -1,
  RESCINDED = 0,
  DRAFT = 1,
  CHECK_PENDING = 2,
  REVIEW_PENDING = 3,
  INVOICE_PENDING = 4,
  PAY_PENDING = 5,
  PAY_DONE = 6,
  SETTLE_DONE = 7,
}

export const BillStatusEnumDisplay = {
  [BillStatusEnum.EXCEPTION]: '异常',
  [BillStatusEnum.RESCINDED]: '已作废',
  [BillStatusEnum.DRAFT]: '草稿',
  [BillStatusEnum.CHECK_PENDING]: '待审核',
  [BillStatusEnum.REVIEW_PENDING]: '待复核',
  [BillStatusEnum.INVOICE_PENDING]: '待开票',
  [BillStatusEnum.PAY_PENDING]: '待缴费',
  [BillStatusEnum.PAY_DONE]: '已缴费',
  [BillStatusEnum.SETTLE_DONE]: '结账完成',
};

export interface OperatingStaff {
  // 抄表员
  meterReader?: string;
  // 复核员
  reviewer?: string;
  // 结算员
  settlementClerk?: string;
  // 审核员
  auditor?: string;
  // 销售单位
  salesOrg: string;
  // 开票地址
  invoiceAddress: string;
}

export interface ElectricityBillAmountResponse {
  // 优惠前总金额
  totalAmount: number;
  // 优惠后总金额
  discountTotalAmount: number;
  // 优惠金额
  discountAmount: number;
  // 调整优惠后金额
  changeDiscountTotalAmount?: number;
  // 调成优惠金额
  changeDiscountAmount?: number;
  // 小计金额（金额合计）
  amount: number;
  // 金额（大写）
  amountWords: number;
  // 已付金额
  payedAmount: number;
  // 应付金额
  oweAmount: number;
  // 账户余额
  accountBalance: number;
  // 优惠模式
  discountType?: HasPreferentialAmountType;
  // 优惠数值
  discountValue?: number;
  // 预计缴后金额
  postPaymentAmount: number;
}

export interface ElectricityBillConfig {
  id: number;
  billId: number;
  timeUseTariffType: HasTimeOfUseElectricityPriceType;
  powerFactorRatio: number;
  timeOfDayRateChecked: HasTimeOfUseElectricityPricePropsType[];
  // 尖单价
  unitSummitRate?: number;
  // 峰单价
  unitPeakRate?: number;
  // 平单价
  unitPlainRate?: number;
  // 谷单价
  unitValleyRate?: number;
  // 是否有公摊
  hasCapitalContributionRate: boolean;
  // 是否有照明费率
  hasLightingTariff: boolean;
  hasSubMeterRateDeduct: boolean; // 是否扣除子表
  subMeterSummitRate: number; // 子表尖时电价
  subMeterPeakRate: number; // 子表峰时电价
  subMeterPlainRate: number; // 子表平时电价
  subMeterValleyRate: number; // 子表谷时电价
}

export enum ElectricityIndustryCategoryEnum {
  // 电力
  ELECTRIC = 1,
}

export const ElectricityIndustryCategoryEnumDisplay = {
  [ElectricityIndustryCategoryEnum.ELECTRIC]: '电力',
};

export interface BaseElectricityBillResponse {
  id: number;
  // 编号
  billNo: string;
  // 销售客户id
  saleClientId: number;
  // 客户名称
  saleClientName: string;
  // 客户编号
  saleClientCode: string;
  // 用电合同id
  saleElectricityContractId: number;
  // 合同编号
  electricityContractCode: string;
  // 物业管理配置id
  propertyManagementConfigId: number;
  // 结算规则id
  saleElectricitySettlementRuleId: number;
  // 行业分类
  industryCategory: ElectricityIndustryCategoryEnum;
  // 结算周期开始日期
  settlementStartTime: string;
  // 结算周期结束日期
  settlementEndTime: string;
  // 结算账期
  settlementPeriod: string;
  // 状态
  status: BillStatusEnum;
  // 缴费状态
  paymentStatus: BillPaymentStatusEnum;
  // 更新人id
  updateByUserId: string;
  // 更新人
  updateByUsername: string;
  // 异常原因
  failReason: string;
  // 结帐/反计帐时间
  checkTime: string;
  createTime: string;
  updateTime: string;
  // 操作人员
  operatingStaff: OperatingStaff;
  // 结算类型
  settlementType: ElectricitySaleContractSettlementType;
  // 缴费账户信息
  payClientAccount: SaleClientAccountInfo;
  payments: PaymentsItem[]; // 缴费明细
  invoice: InvoiceObj; // 基础信息（发票）
  // 金额
  amount: ElectricityBillAmountResponse;
  // 核查联配置信息
  config: ElectricityBillConfig;
  // staff分工类型
  staffWorkDivision: WorkDivisionEnum[];
  // 创建人
  createByUsername?: string;
  // 组织id
  mcid?: Key;
  // ou
  ouId?: Key;
  // 优惠总电量
  totalRateDiscountedQuantity?: number;
  settlementRuleName?: string; // 结算规则名称
  generateType?: GenerateType; // 生成方式
  hasAdjustment?: boolean; //是否有调整单值
}

// 核查联生成方式
export enum GenerateType {
  SYSTEM = 1,
  MANUAL = 2,
}

export interface PaymentsItem {
  id: number;
  billId: number; // 核查联ID
  saleClientAccountId: number; // 账户id
  saleClientAccountCode: number; // 账户编号
  saleClientAccountName: string; // 账户名称
  saleClientAccountTransactionId?: number; // 用电合同id
  type: TransactionType; // 类型
  payAmount: number; // 金额
  description?: string; // 说明
  createByUserId?: string; //  操作人id
  createByUsername: string; // 操作人
  deleteFlag?: boolean;
  createTime?: string;
  updateTime: string; // 操作日期
}

// 基础信息(发票)
export interface InvoiceObj {
  id: number;
  billId: number; // 核查联ID
  salesOrg?: string; // 销售单位
  salesTaxId?: string; // 销售方纳税号
  salesGeneralAccountNumber?: string; // 售方总户号
  salesBeneficialBank?: string; // 售方开户行
  salesBankAccount?: string; // 账号
  salesContactNumber?: string; // 供电热线
  customerSectionAccountNo?: string; // 客户段户号
  customerAddress?: string; // 客户地址
  customerCompanyTitle?: string; // 客户公司抬头
  invoiceTime?: string; // 开票日期
  createTime?: string;
  updateTime?: string;
  deleteFlag?: boolean;
  billTitle?: string;
}

// 获取核查联信息
export function getElectricityBillInfo(id: Key) {
  return fetch<BaseElectricityBillResponse>(
    `/api/v2/electricity/bill/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface ElectricityContractListByPropertyManagementConfigIdOrSaleClientId {
  customerMcid?: string;
  billNo?: string; // 电费核查联编号
  propertyManagementConfigId?: Key; // 物业管理配置id
  saleElectricityContractId?: string; // 所属合同
  status?: BillStatusEnum; // 核查联状态
  saleClientId?: string; // 客户名称
  settlementRuleId?: string; // 结算规则id
  settlementType?: string; // 结算规则类型
  settlementStartTimeBegin?: string; // 结算周期-开始日期-begin
  settlementStartTimeEnd?: string; // 结算周期-开始日期-end
  settlementEndTimeBegin?: string; // 结算周期-结束日期-begin
  settlementEndTimeEnd?: string; // 结算周期-结束日期-end
  settlementStartTime?: Array<Dayjs>; // 结算周期-开始日期
  settlementEndTime?: Array<Dayjs>; // 结算周期-结束日期
  page: number;
  size: number;
}

// 根据物业id和客户查找核查联
export function getElectricityContractListByPropertyManagementConfigIdOrSaleClientId(
  request: ElectricityContractListByPropertyManagementConfigIdOrSaleClientId
) {
  return fetch<PageResponse<BaseElectricityBillResponse>>(
    `api/v2/electricity/bill/page`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

// 核查联有功电量
export interface BillActiveQuantityItem extends MeterIdDeviceNameMeterType {
  quantities: QuantitiesItem[]; // 电量
}

export enum ElectricityBillPhotovoltaicQuantityType {
  //光伏发电量
  PHOTOVOLTAIC = 0,
  //余电上网电量
  SURPLUS = 1,
  // 实际用电量
  ACTUAL = 2,
}

// 电量（余电上网/发电量）
export interface ElectricityBillPhotovoltaicQuantityQuantityMeterGroups {
  // 设备名称
  deviceName?: string;
  // 发电量
  quantities?: QuantitiesItem[];
  // 余电上网电量
  surplusQuantities?: QuantitiesItem[];
}

// 核查联光伏电量
export interface ElectricityBillPhotovoltaicQuantityResponse {
  // 电量（余电上网/发电量）
  quantityMeterGroups?: ElectricityBillPhotovoltaicQuantityQuantityMeterGroups[];
  // 实际电量
  actualQuantities?: QuantitiesItem[];
}

// 获取核查联光伏电量
export function getElectricityBillPhotovoltaicQuantity(id: Key) {
  return fetch<ElectricityBillPhotovoltaicQuantityResponse>(
    `/api/v2/electricity/bill/photovoltaic/quantity`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface ElectricityBillAuditLogRequest {
  id: number;
  // 核查联id
  billId: number;
  // 审核人员id
  staffId: number;
  // 审单人员姓名
  name: string;
  // 分工类型
  workDivision: number;
  // 操作描述
  opDesc: string;
  // 变更内容(json)
  modifyContent: object;
  // 创建时间
  createTime: string;
}

// 获取核查联审核日志
export function getElectricityBillAuditLog(id?: Key) {
  return fetch<{ list: ElectricityBillAuditLogRequest[] }>(
    `/api/v2/electricity/bill/audit/log`,
    {
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    },
    true
  );
}

export enum QuantityTypeEnum {
  QUANTITY = 1, // 市电
  PHOTOVOLTAIC = 2, // 光伏
}
export interface LightingQuantityItem {
  id: number;
  billId: number;
  meterId: number; // 表计Id
  totalActivePowerQuantity: number; // 有功总电量
  totalLightingPowerQuantity: number; // 照明总电量
  fixedRatio: number; // 定比
  powerFactorFee: number; // 力调费
  unitPowerRate: number; // 单价
  totalLightingElectricFee: number; // 总电费
  createTime?: string;
  updateTime?: string;
  deleteFlag?: boolean;
  quantityTypeEnum?: QuantityTypeEnum;
}

export interface QuantitiesItem extends LightingQuantityItem {
  lastQuantity: number; // 上次点示数
  currentQuantity: number; // 本次示数
  multiplyRatio: number; // 倍率
  powerFactor: number; // 功率因数
  totalReactivePowerQuantity: number; // 无功总电量
  powerFactorRatio: number; // 力调系数
  meterType?: ElectricitySaleContractSettlementMeterType; // 表计类型
  highLowType: HasTimeOfUseElectricityPricePropsType; // 尖峰平谷标记
  totalElectricFee: number; //有功电费
  actualActivePowerQuantity?: number; // 实际有功总(不含照明)
  tierConfigName?: string; // 档位名称
}

// 获取核查联有功电量
export function getBillActiveQuantity(id: Key) {
  return fetch<{ list: BillActiveQuantityItem[] }>(
    `/api/v2/electricity/bill/active/quantity`,
    {
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    },
    true
  );
}

// 获取核查联无功电量
export function getBillReactiveQuantity(id: Key) {
  return fetch<QuantitiesItem>(
    `/api/v2/electricity/bill/reactive/quantity`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface PaymentVoucherRequest {
  saleVoucherId: number;
  amount: number;
}

export interface PaymentRequest {
  saleClientAccountId: number;
  vouchers: PaymentVoucherRequest[];
  description?: string;
  id?: Key;
}

export function electricityBillPay(request: PaymentRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/pay`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export enum CapacityDemandType {
  CAPACITY = 1, // 容量
  DEMAND = 2, // 需量
}

export interface ElectricityBillBasicFee {
  id: number;
  billId: number; // 核查联Id
  useElectricCapacity: number; // 售电容量
  demand: number; // 需量示数
  maxDemand: number; // 最大需量
  multiplyRatio: number; // 倍率
  chargeCapacity: number; // 计费容量
  declarationType: TwoPartSystemPowerConsumptionType; // 用电类型：1、用电前报装；2、用电后报装
  capacityDemandType: number; //按容按需 type: 1: 容量, 2: 需量;
  unitCapacityRate: number; // 容量单价
  capacityFee: number; // 容量金额
  capacityPowerFactorFee: number; // 容量力调费
  unitDemandRate: number; // 需量单价
  demandFee: number; // 需量金额
  demandPowerFactorFee: number; // 需量力调费
  unitChargeRate: number; // 计费单价
  chargeFee: number; // 计费金额
  chargePowerFactorFee: number; // 计费力调费
  createTime: string;
  updateTime: string;
  deleteFlag: boolean;
  isSubMeter: boolean; // 是否子表
  meterId?: Key; // 表计id
}

// 核查联基本电费
export function getElectricityBillBasicFee(id: Key) {
  return fetch<{ list: ElectricityBillBasicFee[] }>(
    `/api/v2/electricity/bill/basic/fee`,
    {
      method: 'POST',
      body: JSON.stringify({
        id,
      }),
    },
    true
  );
}

export interface ElectricityCapitalContributionQuantityResponse {
  id: number;
  billId: number;
  totalActivePowerQuantity?: number;
  unitPowerRate?: number;
  highLowType: HasTimeOfUseElectricityPricePropsType;
  totalElectricFee?: number;
}

export function getElectricityCapitalContributionQuantityResponse(id: Key) {
  return fetch<{ list: Array<ElectricityCapitalContributionQuantityResponse> }>(
    `/api/v2/electricity/bill/capital/contribution/quantity`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface CapitalContributionRateRequest {
  summitPowerQuantity?: number;
  peakPowerQuantity?: number;
  plainPowerQuantity?: number;
  valleyPowerQuantity?: number;
}

export interface OperatePasswordRequest {
  isPasswordCheck?: boolean;
  password?: string;
  id?: Key;
}

export interface DiscountRequest {
  discountType?: number;
  discountValue?: number;
  totalRateDiscountedQuantity?: number;
}

export interface DiscountChangeAmountRequest extends CapitalContributionRateRequest, DiscountRequest {}

export function electricityBillDiscountChangeAmount(query: DiscountChangeAmountRequest & OperatePasswordRequest) {
  return fetch<ElectricityBillAmountResponse>(
    `/api/v2/electricity/bill/discount/change/amount`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export interface ElectricityBillStatusProcessRequest {
  billId: number;
  status: BillStatusEnum;
}

export function rescindBill(request: ElectricityBillStatusProcessRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/process/rescinded`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface ElectricityStatusProcessNextRequest
  extends ElectricityBillStatusProcessRequest,
    CapitalContributionRateRequest,
    DiscountRequest {}

export function electricityStatusProcessNext(request: ElectricityStatusProcessNextRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/process/next`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface InvoicingBillRequest extends ElectricityBillStatusProcessRequest {
  invoiceTime: string;
}

export function invoicingBill(request: InvoicingBillRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/process/invoicing`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export function getElectricityLightingQuantityResponse(id: Key) {
  return fetch<{ list: LightingQuantityItem[] }>(
    `/api/v2/electricity/bill/lighting/quantity`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 检查核查联是否未配置公摊
export function checkCapitalContribution(id: Key) {
  return fetch<boolean>(
    `/api/v2/electricity/bill/check/capital/contribution`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface AntiBookkeepingRequest {
  description: string;
  id: number;
}

// 反记账
export function antiBookkeepingRequest(request: AntiBookkeepingRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/anti-bookkeeping`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface ReverseProcessRequest extends ElectricityBillStatusProcessRequest {
  reason?: string;
}

// 流程逆向流转
export function electricityBillReverseProcess(request: ReverseProcessRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/reverseProcess`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

// 重新复核
export function electricityBillReverseProcessRecheck(request: ReverseProcessRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/reverseProcess/recheck`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

// 作废发票
export function electricityBillReverseProcessInvoicingRescinded(request: ReverseProcessRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/reverseProcess/invoiceRescinded`,
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true
  );
}

export interface AllFeedInQuantityItem extends QuantitiesItem {
  reverseLastQuantity: number; // 反向上次示数
  reverseCurrentQuantity: number; // 反向本月示数
  maxDemand: number; // 最大需量
}

export interface TotalQuantityItem {
  totalActivePowerQuantity: number; // 有功总电量
  totalElectricFee: number; // 有功电费
  summitPowerQuantity: number; //	尖电量
  peakPowerQuantity: number; //	峰电量
  plainPowerQuantity: number; // 平电量
  valleyPowerQuantity: number; //	谷电量
}

export interface QuantityMeterGroupsItem extends MeterIdDeviceNameMeterType {
  quantities: AllFeedInQuantityItem[];
}

export interface AllFeedInQuantityResponse {
  quantityMeterGroups: QuantityMeterGroupsItem[];
  totalQuantity: TotalQuantityItem;
}

// 获取核查联光伏电量（全额上网）
export function getElectricityPhotovoltaicAllFeedInQuantityResponse(id: Key) {
  return fetch<AllFeedInQuantityResponse>(
    `/api/v2/electricity/bill/photovoltaic/all-feed-in/quantity`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export enum PowerFactorRatioAdjustmentType {
  ADJUST = 1,
  NO_ADJUST = 2,
  NO_POWER_FACTOR = 3,
}
export interface ManualCreateBillRequest {
  saleElectricityContractId?: number; //合同id
  isCustomizeRule?: boolean; //是否自定义结算规则
  saleElectricitySettlementRuleId?: number; // 结算规则id
  startTime?: string; // 结算周期开始时间
  endTime?: string; // 结算周期结束时间
  billDate?: string; // 账期月份
  hasDemandAdjust: boolean; // 是否进行需量调整
  expectedDemand?: number; // 需量调整数
  powerFactorRatioAdjustmentType: PowerFactorRatioAdjustmentType; // 力调系数调整类型
  reactivePower?: number; // 无功电量
  saleElectricitySettlementRule?: {
    meters?: {
      saleElectricityContractElectricityMeterId: number;
      saleElectricitySettlementRuleElectricityMeterId: number;
    }[];
    // name: string;
    // saleElectricityContractId: number;
    // startTime: string;
    // endTime: string;
    settlementType?: number;
    description?: string;
    onePartInfo?: {
      timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
      rateType?: number; // 电度电价类型
      standingRate?: HasTimeOfUseElectricityPriceType; // 电度单价
      summitRate?: number; // 尖
      peakRate?: number; // 峰
      plainRate?: number; // 平
      valleyRate?: number; // 谷
      hasCapitalContributionRate?: boolean; // 是否有公摊系数
      capitalContributionRate?: number; // 公摊系数
      hasDiscountedRate?: number; // 是否有优惠金额
      discountedType?: HasPreferentialAmountType; // 优惠方式
      totalRateDiscountedRatio?: number; // 电度电费总折扣比例
      totalRateDiscountedRate?: number; // 电度电费总优惠金额
      unitRateDiscountedRate?: number; // 电度电费单价优惠金额
      totalRateDiscountedQuantity?: number; // 优惠电度数
    };
    twoPartInfo?: {
      timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
      declarationType?: TwoPartSystemPowerConsumptionType; // 用电类型
      rateType?: number; // 电度电价类型
      transformerCapacity?: number; // 变压器容量
      unitCapacityRate?: number; // 容量单价
      declaredDemand?: number; // 申报需量
      unitDeclaredDemandRate?: number; // 申报需量单价
      unitDemandRate?: number; // 需量单价
      standingRate?: number; // 电度单价
      summitRate?: number; // 尖
      peakRate?: number; // 峰
      plainRate?: number; // 平
      valleyRate?: number; // 谷
      hasLightingTariff?: boolean; // 是否有照明费率
      lightingTariffRatio?: number; // 照明费率
      unitLightingTariffRate?: number; // 照明费率单价
      hasDiscountedRate?: number; // 是否有优惠金额
      discountedType?: HasPreferentialAmountType; // 优惠方式
      totalRateDiscountedRatio?: number; // 电度电费总折扣比例
      totalRateDiscountedRate?: number; // 电度电费总优惠金额
      unitRateDiscountedRate?: number; // 电度电费单价优惠金额
      hasSubMeterRateDeduct?: boolean;
      subMeterUnitDemandRate?: number; // 子表需量单价
      subMeterSummitRate?: number; // 子表尖时电价
      subMeterPeakRate?: number; //	子表峰时电价
      subMeterPlainRate?: number; // 子表平时电价
      subMeterValleyRate?: number; //子表谷时电价
      totalRateDiscountedQuantity?: number; // 优惠电度数
    };
    distributedNewEnergyInfo?: {
      timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[]; // 电度电价类型
      summitRate?: number; // 尖
      peakRate?: number; // 峰
      plainRate?: number; // 平
      valleyRate?: number; // 谷
      hasDiscountedRate?: number; // 是否有优惠金额
      discountedType?: HasPreferentialAmountType; // 优惠方式
      totalRateDiscountedRatio?: number; // 电度电费总折扣比例
      totalRateDiscountedRate?: number; // 电度电费总优惠金额
      unitRateDiscountedRate?: number; // 电度电费单价优惠金额
      totalRateDiscountedQuantity?: number; // 优惠电度数
    };
    distEnergyAllFeedInInfo?: {
      timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[]; // 电度电价类型
      summitRate?: number; // 尖
      peakRate?: number; // 峰
      plainRate?: number; // 平
      valleyRate?: number; // 谷
      hasDiscountedRate?: number; // 是否有优惠金额
      discountedType?: HasPreferentialAmountType; // 优惠方式
      totalRateDiscountedRatio?: number; // 电度电费总折扣比例
      totalRateDiscountedRate?: number; // 电度电费总优惠金额
      unitRateDiscountedRate?: number; // 电度电费单价优惠金额
      totalRateDiscountedQuantity?: number; // 优惠电度数
    };
    twoPartAndEnergyInfo?: {
      timeOfDayRateChecked?: HasTimeOfUseElectricityPricePropsType[];
      declarationType?: TwoPartSystemPowerConsumptionType; // 用电类型
      rateType?: number; // 电度电价类型
      transformerCapacity?: number; // 变压器容量
      unitCapacityRate?: number; // 容量单价
      declaredDemand?: number; // 申报需量
      unitDeclaredDemandRate?: number; // 申报需量单价
      unitDemandRate?: number; // 需量单价
      standingRate?: number; // 电度单价
      summitRate?: number; // 尖
      peakRate?: number; // 峰
      plainRate?: number; // 平
      valleyRate?: number; // 谷
      hasLightingTariff?: boolean; // 是否有照明费率
      lightingTariffRatio?: number; // 照明费率
      unitLightingTariffRate?: number; // 照明费率单价
      hasDiscountedRate?: number; // 是否有优惠金额
      discountedType?: HasPreferentialAmountType; // 优惠方式
      totalRateDiscountedRatio?: number; // 电度电费总折扣比例
      totalRateDiscountedRate?: number; // 电度电费总优惠金额
      unitRateDiscountedRate?: number; // 电度电费单价优惠金额
      totalRateDiscountedQuantity?: number; // 优惠电度数
      powerFactorRatioCalculationType?: number; //功率因数比值计算规则
    };
  };
  discardFromBill?: number;
  fromBillId?: Key;
}

// 手动生成核查联
export function ManualCreateBill(params: ManualCreateBillRequest) {
  return fetch<void>(
    `/api/v2/electricity/bill/manual`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
export interface PhotovoltaicQuantitiesItem extends QuantitiesItem {
  maxDemand: number; // 最大需量
  reverseLastQuantity: number; // 反向上次示数
  reverseCurrentQuantity: number; // 反向本月示数
  totalGeneratePowerQuantity: number; // 总计发电量
  totalOnlinePowerQuantity: number; // 总计上网电量
  actualActivePowerQuantity: number; // 实际有功总(含照明)
}

export interface MeterIdDeviceNameMeterType {
  deviceName: string; //设备名称
  meterId: Key; //表计id
  meterType: Key;
}
export interface PhotovoltaicMeterGroupsItem extends MeterIdDeviceNameMeterType {
  quantities: PhotovoltaicQuantitiesItem[];
}

export interface ElectricityBillPhotovoltaicCityQuantityRes {
  photovoltaicMeterGroups: PhotovoltaicMeterGroupsItem[]; // 光伏发电量（光伏表计）
  demandMeterGroups: PhotovoltaicMeterGroupsItem[]; // 余电上网电量（需量表计）
  actualQuantities: PhotovoltaicQuantitiesItem[]; // 实际电量
}

// 获取核查联光伏电量（市电）
export function getElectricityBillPhotovoltaicCityQuantity(id: Key) {
  return fetch<ElectricityBillPhotovoltaicCityQuantityRes>(
    `api/v2/electricity/bill/photovoltaic/city/quantity`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 获取核查联扣除子表有功电量
export function getElectricityBillSubQuantity(id: Key) {
  return fetch<{ list: PhotovoltaicMeterGroupsItem[] }>(
    `/api/v2/electricity/bill/sub/quantity`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface SummaryRes {
  id: Key;
  billId: Key; // 核查联Id
  totalActivePowerQuantity: number; // 有功总电量
  deleteFlag: boolean; // 删除标志
  createTime: string;
  updateTime: string;
}

// 获取核查联汇总信息
export function getElectricityBillSummary(id: Key) {
  return fetch<SummaryRes>(
    `/api/v2/electricity/bill/summary`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 核查联批量下载
export function electricityBillBatchDownload(params: ElectricityBillSearchParams) {
  return fetch<boolean>(
    `/api/v2/electricity/bill/batch/download?${qs.stringify(params, { indices: false })}`,
    {
      method: 'GET',
    },
    true
  );
}

export enum TaskStatusType {
  PROCESSING = 1, // 正在处理
  SUCCESS = 2, // 下载
  FAIL = 3, // 处理失败
}

export const TaskStatusTypeDisplay = {
  [TaskStatusType.PROCESSING]: '正在处理',
  [TaskStatusType.SUCCESS]: '下载',
  [TaskStatusType.FAIL]: '处理失败',
};

export interface ElectricityBillBatchListRes {
  id: number;
  staffId: number; // 发起下载任务的租户员工id
  tenantMcid: string; // 所属租户
  taskBillQuantity: number; // 核查联数量
  taskStatus: TaskStatusType; // 下载任务状态
  zipFileKey: string; // 下载key
  zipFileName: string; // 压缩文件名
  createTime: string; // 下载时间
  updateTime: string; // 修改时间
  failReason: string; // 任务失败理由
}

// 核查联批量下载列表
export function getElectricityBillBatchList() {
  return fetch<{ list: ElectricityBillBatchListRes[] }>(
    `/api/v2/electricity/bill/batch/list`,
    {
      method: 'POST',
    },
    true
  );
}

export const ElectricityTypeEnumDisplay = {
  [HasTimeOfUseElectricityPricePropsType.SHARPPEAK]: '尖电量',
  [HasTimeOfUseElectricityPricePropsType.PEAK]: '峰电量',
  [HasTimeOfUseElectricityPricePropsType.AVERAGE]: '平电量',
  [HasTimeOfUseElectricityPricePropsType.VALLEY]: '谷电量',
};

export interface SortableItemProps {
  sortIndex: number;
  item: HasTimeOfUseElectricityPricePropsType;
}

export enum AdjustmentMeterRecordsDataType {
  ORIGINAL = 1,
  ADJUSTMENT,
}

export const AdjustmentMeterRecordsDataTypeDisplay = {
  [AdjustmentMeterRecordsDataType.ORIGINAL]: '抄见值',
  [AdjustmentMeterRecordsDataType.ADJUSTMENT]: '配平值',
};

export enum AdjustmentMeterRecordsSourceFromType {
  LOGIC = 1,
  SCRIPT,
  // LOGIC :1 逻辑生成 SCRIPT :2 脚本插入
}

export interface AdjustmentMeterRecordsRes extends SaleElectricityMeterReadingRecordPageVo {
  createTs?: string; // 创建时间
  eppTs?: string; // 正向有功电能抄表时间
  epnTs?: string; // 反向有功电能抄表时间
  eqpTs?: string; // 正向无功电能抄表时间
  maxDemandTs?: string; // 最大需量抄表时间
  epp1Ts?: string; // 尖正向有功电能抄表时间
  epp2Ts?: string; // 峰正向有功电能抄表时间
  epp3Ts?: string; // 平正向有功电能抄表时间
  epp4Ts?: string; // 谷正向有功电能抄表时间
  epn1Ts?: string; // 尖反向有功电能抄表时间
  epn2Ts?: string; // 峰反向有功电能抄表时间
  epn3Ts?: string; // 平反向有功电能抄表时间
  epn4Ts?: string; // 谷反向有功电能抄表时间
  tenantMcid?: Key; // 租户mcid
  tenantName?: string; // 租户名称
  source?: AdjustmentMeterRecordsSourceFromType; // 数据来源
  dataType?: AdjustmentMeterRecordsDataType; // 记录类型
  originalRecordId?: Key; // 原始单id
  energyAdjustSortSnapshot?: HasTimeOfUseElectricityPricePropsType[]; //电量配平顺序快照
}

export const HasTimeOfUseElectricityPriceDisplay = {
  [HasTimeOfUseElectricityPricePropsType.SHARPPEAK]: '尖', // 尖峰
  [HasTimeOfUseElectricityPricePropsType.PEAK]: '峰', // 峰
  [HasTimeOfUseElectricityPricePropsType.AVERAGE]: '平', // 平
  [HasTimeOfUseElectricityPricePropsType.VALLEY]: '谷', // 谷
};

// 抄见值与配平值合并
export interface AdjustmentMeterRecordsDataTypeAllRes extends AdjustmentMeterRecordsRes {
  adj_energyAdjustSortSnapshot?: HasTimeOfUseElectricityPricePropsType[]; //电量配平顺序快照
  adj_magnification?: number; // 倍率
  adj_eppTs?: string; // 正向有功电能抄表时间
  adj_epp?: number; // 正向有功电能
  adj_eppDisplayValue?: number; // 正向有功电能示数
  adj_epnTs?: string; // 反向有功电能抄表时间
  adj_epn?: number; // 反向有功电能
  adj_epnDisplayValue?: number; // 反向有功电能示数
  adj_eqpTs?: string; // 正向无功电能抄表时间
  adj_eqp?: number; // 正向无功电能
  adj_eqpDisplayValue?: number; // 正向无功电能示数
  adj_maxDemandTs?: string; // 最大需量抄表时间
  adj_maxDemand?: number; // 最大需量
  adj_maxDemandDisplayValue?: number; // 最大需量示数
  adj_epp1Ts?: string; // 尖正向有功电能抄表时间
  adj_epp1?: number; // 尖正向有功电能
  adj_epp1DisplayValue?: number; // 尖正向有功电能示数
  adj_epp2Ts?: string; // 峰正向有功电能抄表时间
  adj_epp2?: number; // 峰正向有功电能
  adj_epp2DisplayValue?: number; // 峰正向有功电能示数
  adj_epp3Ts?: string; // 平正向有功电能抄表时间
  adj_epp3?: number; // 平正向有功电能
  adj_epp3DisplayValue?: number; // 平正向有功电能示数
  adj_epp4Ts?: string; // 谷正向有功电能抄表时间
  adj_epp4?: number; // 谷正向有功电能
  adj_epp4DisplayValue?: number; // 谷正向有功电能示数
  adj_epn1Ts?: string; // 尖反向有功电能抄表时间
  adj_epn1?: number; // 尖反向有功电能
  adj_epn1DisplayValue?: number; // 尖反向有功电能示数
  adj_epn2Ts?: string; // 峰反向有功电能抄表时间
  adj_epn2?: number; // 峰反向有功电能
  adj_epn2DisplayValue?: number; // 峰反向有功电能示数
  adj_epn3Ts?: string; // 平反向有功电能抄表时间
  adj_epn3?: number; // 平反向有功电能
  adj_epn3DisplayValue?: number; // 平反向有功电能示数
  adj_epn4Ts?: string; // 谷反向有功电能抄表时间
  adj_epn4?: number; // 谷反向有功电能
  adj_epn4DisplayValue?: number; // 谷反向有功电能示数
}

export function getAdjustmentMeterRecordsById(id: Key) {
  return fetch<{ list: AdjustmentMeterRecordsRes[] }>(
    `/api/v2/electricity/bill/adjustmentMeterRecords`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface UpdateMeterLogicParams {
  meterReadingRecordDataType: number;
  energyAdjustSort: HasTimeOfUseElectricityPricePropsType[];
  accountDeviceLinkId?: Key;
}

export function updateMeterLogicById(params: UpdateMeterLogicParams) {
  return fetch(
    `/api/v2/electricity/account/bound-device/data-type`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
