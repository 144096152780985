import { FC, Suspense, useEffect } from 'react';
import { TenantLayout as LayoutComponents, Themes, useThemeContext } from '@maxtropy/components';
import TenantLayout from '@/components/Layout';
import { Outlet } from 'react-router-dom';

// scada路由出口
export const ScadaOutlet: FC = () => {
  const { setCustomTheme } = useThemeContext();

  useEffect(() => {
    setCustomTheme?.(Themes.DARK);
    return () => {
      setCustomTheme?.();
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <TenantLayout>
        <Outlet />
      </TenantLayout>
    </Suspense>
  );
};

// 其他页面路由出口
export const MainOutlet: FC = () => {
  return (
    <Suspense fallback={null}>
      <TenantLayout>
        <LayoutComponents>
          <Outlet />
        </LayoutComponents>
      </TenantLayout>
    </Suspense>
  );
};
