import { EnergyWorkingProcessType, EnergyWorkingProcessTypeLabelColor, ItemPort, NodesProps } from '@/api/uet';
import G6 from '@antv/g6';
import { Tooltip } from '@antv/g6-plugin';

export enum EnergyWorkingProcessPermissions {
  ENABLED = 0,
  UNABLED = 1,
}

export const basicConfig = (tooltip: Tooltip) => ({
  container: 'mountNode', // 指定图画布的容器 id，与第 9 行的容器对应
  modes: {
    default: ['drag-canvas', 'drag-node', 'zoom-canvas', 'click-select'],
  },
  plugins: [tooltip],
  layout: {
    type: 'dagre',
    rankdir: 'LR',
    align: undefined,
    preventOverlap: true, //防重叠
  },
  defaultNode: {
    type: 'rect',
    style: {
      lineWidth: 2,
      stroke: '#5B8FF9',
      fill: '#C6E5FF',
    },
  },
  defaultEdge: {
    type: 'line-dash',
    size: 1,
  },

  fitView: true, //超出适配
  fitViewPadding: [120, 100], //页面留白宽度
});

// 注册节点。类型为开始或者结束类型
export const initChartNode = (
  name: string,
  type: EnergyWorkingProcessType,
  permissions: number,
  pictureKey?: string,
  entryVos?: ItemPort[],
  exitVos?: ItemPort[]
) => {
  const max = Math.max((entryVos ?? []).length, (exitVos ?? []).length);
  const size = [80, max * 18 > 80 ? max * 18 : 80];
  const hasPermission = permissions === EnergyWorkingProcessPermissions.ENABLED ? true : false;

  // 注册节点。类型为开始或者结束类型
  G6.registerNode(name, {
    draw: (cfg, group) => {
      const keyShape: any = group!.addShape('rect', {
        attrs: {
          width: size[0] + 12,
          height: size[1] + 12,
          x: -(size[0] + 12) / 2,
          y: -(size[1] + 12) / 2,
          fill: hasPermission
            ? EnergyWorkingProcessTypeLabelColor[type] + '70'
            : EnergyWorkingProcessTypeLabelColor[type] + '20',
          radius: 4,
          cursor: type === EnergyWorkingProcessType.STORAGE ? 'not-allowed' : 'pointer',
        },
        draggable: true,
        name: 'root-keyshape',
      });

      entryVos?.forEach((item, index) => {
        if (item.hasDevice) {
          group!.addShape('rect', {
            attrs: {
              width: 8,
              height: 8,
              radius: 1,
              x: -size[0] / 2 - 10,
              y: -size[1] / 2 + (size[1] / (entryVos.length + 1)) * (index + 1) - 4,
              fill: EnergyWorkingProcessTypeLabelColor[type],
              lineWidth: 0.2,
              cursor: 'pointer',
            },
            name: item.id + 'entry',
          });
        } else {
          group!.addShape('circle', {
            attrs: {
              r: 4,
              x: -size[0] / 2 - 6,
              y: -size[1] / 2 + (size[1] / (entryVos.length + 1)) * (index + 1),
              fill: '#979797',
              stroke: '#979797',
              lineWidth: 0.2,
            },
            name: item.id + 'entry',
          });
        }
      });

      exitVos?.forEach((item, index) => {
        if (item.hasDevice) {
          group!.addShape('rect', {
            attrs: {
              width: 8,
              height: 8,
              r: 1,
              x: size[0] / 2 + 2,
              y: -size[1] / 2 + (size[1] / (exitVos.length + 1)) * (index + 1) - 4,
              fill: EnergyWorkingProcessTypeLabelColor[type],
              lineWidth: 0.2,
              cursor: 'pointer',
            },
            name: item.id + 'exit',
          });
        } else {
          group!.addShape('circle', {
            attrs: {
              r: 4,
              x: size[0] / 2 + 6,
              y: -size[1] / 2 + (size[1] / (exitVos.length + 1)) * (index + 1),
              fill: '#979797',
              stroke: '#979797',
              lineWidth: 0.2,
            },
            name: item.id + 'exit',
          });
        }
      });

      group!.addShape('image', {
        attrs: {
          x: -80 / 2,
          y: -80 / 2,
          width: 80,
          height: 80,
          img: pictureKey,
          opacity: hasPermission ? 0.8 : 0.4,
          cursor: type === EnergyWorkingProcessType.STORAGE ? 'not-allowed' : 'pointer',
        },
        draggable: true,
        name: 'image-shape',
      });
      group!.addShape('text', {
        attrs: {
          y: (size[1] > 80 ? (size[1] + 24) / 2 : 80 / 2 + 12) + 4,
          text: `${cfg!.label}`,
          fontSize: cfg!.label!.toString().length > 6 ? 8 : 12,
          fill: hasPermission ? 'rgba(255, 255, 255, 0.85)' : 'rgba(255, 255, 255, 0.55)',
          textAlign: 'center',
          lineHeight: size[1],
          lineWidth: size[0],
          textBaseline: 'middle',
          cursor: 'pointer',
        },
        draggable: true,
        name: 'ratio-shape',
      });

      return keyShape;
    },
    setState: (name, value, item) => {
      const group = item!.getContainer();
      const shape = group.get('children')[0]; // 顺序根据 draw 时确定
      const itemDto: any = item?.getModel();
      if (
        itemDto.info.permissions === EnergyWorkingProcessPermissions.ENABLED &&
        itemDto.info.type !== EnergyWorkingProcessType.STORAGE &&
        name === 'selected'
      ) {
        if (value) {
          shape.attr({
            stroke: '#fff',
            lineWidth: 2,
          });
        } else {
          shape.attr({
            lineWidth: 0,
          });
        }
      }
    },
  });

  const lineDash = [8, 6, 4, 6];
  G6.registerEdge(
    'line-dash',
    {
      afterDraw(cfg, group) {
        // get the first shape in the group, it is the edge's path here=
        const shape = group!.get('children')[0];
        let index = 0;
        // Define the animation
        shape.animate(
          () => {
            index = index + 0.5;
            if (index > 24) {
              index = 0;
            }
            const res = {
              lineDash,
              lineDashOffset: -index,
            };
            // returns the modified configurations here, lineDash and lineDashOffset here
            return res;
          },
          {
            repeat: true, // whether executes the animation repeatly
            duration: 30000, // the duration for executing once
          }
        );
      },
    },
    'polyline' // extend the built-in edge 'polyline'
  );
};

export const formatNodesAndEdges = (data: { nodes: any[]; pointEdges: any[] }) => {
  let formatData = JSON.parse(JSON.stringify(data));

  const finalResData = {
    nodes: formatData.nodes,
    edges: formatData.pointEdges,
  };

  // 通过出入口id，找到source和target节点与锚点
  finalResData.edges.forEach((item: any) => {
    let findSourceNode = finalResData.nodes.find((i: NodesProps) => i?.exitVos?.map(c => c.id).includes(item.sourceId));
    let findTargetNode = finalResData.nodes.find((i: NodesProps) =>
      i?.entryVos?.map(c => c.id).includes(item.targetId)
    );
    item.source = findSourceNode && findSourceNode?.id.toString();
    item.target = findTargetNode && findTargetNode?.id.toString();
    item.sourceAnchor = (findSourceNode?.entryVos.map((i: ItemPort) => i.id + 'entry') ?? [])
      .concat(findSourceNode?.exitVos.map((i: ItemPort) => i.id + 'exit') ?? [])
      .findIndex((m: string) => m === item.sourceId + 'exit');
    item.targetAnchor = (findTargetNode?.entryVos.map((i: ItemPort) => i.id + 'entry') ?? [])
      .concat(findTargetNode?.exitVos.map((i: ItemPort) => i.id + 'exit') ?? [])
      .findIndex((m: string) => m === item.targetId + 'entry');

    if (findSourceNode && findSourceNode.info) {
      // console.log(EnergyWorkingProcessTypeLabelColor[findSourceNode.info.type as EnergyWorkingProcessType]);
      item.style = {
        stroke: EnergyWorkingProcessTypeLabelColor[findSourceNode.info.type as EnergyWorkingProcessType],
        endArrow: {
          path: G6.Arrow.triangle(4, 8, 4), // 自定义箭头路径
          d: 4, // 偏移量
          fill: EnergyWorkingProcessTypeLabelColor[findSourceNode.info.type as EnergyWorkingProcessType],
          strokeOpacity: 0,
        },
        radius: 3, // 拐弯处的圆角弧度，若不设置则为直角
        lineWidth: 1,
      };
    }
  });

  // 处理连线重叠问题，设置相同source与target的偏移量
  let newData: any = {};
  finalResData.edges.forEach((e: any) => {
    //新建属性名
    if (Object.keys(newData).indexOf(e.source + 'key' + e.target) === -1) {
      newData[e.source + 'key' + e.target] = [];
    }
    //对应插入属性值
    newData[e.source + 'key' + e.target].push(e);
  });
  // console.log(newData);
  Object.keys(newData).forEach(i => {
    newData[i].forEach((item: any, index: number) => {
      item.style = {
        ...item.style,
        offset: 15 + 10 * (index + 1),
      };
    });
  });

  finalResData.nodes.forEach(
    (item: {
      id: string;
      label: string;
      name: string;
      entryIds: number[];
      exitIds: number[];
      type: string;
      anchorPoints: any[];
      sourceAnchor: number;
      targetAnchor: number;
      info: {
        type: EnergyWorkingProcessType;
        permissions: number;
        pictureKey: string;
        alarmStatus?: number;
      };
      entryVos?: ItemPort[]; //入口id列表
      exitVos?: ItemPort[]; //出口id列表
    }) => {
      // 创建节点，根据进出水管的数量,建立左右管道与蓄水池的长宽
      item.type = item.name + (item.entryVos ?? []).length + (item.exitVos ?? []).length;
      // 锚点计算，通过进出水管的数量
      const a = [...new Array((item.entryVos ?? []).length)].map((_: any, index: number) => [
        0,
        (1 / ((item.entryVos ?? []).length + 1)) * (index + 1),
      ]);
      const b = [...new Array((item.exitVos ?? []).length)].map((_: any, index: number) => [
        1,
        (1 / ((item.exitVos ?? []).length + 1)) * (index + 1),
      ]);

      item.anchorPoints = a.concat(b);
      item.label = item.name;
      item.id = String(item.id);

      initChartNode(
        item.type,
        item.info.type,
        item.info.permissions,
        item.info.pictureKey,
        item.entryVos,
        item.exitVos
      );
    }
  );

  return finalResData;
};
