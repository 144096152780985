import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { DeviceStatus, Operator } from '@/shared/types';

export interface Device {
  id: number;
  name: string;
  code: string;
  typeId?: number;
  typeName?: string;
  pic?: string;
  rootMcid: string;
  rootCode: string;
  rootName: string;
  status: DeviceStatus;
  updateSource: Operator;
  updateByUsername: string;
  updateTime: string;
  existAttribute: boolean;
  existEdgeTemplate: boolean;
  existRuleGroup: boolean;
  ruleGroupId: number;
  enable?: boolean;
  customerFullName: string;
  sn: string; // 设备sn
  modelName: string; // 型号
  customerName?: string;
  ruleGroupName?: string;
  deviceId: number;
  thirdParty?: boolean;
  physicalModelId?: number; // 物模型id
  circuitId?: number[];
  circuitName?: string[];
}

export interface DeviceListRequest extends PageRequest {
  codeOrName?: string;
  status?: DeviceStatus;
  typeId?: Array<number>;
  invalid?: boolean;
  tag?: string;
  customerMcid?: string;
  ouId?: number;
  thirdParty?: boolean;
  originDeviceType?: Array<Array<number>>;
}

export interface DeviceListResponse extends PageResponse<Device> {}

export function getDeviceList(params: DeviceListRequest): Promise<DeviceListResponse> {
  return fetch(
    `/api/v2/device/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function getCircuitDeviceList(params: DeviceListRequest) {
  return fetch<PageResponse<Device>>(
    `/api/v2/circuit/device/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface UpdateDeviceStatusRequest {
  deviceId: number;
  status: DeviceStatus;
}

export function updateDeviceStatus(params: UpdateDeviceStatusRequest): Promise<{ flag: boolean }> {
  return fetch(
    `/api/v2/device/updateStatus`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface Organization {
  mcid: string;
  name: string;
  hasPermission: boolean;
  disabledParent?: boolean;
}

export interface OrganizationResponse {
  data: Organization;
  parent?: number;
  children: OrganizationResponse[];
}

export function getOrganizationWithCodeWithCurrent(): Promise<OrganizationResponse> {
  return fetch(
    `/api/v2/device/organization-with-code-with-current`,
    {
      method: 'POST',
    },
    true
  );
}

export interface CreateDevicePropertyRequest {
  name: string;
  sn?: string;
  typeId?: number;
  modelId?: number;
  modelName?: string;
  manufactureDate?: string;
  installationDate?: string;
  pic?: string;
  // rootMcid: string;
  customerMcid: string;
  assetCode: string;
  ouIds?: Array<number>;
  addOuIds?: Array<number>;
  deleteOuIds?: Array<number>;
  tags: string;
  physicalModelId?: Key;
  thirdParty?: boolean;
}

// 资产类型枚举
export enum deviceAssetTypeEmun {
  ENERGYSTORAGEARRAY = 1, // 储能阵列
  PVSTATION = 2, // 光伏站
  NETENERGY = 3, // 微网能源资产
  CHARGING = 4, // 充电站
  TOPOLOGY = 5, // 能源介质拓扑
  NEW_ENERGYSTORAGEARRAY = 6, // 新版储能
  GAS_PREPARATION_STATION = 7, // 气体制备站
  HVAC = 8, // 暖通空调
}

export type deviceAssetTypeItem = {
  [propName in deviceAssetTypeEmun]: deviceAssetsResponse[];
};

export interface deviceAssetsResponse {
  deviceAssetCode: string;
  id: number;
  code: string; // 设备资产编号
  createTime: string;
  deviceAssetId: number;
  deviceAssetType: deviceAssetTypeEmun;
  deviceId: number;
  updateTime: string;
}

export interface DevicePropertyResponse extends CreateDevicePropertyRequest {
  id: number;
  code: string;
  typeName?: string;
  tenantName: string;
  customerName: string;
  customerFullName: string;
  deviceAsset: deviceAssetTypeItem; // 能源资产信息
  manufacturerId: Key; // 厂商id
  manufacturerName: string; // 厂商名称
  physicalModelNo: string; // 物模型号
  modelNo: string;
  existEdgeTemplate?: boolean; // 是否配置数采信息
  existAttribute?: boolean;
}

export function getDeviceProperty(id: string): Promise<DevicePropertyResponse> {
  return fetch(
    `/api/v2/device/info`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function createDeviceProperty(params: CreateDevicePropertyRequest): Promise<DevicePropertyResponse> {
  return fetch(
    `/api/v2/device/add`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface UpdateDevicePropertyRequest
  extends Omit<CreateDevicePropertyRequest, 'typeId' | 'modelId' | 'modeName'> {
  id: number;
}

export function updateDeviceProperty(params: UpdateDevicePropertyRequest): Promise<DevicePropertyResponse> {
  return fetch(
    `/api/v2/device/edit`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface AttributeItem {
  fieldId: number;
  unitId?: number;
  value?: string;
}

export interface DeviceAttributeResponse {
  deviceId: number;
  templateId: number;
  templateVersion: number;
  list: AttributeItem[];
}

export function getDeviceAttribute(id: string): Promise<DeviceAttributeResponse> {
  return fetch(`/api/v2/device/attribute`, { method: 'POST', body: JSON.stringify({ id }) }, true);
}

export function updateDeviceAttribute(params: DeviceAttributeResponse): Promise<DeviceAttributeResponse> {
  return fetch(
    `/api/v2/device/attribute/upsert`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

interface DeviceTransformerListProps {
  id: number;
  name: string;
  code: string;
  typeName: string;
}

export const getDeviceTransformerList = (mcid: string) =>
  fetch<{ list: DeviceTransformerListProps[] }>(
    `/api/v2/device/transformer/list`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid }),
    },
    true
  );

export const getDeviceTransformerAttribute = (id: number) =>
  fetch<AttributeItem>(`/api/v2/device/transformer/attribute`, { method: 'POST', body: JSON.stringify({ id }) }, true);

// 获取用电账户的关联组织列表

export interface CustomerListRes {
  mcid: string;
  name: string;
  fullName: string;
  uscc: string;
  address: string;
  phone: string;
  adCode: string;
  logoUrl: string;
  disabled: boolean;
  provinceCityDistrict: string;
  code: string;
  createTime: string;
  updateTime: string;
}

export function getElectricityAccountCustomerList(id: Key) {
  return fetch<{ list: CustomerListRes[] }>(
    `/api/v2/electricity/account/customer/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 设备导出
type ExportDeviceParams = Omit<DeviceListRequest, 'page' | 'size'>;
export function exportDevice(params: ExportDeviceParams) {
  window.open(`/api/v2/device/batch/export?${qs.stringify(params, { indices: false })}`);
}

export interface PhysicalModelRes {
  id: Key;
  modelNo: string; // 型号
  manufacturerId: Key; // 厂商id
  deviceTypeId: Key; // 设备类目id
  createTime: string;
  updateTime: string;
  manufacturerName: string; // 厂商名称
}

export interface PhysicalParams {
  deviceTypeIds?: Key[]; // 设备类目id
  manufacturerId?: Key; // 厂商id
}

// 根据设备类目和厂商查询物模型列表

export function getPhysicalModelList(params: PhysicalParams) {
  return fetch<{ list: PhysicalModelRes[] }>(
    `/api/v2/physical-model/list`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 查询设备下的ou
export function getOuIdsByDeviceId(id: number) {
  return fetch<{ list: { id: number }[] }>(
    `/api/v2/device/ou/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list);
}
