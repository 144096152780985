import { Cascader, Col, Row, Space } from 'antd';
import { InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isNil } from 'lodash-es';

import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  useCurrent,
  useAsync,
  Form,
  Modal,
  Input,
  Select,
  Radio,
  InputNumber,
  FormTitle,
  SubContent,
  ShowInput,
} from '@maxtropy/components';
import {
  AlarmLevel,
  AlarmLevelDisplay,
  AlarmType,
  IotProtocolType,
  IotProtocolTypeDisplay,
  GatewayAlarmType,
  GateAlarmLogic,
  GatewayAlarmCoolDownModeDisplay,
  GatewayAlarmCoolDownMode,
  AlarmChannel,
} from '@/shared/types';
import AlarmIotProtocolFormItem from '@/shared/components/AlarmIotProtocolFormItem';
import {
  formulaValidator,
  formatOnSave,
  parseOnEcho,
  getRelatedDataPropertiesFromFormula,
} from '@/shared/components/FormulaInput';
import FormulaInput from '@/shared/components/FormulaInput/FormulaInputOfAlarm';
import { formatDeviceTypeToDataNode, sliceDeviceTypeName, useQuery } from '@/shared/utils/utils';
import { getDataPropertiesTemp, getPhysicalModelList } from '@/shared/api/options';
import { getProtocol } from '../../../api/protocol';
import { getDeviceTypeTreeWithoutScene } from '../../../api/deviceType';
import styles from '../List/index.module.scss';
import { getPlatformGatewayId } from '@/api/alarm';
import {
  V2RuleInfoPostResponse,
  apiV2RuleAddPost,
  apiV2RuleInfoPost,
  apiV2RuleUpdatePost,
  apiV2RuleGetChannelPost,
  V2RuleGetChannelPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import AlarmRuleFormItems from '@/pages/Alarm/Create/components/AlarmChannelFormItem';

interface AlarmCreateProps {
  isEdit?: boolean;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const AlarmCreate: React.FC<AlarmCreateProps> = ({ isEdit }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';
  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType>();
  const [info, setInfo] = useState<V2RuleInfoPostResponse>();
  const [deviceType, setDeviceType] = useState<number>();
  const [physicalModelId, setPhysicalModelId] = useState<number>();
  const [alarmChannelList, setAlarmChannelList] = useState<V2RuleGetChannelPostResponse['list']>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [alarmChannel, setAlarmChannel] = useState<AlarmChannel>();
  // 当前用户租户等详情
  const current = useCurrent();
  const mcid = current?.tenant?.mcid;

  // 用户新选择的关联属性, 不包含公式里的
  const [newSelectedRelatedDataProperties, setNewSelectedRelatedDataProperties] = useState<number[]>([]);
  const initialRelatedDataPropertiesRef = useRef<number[]>([]);
  // 平台网关设备的 id
  const [platformGatewayId, setPlatformGatewayId] = useState<number>(5003);

  const iotProtocolData = useAsync(getProtocol);

  const { data: deviceTypeTreeData } = useQuery(useCallback(() => getDeviceTypeTreeWithoutScene(), []));
  const deviceTypeOptions = useMemo(
    () => formatDeviceTypeToDataNode(deviceTypeTreeData?.deviceTypes ?? []),
    [deviceTypeTreeData?.deviceTypes]
  );

  // 获取渠道数据
  useEffect(() => {
    apiV2RuleGetChannelPost().then(res => {
      setAlarmChannelList(res.list ?? []);
    });
  }, []);

  // 获取平台网关设备类目 id
  useEffect(() => {
    getPlatformGatewayId().then(res => {
      setPlatformGatewayId(res.id);
    });
  }, []);

  const { data: dataProperties = [] } = useQuery(
    useCallback(
      () =>
        iotProtocolType && !isNil(deviceType)
          ? getDataPropertiesTemp(iotProtocolType, deviceType, physicalModelId)
          : Promise.resolve([]),
      [iotProtocolType, deviceType, physicalModelId]
    )
  );

  const { data: physicalModelOptions = [] } = useQuery(
    useCallback(async () => {
      if (!isNil(deviceType) && !isEdit) {
        const response = await getPhysicalModelList({ deviceTypeIds: [deviceType] });
        return response.list;
      }
      return [];
    }, [deviceType, isEdit])
  );

  const onDeviceTypeIdChange = useCallback(
    (e: any) => {
      setDeviceType(e[1] as number | undefined);
      setPhysicalModelId(undefined);
      form.setFieldsValue({
        formula: undefined,
        physicalModelId: undefined,
        duration: 0,
        relatedDataProperties: undefined,
      });
    },
    [form]
  );

  // 报警方式, 边缘触发报警或者云端代理边缘报警
  const gatewayAlarmType = Form.useWatch('alarmMode', form);

  useEffect(() => {
    if (id) {
      apiV2RuleInfoPost({ id: +id }).then(data => {
        setIotProtocolType(data.iotProtocol);
        setInfo(data);
        setDeviceType(data.deviceTypeId);
        setAlarmChannel(data.channel);
        setPhysicalModelId(data.physicalModelId);
        // 保存最初的关联属性, 用于增量提交时候的比较
        initialRelatedDataPropertiesRef.current = data.relatedPropertyIds ?? [];

        form.setFieldsValue({
          ...data,
          physicalModelId: data.modelNo,
          channel: alarmChannelList?.find(i => i.code === data.channel)?.desc ?? '--',
          iotProtocol: IotProtocolTypeDisplay[data.iotProtocol as IotProtocolType],
          deviceTypeId: data.deviceTypeName ? sliceDeviceTypeName(data.deviceTypeName) : '--',
          formula: parseOnEcho(data.formula),
          relatedDataProperties: data.relatedPropertyIds ?? [],
        });
      });
    }
  }, [form, id, alarmChannelList]);

  const onFinish = async (values: any) => {
    // 需要判断关联属性哪些为新增, 哪些为删除
    // 判断是否有公式, 当选择云端代理边缘报警的时候不能传公式
    const params = values.formula
      ? {
          ...values,
          rootMcid: isEdit ? info?.rootMcid : values.rootMcid,
          type: AlarmType.MARGIN,
          iotProtocol: isEdit ? info?.iotProtocol : values.iotProtocol,
          deviceTypeId: isEdit ? info?.deviceTypeId : deviceType,
          physicalModelId: isEdit ? info?.physicalModelId : values.physicalModelId,
          formula: formatOnSave(values.formula),
        }
      : {
          ...values,
          rootMcid: isEdit ? info?.rootMcid : values.rootMcid,
          type: AlarmType.MARGIN,
          iotProtocol: isEdit ? info?.iotProtocol : values.iotProtocol,
          deviceTypeId: isEdit ? info?.deviceTypeId : deviceType,
        };
    const { relatedDataProperties, ...restParams } = params;
    // 渠道为三方和朗新时 iotProtocol 协议传 3
    const iotProtocol =
      alarmChannel === AlarmChannel.LONGSHINE || alarmChannel === AlarmChannel.THIRD
        ? IotProtocolType.LONGSHINE
        : iotProtocolType;
    // 增量提交, 编辑时传 addRelatedPropertyIds 和 deleteRelatedPropertyIds 字段
    // 添加规则时全是新增, 因此只需传 relatedPropertyIds
    // 需要判断设备类目是平台网关且是云端报警的时候不传 relatedDataProperties 相关逻辑
    const res = isEdit
      ? params?.deviceTypeId === platformGatewayId && params.alarmMode === GatewayAlarmType.CLOUD
        ? await apiV2RuleUpdatePost({
            ...restParams,
            channel: alarmChannel,
            id: Number(id),
          })
        : await apiV2RuleUpdatePost({
            ...restParams,
            channel: alarmChannel,
            id: Number(id),
            addRelatedPropertyIds:
              initialRelatedDataPropertiesRef.current &&
              values?.relatedDataProperties?.filter(
                (v: number) => !initialRelatedDataPropertiesRef.current?.includes(v)
              ),
            deleteRelatedPropertyIds:
              initialRelatedDataPropertiesRef.current &&
              initialRelatedDataPropertiesRef.current?.filter(v => !values.relatedDataProperties?.includes(v)),
          })
      : params?.deviceTypeId === platformGatewayId && params.alarmMode === GatewayAlarmType.CLOUD
      ? await apiV2RuleAddPost({
          ...restParams,
          rootMcid: mcid,
          iotProtocol,
        })
      : await apiV2RuleAddPost({
          ...restParams,
          relatedPropertyIds: values.relatedDataProperties,
          rootMcid: mcid,
          iotProtocol,
        });

    if (res) {
      navigate(`/device/rule/list?tabs=${tabs}`);
    }
  };

  useEffect(() => {
    if (isEdit) return;
    // 默认渠道极熵
    form.setFieldsValue({
      channel: AlarmChannel.MAXTROPY,
    });
    setAlarmChannel(AlarmChannel.MAXTROPY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const routes = useMemo(() => {
    return [{ name: `${isEdit ? '编辑' : '新建'}设备报警规则` }];
  }, [isEdit]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <FormTitle title={routes[0].name} />

      <Form layout="vertical" form={form} {...formLayout} onFinish={onFinish}>
        <SubContent className="mb-8">
          <Row>
            <Col span={8}>
              <Form.Item
                name="name"
                label="规则名称"
                rules={[
                  { required: true, message: '请输入规则名称' },
                  { max: 20, message: '最多输入二十个字' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="alarmType" label="报警等级" rules={[{ required: true, message: '请选择报警等级' }]}>
                <Select placeholder="请选择">
                  <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
                  <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
                  <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
                  <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
                  <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="alarmName"
                label="报警信息"
                rules={[
                  { required: true, message: '请输入报警信息' },
                  { max: 500, message: '最多输入500字' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8}>
              {isEdit ? (
                <>
                  <Form.Item name="channel" label="渠道">
                    <ShowInput />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item name="channel" label="渠道" rules={[{ required: true, message: '请选择渠道' }]}>
                    <Select
                      onChange={setAlarmChannel}
                      options={(alarmChannelList ?? []).map(({ code, desc }) => ({
                        label: desc,
                        value: code,
                      }))}
                    />
                  </Form.Item>
                </>
              )}
            </Col>
            {/* 渠道 */}
            {alarmChannel === AlarmChannel.MAXTROPY && (
              <>
                <Col span={8}>
                  <Form.Item
                    name="iotProtocol"
                    rules={[{ required: true, message: '请选择协议选择' }]}
                    label="物联层协议"
                  >
                    {isEdit ? (
                      <ShowInput />
                    ) : (
                      <Select<IotProtocolType> onChange={setIotProtocolType} placeholder="请选择">
                        {iotProtocolData?.list?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {/* 物联层协议 */}
                {iotProtocolType === IotProtocolType.MOCKINGBIRD && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name="deviceTypeId"
                        label="适用设备类目"
                        rules={[{ required: true, message: '请选择适用设备类目' }]}
                      >
                        {isEdit ? (
                          <ShowInput />
                        ) : (
                          <Cascader
                            allowClear={false}
                            style={{ width: '100%' }}
                            options={deviceTypeOptions}
                            fieldNames={{ label: 'name', value: 'id' }}
                            onChange={onDeviceTypeIdChange}
                            placeholder="请选择"
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="physicalModelId" label="物模型型号">
                        {isEdit ? (
                          <ShowInput />
                        ) : (
                          <Select
                            placeholder="请选择"
                            onChange={v => {
                              setPhysicalModelId(v);
                              form.setFieldsValue({
                                formula: undefined,
                                duration: 0,
                                relatedDataProperties: undefined,
                              });
                            }}
                            options={physicalModelOptions.map(item => ({
                              label: item.modelNo,
                              value: item.id,
                            }))}
                            allowClear
                          />
                        )}
                      </Form.Item>
                    </Col>

                    {deviceType === platformGatewayId ? (
                      <>
                        <Col span={8}>
                          <Form.Item
                            name="alarmMode"
                            label="报警方式"
                            rules={[{ required: true, message: '请选择报警方式' }]}
                            initialValue={GatewayAlarmType.EDGE}
                            tooltip={{
                              title: '部分类型报警，如网关离线报警，需平台代理触发，可选择【云端代理边缘报警】。',
                              icon: <ExclamationCircleOutlined />,
                            }}
                          >
                            <Radio.Group>
                              <Radio value={GatewayAlarmType.EDGE}>边缘触发报警</Radio>
                              <Radio value={GatewayAlarmType.CLOUD}>云端代理边缘报警</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        {gatewayAlarmType === GatewayAlarmType.CLOUD ? (
                          <>
                            <Col span={8}>
                              <Form.Item
                                name="alarmLogic"
                                label="报警逻辑"
                                rules={[{ required: true, message: '请选择报警逻辑' }]}
                                initialValue={GateAlarmLogic.OFFLINE}
                              >
                                <Radio.Group>
                                  <Radio value={GateAlarmLogic.OFFLINE}>网关离线报警</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                label="离线持续时间"
                                name="offlineDuration"
                                initialValue={0}
                                rules={[{ required: true, message: '请输入离线持续时间' }]}
                                extra="网关离线时间超过持续时间后触发报警。根据实际现场情况可能会有1-5分钟延迟。"
                              >
                                <InputNumber
                                  addonAfter="min"
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={99999}
                                  step={1}
                                  precision={0}
                                  placeholder="请输入0~99999的数字"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                label="报警冷却时间"
                                extra="相同网关离线恢复后，冷却时间内不重复报警。过滤信号波动造成的重复报警。"
                              >
                                <Input.Group compact>
                                  <Form.Item
                                    name="coolDownMode"
                                    noStyle
                                    initialValue={GatewayAlarmCoolDownMode.TIME_TILL_ALARM}
                                  >
                                    <Select
                                      placeholder="请选择"
                                      style={{ width: '40%' }}
                                      options={Object.entries(GatewayAlarmCoolDownModeDisplay).map(([k, v]) => ({
                                        label: v,
                                        value: Number(k),
                                      }))}
                                    />
                                  </Form.Item>
                                  <Form.Item name="coolDownTime" noStyle initialValue={0}>
                                    <InputNumber
                                      addonAfter="min"
                                      style={{ width: '60%' }}
                                      min={0}
                                      max={99999}
                                      precision={0}
                                      step={1}
                                      placeholder="请输入0~99999的数字"
                                    />
                                  </Form.Item>
                                </Input.Group>
                              </Form.Item>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={8}>
                              <Form.Item
                                name="duration"
                                initialValue={0}
                                label="报警持续时间"
                                rules={[{ required: true, message: '请输入报警持续时间' }]}
                              >
                                <InputNumber
                                  addonAfter="ms"
                                  style={{ width: '100%' }}
                                  min={0}
                                  max={86400000}
                                  // step={1}
                                  precision={0}
                                  placeholder="请输入0~86400000的数字"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                name="relatedDataProperties"
                                label="关联属性"
                                tooltip={{
                                  title: '报警详情中可显示相关设备属性数值，并提供录波数据参考',
                                  icon: <InfoCircleOutlined />,
                                }}
                              >
                                <Select
                                  optionFilterProp="label"
                                  disabled={isNil(deviceType)}
                                  mode="multiple"
                                  allowClear
                                  options={dataProperties.map(v => ({ label: v.name, value: v.id }))}
                                  onChange={(changedValue: number[]) => {
                                    // 监听关联属性多选框改动, 一旦改动设置用户新选择的属性
                                    const formulaRelatedDataProperties = getRelatedDataPropertiesFromFormula(
                                      formatOnSave(form.getFieldValue('formula')),
                                      dataProperties
                                    ).map(v => v.id);
                                    setNewSelectedRelatedDataProperties(
                                      changedValue.filter((v: number) => !formulaRelatedDataProperties.includes(v))
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item
                                name="formula"
                                label="报警公式"
                                validateFirst
                                rules={[{ required: true, message: '请填写公式' }, { validator: formulaValidator }]}
                              >
                                <FormulaInput
                                  disabled={isNil(deviceType)}
                                  onBlur={() => {
                                    const newRelatedDataProperties = getRelatedDataPropertiesFromFormula(
                                      formatOnSave(form.getFieldValue('formula')),
                                      dataProperties
                                    ).map(v => v.id);
                                    form.setFieldValue('relatedDataProperties', [
                                      ...new Set(newRelatedDataProperties.concat(newSelectedRelatedDataProperties)),
                                    ]);
                                  }}
                                  dataProperties={dataProperties.map(item => ({
                                    id: `x_${item.id}`,
                                    display: item.name,
                                    description: '建模',
                                  }))}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Col span={8}>
                          <Form.Item
                            name="duration"
                            initialValue={0}
                            label="报警持续时间"
                            rules={[{ required: true, message: '请输入报警持续时间' }]}
                          >
                            <InputNumber
                              addonAfter="ms"
                              style={{ width: '100%' }}
                              min={0}
                              max={86400000}
                              precision={0}
                              placeholder="请输入0~86400000的数字"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            name="relatedDataProperties"
                            label="关联属性"
                            tooltip={{
                              title: '报警详情中可显示相关设备属性数值，并提供录波数据参考',
                              icon: <InfoCircleOutlined />,
                            }}
                          >
                            <Select
                              optionFilterProp="label"
                              disabled={isNil(deviceType)}
                              mode="multiple"
                              allowClear
                              options={dataProperties.map(v => ({ label: v.name, value: v.id }))}
                              onChange={(changedValue: number[]) => {
                                // 监听关联属性多选框改动, 一旦改动设置用户新选择的属性
                                const formulaRelatedDataProperties = getRelatedDataPropertiesFromFormula(
                                  formatOnSave(form.getFieldValue('formula')),
                                  dataProperties
                                ).map(v => v.id);
                                setNewSelectedRelatedDataProperties(
                                  changedValue.filter((v: number) => !formulaRelatedDataProperties.includes(v))
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            name="formula"
                            label="报警公式"
                            validateFirst
                            rules={[{ required: true, message: '请填写公式' }, { validator: formulaValidator }]}
                          >
                            <FormulaInput
                              disabled={isNil(deviceType)}
                              onBlur={() => {
                                const newRelatedDataProperties = getRelatedDataPropertiesFromFormula(
                                  formatOnSave(form.getFieldValue('formula')),
                                  dataProperties
                                ).map(v => v.id);
                                form.setFieldValue('relatedDataProperties', [
                                  ...new Set(newRelatedDataProperties.concat(newSelectedRelatedDataProperties)),
                                ]);
                              }}
                              dataProperties={dataProperties.map(item => ({
                                id: `x_${item.id}`,
                                display: item.name,
                                description: '建模',
                              }))}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                )}
                {iotProtocolType && <AlarmIotProtocolFormItem type={iotProtocolType} />}
              </>
            )}
            {
              // 根据渠道显示不同的表单项
              alarmChannel && <AlarmRuleFormItems channel={alarmChannel} />
            }
          </Row>
        </SubContent>
        <Space size={8} className="sticky-footer">
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button
            onClick={() => {
              Modal.confirm({
                title: <div>是否放弃所有未保存信息并返回列表？</div>,
                onOk: () => navigate(`/device/rule/list?tabs=${tabs}`),
              });
            }}
          >
            取消
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default AlarmCreate;
