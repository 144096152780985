import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import CreateTransformerModal from './components/CreateTransformerModal';
import List, { changeTargetLoadProps } from './components/List';
import { columns, formatTwoLevelColor, formatTwoLevelList, loadTransformers } from './columns';
import { createUetProdLoad, UetInfoProps, uetProdLoadInfo, updateUetProdLoad } from '../../../../api/uet';
import { Link } from 'react-router-dom';
import { useUpdate, Button, message, InputNumber, Table, Modal } from '@maxtropy/components';
import FixedBottomButton from '@/components/FixedBottomButton';
import { isNil } from 'lodash-es';

interface ProductionLoadProps {
  info?: UetInfoProps;
  isDetail?: boolean;
}

const ProductionLoad: React.FC<ProductionLoadProps> = ({ info, isDetail }) => {
  const [parentTransformer, setParentTransformer] = useState(false);
  const [subTransformer, setSubTransformer] = useState(false);
  const [level, setLevel] = useState<string>();
  const [list, setList] = useState<loadTransformers[]>([]);
  const [parentInfo, setParentInfo] = useState<loadTransformers>();
  const [editInfo, setEditInfo] = useState<loadTransformers>();
  const [edit, setEdit] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(true);
  const btnDisabled = isDetail || !edit;
  const [x, forceUpdate] = useUpdate();
  const [isModalEdit, setIsModalEdit] = useState<boolean>(false);
  const [modalEditInfo, setModalEditInfo] = useState<number>();

  useEffect(() => {
    if (info) {
      uetProdLoadInfo(info.id.toString()).then(data => {
        if (data.loadTransformerDetailDtos.length > 0) {
          setInit(false);
          const initList = data.loadTransformerDetailDtos.map(i => {
            const parentInfo = i.uetProdLoadLinkDto.parent
              ? data.loadTransformerDetailDtos.find(d => d.id === i.uetProdLoadLinkDto.parent)
              : undefined;
            return {
              deviceId: i.deviceId,
              level: i.uetProdLoadLinkDto.level.toString(),
              sequence: i.uetProdLoadLinkDto.sequence,
              code: i.device.code,
              name: i.device.name,
              typeName: i.device.typeName,
              capacity: i.capacity,
              targetLoad: i.targetLoad,
              targetLoadRate:
                !isNil(i.targetLoad) && !isNil(i.capacity)
                  ? Number(((i.targetLoad / i.capacity) * 100).toFixed(2))
                  : undefined,
              totalLoadDeviceId: i.totalLoadDeviceId,
              totalLoadDeviceCode: i.totalLoadDevice?.code,
              totalLoadDeviceName: i.totalLoadDevice?.name,
              productionLoadDeviceId: i.productionLoadDeviceId,
              productionLoadDeviceName: i.productionLoadDevice?.name,
              productionLoadDeviceCode: i.productionLoadDevice?.code,
              parentDeviceId: data.loadTransformerDetailDtos.find(d => d.id === i.uetProdLoadLinkDto.parent)?.deviceId,
              rootDeviceId: data.loadTransformerDetailDtos.find(d => d.id === i.uetProdLoadLinkDto.root)?.deviceId,
              parentInfo: parentInfo
                ? {
                    level: parentInfo.uetProdLoadLinkDto.level.toString(),
                    sequence: parentInfo.uetProdLoadLinkDto.sequence,
                  }
                : undefined,
              ouIdOfProductionLoadDevice: i.ouIdOfProductionLoadDevice,
              ouIdOfTotalLoadDevice: i.ouIdOfTotalLoadDevice,
              electricityAccountId: i.electricityAccountId,
              electricityAccountNumber: i.electricityAccountNumber,
            };
          });
          console.log(initList);
          setParentTransformer(!!initList.find(i => i.level === '0'));
          setSubTransformer(!!initList.find(i => i.level === '1'));
          const otherLevel = initList.filter(i => i.level !== '2');
          const twoLevel = formatTwoLevelColor(initList.filter(i => i.level === '2'));
          setList([...otherLevel, ...twoLevel]);
        } else {
          setParentTransformer(false);
          setSubTransformer(false);
        }
      });
    }
  }, [info, x]);

  const submitAction = (v: loadTransformers, levels: string) => {
    const rootDeviceId = list.find(i => i.level === '0')?.deviceId;
    if (levels === '0') {
      if (isModalEdit) {
        const newData = [...list];
        const index = newData.findIndex(item => item.deviceId === modalEditInfo);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...v,
        });
        setList(newData);
      } else {
        setList([{ ...v, sequence: 1, rootDeviceId: v.deviceId }]);
      }
    } else if (levels === '1') {
      if (isModalEdit) {
        const sequence = list.filter(i => i.level === '1').length;
        const newData = [...list];
        const index = newData.findIndex(item => item.deviceId === modalEditInfo);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...v,
          rootDeviceId: rootDeviceId,
          sequence,
        });
        setList(newData);
      } else {
        const sequence = list.filter(i => i.level === '1').length + 1;
        setList([...list, { ...v, sequence: sequence, rootDeviceId: rootDeviceId }]);
      }
    } else if (levels === '2') {
      const otherLevel = list.filter(i => i.level !== '2');
      const twoLevel = formatTwoLevelList(list, v, rootDeviceId!);
      setList([...otherLevel, ...twoLevel]);
    }
    onCancelAction();
  };

  const changeTargetLoad = (v: changeTargetLoadProps) => {
    setList(
      list.map(i => {
        if (i.deviceId === v.data.deviceId) {
          let item = { ...i };
          if (v.key === 'electricityAccountId') {
            item = { ...item, ...v.value };
          } else {
            item.targetLoad =
              v.key === 'targetLoad'
                ? v.value
                : !v.data.capacity
                ? v.data.targetLoad
                : Number((v.data.capacity * (v.value / 100)).toFixed(2));
            item.targetLoadRate =
              v.key === 'targetLoadRate'
                ? v.value
                : !v.data.capacity
                ? v.data.targetLoadRate
                : Number(((v.value / v.data.capacity) * 100).toFixed(2));
          }
          return v.key === 'electricityAccountId' ? { ...item, ...v.value } : item;
        } else {
          return i;
        }
      })
    );
  };

  const onFinish = async () => {
    if (list.length > 0) {
      const body = {
        loadTransformers: list,
      };
      if (init) {
        const res = await createUetProdLoad(info!.id, body);
        if (res) {
          message.success('保存成功');
          setEdit(false);
          setInit(false);
        }
      } else {
        const res = await updateUetProdLoad(info!.id, body);
        if (res) {
          if (!res.result) {
            return message.error('节点和设备必须先从子阵列进行移除');
          }
          message.success('保存成功');
          setEdit(false);
          setInit(false);
        }
      }
    } else {
      message.error('请至少选择一条！');
    }
  };

  const addAction = (value: loadTransformers) => {
    console.log(value);
    setParentInfo(value);
    setLevel((Number(value.level) + 1).toString());
  };

  const deleteAction = (value: loadTransformers) => {
    if (value.level === '1') {
      const newList = list.filter(i => {
        if (i.level === '1') return i.deviceId !== value.deviceId;
        if (i.level === '2') return i.parentDeviceId !== value.deviceId;
        return {};
      });
      setList(newList);
    }
    if (value.level === '2') {
      const otherLevel = list.filter(i => i.level !== '2');
      const twoLevel = formatTwoLevelColor(list.filter(i => i.level === '2' && i.deviceId !== value.deviceId));
      setList([...otherLevel, ...twoLevel]);
    }
  };

  const actionColumns = {
    title: '操作',
    width: 160,
    fixed: 'right' as const,
    render: (v: loadTransformers) => (
      <Space size={16}>
        <Button
          type="link"
          disabled={btnDisabled}
          onClick={() => {
            setLevel('2');
            setEditInfo(v);
            setParentInfo(oneList.find(i => i.sequence === v.parentInfo!.sequence));
          }}
        >
          编辑
        </Button>
        <Button type="link" onClick={() => deleteAction(v)} disabled={btnDisabled}>
          删除
        </Button>
      </Space>
    ),
  };

  const targetLoadColumns = [
    {
      title: '目标负载',
      width: 150,
      ellipsis: { showTitle: true },
      render: (i: loadTransformers) => (
        <InputNumber
          addonAfter="kW"
          value={i.targetLoad}
          min={0}
          max={i.capacity}
          precision={2}
          disabled={btnDisabled}
          onChange={v => changeTargetLoad({ value: v, key: 'targetLoad', data: i })}
        />
      ),
    },
    {
      title: '目标负载率',
      width: 150,
      ellipsis: { showTitle: true },
      render: (i: loadTransformers) => (
        <InputNumber
          addonAfter="%"
          value={i.targetLoadRate}
          min={0}
          max={100}
          precision={2}
          disabled={btnDisabled}
          onChange={v => changeTargetLoad({ value: v, key: 'targetLoadRate', data: i })}
        />
      ),
    },
  ];

  const onCancelAction = () => {
    // setUniKey(undefined);
    setParentInfo(undefined);
    setEditInfo(undefined);
    setLevel(undefined);
  };

  const zeroList = list.find(i => i.level === '0');
  const oneList = list.filter(i => i.level === '1');
  const twoList = list.filter(i => i.level === '2');

  useEffect(() => {
    if (zeroList && level === '0' && isModalEdit && modalEditInfo) {
      console.log('zeroList', zeroList);
      setEditInfo(zeroList);
    } else if (zeroList && oneList && level === '1' && isModalEdit && modalEditInfo) {
      setParentInfo(zeroList);
      setEditInfo(oneList.find(i => i.deviceId === modalEditInfo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zeroList, level]);

  return (
    <>
      <Space style={{ marginBottom: 20 }}>
        <Button
          className={classnames(styles.btn, parentTransformer && styles.checked, {
            [styles.disabled]: !!zeroList || btnDisabled,
          })}
          icon={parentTransformer && <CheckCircleOutlined />}
          // disabled={!!zeroList || btnDisabled}
          onClick={() => setParentTransformer(!parentTransformer)}
        >
          UET级总负载变压器
        </Button>
        <Button
          className={classnames(styles.btn, {
            [styles.checked]: subTransformer,
            [styles.disabled]: !zeroList || btnDisabled || oneList.length > 0,
          })}
          icon={subTransformer && <CheckCircleOutlined />}
          disabled={!zeroList || btnDisabled || oneList.length > 0}
          onClick={() => setSubTransformer(!subTransformer)}
        >
          UET级下级子拓扑变压器
        </Button>
      </Space>
      {parentTransformer && (
        <div style={{ marginBottom: 20 }}>
          <p className={styles.title}>0级子网络变压器</p>
          <Button
            type="dashed"
            disabled={!!zeroList || btnDisabled}
            icon={<PlusOutlined />}
            className={styles.defaultBtn}
            onClick={() => {
              setLevel('0');
              setIsModalEdit(false);
            }}
          >
            选择总负载变压器
          </Button>
        </div>
      )}
      {zeroList && (
        <List
          setModalEditInfo={setModalEditInfo}
          setIsModalEdit={setIsModalEdit}
          mcid={info!.mcid}
          values={[zeroList]}
          setLevel={setLevel}
          changeTargetLoad={changeTargetLoad}
          disabled={btnDisabled}
        />
      )}
      {subTransformer && (
        <>
          <p className={styles.title} style={{ marginTop: 16 }}>
            1级子网络变压器
          </p>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            className={styles.defaultBtn}
            disabled={btnDisabled}
            onClick={() => {
              setLevel('1');
              setParentInfo(zeroList);
              setIsModalEdit(false);
            }}
          >
            选择1级子网络变压器
          </Button>
          {oneList && (
            <List
              setModalEditInfo={setModalEditInfo}
              setIsModalEdit={setIsModalEdit}
              setLevel={setLevel}
              mcid={info!.mcid}
              values={oneList}
              disabled={btnDisabled}
              changeTargetLoad={changeTargetLoad}
              addAction={addAction}
              deleteAction={deleteAction}
            />
          )}
        </>
      )}
      {twoList.length > 0 && (
        <>
          <p className={styles.title} style={{ marginTop: 16 }}>
            2级子网络变压器
          </p>
          <Table
            dataSource={twoList}
            columns={[...columns(targetLoadColumns), actionColumns]}
            // rowClassName={r => styles[`${r.color}`]}
            rowClassName={styles.defaultCol}
            scroll={{ x: 1600 }}
            pagination={false}
          />
        </>
      )}
      {level && (
        <CreateTransformerModal
          level={level}
          parentInfo={parentInfo}
          list={list}
          info={info}
          editInfo={editInfo}
          submitAction={v => submitAction(v, level)}
          onCancel={onCancelAction}
        />
      )}
      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={onFinish}>
              保存
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                  onOk: () => {
                    forceUpdate();
                    setEdit(false);
                  },
                });
              }}
            >
              取消
            </Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>
    </>
  );
};

export default ProductionLoad;
