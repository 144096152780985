import { fetch, Key } from '@maxtropy/components';
import qs from 'qs';
import { PageRequest, PageResponse } from './page';
import { ElectricitySaleContractSettlementType } from './electricitySettlementRules';
import { TimeIntervalType } from './energyAssetsSettlementsRules';

export enum EnergyAssetsBillStatus {
  ABNORMAL_SYSTEM_ERROR = -1, // 异常 系统错误
  NORMAL = 0, // 正常
  ABNORMAL_FAIL_GET = 1, // 异常 抄表获取失败
  ABNORMAL_MISSING_METER = 2, // 异常 缺少计量电表
  ABNORMAL_MISSING_MODAL_PRICE = 3, // 异常 电价模板没有配置电度电价
  ABNORMAL_MISSING_UET_ID = 4, // 异常 uetId获取失败
  ABNORMAL_MANUAL_FAILED = 5, // 异常 手工抄表失败
  ABNORMAL_MISSING_FEE = 6, // 异常 缺少费率 实际上就是电价模板没有配置电度电价
  ABNORMAL_RATE_NULL = 7, // 异常 存在倍率为空的设备
}

export const EnergyAssetsBillStatusDisplay = {
  [EnergyAssetsBillStatus.NORMAL]: '正常',
  [EnergyAssetsBillStatus.ABNORMAL_FAIL_GET]: '异常',
  [EnergyAssetsBillStatus.ABNORMAL_MISSING_METER]: '异常',
  [EnergyAssetsBillStatus.ABNORMAL_MISSING_MODAL_PRICE]: '异常',
  [EnergyAssetsBillStatus.ABNORMAL_SYSTEM_ERROR]: '异常',
  [EnergyAssetsBillStatus.ABNORMAL_MISSING_UET_ID]: '异常',
  [EnergyAssetsBillStatus.ABNORMAL_MANUAL_FAILED]: '异常',
  [EnergyAssetsBillStatus.ABNORMAL_MISSING_FEE]: '异常',
  [EnergyAssetsBillStatus.ABNORMAL_RATE_NULL]: '异常',
};

export enum DiscardStatus {
  NORMAL = 1, // 未作废
  DISCARD = 0, // 已作废
}

// 根据bsaId查询设备列表
export interface BSADeviceItem {
  id: Key;
  name: string; // 设备名称;
  code: string; // 设备编号
  addressId: Key; // 设备地址id
  deviceTypeId: Key; // 设备类型id
  rate: number; // 倍率
}

export const getBSADeviceItem = (bsaId: Key) =>
  fetch<BSADeviceItem>(
    `/api/v2/bsa/device/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id: bsaId }),
    },
    true
  );

export enum MeterType {
  GATEWAY = 0, // 关口表
  POINT_OF_INTEGRATION = 1, //  并网点电表
  METERING = 2, // 计量电表
}

export const MeterTypeDisplay = {
  [MeterType.GATEWAY]: '关口表',
  [MeterType.POINT_OF_INTEGRATION]: '并网点电表',
  [MeterType.METERING]: '计量电表',
};

export interface EnergyAssetsBillPageBody {
  customerMcid?: string; //所属组织
  saleElectricityContractId?: Key; //合同id
  saleClientId?: Key; //客户id
  code?: string; //结算单编号
  settlementStartTimeLower?: string; //结算周期开始时间下值
  settlementStartTimeUpper?: string; //结算周期开始时间上值
  settlementEndTimeLower?: string; // 结算周期结束时间下值
  settlementEndTimeUpper?: string; // 结算周期结束时间上值
  showObsolete?: boolean; //是否展示已作废列表
  arrayName?: string; //站点名称
}

export interface ErrorDevicesItem {
  id: Key;
  name: string; //设备名称
  code: string; //设备编号
}

export interface EnergyAssetsBillPageItem {
  id: Key;
  code: string; // 结算单编号
  saleElectricityContractId: Key; // 合同id
  saleElectricityContractName: string; // 合同名称
  customerMcid: string; //  组织id
  customerName: string; //  组织名称
  saleClientId: Key; //  客户id
  saleClientName: string; //  客户名称
  settlementRuleId: Key; //  结算规则id
  settlementRuleName: string; //  结算规则名称
  arrayId: Key; // 站点id
  arrayName: string; // 站点名称
  settlementStartTime: string; //  结算周期开始时间
  settlementEndTime: string; //  结算周期结束时间
  meterReadingRecordSource: MeterReadingRecordSource; //  抄表来源
  incomeFee: number; //  收入
  payFee: number; //  支出
  totalFee: number; //  总费用
  summitPrice: number; //  尖价格
  peakPrice: number; //  峰价格
  plainPrice: number; //  平价格
  valleyPrice: number; //  谷价格
  gridPrice: number; //  上网电价
  discountForOwner: number; //  放电折扣/电价折扣
  gridPercentageForOwner: number; //  上网分成比例
  errorCode: EnergyAssetsBillStatus; //  异常码
  errorDevices: ErrorDevicesItem[]; //  异常设备
  discard: DiscardStatus; //  作废状态
  createUserName: string; //  创建人
  generatingTime: string; //  生成时间
  createTime: string; //  创建时间
  updateTime: string; //  更新时间
  tenantMcid: string;
}

// 结算单列表
export const getEnergyAssetsBillPage = (query: PageRequest, body: EnergyAssetsBillPageBody) =>
  fetch<PageResponse<EnergyAssetsBillPageItem>>(
    `/api/v2/energy-asset/settlement-bill/page`,
    {
      method: 'POST',
      body: JSON.stringify({ ...query, ...body }),
    },
    true
  );

// 作废结算单
export const discardEnergyAssetsBill = (id: Key) =>
  fetch(
    `/api/v2/energy-asset/settlement-bill/void`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

// 抄表来源
export enum MeterReadingRecordSource {
  SYSTEM = 1, // 系统自动抄表
  MANUAL = 2, // 人工录入
}

// 电流方向
export enum Direction {
  FORWARD = 1, // 正向
  REVERSE = 2, // 反向
}

// 抄表数据item
export interface MeterReadingDataItem {
  deviceId: Key; // 设备id
  timeInterval: TimeIntervalType; // 时段（1尖 2峰 3平 4谷）
  direction: Direction; // 电流方向（1正向 2反向）
  value?: Key | null; // 人工抄表值
}

// 手动生成结算单body
export interface ManualGenerateEnergyAssetsBillBody {
  settlementRuleId?: Key | null; //结算规则id null代表是自定义结算规则
  settlementType?: ElectricitySaleContractSettlementType; // 自定义结算规则:结算类型6 分布式光伏7 分布式储能
  saleElectricityContractId?: Key | null; //自定义结算规则: 销售用电合同id
  summitPrice?: number; // 自定义结算规则:尖时电价
  peakPrice?: number; // 自定义结算规则:峰时电价
  plainPrice?: number; // 自定义结算规则:平时电价
  valleyPrice?: number; // 自定义结算规则:谷时电价
  gridPrice?: number; // 自定义结算规则:上网电价（仅光伏）
  discountForOwner?: number; // 自定义结算规则:电价折扣（光伏）/放电折扣（储能）
  gridPercentageForOwner?: number; // 自定义结算规则:上网分成比例（仅光伏）
  arrayId?: Key; // 自定义结算规则:光伏站id或者储能站id
  settlementStartDate: string; // 结算周期开始时间 选择的结算周期的开始日期+0点
  settlementEndDate: string; // 结算周期结束时间 选择的结算周期的结束日期加1日+0点
  meterReadingRecordSource: MeterReadingRecordSource; //抄表来源（1系统自动抄表 2人工录入）
  beginDayData?: MeterReadingDataItem[]; //	抄表来源：人工录入期初抄表数据
  endDayData?: MeterReadingDataItem[]; //抄表来源：人工录入期末抄表数据
}

// 手动生成结算单返回值
export interface ManualGenerateEnergyAssetsBillRes {
  errorCode: EnergyAssetsBillStatus; // 异常码（1抄表获取失败 2缺少计量电表 3系统错误 0正常）
  data: Key; // 异常码为1时表示的是设备编号 异常码为2时表示的是结算规则id
}

export const manualGenerateEnergyAssetsBill = (body: ManualGenerateEnergyAssetsBillBody) =>
  fetch<ManualGenerateEnergyAssetsBillRes>(
    '/api/v2/energy-asset/settlement-bill/create',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );

// 储能站Item
export interface BSAItem {
  id: Key;
  name: string; //阵列名称
}

export const getBSAList = () =>
  fetch<{ list: BSAItem[] }>(
    `/api/v2/bsa/list`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);

// 根据客户获取结算规则列表
export interface EnergyAssetsSettlementsRulesItem {
  ruleId: Key; // 结算规则id
  settlementType: ElectricitySaleContractSettlementType; // 结算类型（6分布式光伏 7分布式储能）
  ruleName: string; // 规则名称
}

export const getEnergyAssetsSettlementsRulesList = (saleClientId: Key) => {
  return fetch<{ list: EnergyAssetsSettlementsRulesItem[] }>(
    `/api/v2/sale-electricity/marketingOfEnergyAssets/getRuleBasicList`,
    {
      method: 'POST',
      body: JSON.stringify({ id: saleClientId }),
    },
    true
  );
};

// 根据客户id获取客户下有效售电合同基础信息列表
export interface ElectricitySaleContractItem {
  id: Key; // 售电合同id
  name: string; // 合同名称
  code: string; // 合同编号
}

export const getElectricitySaleContractList = (id: Key) =>
  fetch<{ list: ElectricitySaleContractItem[] }>(
    `/api/v2/sale-electricity/contract/getContractListByClientId`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export enum TimeIntervalTableType {
  PV_OUTPUT = 1, // 光伏发电量
  PV_ONLINE = 2, // 光伏上网电量
  PV_CONSUMPTION = 3, // 光伏消纳电量
  BSA_CHARGE = 4, // 储能充电量
  BSA_DISCHARGE = 5, // 储能放电量
}

export const TimeIntervalTableTypeDisplay = {
  [TimeIntervalTableType.PV_OUTPUT]: '光伏发电量',
  [TimeIntervalTableType.PV_ONLINE]: '光伏上网电量',
  [TimeIntervalTableType.PV_CONSUMPTION]: '光伏消纳电量',
  [TimeIntervalTableType.BSA_CHARGE]: '储能充电量',
  [TimeIntervalTableType.BSA_DISCHARGE]: '储能放电量',
};

export interface EnergyAssetsBillDetailItem {
  id: Key;
  energyAssetSettlementBillId: Key;
  detailType: TimeIntervalTableType;
  deviceId: Key;
  deviceName: string; //设备名称
  timeInterval: TimeIntervalType; //时段（1尖 2峰 3平 4谷 5总）
  presentMonthDisplay: number; //本月示数
  lastMonthDisplay: number; //上月示数
  times: number; //倍率
  generatingCapacity: number; //发电量
  gridCapacity: number; //上网电量
  gridPercentageAmount: number; //上网分成
  usedCapacity: number; //消纳电量
  usedAmount: number; //消纳电费
  chargingCapacity: number; //充电量
  chargingAmount: number; //充电成本
  dischargingCapacity: number; //放电量
  dischargingAmount: number; //放电成本
  createTime: string;
  updateTime: string;
  tenantMcid: string;
}

// 结算单详情
export interface EnergyAssetsBillDetail {
  id: Key;
  code: string; // 结算单编号
  saleElectricityContractId: Key; // 合同id
  saleElectricityContractCode: string; // 合同编号
  saleClientId: Key; // 客户id
  saleClientName: string; // 客户名称
  saleClientCode: string; // 客户编号
  settlementRuleId: Key; // 结算规则id
  arrayId: Key; // 站点id
  settlementStartTime: string; // 结算周期开始时间
  settlementEndTime: string; // 结算周期结束时间
  meterReadingRecordSource: MeterReadingRecordSource; // 抄表来源
  incomeFee: number; // 收入 (上网分成、充电成本)
  payFee: number; // 支出(消纳电费、放电电费)
  incomeQuantity: number; // 收入（上网电量/充电电量）
  payQuantity: number; // 支出（消纳电量/放电电量）
  totalFee: number; // 总费用
  summitPrice: number; // 尖价格
  peakPrice: number; // 峰价格
  plainPrice: number; // 平价格
  valleyPrice: number; // 谷价格
  gridPrice: number; // 上网电价
  discountForOwner: number; // 放电折扣/电价折扣
  gridPercentageForOwner: number; // 上网分成比例
  errorCode: EnergyAssetsBillStatus; // 异常码
  discard: DiscardStatus; // 作废状态
  createUserName: string; // 创建人
  generatingTime: string; // 生成时间
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  tenantMcid: string;
  settlementSource: MeterReadingRecordSource; // 结算来源
  meterDeviceIdStr: string; // 表的设备ids
  settlementType: ElectricitySaleContractSettlementType; // 结算类型
  details: EnergyAssetsBillDetailItem[]; // 详细信息
  picKeys: string[]; // 图片
}

export const getEnergyAssetsBillDetail = (billId: Key) =>
  fetch<EnergyAssetsBillDetail>(
    `/api/v2/energy-asset/settlement-bill/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ billId }),
    },
    true
  );
