import { Button, Empty, Key, Modal, Select, Form } from '@maxtropy/components';
import { Col, Divider, Row, Cascader } from 'antd';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import PieCharts from './PieCharts';
import circlePng from '../../assets/circle.png';
import EnergyTable from './EnergyTable';
import { useEffect, useState } from 'react';
import { formateTimeMap, totalUseTransformUnit, transformUnit } from '../../BannerInfo';
import fileText from '../../assets/fileText.png';
import { formatMomRatio } from './EnergyTable';
import { isNil } from 'lodash-es';
import { baseCenterTypes } from '../../utils';
import {
  V2LeanEnergyFlowAnalysisPieChartProductionBasePostResponse,
  apiV2ProductionBaseCompleteTreePost,
  apiV2ProductionBaseQueryMediumListUnderBasePost,
  apiV2LeanEnergyFlowAnalysisDetailDataPost,
  V2LeanEnergyFlowAnalysisDetailDataPostResponse,
  V2LeanEnergyFlowAnalysisFlowDetailProductionBasePostResponse,
  apiV2LeanEnergyFlowAnalysisPieChartDataPost,
} from '@maxtropy/device-customer-apis-v2';
import { unitTreeItem, WorkCenterTreeItem, StationTreeItem } from '../../utils';

export const getEmpty = (description?: string) => {
  return (
    <div style={{ width: '100%', height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Empty description={description} />
    </div>
  );
};
export function concatMessage(data: any) {
  const length = data.length;
  if (length === 2) {
    return data[1].label;
  } else if (length === 1) {
    const childrenNames = data[0].children.map((child: any) => child.label).join('、');
    return `${data[0].label}下${childrenNames}工作中心`;
  } else {
    const names = data
      .slice(1)
      .map((item: any) => item.label)
      .join('-');
    return names;
  }
}
export type TypeConfig = {
  productionBase: { title: string; dataIndex: string };
  workCenter: { title: string; dataIndex: string };
  procedure: { title: string; dataIndex: string };
  workStation: { title: string; dataIndex: string };
  energyUnit: { title: string; dataIndex: string };
};
interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  energyUnitGroupId?: Key;
  currentDate: string;
  currentDateType: string;
  choseproductionBaseId?: number;
  choseWorkerCenterIds?: number[];
  type: keyof TypeConfig;
  setType: (val: keyof TypeConfig) => void;
  selectedMediumType: Key;
  selectedBasePhysicalQuantityId?: number;
  tableData: V2LeanEnergyFlowAnalysisFlowDetailProductionBasePostResponse['list'];
  setTableData: (val: V2LeanEnergyFlowAnalysisFlowDetailProductionBasePostResponse['list']) => void;
  currentSelectedIds: number[];
}

interface OptionType {
  value?: string;
  label?: string;
  basePhysicalQuantityId?: number;
  mediumId?: number;
}

const EnergyFlowDetailsModal: React.FC<Props> = ({
  open,
  setOpen,
  currentDate,
  currentDateType,
  choseWorkerCenterIds,
  choseproductionBaseId,
  type,
  selectedMediumType,
  selectedBasePhysicalQuantityId,
  setType,
  setTableData,
  tableData,
  currentSelectedIds,
}) => {
  const [form] = Form.useForm();

  const [mediumOptions, setMediumOptions] = useState<OptionType[]>([]);
  const [pieId, setPieId] = useState<number>();
  const [chartData, setChartData] = useState<V2LeanEnergyFlowAnalysisPieChartProductionBasePostResponse['list']>([]);
  const [baseCenterHierarchyOptions, setBaseCenterHierarchyOptions] = useState<any>([]);
  const [modalChoseCenterIds, setModalChoseWorkCenterIds] = useState<number[]>([]);
  const [modalworkProcedure, setModalworkProcedure] = useState<number>();
  const [modalworkStation, setModalworkStation] = useState<number>();
  const [childDetail, setChildDetail] = useState<string>('-');
  const [currentOption, setCurrentOption] = useState<any>([]);
  const [firstBaseId, setFirstBaseId] = useState<number>();
  const [detailData, setDetailData] = useState<V2LeanEnergyFlowAnalysisDetailDataPostResponse>({});
  const [maxEnergy, setMaxEnergy] = useState<any>();
  const [basePhysicalQuantityId, setBasePhysicalQuantityId] = useState<number | undefined>(
    selectedBasePhysicalQuantityId
  );
  const [mediumId, setMediumId] = useState<number | undefined>(currentSelectedIds[0]);

  const getTimestamps = (inputDate: string): string[] => {
    if (currentDateType === 'M') {
      const startDate = dayjs(inputDate).startOf('month').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('month').format('YYYY-MM-DD');
      return [startDate.toString(), endDate.toString()];
    } else if (currentDateType === 'D') {
      const startDate = dayjs(inputDate).startOf('day').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('day').format('YYYY-MM-DD');
      return [startDate.toString(), endDate.toString()];
    } else if (currentDateType === 'Y') {
      const startDate = dayjs(inputDate).startOf('year').format('YYYY-MM-DD');
      const endDate = dayjs(inputDate).endOf('year').format('YYYY-MM-DD');
      return [startDate.toString(), endDate.toString()];
    } else {
      return ['0', '0'];
    }
  };
  const [energyTitle, setEnergyTitle] = useState<string>();
  useEffect(() => {
    form.setFieldsValue({
      mediumIdAndBasePhysicalQuantityId:
        currentSelectedIds.length === 0 ? `100-0` : `${currentSelectedIds[0]}-${selectedBasePhysicalQuantityId}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedIds, selectedBasePhysicalQuantityId]);
  useEffect(() => {
    const findData = mediumOptions.find(
      i => i.mediumId === mediumId && i.basePhysicalQuantityId === basePhysicalQuantityId
    );
    setEnergyTitle(findData?.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediumOptions, mediumId, basePhysicalQuantityId]);

  // 级联框生产基地
  useEffect(() => {
    if (baseCenterHierarchyOptions && baseCenterHierarchyOptions.length > 0) {
      const findData = baseCenterHierarchyOptions.find(
        (i: baseCenterTypes) => i.id === baseCenterHierarchyOptions[0].id
      );
      if (isNil(findData)) return;
      const resultMap = findData.value;
      form.setFieldsValue({
        formproductionBaseId: [resultMap],
      });
      setFirstBaseId(findData.value);
      setPieId(findData.value);
      setModalChoseWorkCenterIds(findData.children.map((child: any) => child?.id));
      const childrenNames = findData.children.map((child: any) => child?.name).join('、');
      setChildDetail(`${findData.label}下${childrenNames}工作中心`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseCenterHierarchyOptions, choseproductionBaseId]);

  useEffect(() => {
    if (isNil(pieId)) return;
    const formatDate = getTimestamps(currentDate);
    const params = {
      startDate: formatDate[0],
      endDate: formatDate[1],
    };
    apiV2LeanEnergyFlowAnalysisPieChartDataPost({
      workCenterId: modalChoseCenterIds[0],
      workProcedureId: modalworkProcedure,
      workStationId: modalworkStation,
      energyUnitId: currentOption.energyUnitId,
      energyMediumId: mediumId,
      basePhysicalQuantityId,
      productionBaseId: choseproductionBaseId,
      date: currentDate,
      resolution: currentDateType,
      ...params,
    }).then(res => {
      setChartData(res.list);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieId, currentDate, currentDateType, currentOption, modalChoseCenterIds, modalworkProcedure, mediumId]);

  useEffect(() => {
    if (isNil(pieId) || isNil(mediumId)) return;
    const detailParams = {
      energyMediumId: mediumId,
      date: currentDate,
      resolution: currentDateType,
      productionBaseId: firstBaseId,
      basePhysicalQuantityId,
    };
    if (type === 'productionBase') {
      apiV2LeanEnergyFlowAnalysisDetailDataPost({ ...detailParams, workCenterIds: choseWorkerCenterIds }).then(res => {
        setDetailData(res);
      });
    } else if (type === 'workCenter') {
      apiV2LeanEnergyFlowAnalysisDetailDataPost({
        workCenterId: currentOption.value,
        ...detailParams,
      }).then(res => {
        setDetailData(res);
      });
    } else if (type === 'procedure') {
      apiV2LeanEnergyFlowAnalysisDetailDataPost({
        workCenterId: currentOption.workCenterId,
        ...detailParams,
      }).then(res => {
        setDetailData(res);
      });
    } else if (type === 'workStation') {
      apiV2LeanEnergyFlowAnalysisDetailDataPost({
        workCenterId: currentOption.workCenterId,
        ...detailParams,
      }).then(res => {
        setDetailData(res);
      });
    } else if (type === 'energyUnit') {
      apiV2LeanEnergyFlowAnalysisDetailDataPost({
        workCenterId: currentOption.workCenterId,
        energyUnitId: currentOption.energyUnitId,
        ...detailParams,
      }).then(res => {
        setDetailData(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediumId, pieId, currentDateType, currentDate, currentOption, basePhysicalQuantityId]);

  function formatTree(data: any) {
    let tree = [];
    const format = (child: WorkCenterTreeItem[]) => {
      return child
        .filter((i: WorkCenterTreeItem) => choseWorkerCenterIds?.includes(Number(i.id)))
        .map((i: WorkCenterTreeItem) => {
          return {
            ...i,
            key: `workCenter-${i.id}`,
            label: i.name,
            value: i.id,
            children: (i?.procedureTree ?? []).map(item => {
              return {
                ...item,
                key: `workOrder-${item.workCenterId}-${item.centerProcedureLinkId}-${item.procedureId}`,
                label: item.procedureName,
                value: item.procedureId,

                children: (item?.stationTree ?? []).map((item: StationTreeItem) => {
                  return {
                    ...item,
                    key: `workStation-${item.workCenterId}-${item.centerProcedureLinkId}-${item.procedureStationLinkId}-${item.stationId}`,
                    label: item.stationName,
                    value: item.stationId,
                    children: (item?.unitTree ?? []).map((item: unitTreeItem) => {
                      return {
                        ...item,
                        key: `energyUnit-${item.workCenterId}-${item.centerProcedureLinkId}-${item.procedureStationLinkId}-${item.energyUnitId}`,
                        label: item.energyUnitName,
                        value: item.energyUnitId,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        });
    };
    tree = data?.map((i: any) => ({
      key: `productionBase-${i.id}`,
      label: i.name,
      value: i.id,
      children: format(i.workCenterTree ?? []),
    }));
    return tree;
  }

  // 2025-03-19排查：接口中已把maxDispersionRateWorkProcedureName等字段去掉了
  // const { data: detail } = useRequest(
  //   () => {
  //     return apiV2LeanEnergyFlowAnalysisBannerPost({
  //       workCenterIds: [modalChoseCenterIds[0]],
  //       date: currentDate,
  //       resolution: currentDateType,
  //     });
  //   },
  //   {
  //     ready: !!modalChoseCenterIds && !!currentDate && type === 'workCenter' && !!mediumId,
  //     refreshDeps: [modalChoseCenterIds, currentDate, mediumId, currentDateType],
  //   }
  // );

  useEffect(() => {
    if (isNil(choseproductionBaseId)) return;
    apiV2ProductionBaseQueryMediumListUnderBasePost({
      baseId: choseproductionBaseId,
    }).then(res => {
      const options = (res?.list ?? []).map(item => ({
        label: `${item.energyMediumName}-${item.generalName}`,
        value: `${item.energyMediumId}-${item.basePhysicalQuantityId}`,
        basePhysicalQuantityId: item.basePhysicalQuantityId,
        mediumId: item.energyMediumId,
      }));
      if (options.length > 0) {
        options.unshift({
          label: '综合能耗',
          value: `100-0`,
          basePhysicalQuantityId: 0,
          mediumId: 100,
        });
      }
      setMediumOptions(options);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choseproductionBaseId]);

  useEffect(() => {
    apiV2ProductionBaseCompleteTreePost({}).then(res => {
      const mappedData = formatTree(res?.list).find((item: any) => item.value === choseproductionBaseId);
      setBaseCenterHierarchyOptions([mappedData] as baseCenterTypes[]);
      console.log([mappedData]);
    });
  }, []);

  const onClose = () => {
    setType('productionBase');
    setOpen(false);
  };

  return (
    <Modal
      size="big"
      open={open}
      title="单元能流详情"
      bodyScroll
      onCancel={onClose}
      footer={
        <Button type="primary" onClick={onClose}>
          确定
        </Button>
      }
    >
      <div className={styles.formContent}>
        <Form form={form} isOrigin>
          <Row>
            <Col span={8}>
              <Form.Item label={'生产基地选择'} name="formproductionBaseId">
                <Cascader
                  options={baseCenterHierarchyOptions}
                  allowClear={false}
                  maxTagCount="responsive"
                  showSearch
                  onChange={(value, workCenterOptions) => {
                    workCenterOptions.map(item => item);
                    console.log(baseCenterHierarchyOptions);
                    const childrenDetail = concatMessage(workCenterOptions);
                    setChildDetail(childrenDetail);
                    setCurrentOption(workCenterOptions.slice(-1)[0]);
                    setFirstBaseId(workCenterOptions[0].value as number);
                    setPieId(value.slice(-1)[0] as number);
                    if (value.length === 1) {
                      const map = workCenterOptions[0].children?.map(item => Number(item.value) as number);
                      setType('productionBase');
                      setModalChoseWorkCenterIds(map as number[]);
                    } else if (value.length === 2) {
                      setModalChoseWorkCenterIds([Number(value[1])]);
                      setType('workCenter');
                    } else if (value.length === 3) {
                      setModalChoseWorkCenterIds([Number(value[1])]);
                      setModalworkProcedure(Number(value[2]));
                      setType('procedure');
                    } else if (value.length === 4) {
                      setModalChoseWorkCenterIds([Number(value[1])]);
                      setModalworkProcedure(Number(value[2]));
                      setModalworkStation(Number(value[3]));
                      setType('workStation');
                    } else if (value.length === 5) {
                      setType('energyUnit');
                    }
                  }}
                  changeOnSelect
                  showCheckedStrategy={'SHOW_CHILD'}
                  placeholder="请选择"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'能源介质选择'} name="mediumIdAndBasePhysicalQuantityId">
                <Select
                  options={mediumOptions}
                  placeholder="请选择能源介质选择"
                  maxTagCount="responsive"
                  onChange={(_, option) => {
                    setBasePhysicalQuantityId((option as OptionType)?.basePhysicalQuantityId || undefined);
                    setMediumId((option as OptionType)?.mediumId || undefined);
                  }}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className={styles.textArea}>
          <div className={styles.infoLine}>
            <p>
              时间：
              <span>{dayjs(currentDate).format(formateTimeMap[currentDateType])}</span>
            </p>
            <p>
              {energyTitle}：
              <span>
                {isNil(detailData) || isNil(detailData.totalConsumptionValue) ? (
                  '--'
                ) : (
                  <>
                    {transformUnit(
                      detailData.totalConsumptionValue!,
                      isNil(detailData.generalName) ? 'kgce' : detailData.generalName
                    )}
                  </>
                )}
              </span>
            </p>
            <p>
              环比：<span>{formatMomRatio(detailData?.momPercent)}</span>
            </p>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.analysisContent}>
            <img src={fileText} alt="" className={styles.img} /> 分析结论：
            {dayjs(currentDate).format(formateTimeMap[currentDateType])}
            {childDetail} 总用能为
            <span className={styles.text}>{totalUseTransformUnit(detailData?.totalConsumptionValue)} </span>， 其中
            {detailData?.mediumData?.map(item => {
              return (
                <span key={item.energyMediumId}>
                  {item.energyMediumName}用量
                  <span className={styles.text}>{transformUnit(item.consumptionValue!, item.generalName)}</span>
                </span>
              );
            })}
            {type !== 'energyUnit' ? (
              <>
                ，
                <span className={styles.text2}>
                  [{type === 'productionBase' ? maxEnergy?.workCenterName ?? '-' : null}
                  {type === 'workCenter' ? maxEnergy?.workProcedureName ?? '-' : null}
                  {type === 'procedure' ? maxEnergy?.workStationName ?? '-' : null}
                  {type === 'workStation' ? maxEnergy?.energyUnitName ?? '-' : null}]
                </span>
                用能最高，占比
                <span className={styles.text}>{maxEnergy?.ratio ?? '-'}%。</span>
              </>
            ) : null}
            {/* {detail?.maxDispersionRateWorkProcedureName && (
              <div>
                <span className={styles.text3}>
                  <WarningOutlined />[{detail?.maxDispersionRateWorkProcedureName ?? '-'}]
                </span>
                用能离散率最大，该工序下
                <span className={styles.text3}>[{detail?.wrongWorkStationName ?? '-'}]</span>
                可能存在问题，需要及时检查!
              </div>
            )} */}
          </div>
        </div>

        <div className={styles.detail}>
          {selectedMediumType === '综合能耗' && (
            <div className={styles.pieInfo}>
              <p className={styles.title}>能源介质占比</p>
              {chartData && chartData.length > 0 ? (
                <>
                  <img className={styles.img} alt="" src={circlePng} />
                  <PieCharts data={chartData ?? []} />
                </>
              ) : (
                <div className={styles.empty}>
                  <Empty />
                </div>
              )}
            </div>
          )}
          {selectedMediumType === '综合能耗' && <Divider type="vertical" className={styles.divider} />}

          <div className={styles.mediumWidth}>
            <EnergyTable
              mediumId={mediumId}
              date={currentDate}
              resolution={currentDateType}
              setData={setTableData}
              data={tableData}
              setMaxEnergy={setMaxEnergy}
              modalChoseCenterIds={modalChoseCenterIds}
              modalworkProcedure={modalworkProcedure}
              modalworkStation={modalworkStation}
              type={type}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EnergyFlowDetailsModal;
