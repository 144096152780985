import { AlarmChannel } from '@/shared/types';
import { fetch, PromiseWithErrorHandler } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { Key } from 'react';
import { AlarmStatus, IotProtocolType, Operator } from '@/shared/types';
import { RulePageProps, unbindDeviceSearchParams } from './rule';
import { Device } from './device';

export interface RuleGroupPageRequest extends PageRequest {
  nameOrCode?: string;
  status?: AlarmStatus;
  ruleCode?: Key;
}

export interface RuleGroupPageProps {
  id: number;
  code: string;
  name: string;
  status: AlarmStatus;
  rootMcid: string;
  rootName: string;
  rootFullName: string;
  tenantName: string;
  channel: AlarmChannel;
  tenantCode?: string;
  useDeviceCount?: number;
  iotProtocol: IotProtocolType;
  updateSource: Operator;
  updateByUserId: number;
  updateByUsername: string;
  updateTime: string;
  deviceTypeName?: string;
  deviceTypeId?: number;
}

export function deleteRuleGroupById(id: number) {
  return fetch(
    `/api/v2/rule/group/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface CreateRuleGroupRequest {
  name: string;
  iotProtocol: number;
  channel: AlarmChannel;
  ruleIds: number[];
  deviceTypeId?: number;
}

export interface UpdateRuleGroupRequest extends Omit<CreateRuleGroupRequest, 'rootMcid' | 'iotProtocol' | 'ruleIds'> {
  id: number;
  deleteRuleIds: number[];
  addRuleIds: number[];
  deviceTypeId?: number;
}

export function updateRuleGroup(params: UpdateRuleGroupRequest): Promise<RuleGroupPageProps> {
  return fetch(
    `/api/v2/rule/group/update`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function disableRuleGroup(id: number) {
  return fetch(
    `/api/v2/rule/group/disable`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function enableRuleGroup(id: number) {
  return fetch(
    `/api/v2/rule/group/enable`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function getRulesListById(id: Key): Promise<{ list: RulePageProps[] }> {
  return fetch(
    `/api/v2/rule/group/getRules/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface RulesPageByIdRequest extends PageRequest {
  id: number;
}

export function getRulesPageById(query: RulesPageByIdRequest): PromiseWithErrorHandler<PageResponse<RulePageProps>> {
  return fetch(
    `/api/v2/rule/group/getRules/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export interface DeviceRuleGroupPageRequest extends PageRequest {
  nameOrCode?: string;
  deviceId?: string;
}

export interface DeviceRuleGroupPageResponse {
  id: number;
  code: string;
  name: string;
  rulleCount: number;
}

export interface DeviceRuleGroupIdResponse {
  deviceId: number;
  ruleGroupId: number;
}

export function getDeviceRuleGroupId(id: number): Promise<DeviceRuleGroupIdResponse> {
  return fetch(
    `/api/v2/rule/group/device/getByDeviceId`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function updateDeviceRuleGroupId(params: DeviceRuleGroupIdResponse): Promise<{ flag: boolean }> {
  return fetch(
    `/api/v2/rule/group/device/assign`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export function unBindDeviceRuleGroup(id: number): Promise<{ flag: boolean }> {
  return fetch(
    `/api/v2/rule/group/device/unbinding-rule-group`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface BatchRuleGroupDeviceRequest extends PageRequest {
  name?: string;
  deviceTypeId?: number;
  iotProtocol: IotProtocolType;
  deviceType: Array<number>;
  physicalModelId?: Key;
}

export interface BatchRuleGroupDeviceResponse {
  id: number;
  code: string;
  name: string;
  typeName: string;
  customerName: string;
}

export interface BatchRuleGroupPageRequest extends PageRequest {
  name?: string;
  deviceTypeId?: number;
  iotProtocol: IotProtocolType;
}

export interface BatchRuleGroupPageResponse {
  id: number;
  code: string;
  name: string;
  ruleCount: number;
}

export interface BatchDeviceBindingRuleGroupRequest {
  deviceIdList: number[];
  ruleGroupId: number;
}

export function batchDeviceBindingRuleGroup(params: BatchDeviceBindingRuleGroupRequest): Promise<{ flag: boolean }> {
  return fetch(
    `/api/v2/rule/group/device/batch-binding`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 获取规则组关联的设备分页详情
export function getRuleGroupDevicePage(id: Key, query: unbindDeviceSearchParams): Promise<PageResponse<Device>> {
  return fetch(
    `/api/v2/rule/group/device/page/byId`,
    {
      method: 'POST',
      body: JSON.stringify({ id, ...query }),
    },
    true
  );
}

export interface UnbindRuleGroupDeviceBody {
  ruleGroupId: Key;
  deviceIds: Key[];
}

// 规则关联的规则组解绑设备
export function unbindRuleGroupDevice(body: UnbindRuleGroupDeviceBody) {
  return fetch(
    `/api/v2/rule/group/device/unbind`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}
