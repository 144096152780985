import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import { ConstraintValue, DeviceAttribute, DriveType, IotProtocolType, PointType } from '@/shared/types';

export interface Template {
  id: number;
  name: string;
  serialNumber: string;
  deviceTypeId: number;
  deviceTypeName: string;
  lastVersion: number;
  attributeList: string[];
  deviceAttributes: DeviceAttribute[];
  remark?: string;
  description?: string;
  manufacturerName?: string;
  modelNo?: string;
  physicalModelId?: number;
}

export type TemplateResponse = PageResponse<Template>;

export interface TemplateRequest extends PageRequest {
  name?: string;
  deviceType?: Array<number>;
  manufacturerId?: number;
  physicalModelId?: number;
  originDeviceType?: Array<Array<number>>;
}

export const getTemplateList = (params: TemplateRequest) =>
  fetch<TemplateResponse>(
    `/api/v2/template/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

interface TemplateDetailResponse {
  template: TemplateDetail;
}

interface TemplateDetail {
  deviceAttributes: Array<DeviceItem>;
  deviceTypeId: number;
  deviceTypeName: string;
  id: number;
  lastUsername: string;
  lastVersion: number;
  name: string;
  remark: string;
  serialNumber: string;
  status: number;
  description?: string;
}

interface DeviceItem {
  deviceFields: Array<DeviceField>;
  id: number;
  name: string;
  serialNumber: string;
}

export interface DeviceField {
  id: number;
  name: string;
  type: number;
  controlType: number;
  digitalAccuracy: number;
  inputType: number;
  numberType: number;
  physicalUnits: Array<PhysicalUnits>;
  sequence: number;
  constraintValue: ConstraintValue;
}

interface PhysicalUnits {
  b: number;
  basePhysicalUnitId: number;
  br: number;
  chineseName: string;
  generalName: string;
  id: number;
  isBasic: boolean;
  k: number;
  kr: number;
}

export const getTemplateDetail = (id: number) =>
  fetch<TemplateDetailResponse>(
    `/api/v2/template/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface EdgeTemplatePageRequest extends PageRequest {
  name?: string;
  templateId?: number;
  templateVersionId?: number;
  deviceType?: Array<number>;
  driveType?: DriveType;
  iotProtocol?: IotProtocolType;
  physicalModelId?: Key;
}

export interface EdgeTemplate {
  id: number;
  remark: string;
  status: number;
  templateName: string;
  templateSerialNumber: string;
  updateByUsername: string;
  updateTIme: string;
  usedByDevice: number;
  version: number;
  usedByUsername: string;
  updateTime: string;
}

export type EdgeTemplateResponse = PageResponse<EdgeTemplate>;

export const getEdgeTemplatePage = (params: EdgeTemplatePageRequest) =>
  fetch<EdgeTemplateResponse>(
    `/api/v2/edgeTemplate/version/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface EdgeTemplateDetail {
  id: number;
  name: string;
  serialNumber: string;
  remark: string;
  latestVersion: number;
  updateByUserId: string;
  updateByUsername: string;
  status: number;
  iotProtocol: number;
  deviceTypeId: number;
  deviceModelId: number;
  createTime: string;
  updateTime: string;
  deviceTypeName: string;
  deviceModelName: string;
  version: number;
  versionStatus: number;
  versionRemark: string;
}

export const getEdgeTemplateDetail = (id: number) =>
  fetch<EdgeTemplateDetail>(
    `/api/v2/edgeTemplate/version/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

export interface Points {
  id: number;
  dataPropertyId: number;
  physicalUnitId: number;
  uploadType: number;
  iotProtocol: number;
}

export const getPointsByTemplateVersionId = (id: number) =>
  fetch<{ list: PointType[] }>(
    `/api/v2/edgeTemplate/version/point/list`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  ).then(res => res.list ?? []);

export enum Status {
  DISABLE,
  ENABLE,
}

export interface TemplateListProps {
  id: number;
  name: string;
  serialNumber: string;
  deviceTypeId: number;
  lastVersion: number;
  status: Status;
  createTime: string;
  updateTime: string;
  lastUsername: string;
  remark: string;
  attributeList: string;
}

export const getAllTemplateList = (typeId: number, physicalModelId?: number) =>
  fetch<{ list: TemplateListProps[] }>(
    `/api/v2/template/listByType`,
    {
      method: 'POST',
      body: JSON.stringify({
        typeId,
        physicalModelId,
      }),
    },
    true
  );

export const getTemplateByVersion = (id: number, version: number) =>
  fetch<{ template: Template }>(
    `/api/v2/template/getByVersion`,
    {
      method: 'POST',
      body: JSON.stringify({ id, version }),
    },
    true
  );

export interface AttributeTemplateListRequest extends PageRequest {
  codeOrName?: string;
  templateId?: number;
  deviceTypeId: number;
  operationType: number;
  physicalModelId?: number;
}

export interface AttributeTemplate {
  id: number;
  name: string;
  serialNumber: string;
  version: number;
  useCount: number;
}

export type AttributeTemplateListResponse = PageResponse<AttributeTemplate>;

export const getAttributeTemplateList = (params: AttributeTemplateListRequest) =>
  fetch<AttributeTemplateListResponse>(
    `/api/v2/batch/device/template/attribute/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface ManufacturerItem {
  id: Key;
  name: string; // 名称
  updateTime: string;
  createTime: string;
}

export const getManufacturerList = async () => {
  const res = await fetch<{ list: ManufacturerItem[] }>(
    `/api/v2/manufacturer/list`,
    {
      method: 'POST',
    },
    true
  );
  return res.list;
};
