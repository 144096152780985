import {
  EllipsisSpan,
  Key,
  Paging,
  Table,
  usePaging,
  Button,
  useUpdate,
  Tag,
  Form,
  Select,
  TreeSelect,
  DatePicker,
  Input,
  Tabs,
  Modal,
  CustomFilter,
} from '@maxtropy/components';
import { Space, TabsProps } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ElectricitySaleContractVo,
  getPropertyListOwned,
  PropertyItem,
} from '../../../../api/electricitySalesContract';

import { getSaleClientList, SaleClientInfo } from '../../../../api/sale';
import {
  getMeterReadingAdjustmentById,
  reBuildEleMeter,
  SaleElectricityMeterReadingType,
  SaleElectricityMeterReadingTypeDisplay,
  SaleElectricityMeterStatus,
} from '../../../../api/saleElectricity';
import qs from 'qs';
import dayjs, { Dayjs } from 'dayjs';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { OrganizationTreeProps } from '../../../../api/customer-user';
import { getOuListOwned, OuListResponse } from '../../../../api/ou';
import { OuStatus } from '@/shared/const';
import { getOrganizationWithCodeWithCurrent } from '../../../../api/device';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  getSaleElectricityContractList,
  HasTimeOfUseElectricityPricePropsType,
} from '../../../../api/electricitySettlementRules';
import styles from './index.module.scss';
import {
  AdjustmentMeterRecordsDataType,
  AdjustmentMeterRecordsDataTypeAllRes,
  AdjustmentMeterRecordsRes,
  HasTimeOfUseElectricityPriceDisplay,
} from '@/api/electricity';
import { formatAdjustmentMeterRecords } from '@/pages/ElectricityBill/Detail';
import { isNil, debounce, cloneDeep } from 'lodash-es';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import ImportLog from '../../components/ImportLog';
import FileLog from '../../components/MeterReadingImport';
import MeterReading from '../../components/MeterReadingSourceData';
import {
  apiV2DeviceListByOuIdsAndNameOrSnPost,
  apiV2SaleElectricityMeterReadingPagePost,
  V2SaleElectricityMeterReadingPagePostRequest,
} from '@maxtropy/device-customer-apis-v2';

const mergeDisplay = (copyValue: Key | undefined, matchValue: Key | undefined) => {
  return (
    <div>
      <p style={{ paddingLeft: '8px', margin: 0, borderBottom: '1px solid var(--mx-light-border-color)' }}>
        {' '}
        {copyValue ?? '--'}
      </p>
      <p style={{ paddingLeft: '8px', margin: 0 }}>{matchValue ?? '--'}</p>
    </div>
  );
};

type DeviceProp = {
  value?: number;
  label?: string;
  title?: string;
};

export const tableColumns: ColumnType<AdjustmentMeterRecordsRes>[] = [
  {
    title: '倍率',
    dataIndex: 'magnification',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '无功示数',
    dataIndex: 'eqpDisplayValue',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '无功电量（kWh）',
    dataIndex: 'eqp',
    width: 140,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电量示数',
    dataIndex: 'eppDisplayValue',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '电量（kWh）',
    dataIndex: 'epp',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最大需量示数',
    dataIndex: 'maxDemandDisplayValue',
    width: 110,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最大需量（kW）',
    dataIndex: 'maxDemand',
    width: 130,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '尖电量示数',
    dataIndex: 'epp1DisplayValue',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '峰电量示数',
    dataIndex: 'epp2DisplayValue',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '平电量示数',
    dataIndex: 'epp3DisplayValue',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '谷电量示数',
    dataIndex: 'epp4DisplayValue',
    width: 100,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '尖电量（kWh）',
    dataIndex: 'epp1',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '峰电量（kWh）',
    dataIndex: 'epp2',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '平电量（kWh）',
    dataIndex: 'epp3',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '谷电量（kWh）',
    dataIndex: 'epp4',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向电量示数',
    dataIndex: 'epnDisplayValue',
    width: 110,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向电量（kWh）',
    dataIndex: 'epn',
    width: 140,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向尖电量示数',
    dataIndex: 'epn1DisplayValue',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向峰电量示数',
    dataIndex: 'epn2DisplayValue',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向平电量示数',
    dataIndex: 'epn3DisplayValue',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向谷电量示数',
    dataIndex: 'epn4DisplayValue',
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向尖电量（kWh）',
    dataIndex: 'epn1',
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向峰电量（kWh）',
    dataIndex: 'epn2',
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向平电量（kWh）',
    dataIndex: 'epn3',
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '反向谷电量（kWh）',
    dataIndex: 'epn4',
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
const modalColumns: ColumnType<AdjustmentMeterRecordsDataTypeAllRes>[] = [
  {
    title: '倍率',
    dataIndex: 'magnification',
    width: 100,
    render: (v, record) => mergeDisplay(record.magnification, record.adj_magnification),
  },
  {
    title: '无功示数',
    dataIndex: 'eqpDisplayValue',
    width: 100,
    render: (v, record) => mergeDisplay(record.eqpDisplayValue, record.adj_eqpDisplayValue),
  },
  {
    title: '无功电量（kWh）',
    dataIndex: 'eqp',
    width: 140,
    render: (v, record) => mergeDisplay(record.eqp, record.adj_eqp),
  },
  {
    title: '电量示数',
    dataIndex: 'eppDisplayValue',
    width: 100,
    render: (v, record) => mergeDisplay(record.eppDisplayValue, record.adj_eppDisplayValue),
  },
  {
    title: '电量（kWh）',
    dataIndex: 'epp',
    width: 120,
    render: (v, record) => mergeDisplay(record.epp, record.adj_epp),
  },
  {
    title: '最大需量示数',
    dataIndex: 'maxDemandDisplayValue',
    width: 110,
    render: (v, record) => mergeDisplay(record.maxDemandDisplayValue, record.adj_maxDemandDisplayValue),
  },
  {
    title: '最大需量（kW）',
    dataIndex: 'maxDemand',
    width: 130,
    render: (v, record) => mergeDisplay(record.maxDemand, record.adj_maxDemand),
  },
  {
    title: '尖电量示数',
    dataIndex: 'epp1DisplayValue',
    width: 100,
    render: (v, record) => mergeDisplay(record.epp1DisplayValue, record.adj_epp1DisplayValue),
  },
  {
    title: '峰电量示数',
    dataIndex: 'epp2DisplayValue',
    width: 100,
    render: (v, record) => mergeDisplay(record.epp2DisplayValue, record.adj_epp2DisplayValue),
  },
  {
    title: '平电量示数',
    dataIndex: 'epp3DisplayValue',
    width: 100,
    render: (v, record) => mergeDisplay(record.epp3DisplayValue, record.adj_epp3DisplayValue),
  },
  {
    title: '谷电量示数',
    dataIndex: 'epp4DisplayValue',
    width: 100,
    render: (v, record) => mergeDisplay(record.epp4DisplayValue, record.adj_epp4DisplayValue),
  },
  {
    title: '尖电量（kWh）',
    dataIndex: 'epp1',
    width: 120,
    render: (v, record) => mergeDisplay(record.epp1, record.adj_epp1),
  },
  {
    title: '峰电量（kWh）',
    dataIndex: 'epp2',
    width: 120,
    render: (v, record) => mergeDisplay(record.epp2, record.adj_epp2),
  },
  {
    title: '平电量（kWh）',
    dataIndex: 'epp3',
    width: 120,
    render: (v, record) => mergeDisplay(record.epp3, record.adj_epp3),
  },
  {
    title: '谷电量（kWh）',
    dataIndex: 'epp4',
    width: 120,
    render: (v, record) => mergeDisplay(record.epp4, record.adj_epp4),
  },
  {
    title: '反向电量示数',
    dataIndex: 'epnDisplayValue',
    width: 110,
    render: (v, record) => mergeDisplay(record.epnDisplayValue, record.adj_epnDisplayValue),
  },
  {
    title: '反向电量（kWh）',
    dataIndex: 'epn',
    width: 140,
    render: (v, record) => mergeDisplay(record.epn, record.adj_epn),
  },
  {
    title: '反向尖电量示数',
    dataIndex: 'epn1DisplayValue',
    width: 120,
    render: (v, record) => mergeDisplay(record.epn1DisplayValue, record.adj_epn1DisplayValue),
  },
  {
    title: '反向峰电量示数',
    dataIndex: 'epn2DisplayValue',
    width: 120,
    render: (v, record) => mergeDisplay(record.epn2DisplayValue, record.adj_epn2DisplayValue),
  },
  {
    title: '反向平电量示数',
    dataIndex: 'epn3DisplayValue',
    width: 120,
    render: (v, record) => mergeDisplay(record.epn3DisplayValue, record.adj_epn3DisplayValue),
  },
  {
    title: '反向谷电量示数',
    dataIndex: 'epn4DisplayValue',
    width: 120,
    render: (v, record) => mergeDisplay(record.epn4DisplayValue, record.adj_epn4DisplayValue),
  },
  {
    title: '反向尖电量（kWh）',
    dataIndex: 'epn1',
    width: 150,

    render: (v, record) => mergeDisplay(record.epn1, record.adj_epn1),
  },
  {
    title: '反向峰电量（kWh）',
    dataIndex: 'epn2',
    width: 150,
    render: (v, record) => mergeDisplay(record.epn2, record.adj_epn2),
  },
  {
    title: '反向平电量（kWh）',
    dataIndex: 'epn3',
    width: 150,
    render: (v, record) => mergeDisplay(record.epn3, record.adj_epn3),
  },
  {
    title: '反向谷电量（kWh）',
    dataIndex: 'epn4',
    width: 150,
    render: (v, record) => mergeDisplay(record.epn4, record.adj_epn4),
  },
];

const columns: ColumnType<AdjustmentMeterRecordsRes>[] = [
  {
    title: '所属客户',
    dataIndex: 'saleClientName',
    width: 180,
    render: (v: string) => <EllipsisSpan value={v} />,
    fixed: 'left',
  },
  {
    title: '设备sn',
    dataIndex: 'deviceSn',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '抄表状态',
    dataIndex: 'status',
    width: 150,
    render: (v: SaleElectricityMeterStatus) => (
      <EllipsisSpan
        value={
          v ? (
            v === SaleElectricityMeterStatus.SUCCESS ? (
              <Tag border="solid" color="success">
                成功
              </Tag>
            ) : (
              <Tag border="solid" color="error">
                失败
              </Tag>
            )
          ) : undefined
        }
      />
    ),
  },
  {
    title: '计划抄表时间',
    dataIndex: 'meterReadingTs',
    width: 180,
    render: (v: string) => <EllipsisSpan value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />,
  },
  ...tableColumns,
  {
    title: '抄表来源',
    dataIndex: 'type',
    width: 150,
    render: (v: SaleElectricityMeterReadingType) => <EllipsisSpan value={SaleElectricityMeterReadingTypeDisplay[v]} />,
  },
  {
    title: '表计编号',
    dataIndex: 'saleMeterCode',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    width: 220,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export const meterRecordColumns: ColumnType<AdjustmentMeterRecordsRes>[] = [
  {
    title: '所属客户',
    dataIndex: 'saleClientName',
    width: 180,
    render: (v: string) => <EllipsisSpan className={styles.spanStyle} value={v} />,
  },
  {
    title: '设备sn',
    dataIndex: 'deviceSn',
    width: 220,
    render: (v: string) => <EllipsisSpan className={styles.spanStyle} value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    width: 220,
    render: (v: string) => <EllipsisSpan className={styles.spanStyle} value={v} />,
  },
  {
    title: '计划抄表时间',
    dataIndex: 'meterReadingTs',
    width: 180,
    render: (v: string) => (
      <EllipsisSpan className={styles.spanStyle} value={`${dayjs(v).format('YYYY-MM-DD HH:mm:ss')} `} />
    ),
  },
  {
    title: '配平优先级',
    dataIndex: 'adj_energyAdjustSortSnapshot',
    width: 180,
    render: (v: HasTimeOfUseElectricityPricePropsType[]) => {
      const value = (v ?? []).map(i => HasTimeOfUseElectricityPriceDisplay[i]).join('-');
      return <EllipsisSpan className={styles.spanStyle} value={value.length === 0 ? '--' : value} />;
    },
  },
  {
    title: '抄表值',
    width: 180,
    render: (v: string) => mergeDisplay('抄见值', '配平值'),
  },
  ...modalColumns,
];

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

interface FilterParams extends Omit<V2SaleElectricityMeterReadingPagePostRequest, 'page' | 'size'> {
  [x: string]: any;
  plannedMeterReadingTime?: RangeValue;
}

const SaleMeterElectricityRecordList: FC = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const url_customerMcid = urlSearchParams.get('customerMcid') || undefined;
  const url_ouId = urlSearchParams.get('ouId') || undefined;
  const url_contractId = urlSearchParams.get('contractId') || undefined;
  const url_propMgmtDeptId = urlSearchParams.get('propMgmtDeptId') || undefined;

  const [form] = Form.useForm();
  const [saleClientList, setSaleClientList] = useState<SaleClientInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<FilterParams>({
    // customerMcid: url_customerMcid,
    contractId: url_contractId ? Number(url_contractId) : undefined,
    // ouId: url_ouId,
    // propMgmtDeptId: url_propMgmtDeptId
  });
  const [data, setData] = useState<AdjustmentMeterRecordsRes[]>([]);
  const [saleElectricityContractList, setSaleElectricityContractList] = useState<ElectricitySaleContractVo[]>([]); // 根据ou权限获取合同列表
  const [treeData, setTreeData] = useState<DefaultOptionType[]>([]);
  const [ouList, setOuList] = useState<OuListResponse[]>([]); // ouList
  const [propertyList, setPropertyList] = useState<PropertyItem[]>([]); // 物业list

  const [dates, setDates] = useState<RangeValue>(null);
  const [hackValue, setHackValue] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [meterRecordModalOpen, setMeterRecordModalOpen] = useState<boolean>(false);
  const [adjustmentMeterRecords, setAdjustmentMeterRecords] = useState<AdjustmentMeterRecordsDataTypeAllRes[]>([]);

  const [successCount, setSuccessCount] = useState<number>();
  const [count, setCount] = useState<number>();
  const [deviceList, setDeviceList] = useState<DeviceProp[]>([]);

  const [tabKey, setTabKey] = useState<number>(0);

  const [update, updateFn] = useUpdate();
  const hasREGENERATEMETERREADING = useHasPermission(PermissionsType.B_REGENERATEMETERREADING);

  const hasMeterReadingImportPermission = useHasPermission(PermissionsType.B_METER_READING_IMPORT);
  const hasImportLogsPermission = useHasPermission(PermissionsType.B_IMPORT_LOGS);

  useEffect(() => {
    if (url_customerMcid && url_propMgmtDeptId && url_ouId && url_contractId) {
      form.setFieldsValue({
        mcid: url_customerMcid,
        propertyManagementId: Number(url_propMgmtDeptId),
        ouId: Number(url_ouId),
        contractId: Number(url_contractId),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDeviceList = (value?: string) => {
    apiV2DeviceListByOuIdsAndNameOrSnPost({ deviceNameOrSn: value }).then(res => {
      const list: DeviceProp[] =
        res?.list?.map(i => ({ value: i.id, label: `${i.name}/${i.sn}`, title: i.name })) || [];
      setDeviceList(list);
    });
  };

  useEffect(() => {
    getDeviceList();
  }, []);

  const ouListOptions = useMemo(() => {
    return ouList.map(i => ({ label: i.name, value: i.id }));
  }, [ouList]);

  const propertyListOptions = useMemo(() => {
    if (propertyList.length !== 0) {
      return propertyList.map(i => ({ label: `${i.code}【${i.salesOrg}】`, value: i.id }));
    }
  }, [propertyList]);

  const saleElectricityContractListOptions = useMemo(() => {
    if (saleElectricityContractList.length !== 0) {
      return saleElectricityContractList.map(i => ({ label: `${i.code}【${i.name}】`, value: i.id }));
    }
  }, [saleElectricityContractList]);

  const saleClientListOptions = useMemo(() => {
    if (saleClientList.length !== 0) {
      const partOptions: Array<{ label: string; value: string | number }> = saleClientList.map(i => ({
        label: `${i.code}【${i.name}】`,
        value: i.id,
      }));
      partOptions.unshift({ label: '全选', value: '0' });
      return partOptions;
    }
  }, [saleClientList]);

  const formatTreeData = (data: OrganizationTreeProps[]) => {
    return data.map(item => {
      const res: DefaultOptionType = {
        key: item.data?.mcid ?? '',
        value: item.data?.mcid ?? '',
        title: item.data?.name,
        children: formatTreeData(item.children),
        disabled: !item.data?.hasPermission,
      };
      return res;
    });
  };

  // 查询所属组织和ou
  useEffect(() => {
    getOrganizationWithCodeWithCurrent().then(res => {
      setTreeData(formatTreeData([res]));
    });
    getOuListOwned().then(res => {
      setOuList(res?.filter(i => i.status === OuStatus.ENABLE) || []);
    });
    getPropertyListOwned().then(res => {
      setPropertyList(res);
    });
    getSaleElectricityContractList().then(res => {
      setSaleElectricityContractList(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSaleClientList().then(setSaleClientList);
  }, []);

  // 监听submit的提交
  useEffect(() => {
    setLoading(true);
    apiV2SaleElectricityMeterReadingPagePost({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
      status: tabKey === 0 ? undefined : (tabKey as 1 | 2),
    })
      .then(res => {
        setData(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageOffset, pageSize, searchParams, setTotalCount, tabKey, update]);

  useEffect(() => {
    // 获取成功条数
    apiV2SaleElectricityMeterReadingPagePost({
      ...searchParams,
      status: SaleElectricityMeterStatus.SUCCESS,
      page: 1,
      size: 1,
    }).then(res => {
      setSuccessCount(res.total);
    });
    // 获取所有条数
    apiV2SaleElectricityMeterReadingPagePost({
      ...searchParams,
      status: undefined,
      page: 1,
      size: 1,
    }).then(res => {
      setCount(res.total);
    });
  }, [searchParams, update]);

  const DATE_FORMAT = 'YYYY-MM-DDTHH:00:00[Z]';
  const onFinish = (val: FilterParams) => {
    const params = {
      mcid: val.mcid,
      propertyManagementId: val.propertyManagementId,
      ouId: val.ouId,
      saleClientIds: val.saleClientId,
      contractId: val.contractId ?? undefined,
      meterReadingStartTs: val.plannedMeterReadingTime
        ? dayjs(val.plannedMeterReadingTime[0]).format(DATE_FORMAT)
        : undefined,
      meterReadingEndTs: val.plannedMeterReadingTime
        ? dayjs(val.plannedMeterReadingTime[1]).format(DATE_FORMAT)
        : undefined,
      deviceIds: val.deviceIds,
      // 添加的抄表来源 表计编号
      type: val.deviceMeterReading,
      meterCode: val.deviceListNumber,
      status: tabKey === 0 ? undefined : (tabKey as 1 | 2),
    };
    setPageOffset(1);
    setSearchParams(params);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  const onExport = () => {
    const params = cloneDeep(searchParams);
    if (params.meterReadingStartTs) {
      params.meterReadingStartTs = dayjs(params.meterReadingStartTs).format('YYYY-MM-DD HH:00:00');
    }
    if (params.meterReadingEndTs) {
      params.meterReadingEndTs = dayjs(params.meterReadingEndTs).format('YYYY-MM-DD HH:00:00');
    }
    window.open(
      `/api/v2/sale-electricity/meter-reading/export?${qs.stringify(
        { ...params, status: tabKey === 0 ? undefined : tabKey },
        { indices: false }
      )}`
    );
  };

  const handleChange = (val: any[]) => {
    // 第⼀个是全选再选择其他时，去掉全选
    // 第⼀个不是全选⽽后再选全选时，只保留全选
    // 第⼀个是全选且只有全选时，只保留全选
    if ((val[0] !== '0' && new Set(val).has('0')) || (val[0] === '0' && val.length === 1)) {
      form.setFieldsValue({ saleClientId: (saleClientList || []).map(item => item.id) });
    } else {
      form.setFieldsValue({ saleClientId: val });
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      plannedMeterReadingTime: hackValue || value,
    });
  }, [hackValue, value, form]);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') > 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') > 6;
    // if (!!tooEarly && !!tooLate) {
    //   form.setFieldsValue({ plannedMeterReadingTime: undefined })
    // }
    return !!tooEarly || !!tooLate;
  };
  const buildColumns: ColumnType<AdjustmentMeterRecordsRes>[] = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 100,
      render: (v, record) => {
        if (record.dataType === AdjustmentMeterRecordsDataType.ADJUSTMENT) {
          return (
            <Space>
              <Button
                type="link"
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onClick={() => {
                  if (record.id) {
                    getMeterReadingAdjustmentById(record.id).then(res => {
                      const records = formatAdjustmentMeterRecords(res);
                      setAdjustmentMeterRecords(records);
                      setMeterRecordModalOpen(true);
                    });
                  }
                }}
              >
                查看调整单
              </Button>
            </Space>
          );
        } else {
          return <EllipsisSpan value={'-'} />;
        }
      },
    },
  ];

  const onCancel = () => {
    setMeterRecordModalOpen(false);
  };
  const onOk = () => {
    setMeterRecordModalOpen(false);
  };

  const [failedMeterKeys, setFailedMeterKeys] = useState<Key[]>([]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AdjustmentMeterRecordsRes[]) => {
      setFailedMeterKeys(selectedRowKeys);
    },
  };

  const rebuildMeterConfirmOnClick = () => {
    if (failedMeterKeys && failedMeterKeys.length > 0) {
      Modal.confirm({
        title: '确定重新生成用电抄表记录？',
        onOk: () => {
          reBuildEleMeter({ saleElectricityMeterReadingRecordIds: failedMeterKeys }).then(() => {
            setFailedMeterKeys([]);
            updateFn();
          });
        },
      });
    }
  };

  const tabItems: TabsProps['items'] = [
    {
      key: '0',
      label: `全部（${count ?? '--'}）`,
    },
    {
      key: '1',
      label: `抄表成功（${successCount ?? '--'}）`,
    },
    {
      key: '2',
      label: `抄表失败(${!isNil(count) && !isNil(successCount) ? count - successCount : '--'})`,
    },
  ];

  // 导入日志的弹窗
  const [refreshState, refresh] = useUpdate();
  const [batchEdgeBlogListOpen, setBatchEdgeBlogListOpen] = useState<boolean>(false);

  // 抄表导入的弹窗
  const [batchEdgeOpen, setBatchEdgeOpen] = useState<boolean>(false);

  const onChangeDevice = debounce((value: string) => {
    getDeviceList(value);
  }, 100);

  return (
    <>
      <div className={styles.filterWrapper}>
        {/* 抄表记录的筛选 */}

        <CustomFilter<FilterParams> form={form} onFinish={val => onFinish(val as any)} onReset={onReset}>
          <Form.Item name="mcid" label="所属组织">
            <TreeSelect
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeDefaultExpandAll
              treeData={treeData}
              placeholder="请选择"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item name="propertyManagementId" label="所属物业">
            <Select placeholder="请选择" options={propertyListOptions} />
          </Form.Item>
          <Form.Item name="ouId" label="所属运营单元">
            <Select placeholder="请选择" options={ouListOptions} showSearch optionFilterProp="label" />
          </Form.Item>
          <Form.Item name="contractId" label="所属合同">
            <Select
              placeholder="请选择"
              options={saleElectricityContractListOptions}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item name="saleClientId" label="客户名称">
            <Select
              placeholder="请选择/输入客户名称"
              showSearch
              mode="multiple"
              maxTagCount="responsive"
              onChange={handleChange}
              allowClear
              options={saleClientListOptions}
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item name="plannedMeterReadingTime" label="计划抄表时间">
            <DatePicker.RangePicker
              disabledDate={disabledDate}
              showTime
              format="YYYY-MM-DD HH"
              // allowClear={false}
              // value={hackValue || value}
              onCalendarChange={val => setDates(val)}
              onChange={val => {
                setValue(val);
              }}
              onOpenChange={onOpenChange}
            />
          </Form.Item>
          <Form.Item name="deviceIds" label="设备名称/sn">
            <Select
              mode="multiple"
              onSearch={onChangeDevice}
              showSearch
              optionFilterProp="label"
              optionLabelProp="title"
              placeholder="请输入"
              options={deviceList}
            />
          </Form.Item>
          {/* 抄表来源 */}
          <Form.Item name="deviceMeterReading" label="抄表来源">
            <Select allowClear placeholder="请选择" style={{ width: '100%' }} options={MeterReading} />
          </Form.Item>
          {/* 表记编号 */}
          <Form.Item name="deviceListNumber" label="表计编号">
            <Input placeholder="请输入编号" />
          </Form.Item>
        </CustomFilter>
      </div>
      <div className={styles.spaceStyle} />

      <div className={styles.content}>
        {/* 导出 导入 功能 */}
        <Tabs
          className={styles.tabsStyle}
          tabBarExtraContent={
            <Space size={8} style={{ width: '100%' }}>
              {tabKey === SaleElectricityMeterStatus.FAILED && hasREGENERATEMETERREADING && (
                <Button
                  type="primary"
                  onClick={rebuildMeterConfirmOnClick}
                  disabled={!failedMeterKeys || !failedMeterKeys.length}
                >
                  选中重新生成
                </Button>
              )}
              <Button type="primary" onClick={onExport}>
                导出
              </Button>
              <span>
                <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
                <span style={{ paddingLeft: '5px' }}>最多导出列表的前3000条抄表记录。</span>
              </span>

              {/* 抄表导入需求 */}
              {hasMeterReadingImportPermission && (
                <Button
                  type="primary"
                  onClick={() => {
                    setBatchEdgeOpen(true);
                  }}
                >
                  抄表导入
                </Button>
              )}
              {hasImportLogsPermission && (
                <Button
                  type="primary"
                  onClick={() => {
                    setBatchEdgeBlogListOpen(true);
                  }}
                >
                  导入日志
                </Button>
              )}
            </Space>
          }
          onChange={v => {
            setData([]);
            setPageOffset(1);
            setTabKey(Number(v));
          }}
          items={tabItems}
        />
        {tabKey === 2 ? (
          <>
            {/* 抄表失败 的全选导入 */}
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              rowKey={'id'}
              sticky
              scroll={{ x: 1300 }}
              loading={loading}
              dataSource={data}
              // buildColumns 这个控制所有的数据  这里是失败的
              columns={buildColumns}
            />
            <Paging pagingInfo={pagingInfo} />
          </>
        ) : (
          <>
            <Table
              // buildColumns 这里是成功的全部数据
              rowKey={'id'}
              sticky
              scroll={{ x: 1300 }}
              loading={loading}
              dataSource={data}
              columns={buildColumns}
            />
            <Paging pagingInfo={pagingInfo} />
          </>
        )}
      </div>

      {/* {抄表导入弹窗} */}
      <FileLog refresh={refresh} batchEdgeOpen={batchEdgeOpen} setBatchEdgeOpen={setBatchEdgeOpen} />

      {/* 导入日志的弹窗 */}
      <ImportLog
        refreshState={refreshState}
        batchEdgeBlogListOpen={batchEdgeBlogListOpen}
        setBatchEdgeBlogListOpen={setBatchEdgeBlogListOpen}
      />

      <Modal size="big" title="抄表记录调整单" onCancel={onCancel} onOk={onOk} open={meterRecordModalOpen}>
        <p>抄表逻辑：尖峰平谷按总有功配平</p>
        <p>判断需要调整的条件：【调整值w】=【（反向）总有功】-【（反向）尖峰平谷】，|w|≥0.01。</p>
        <Table
          rowClassName={styles.meterRecordModalTable}
          scroll={{ x: 1198 }}
          rowKey={'id'}
          columns={meterRecordColumns}
          dataSource={adjustmentMeterRecords}
          bordered
          size="middle"
        />
      </Modal>
    </>
  );
};

export default SaleMeterElectricityRecordList;
