import { DatePicker, Form, Modal, Select, Switch } from '@maxtropy/components';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import React, { FC, useEffect } from 'react';
import { InferenceTimeType, InferenceTimeTypeDisplay } from '../../utils';
import { useRequest } from 'ahooks';
import { apiV2ReasoningGetPredictConfigPost } from '@maxtropy/device-customer-apis-v2';

const { Option } = Select;

export interface formInferenceSubmitProps {
  predictSwitch: boolean;
  predictStartTime: number;
  predictResolution: string;
}

export interface IInferenceConfig {
  modelId?: number;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: formInferenceSubmitProps) => Promise<void>;
}

const InferenceConfig: FC<IInferenceConfig> = props => {
  const { modelId, visible, onCancel, onSubmit } = props;
  const [form] = Form.useForm();
  const predictSwitch: boolean = Form.useWatch('predictSwitch', form);

  // 编辑查询详情
  const { data: detail } = useRequest(
    () => {
      return apiV2ReasoningGetPredictConfigPost({
        id: modelId,
      });
    },
    {
      ready: !!modelId && visible,
      refreshDeps: [modelId, visible],
    }
  );

  // 编辑的时候表达赋值
  useEffect(() => {
    if (!detail) return;
    form.setFieldsValue({
      predictSwitch: detail.predictSwitch,
      predictStartTime: dayjs(detail.predictStartTime),
      predictResolution: detail.predictResolution,
    });
  }, [detail]);

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // 计算半年前的日期
    const sixMonthsAgo = dayjs().subtract(6, 'month').startOf('day');
    return current && current.isBefore(sixMonthsAgo);
  };

  const onOK = async () => {
    const values = await form.validateFields();
    await onSubmit({
      ...values,
    });
    form.resetFields();
  };

  return (
    <Modal
      contentClassName="modal-form-content"
      size="normal"
      destroyOnClose
      open={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={onOK}
      title={'推理配置'}
    >
      <Form form={form} labelAlign="left">
        <Form.Item
          name="predictSwitch"
          label="推理开关"
          rules={[{ required: true, message: '请选择推理开关' }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        {predictSwitch && (
          <>
            <Form.Item
              name="predictStartTime"
              label="推理数据开始日期"
              rules={[{ required: true, message: '请选择推理数据开始日期' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={disabledDate}
                showTime={{ format: 'YYYY-MM-DD HH:mm' }}
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>

            <Form.Item
              label="推理数据定时任务"
              name="predictResolution"
              rules={[{ required: true, message: '请选择推理数据定时任务' }]}
            >
              <Select>
                <Option value={InferenceTimeType.DAY}>{InferenceTimeTypeDisplay[InferenceTimeType.DAY]}</Option>
                <Option value={InferenceTimeType.HOUR}>{InferenceTimeTypeDisplay[InferenceTimeType.HOUR]}</Option>
                <Option value={InferenceTimeType.FIFTEEN}>{InferenceTimeTypeDisplay[InferenceTimeType.FIFTEEN]}</Option>
              </Select>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default InferenceConfig;
