import {
  EnergyWorkingProcessType,
  EnergyWorkingProcessTypeDisplay,
  EnergyWorkingProcessTypeLabelColor,
  EnergyWorkingProcessTypeListDisplay,
} from '@/api/uet';
import { PermissionsType } from '@/common/permissionsConst';
import {
  Button,
  Form,
  FormTitle,
  Input,
  Radio,
  ShowInput,
  SubContent,
  Tag,
  Tooltip,
  useBreadcrumbRoutes,
  useTenantPermissions,
  Wrapper,
} from '@maxtropy/components';
import { Badge, Card, Col, Collapse, Divider, FloatButton, Row, Space, Spin } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import {
  apiV2UetEmtProcessDetailPost,
  apiV2UetEmtProcessListPost,
  V2UetEmtProcessDetailPostResponse,
  V2UetEmtProcessListPostResponse,
} from '@maxtropy/device-customer-apis-v2';

const routes = [{ name: `查看能源介质拓扑` }];

const { Panel } = Collapse;

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

// type processEntryVos = Exclude<V2UetEmtProcessDetailPostResponse['processEntryVos'], undefined>[number];
// type processExitVos = Exclude<V2UetEmtProcessDetailPostResponse['processExitVos'], undefined>[number];
// type processNodeVo = V2UetEmtProcessDetailPostResponse['processNodeVo'];
type ItemInterface = Exclude<V2UetEmtProcessListPostResponse['processVos'], undefined>[number];

const EnergyWorkingDetail: FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { id, key: routerKey, energyId } = useParams<{ id: string; key: string; energyId: string }>();
  const permission = useTenantPermissions();
  const [processList, setProcessList] = useState<V2UetEmtProcessListPostResponse>(); // 列表
  const processListRef = useRef<V2UetEmtProcessListPostResponse>(); // 前端做查询的temp
  const [searchForm] = Form.useForm(); // 搜索的表单
  const [topoNameOrEnterExitName, setTopoNameOrEnterExitName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [detailLoading, setDetailLoading] = useState<boolean>(false); // 详情加载状态
  const [activeKey, setActiveKey] = useState<string | string[]>();
  const [uetEMTProcessDetail, setUetEMTProcessDetail] = useState<V2UetEmtProcessDetailPostResponse>(); // 每个折叠的详情
  const [tabsActiveKey, setTabsActiveKey] = useState<string>('1'); // 选择输出还是输入

  useEffect(() => {
    if (id && energyId) {
      setLoading(true);
      apiV2UetEmtProcessListPost({ id: energyId }).then(res => {
        setProcessList(res);
        processListRef.current = res;
        setLoading(false);
      });
    }
  }, [id, energyId]);

  useEffect(() => {
    setProcessList(processListRef.current); // 重置所有
    if (topoNameOrEnterExitName) {
      setProcessList(list => {
        let newList: V2UetEmtProcessListPostResponse = JSON.parse(JSON.stringify(list));
        const finalList = fontfilterArr(newList, topoNameOrEnterExitName);
        return { energyMediumTopologyVo: newList.energyMediumTopologyVo, processVos: finalList };
      });
    }
  }, [topoNameOrEnterExitName]);

  useEffect(() => {
    if (id && energyId && activeKey && activeKey.length) {
      setDetailLoading(true);
      apiV2UetEmtProcessDetailPost({ id: activeKey[0] }).then(res => {
        setUetEMTProcessDetail(res);
        setDetailLoading(false);
      });
    }
  }, [id, energyId, activeKey]);

  const onCollapseChange = (key: string | string[]) => {
    // 查询详情 放在下面展开列表中
    setActiveKey(key);
  };

  const filters = (
    <Form className={styles.formStyle} form={searchForm} labelAlign={'left'}>
      <Form.Item name="NameOrEnterExitName" className={styles.itemFormSty}>
        <Input.Search
          className={styles.search}
          allowClear
          placeholder="输入节点名称或入口、出口名称搜索"
          onSearch={value => setTopoNameOrEnterExitName(value)}
          onChange={e => {
            if (e.type === 'click') {
              setTopoNameOrEnterExitName(undefined);
            }
          }}
          onBlur={e => {
            if (e.target.value === '') {
              setTopoNameOrEnterExitName(undefined);
            } else {
              setTopoNameOrEnterExitName(e.target.value);
            }
          }}
          onPressEnter={(e: any) => setTopoNameOrEnterExitName(e.target.value)}
        />
      </Form.Item>
    </Form>
  );

  const tabs = (item: ItemInterface) => {
    const tabsTemp = [
      {
        key: '1',
        label: (
          <>
            输入
            <Badge
              style={{ marginLeft: 8, color: tabsActiveKey === '1' ? 'var(--mx-primary-color)' : '#FFF' }}
              color={tabsActiveKey === '1' ? '#FFF' : 'var(--mx-primary-color)'}
              count={(item.processEntrySimpleVos ?? []).length}
            />
          </>
        ),
      },
      {
        key: '2',
        label: (
          <>
            节点内
            <Badge
              style={{ marginLeft: 8, color: tabsActiveKey === '2' ? 'var(--mx-primary-color)' : '#FFF' }}
              color={tabsActiveKey === '2' ? '#FFF' : 'var(--mx-primary-color)'}
              count={item.processNodeSimpleVo ? 1 : 0}
            />
          </>
        ),
      },
      {
        key: '3',
        label: (
          <>
            输出
            <Badge
              style={{ marginLeft: 8, color: tabsActiveKey === '3' ? 'var(--mx-primary-color)' : '#FFF' }}
              color={tabsActiveKey === '3' ? '#FFF' : 'var(--mx-primary-color)'}
              count={(item.processExitSimpleVos ?? []).length}
            />
          </>
        ),
      },
    ];
    if (item.type !== EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION) {
      return tabsTemp;
    } else {
      return tabsTemp.filter(i => i.key !== '2');
    }
  };

  return (
    <div id="handelDocID" style={{ height: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className="page_wrapper">
        <Form layout="vertical">
          <FormTitle
            title={routes[0].name}
            extraContent={
              <Space size={8}>
                {(permission ?? []).includes(PermissionsType.B_UPDATEEMT) && (
                  <Button type="primary">
                    <Link to={`/energy/basic/uet/edit/${id}/${routerKey}/editEnergyWorkingTopo/${energyId}`}>编辑</Link>
                  </Button>
                )}
                {(permission ?? []).includes(PermissionsType.B_EMTPREVIEW) && (
                  <Button type="primary">
                    <Link
                      to={`/energy/basic/uet/edit/${id}/${routerKey}/viewEnergyWorkingTopo/${energyId}`}
                      target="_blank"
                    >
                      预览
                    </Link>
                  </Button>
                )}
                <Button
                  onClick={() => {
                    navigate(`/energy/basic/uet/edit/${id}/${routerKey}`);
                  }}
                >
                  返回
                </Button>
              </Space>
            }
          />
          <SubContent title="能源介质拓扑" className="mb-8">
            <Row>
              <Col span={8}>
                <Form.Item label="能源介质拓扑名称">
                  <ShowInput value={processList?.energyMediumTopologyVo?.name} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="能源介质场景">
                  <ShowInput value={processList?.energyMediumTopologyVo?.energyMediumSceneName} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="能源介质拓扑编号">
                  <ShowInput
                    value={
                      <Tag border="solid" color="#177DDC">
                        {processList?.energyMediumTopologyVo?.code}
                      </Tag>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </SubContent>
        </Form>

        <Divider dashed />

        {(processList?.processVos ?? []).length > 0 && <div className={styles.filterPosition}>{filters}</div>}

        {(processList?.processVos ?? []).map(item => (
          <Collapse
            key={item.id}
            accordion
            activeKey={activeKey}
            collapsible="icon"
            expandIcon={({ isActive }) =>
              isActive ? (
                <span style={{ fontSize: 14, color: '#3d9ce9' }}>收起</span>
              ) : (
                <span style={{ fontSize: 14, color: '#3d9ce9' }}>展开详情</span>
              )
            }
            style={{
              borderLeftColor: EnergyWorkingProcessTypeLabelColor[item.type as EnergyWorkingProcessType],
            }}
            className={styles.CollapseStyle}
            onChange={key => onCollapseChange(key)}
          >
            <Panel
              key={item.id!.toString()}
              style={{ borderBottom: 'transparent' }}
              header={
                <>
                  <Row>
                    <Col span={3} className={styles.itemCol}>
                      <Tooltip title={item.name}>
                        <div className={styles.nameSty}>{item.name}</div>
                      </Tooltip>
                      <Tag
                        border="solid"
                        color={EnergyWorkingProcessTypeLabelColor[item.type as EnergyWorkingProcessType]}
                      >
                        {EnergyWorkingProcessTypeDisplay[item.type as EnergyWorkingProcessType]}
                      </Tag>
                    </Col>
                    <Col span={4} className={styles.ColStyle}>
                      <div>
                        <Tooltip title={item.energyMediumVos?.map(i => i.energyName).join()}>
                          <p className={styles.ColSpanStyle}>{item.energyMediumVos?.map(i => i.energyName).join()}</p>
                        </Tooltip>

                        <p className={styles.subTitle_sty}>
                          {EnergyWorkingProcessTypeListDisplay[item.type as EnergyWorkingProcessType]}
                        </p>
                      </div>
                    </Col>
                    <Col span={4} className={styles.ColStyle}>
                      <div>
                        <Tooltip title={item.ouSimpleVos?.map(i => i.name).join()}>
                          <p className={styles.ColSpanStyle}>{item.ouSimpleVos?.map(i => i.name).join()}</p>
                        </Tooltip>

                        <p className={styles.subTitle_sty}>所属运营单元</p>
                      </div>
                    </Col>
                    {item.type === EnergyWorkingProcessType.PREPARATION_AND_HANDLE && (
                      <Col span={2} className={styles.ColStyle}>
                        <div>
                          <p className={styles.ColSpanStyle}>{item.hasConversion ? '是' : '否'}</p>
                          <p className={styles.subTitle_sty}>是否发生转化</p>
                        </div>
                      </Col>
                    )}
                    <Col span={2} className={styles.ColStyle}>
                      <div>
                        <Tooltip title={item.processExitSimpleVos?.map(i => i.name).join()}>
                          <p className={styles.ColSpanStyle}>{item.processEntrySimpleVos?.map(i => i.name).join()}</p>
                        </Tooltip>
                        <p className={styles.subTitle_sty}>入口</p>
                      </div>
                    </Col>
                    <Col span={2} className={styles.ColStyle}>
                      <div>
                        <Tooltip title={item.processExitSimpleVos?.map(i => i.name).join()}>
                          <p className={styles.ColSpanStyle}>{item.processExitSimpleVos?.map(i => i.name).join()}</p>
                        </Tooltip>
                        <p className={styles.subTitle_sty}>出口</p>
                      </div>
                    </Col>
                  </Row>
                </>
              }
            >
              <Spin spinning={detailLoading}>
                <Col className={styles.AddProcessTabs} span={24}>
                  <div className={styles.switchBtn}>
                    <Radio.Group
                      onChange={e => setTabsActiveKey(e.target.value)}
                      value={tabsActiveKey}
                      buttonStyle="solid"
                    >
                      {tabs(item)?.map(item => (
                        <Radio.Button key={item.key} value={item.key}>
                          {item.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                  {tabsActiveKey === '1' && (
                    <div className={styles.tab_content_wrapper}>
                      {uetEMTProcessDetail?.processEntryVos?.map((i, index) => (
                        <Card className={styles.CardDetailStyle} key={i.id}>
                          <Form layout="horizontal" {...formLayout}>
                            <Form.Item label={<>入口{index + 1}</>} style={{ marginBottom: 16 }}>
                              <ShowInput value={i.name} />
                            </Form.Item>
                            <Form.Item label="能源介质" style={{ marginBottom: 16 }}>
                              <ShowInput value={i.energyMediumName} />
                            </Form.Item>
                            <Form.Item label="引自" style={{ marginBottom: 16 }}>
                              <ShowInput value={i.refName} />
                            </Form.Item>
                            <Form.Item label="计量设备" style={{ marginBottom: 16 }}>
                              {(i?.deviceOuVos ?? []).length > 0
                                ? i.deviceOuVos?.map(i => (
                                    <Tag border="solid" key={i.id} style={{ marginBottom: 4 }}>
                                      {i.name}
                                    </Tag>
                                  ))
                                : '--'}
                            </Form.Item>
                          </Form>
                        </Card>
                      ))}
                    </div>
                  )}
                  {tabsActiveKey === '2' && (
                    <div className={styles.tab_content_wrapper}>
                      {(uetEMTProcessDetail?.processNodeVo ? [uetEMTProcessDetail?.processNodeVo] : []).map(i => (
                        <Card key={i.id} className={styles.CardDetailStyle}>
                          <Form layout="horizontal" {...formLayout}>
                            <Form.Item label="能源介质" style={{ marginBottom: 16 }}>
                              <ShowInput value={i.energyMediumName} />
                            </Form.Item>
                            <Form.Item label="计量设备" style={{ marginBottom: 16 }}>
                              {(i?.deviceOuVos ?? []).length > 0
                                ? i.deviceOuVos?.map(i => (
                                    <Tag border="solid" key={i.id} style={{ marginBottom: 4 }}>
                                      {i.name}
                                    </Tag>
                                  ))
                                : '--'}
                            </Form.Item>
                          </Form>
                        </Card>
                      ))}
                    </div>
                  )}
                  {tabsActiveKey === '3' && (
                    <div className={styles.tab_content_wrapper}>
                      {uetEMTProcessDetail?.processExitVos?.map((i, index) => (
                        <Card key={i.id} className={styles.CardDetailStyle}>
                          <Form layout="horizontal" {...formLayout}>
                            <Form.Item label={<>出口{index + 1}</>} style={{ marginBottom: 16 }}>
                              <ShowInput value={i.name} />
                            </Form.Item>
                            <Form.Item label="能源介质" style={{ marginBottom: 16 }}>
                              <ShowInput value={i.energyMediumName} />
                            </Form.Item>
                            <Form.Item label="计量设备" style={{ marginBottom: 16 }}>
                              {(i?.deviceOuVos ?? []).length > 0
                                ? i.deviceOuVos?.map(i => (
                                    <Tag border="solid" key={i.id} style={{ marginBottom: 4 }}>
                                      {i.name}
                                    </Tag>
                                  ))
                                : '--'}
                            </Form.Item>
                          </Form>
                        </Card>
                      ))}
                    </div>
                  )}
                </Col>
              </Spin>
            </Panel>
          </Collapse>
        ))}
      </Wrapper>
      <FloatButton.BackTop
        visibilityHeight={300}
        target={() => document.getElementById('handelDocID') as HTMLElement}
      />
    </div>
  );
};

export default EnergyWorkingDetail;

export const fontfilterArr = (newList: V2UetEmtProcessListPostResponse, topoNameOrEnterExitName: string) => {
  let res1 = newList?.processVos?.filter(i => i.name?.includes(topoNameOrEnterExitName));
  let res2 = newList.processVos
    ?.map(i => {
      return {
        ...i,
        processEntrySimpleVos: i.processEntrySimpleVos?.map(item => item.name).join(),
        processExitSimpleVos: i.processExitSimpleVos?.map(item => item.name).join(),
      };
    })
    .filter(
      item =>
        item.processEntrySimpleVos?.includes(topoNameOrEnterExitName) ||
        item.processExitSimpleVos?.includes(topoNameOrEnterExitName)
    );
  let searchList = Array.from(new Set((res1 ?? []).map(i => i.id).concat((res2 ?? []).map(i => i.id))));
  return newList?.processVos?.filter(i => searchList.includes(i.id));
};
