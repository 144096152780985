import React, { useEffect, useState } from 'react';
import { Modal, Button, FormTitle, message } from '@maxtropy/components';
import OpenAI from 'openai';
import html2canvas from 'html2canvas';
import ReactMarkdown from 'react-markdown';
import { marked } from 'marked';
import { Spin } from 'antd';
// @ts-ignore
import topGif from '../../assets/topGif.mp4';
import rotateIcon from '../../assets/rotateIcon.png';
import './index.scss';

const openai = new OpenAI({
  apiKey: 'sk-17a8762413d04131831830ecb0864f24',
  // apiKey: 'sk-418a77166af348dbb1342eb80ad0468f',
  baseURL: 'https://dashscope.aliyuncs.com/compatible-mode/v1',
  dangerouslyAllowBrowser: true,
});

const convertMarkdownToText = async (markdown: string) => {
  // 将 Markdown 转换为 HTML
  const html = await marked(markdown);
  // 创建一个临时的 DOM 元素
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;
  // 使用 innerText 提取文本
  return tempElement.innerText;
};

export default function AlModal({ setOpen }: { setOpen: (val: boolean) => void }) {
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // 这里应该调用AI分析的函数
    const controller = new AbortController();
    getAnalysisByImage(controller);

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, []);

  const getAnalysisByImage = async (controller: AbortController) => {
    const element = document.getElementById('energyAnalysis');
    if (!element) return;

    setLoading(true);
    const base64Image = await html2canvas(element).then(canvas => {
      // 将 Canvas 转换为 Base64 图片
      const base64Image = canvas.toDataURL('image/png');
      return base64Image;
    });
    try {
      const completion = await openai.chat.completions.create(
        {
          model: 'qwen-vl-max', //模型列表：https://help.aliyun.com/zh/model-studio/getting-started/models
          messages: [
            {
              role: 'system',
              content: '你是一个专业的能源管理专家，需要你根据页面图分析，给出专业的结论',
            },
            {
              role: 'user',
              content: [
                {
                  type: 'image_url',
                  image_url: { url: `${base64Image}` },
                },
                {
                  type: 'text',
                  text: `
                    #角色（role）
                    你是一个专业的能源管理专家，需要你根据页面图分析，给出专业的结论。

                    #情境（situation）
                    你收到一张能源管理系统截图，截图内容框架如下：
                    1.页面上方通过控件和卡片显示了所选的【时间范围】，和各能源种类在该时间范围内的用量和环比变化。比如【综合能耗】是统一转换为标准煤的能耗，包括各类能源。【电能】是用电。
                    2.页面下方显示了桑基图，表示各级【所选能源种类】（上方卡片为选中态）的流向和用量，纵向越长，表示用量越大。各级名称可能是生产工序、设备、车间等，需要你做出判断。
                    3.如果上下级用量数据不匹配，比如上级比下级多，会有上级发生的【用能损耗】；如果下级比上级多，会有下级多出的【未知能耗】，多为计量偏差或异常。

                    #任务（task）
                    需要清晰地以中文，结构化形式输出以下内容：
                    1.数据描述：描述该页面图表示的主要内容。比如：在所选时间范围内，各项能源的主要用量，环比变化量。主要的用能对象是哪些。在此过程中，你需要根据卡片选中态判断当前的桑基图展示的是哪种能源介质的数据内容。
                    2.诊断分析：根据桑基图中各层级的名称，以及占比进行分析。给出合理的排查、诊断意见。比如：在某个车间/工序下，同类设备中，A设备在较同类型用能量显著偏大。需要进一步结合生产情况排查异常。B和下游设备有大量的【所选能源介质】损耗发生，请检查管道泄漏情况。

                    #限制（limit）
                    1.在回答中不要有信息提示你收到了一张截图，把自己当做系统内置的智能助手，帮助当前看到该页面的用户理解和分析。
                    2.仅回答能源、生产制造领域相关内容。如果收到的截图有异常。比如信息不完整，或截图内容与能源系统完全无关。请回答“网络繁忙，请稍后重试。”
                    3.如果图中的数据缺省，没有正常显示图表。请回答“当前无用能数据，请重新加载或检查配置。”`,
                },
              ],
            },
          ],
          stream: true,
        },
        { signal: controller.signal }
      );

      let fullContent = '';
      for await (const chunk of completion) {
        if (controller.signal.aborted) {
          console.log('退出循环');
          break; // 如果请求被中止，退出循环
        }
        if (chunk.choices[0].delta.content != null) {
          fullContent += chunk.choices[0].delta.content;
          setText(fullContent);
        }
      }
      setLoading(false);
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('请求已中止');
      } else {
        console.error('请求出错:', error);
      }
      setLoading(false);
    }
  };

  const onCopy = async () => {
    try {
      const plainText = await convertMarkdownToText(text);
      navigator.clipboard.writeText(plainText);
      message.success('复制成功');
    } catch (error) {
      message.error('复制失败');
    }
  };

  return (
    <Modal
      open={true}
      className="customModal"
      maskClosable={false}
      title={
        <div className="customModalHeader">
          <video autoPlay muted loop playsInline src={topGif} width={1024} height={160}></video>
          <div className="titleContent">
            <div className="title">AI能流分析</div>
            {loading ? (
              <span className="loadingText">
                正在根据当前数据分析中，请稍等
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
              </span>
            ) : (
              '分析已完成'
            )}
          </div>
        </div>
      }
      size="big"
      footer={<Button onClick={() => setOpen(false)}>关闭</Button>}
      onCancel={() => setOpen(false)}
    >
      <div className="analysisSubTitle">
        <div>
          <img
            src={rotateIcon}
            alt="AI分析结果"
            width={24}
            style={{
              marginRight: 8,
              animation: 'rotate 1.2s linear infinite',
            }}
          />
          AI分析结果：
        </div>
        <Button disabled={loading} onClick={onCopy}>
          复制结果
        </Button>
      </div>
      <div className="analysisContent">
        {text && <ReactMarkdown children={text} />}
        {loading && <Spin size="small" />}
      </div>
    </Modal>
  );
}
