import {
  Button,
  CustomFilter,
  Dropdown,
  EllipsisSpan,
  Form,
  Input,
  Paging,
  PopConfirm,
  Select,
  Table,
  Tag,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import styles from './index.module.scss';
import React, { useRef, useState } from 'react';
import { Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CreateOrEditModelModal, { formSubmitProps } from './components/CreateOrEditModelModal';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import {
  apiV2ReasoningAddPost,
  apiV2ReasoningChangeStatusPost,
  apiV2ReasoningDelPost,
  apiV2ReasoningEditPost,
  apiV2ReasoningListPost,
  apiV2ReasoningSavePredictConfigPost,
  V2ReasoningListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import ViewPredictionsModal from './components/ViewPredictionsModal';
import {
  ModelStatus,
  ModelStatusColorDisplay,
  ModelStatusDisplay,
  ModelType,
  ModelTypeDisplay,
  PredictDataGranularType,
  TrainStatus,
  TrainStatusColorDisplay,
  TrainStatusDisplay,
} from './utils';
import InferenceConfig, { formInferenceSubmitProps } from './components/InferenceConfig';

export interface FilterParams {
  modelInfo?: string;
  status?: ModelStatus;
}

const columns = [
  {
    title: '模型编码',
    dataIndex: 'modelCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模型名称',
    dataIndex: 'modelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模型类型',
    dataIndex: 'modelType',
    ellipsis: { showTitle: true },
    render: (v: ModelType) => <EllipsisSpan value={ModelTypeDisplay[v]} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: ModelStatus) => (
      <Tag border="solid" color={ModelStatusColorDisplay[v]}>
        {ModelStatusDisplay[v]}
      </Tag>
    ),
  },
  {
    title: '训练状态',
    dataIndex: 'trainStatus',
    ellipsis: { showTitle: true },
    render: (v: TrainStatus) => (
      <Tag border="solid" color={TrainStatusColorDisplay[v]}>
        {TrainStatusDisplay[v]}
      </Tag>
    ),
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateUser',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export type itemProps = Exclude<V2ReasoningListPostResponse['list'], undefined>[number];

const MicrogridModel = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging(20);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [update, updateFn] = useUpdate();
  const hasAddPvModelPermission = useHasPermission(PermissionsType.B_ADDPHOTOVOLTAICSTATIONPREDICTIONMODEL);
  const hasAddWindElecModelPermission = useHasPermission(PermissionsType.B_ADDWINDELECPREDICTMODEL);
  const hasAddLoadModelPermission = useHasPermission(PermissionsType.B_ADDLOADPREDICTMODEL);

  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<FilterParams>({});

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false); // 新建编辑模型弹窗
  const [predictModalOpen, setPredictModalOpen] = useState<boolean>(false); // 查看预测弹窗
  const [inferenceModalOpen, setInferenceModalOpen] = useState<boolean>(false); // 推理配置弹窗
  const [modelId, setModelId] = useState<number>();
  const editTypeRef = useRef<ModelType>(); // 模型类型
  const predictDataGranularRef = useRef<PredictDataGranularType>(); // 预测数据颗粒度

  // 查询列表
  const { data: pageList, loading } = useRequest(
    async () => {
      const res = await apiV2ReasoningListPost({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      });
      setTotalCount(res.total!);
      return res.list;
    },
    {
      refreshDeps: [pageOffset, pageSize, searchParams, update],
    }
  );

  // 删除
  const onDelete = (id?: number) => {
    apiV2ReasoningDelPost({
      id,
    }).then(_ => {
      updateFn();
    });
  };

  // 下载
  const downLoadAction = (id?: number) => {
    window.open(`/api/v2/reasoning/downloadModel?id=${id}`);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (_: any, record: itemProps) => {
        return (
          <Space size={10}>
            <Button
              type="link"
              disabled={record.trainStatus === TrainStatus.TRAINING || record.trainStatus === TrainStatus.FAILED}
              onClick={() => {
                predictDataGranularRef.current = record.predictResolutionType as PredictDataGranularType;
                setPredictModalOpen(true);
                setModelId(record.id);
              }}
            >
              查看预测
            </Button>
            <Button
              type="link"
              disabled={record.trainStatus === TrainStatus.TRAINING}
              onClick={() => {
                setEditModalOpen(true);
                editTypeRef.current = record.modelType;
                setModelId(record.id);
              }}
            >
              编辑
            </Button>

            <Button
              disabled={record.trainStatus === TrainStatus.TRAINING || record.trainStatus === TrainStatus.FAILED}
              type="link"
              onClick={() => downLoadAction(record.id)}
            >
              下载模型
            </Button>
            <Button
              disabled={record.status === ModelStatus.DISABLED}
              type="link"
              onClick={() => {
                setModelId(record.id);
                setInferenceModalOpen(true);
              }}
            >
              推理配置
            </Button>

            <Dropdown
              trigger={['click']}
              type="link"
              menu={{
                items: [
                  {
                    key: 'disabledOrEnabled',
                    label:
                      record.status === ModelStatus.ENABLED ? (
                        <Button
                          type="link"
                          disabled={
                            record.trainStatus === TrainStatus.TRAINING || record.trainStatus === TrainStatus.FAILED
                          }
                          onClick={() => {
                            if (record.id) {
                              apiV2ReasoningChangeStatusPost({ id: record.id, status: ModelStatus.DISABLED }).then(
                                res => {
                                  if (res.flag) {
                                    updateFn();
                                  }
                                }
                              );
                            }
                          }}
                        >
                          禁用
                        </Button>
                      ) : (
                        <Button
                          type="link"
                          disabled={record.trainStatus === TrainStatus.TRAINING}
                          onClick={() => {
                            if (record.id) {
                              apiV2ReasoningChangeStatusPost({ id: record.id, status: ModelStatus.ENABLED }).then(
                                res => {
                                  if (res.flag) {
                                    updateFn();
                                  }
                                }
                              );
                            }
                          }}
                        >
                          启用
                        </Button>
                      ),
                  },
                  {
                    key: 'deleteModel',
                    label: (
                      <PopConfirm
                        title="是否删除该模型？删除后无法恢复"
                        onConfirm={() => {
                          onDelete(record.id);
                        }}
                      >
                        <Button type="link">删除</Button>
                      </PopConfirm>
                    ),
                  },
                ],
              }}
            >
              更多
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const onFinish = (val: FilterParams) => {
    setPageOffset(1);
    setSearchParams(val);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  const filters = (
    <>
      <CustomFilter form={form} onFinish={(v: FilterParams) => onFinish(v)} onReset={onReset}>
        <Form.Item name="modelInfo" label="模型编码/名称">
          <Input style={{ width: '100%' }} placeholder="请输入" />
        </Form.Item>
        <Form.Item name="modelType" label="模型类型">
          <Select
            allowClear
            placeholder="全部"
            style={{ width: '100%' }}
            options={Object.entries(ModelTypeDisplay).map(([k, v]) => ({ label: v, value: k }))}
          />
        </Form.Item>
        <Form.Item name="status" label="状态">
          <Select
            allowClear
            placeholder="全部"
            style={{ width: '100%' }}
            options={Object.entries(ModelStatusDisplay).map(([k, v]) => ({ label: v, value: k }))}
          />
        </Form.Item>
      </CustomFilter>
    </>
  );

  const onCancel = () => {
    setInferenceModalOpen(false);
    setEditModalOpen(false);
    editTypeRef.current = undefined;
    setModelId(undefined);
  };

  // 新建模型
  const onSubmit = async (values: formSubmitProps) => {
    const params = {
      modelName: values.modelName,
      uetId: values.uetId,
      pvStationId: editTypeRef.current === ModelType.PV ? values?.objectId?.split('-')?.[0] : undefined,
      objectId: editTypeRef.current === ModelType.PV ? values?.objectId?.split('-')?.[1] : values.objectId,
      propertyId: values.propertyId,
      propertyName: values.propertyName,
      modelType: editTypeRef.current,
      trainType: values.trainType,
      trainStartTs:
        values.trainType === 0
          ? dayjs((values.trainTs as string).split('~')[0]).valueOf()
          : dayjs(values.trainTs?.[0]).valueOf(),
      trainEndTs:
        values.trainType === 0
          ? dayjs((values.trainTs as string).split('~')[1]).valueOf()
          : dayjs(values.trainTs?.[1]).valueOf(),
      predictRange: values.predictRange,
      predictRangeType: values.predictRangeType,
      predictResolutionType: values.predictResolutionType,
      inWindow: values.inWindow,
      inWindowResolution: values.inWindowResolution,
      csvKey: values.csvKey,
      environKey: values?.environKey,
    };
    if (modelId) {
      await apiV2ReasoningEditPost({
        ...params,
        id: modelId,
      });
      onCancel();
      updateFn();
    } else {
      await apiV2ReasoningAddPost({
        ...params,
      });
      onCancel();
      setPageOffset(1);
      updateFn();
    }
  };

  const items = [
    {
      key: '1',
      // show: hasAddPvModelPermission,
      show: true,
      label: (
        <Button
          type="link"
          style={{ color: 'var(--mx-dropdown-menu-color)' }}
          onClick={() => {
            setEditModalOpen(true);
            editTypeRef.current = ModelType.PV;
            setModelId(undefined);
          }}
        >
          新建光伏预测模型
        </Button>
      ),
    },
    {
      key: '2',
      // show:hasAddWindElecModelPermission,
      show: true,
      label: (
        <Button
          type="link"
          style={{ color: 'var(--mx-dropdown-menu-color)' }}
          onClick={() => {
            setEditModalOpen(true);
            editTypeRef.current = ModelType.WIND_POWER;
            setModelId(undefined);
          }}
        >
          新建风电站预测模型
        </Button>
      ),
    },
    {
      key: '3',
      // show:hasAddLoadModelPermission,
      show: true,
      label: (
        <Button
          type="link"
          style={{ color: 'var(--mx-dropdown-menu-color)' }}
          onClick={() => {
            setEditModalOpen(true);
            editTypeRef.current = ModelType.LOAD;
            setModelId(undefined);
          }}
        >
          新建负荷预测模型
        </Button>
      ),
    },
  ].filter(c => c.show);

  // 推理配置
  const onInferenceConfigSubmit = async (values: formInferenceSubmitProps) => {
    if (modelId) {
      await apiV2ReasoningSavePredictConfigPost({
        ...values,
        predictStartTime: dayjs(values.predictStartTime).valueOf(),
        modelId: modelId,
      });
      onCancel();
      updateFn();
    } else {
      await apiV2ReasoningSavePredictConfigPost({
        ...values,
        predictStartTime: dayjs(values.predictStartTime).valueOf(),
      });
      onCancel();
      setPageOffset(1);
      updateFn();
    }
  };

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrapper}>
        <Space size={10} direction="horizontal" className={styles.btnBlank}>
          <Dropdown menu={{ items }} placement="bottom" type="primary">
            <PlusOutlined />
            新建预测模型
          </Dropdown>
        </Space>
        <Table
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={pageList ?? []}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
      {/* 推理配置 */}
      <InferenceConfig
        modelId={modelId}
        visible={inferenceModalOpen}
        onCancel={() => setInferenceModalOpen(false)}
        onSubmit={onInferenceConfigSubmit}
      />
      {/* 查看预测 */}
      <ViewPredictionsModal
        modelId={modelId}
        visible={predictModalOpen}
        predictDataGranular={predictDataGranularRef.current}
        onCancel={() => {
          predictDataGranularRef.current = undefined;
          setPredictModalOpen(false);
        }}
      />
      {/* 编辑 */}
      <CreateOrEditModelModal
        editType={editTypeRef.current}
        modelId={modelId}
        visible={editModalOpen}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default MicrogridModel;
