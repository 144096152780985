import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import * as echarts from 'echarts';
import { sum } from '../../utils';
import { LossDataPostItemResponse } from '../LossDuringTransAndDisProcess';
import { V2EnergyCompareGetMediumIndicatorLossDataLossDetailPostResponse } from '@maxtropy/device-customer-apis-v2';
import { MediumIndicatorDisplayUnit } from '@/api/energyMedium';

export enum Status {
  OVER = 1, // 超限
  NORMAL = 2, // 正常
}
export const StatusDisplay = {
  [Status.OVER]: '超限',
  [Status.NORMAL]: '正常',
};

const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};

export const getNormalAxis = () => {
  return [
    {
      ...xAxis,
      offset: 18,
      type: 'time',
      minInterval: 60 * 1000 * 60 * 24,
      axisLabel: {
        formatter: function (e: number) {
          return dayjs(e, 'x').format('MM-DD');
        },
      },
    },
  ];
};

export const getTooltip = ({ unit = '', energyMediumName = '' }: { unit?: string; energyMediumName?: string }) => ({
  trigger: 'axis',
  axisPointer: {
    type: 'shadow',
  },
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  textStyle: {
    color: '#fff',
  },
  formatter(items: any) {
    const { axisValue } = items[0];
    // 根据选择的颗粒度，展示不同的tooltip
    let str: string = dayjs(axisValue, 'x').format('YYYY-MM-DD');
    items.forEach((item: any) => {
      const { seriesName, data, marker } = item;
      if (seriesName.includes(StatusDisplay[Status.OVER]) || seriesName.includes(StatusDisplay[Status.NORMAL])) {
        if (!isNil(data.value[1])) {
          const value = !isNil(data.value[1]) ? `${data.value[1].toFixed(2)}${unit}` : '--';
          str += `<br> ${marker}<span style='display:inline-block;width:168px'>${seriesName}</span> ${value}`;
        }
      } else {
        const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${unit}` : '--';
        str += `<br> ${marker}<span style='display:inline-block;width:168px'>${seriesName}--${energyMediumName}</span>${value}`;
        (data[data.length - 1] ?? []).forEach((i: any) => {
          const value = !isNil(i.sum) ? `${i.sum.toFixed(2)}${unit}` : '--';
          str += `<br><span style='display:inline-block;width:178px;padding-left:16px'>${i.entryExitName}</span> ${value}`;
        });
      }
    });
    return str;
  },
});

// 获取图例
export const getLegend = () => {
  return {
    show: true,
    right: 20,
    top: 10,
    textStyle: {
      color: '#AFBCC4',
    },
    data: [
      {
        name: `输入`,
        icon: 'rect',
      },
      {
        name: `输出`,
        icon: 'rect',
      },
      {
        name: `损耗（超限）`,
        icon: 'rect',
      },
      {
        name: `损耗（正常）`,
        icon: 'rect',
      },
    ],
  };
};

export const getChartOption = (unit: string, energyMediumName?: string) => {
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 80,
      right: 50,
      top: 55,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
        // startValue: dayjs().startOf('d').format('MM-DD[\n]HH:mm'),
      },
    ],
    color: colors,
    tooltip: getTooltip({
      unit,
      energyMediumName,
    }),
    xAxis: getNormalAxis(),
    yAxis: {
      type: 'value',
      name: `${unit}`,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
  };
};

const lossMarkArea = (processLossViewVoToIns: LossDataPostItemResponse['processLossViewVoToIns']) => {
  return processLossViewVoToIns?.map(sl => {
    return [
      {
        xAxis: dayjs(sl.ts as string)
          .startOf('day')
          .subtract(12, 'hour')
          .valueOf(),
        label: {
          show: false,
          width: 120 - 24,
          height: 60 - 16,
          backgroundColor: 'rgba(0,0,0,0.8)',
          padding: [8, 12],
          zIndex: 99,
          z: 99,
          formatter: [
            `{status|${sl.lossDataVo?.ifExceed ? StatusDisplay[Status.OVER] : StatusDisplay[Status.NORMAL]}}状态`,
            `{time|${dayjs(sl.ts as string).format('MM-DD')}}`,
          ].join('\n\n'),

          rich: {
            status: {
              color: 'rgba(255,255,255,0.65)',
              lineHeight: 17,
            },
            time: {
              color: 'rgba(255,255,255,0.85)',
              lineHeight: 17,
              fonWeight: 500,
            },
          },
        },
        emphasis: {
          label: {
            show: true,
            position: ['0%', '-600%'],
            zIndex: 99,
          },
        },
        blur: {
          label: {
            show: false,
          },
        },
        itemStyle: {
          color: sl.lossDataVo?.ifExceed ? '#91383A' : '#2A6948',
        },
        y: '84%',
      },
      {
        xAxis: dayjs(sl.ts as string)
          .endOf('day')
          .subtract(12, 'hour')
          .valueOf(),
        y: '86%',
      },
    ];
  });
};

const biasItemSty = (fullColor: string, biasColor: string) => {
  return {
    color: fullColor,
    decal: {
      color: biasColor,
      dashArrayX: [1, 0],
      dashArrayY: [4, 3],
      rotation: -Math.PI / 4,
    },
  };
};

export const getEnergyMediumLossChartOption = (
  processLossViewVoToIns?: LossDataPostItemResponse['processLossViewVoToIns'],
  energyMediumIndicatorId?: number,
  mediumIndicatorDisplayUnitData?: MediumIndicatorDisplayUnit,
  energyMediumName?: string
) => {
  if (processLossViewVoToIns && energyMediumIndicatorId && mediumIndicatorDisplayUnitData) {
    const unit = mediumIndicatorDisplayUnitData?.find(
      i => i.energyMediumIndicatorId === energyMediumIndicatorId
    )?.displayPhysicalUnitGeneralName;

    const option = unit && getChartOption(unit, energyMediumName);
    // const colors = ['#00ADFF', '#00ADFF'];
    return {
      ...option,
      legend: getLegend(),
      series: [
        {
          name: '输入',
          type: 'bar',
          // stack: 'Ad',
          color: '#2D8DFF',
          emphasis: {
            focus: 'series',
          },
          z: 0,
          data: processLossViewVoToIns.map(i => [
            i.ts,
            !isNaN(sum(i.entryDataList?.map(m => m.sum))) ? sum(i.entryDataList?.map(m => m.sum)) : null,
            i.entryDataList,
          ]),
          markArea: {
            data: lossMarkArea(processLossViewVoToIns),
          },
        },
        {
          name: '输出',
          type: 'bar',
          stack: 'Ad',
          color: '#16DD8E',
          emphasis: {
            focus: 'series',
          },
          z: 0,
          data: processLossViewVoToIns.map(i => [
            i.ts,
            !isNaN(sum(i.exitDataList?.map(m => m.sum))) ? sum(i.exitDataList?.map(m => m.sum)) : null,
            i.exitDataList,
          ]),
          markArea: {
            data: lossMarkArea(processLossViewVoToIns),
          },
        },
        {
          name: '损耗（超限）',
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series',
          },
          z: 0,
          itemStyle: biasItemSty('rgba(255, 77, 79, 0.4)', 'rgb(255, 77, 79)'),
          data: processLossViewVoToIns.map(m => {
            return {
              value: [m.ts, m.lossDataVo?.ifExceed ? m.lossDataVo?.loss : null],
              itemStyle: biasItemSty('rgba(255, 77, 79, 0.4)', 'rgb(255, 77, 79)'),
            };
          }),
          markArea: {
            data: lossMarkArea(processLossViewVoToIns),
          },
        },
        {
          name: '损耗（正常）',
          type: 'bar',
          stack: 'Ad',
          emphasis: {
            focus: 'series',
          },
          z: 0,
          data: processLossViewVoToIns.map(m => {
            return {
              value: [m.ts, !m.lossDataVo?.ifExceed ? m.lossDataVo?.loss : null],
              itemStyle: biasItemSty('rgba(255,203,71,0.4)', 'rgb(255,203,71)'),
            };
          }),
          itemStyle: biasItemSty('rgba(255,203,71,0.4)', 'rgb(255,203,71)'),
          markArea: {
            data: lossMarkArea(processLossViewVoToIns),
          },
        },
      ],
    };
  }
};

// ------------------------下面是弹窗详情的损耗

// tooltip 通用配置
export const getLossModalTooltip = ({ unit = '' }: { unit?: string }) => ({
  trigger: 'axis',
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  textStyle: {
    color: '#fff',
  },
  formatter(items: any) {
    const { axisValue } = items[0];
    // 根据选择的颗粒度，展示不同的tooltip
    let str: string = dayjs(axisValue, 'x').format('MM-DD[\n]HH:mm');
    items.forEach((item: any) => {
      const { seriesName, data, marker } = item;
      const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${unit}` : '--';
      str += `<br> ${marker}${seriesName}: ${value}`;
    });
    return str;
  },
});

export const getLossModalNormalAxis = () => {
  return [
    {
      ...xAxis,
      type: 'time',
      minInterval: 60 * 1000,
      axisLabel: {
        formatter: function (e: number) {
          return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
        },
      },
    },
  ];
};

export const getLossDetailBasicChartOption = (unit: string) => {
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 80,
      right: 50,
      top: 55,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
        // startValue: dayjs().startOf('d').format('MM-DD[\n]HH:mm'),
      },
    ],
    color: colors,
    tooltip: getLossModalTooltip({
      unit,
    }),
    xAxis: getLossModalNormalAxis(),
    yAxis: {
      type: 'value',
      name: `${unit}`,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
    },
  };
};

// 获取图例
export const getLossModalLegend = () => {
  return {
    show: true,
    right: 20,
    top: 10,
    textStyle: {
      color: '#AFBCC4',
    },
    data: [
      {
        name: `输入`,
        icon: 'rect',
      },
      {
        name: `输出`,
        icon: 'rect',
      },
    ],
  };
};

export const getLossDetailChartOption = (
  energyMediumIndicatorId?: number,
  chartData?: V2EnergyCompareGetMediumIndicatorLossDataLossDetailPostResponse,
  mediumIndicatorDisplayUnitData?: MediumIndicatorDisplayUnit
) => {
  if (chartData && energyMediumIndicatorId && mediumIndicatorDisplayUnitData) {
    const unit = mediumIndicatorDisplayUnitData?.find(
      i => i.energyMediumIndicatorId === energyMediumIndicatorId
    )?.displayPhysicalUnitGeneralName;
    const option = unit && getLossDetailBasicChartOption(unit);
    const entryColors = ['#52A4FF', '#52A4FF'];
    const exitColors = ['#4FC448', '#4FC448'];

    return {
      ...option,
      legend: getLossModalLegend(),
      series: [
        {
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: entryColors,
          areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#51DBFF15',
              },
              {
                offset: 1,
                color: '#51A1FF05',
              },
            ]),
          },
          name: '输入',
          data: (chartData?.entryDataDetailList ?? []).map(a => {
            return [a.ts, a.sum];
          }),
        },
        {
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: exitColors,
          areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#47FF6615',
              },
              {
                offset: 1,
                color: '#5AD04205',
              },
            ]),
          },
          name: '输出',
          data: (chartData.exitDataDetailList ?? []).map(a => {
            return [a.ts, a.sum];
          }),
        },
      ],
    };
  }
};
