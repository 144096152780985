import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  FormTitle,
  MXTabsProps,
  Modal,
  Tabs,
  Themes,
  Wrapper,
  message,
  useBreadcrumbRoutes,
  useTheme,
  useUpdate,
} from '@maxtropy/components';
import { Graph, Shape, Node, Cell } from '@antv/x6';
import { Stencil } from '@antv/x6-plugin-stencil';
import { register } from '@antv/x6-react-shape';
import ResizeObserver from 'rc-resize-observer';
import {
  V2HvacTopologyDetailPostResponse,
  apiV2HvacNodeDeletePost,
  apiV2HvacTopologyDetailPost,
  apiV2HvacTopologySavePost,
} from '@maxtropy/device-customer-apis-v2';
import gragProcessIcon from '../icons/gragProcess@2x.png';
import grapProcessLightIcon from '../icons/grapProcessLight.png';
import CustomNode from '../components/CustomNode';
import styles from './index.module.scss';
import {
  LineType,
  LineTypeColor,
  LineTypeDashed,
  LineTypeDisplay,
  LineTypeStyle,
  NodeType,
  OperationType,
  chilledNodeList,
  coolingdNodeList,
  nodeSize,
} from '../utils';
import { BaseNodeList } from '../utils';
import NewNode from '../components/NewNode';
import { isNil } from 'lodash-es';

const rectPort = {
  markup: [
    {
      tagName: 'rect',
      selector: 'rect',
    },
  ],
  attrs: {
    rect: {
      magnet: true,
      width: 6,
      height: 6,
      stroke: '#57FB8B',
      fill: '#57FB8B',
      strokeWidth: 2,
      x: -3,
      y: -3,
      style: {
        visibility: 'hidden',
      },
    },
  },
};

const ports = {
  // 链接桩组定义
  groups: {
    top: {
      position: 'top',
      ...rectPort,
    },
    right: {
      position: 'right',
      ...rectPort,
    },
    bottom: {
      position: 'bottom',
      ...rectPort,
    },
    left: {
      position: 'left',
      ...rectPort,
    },
  },
  // 链接桩
  items: [
    { group: 'top' },
    { group: 'right' },
    { group: 'right' },
    { group: 'right' },
    { group: 'bottom' },
    { group: 'left' },
    { group: 'left' },
    { group: 'left' },
  ],
};

// 注册节点
register({
  shape: 'custom-node',
  width: 160,
  height: 48,
  effect: ['data'],
  component: CustomNode,
});

const HVACTopology = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const navigate = useNavigate();
  const { id: uetId } = useParams<{ id: string }>();
  const searchParams = new URLSearchParams(window.location.search);
  const stationId = searchParams.get('stationId') || undefined;
  const stationName = searchParams.get('stationName') || undefined;

  // 画布相关
  const graph = useRef<Graph>();
  const theme = useTheme();
  const refStencil = useRef<HTMLDivElement>();
  const refContainer = useRef<HTMLDivElement>();
  const [nodeList, setNodeList] = useState<Node<Node.Properties>[]>([]); // 画布上的节点
  const [topoData, setTopoData] = useState<V2HvacTopologyDetailPostResponse>();
  const [selectedNode, setSelectedNode] = useState<Node<Node.Properties>>();
  const [isInit, setIsInit] = useState<boolean>(true);

  const [operationType, setOperationType] = useState<OperationType>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [lineType, setLineType] = useState<LineType>(LineType.CHILLED_SUPPLY);
  const lineTypeRef = useRef<LineType>(LineType.CHILLED_SUPPLY);
  const [activeKey, setActiveKey] = useState<string>('1');
  const [updateState, updateFn] = useUpdate();

  useEffect(() => {
    if (stationId) {
      apiV2HvacTopologyDetailPost({ id: stationId }).then(res => {
        setTopoData(res);
      });
    }
  }, [stationId]);

  useEffect(() => {
    graph.current = new Graph({
      container: refContainer.current!,
      panning: true,
      mousewheel: true,
      connecting: {
        router: {
          name: 'manhattan',
          args: {
            padding: 40,
          },
        },
        anchor: 'center',
        connectionPoint: 'anchor',
        allowNode: false,
        allowBlank: false,
        allowLoop: true,
        allowMulti: false,
        snap: {
          radius: 20,
        },

        createEdge() {
          return new Shape.Edge({
            attrs: {
              line: {
                stroke: LineTypeColor[lineTypeRef.current],
                strokeWidth: 2,
                strokeDasharray: LineTypeDashed[lineTypeRef.current],
                targetMarker: 'classic',
                class: styles.antLine,
              },
            },
            zIndex: 0,
          });
        },
        validateConnection({ sourceCell, targetCell }) {
          // 每个节点不能指向本身
          if (targetCell?.id === sourceCell?.id) {
            return false;
          }
          // 供水端口只能接受线，不能拖出线
          const { type: sourceType } = sourceCell?.getData();
          if (sourceType === NodeType.SUPPLY_WATER_PORT) {
            return false;
          }
          // 回水端口只能拖出线，不能接受线
          const { type: targetType } = targetCell?.getData();
          if (targetType === NodeType.RETURN_WATER_PORT) {
            return false;
          }

          return true;
        },
      },
      highlighting: {
        magnetAdsorbed: {
          name: 'stroke',
          args: {
            attrs: {
              fill: '#57FB8B',
              stroke: '#57FB8B',
            },
          },
        },
      },
      interacting() {
        return true;
      },
    });

    const stencil = new Stencil({
      title: '设备',
      target: graph.current,
      stencilGraphWidth: 160,
      stencilGraphHeight: 0,
      stencilGraphPadding: 0,

      layoutOptions: {
        columns: 1,
        columnWidth: 140,
        rowHeight: 56,
      },
    });

    //将创建的实例添加到容器中
    refStencil.current?.appendChild(stencil.container);

    // 创建节点
    const nodeList = BaseNodeList.map(k => {
      return graph.current?.createNode({
        shape: 'custom-node',
        data: {
          name: k.name,
          area: 'left',
          type: k.type,
        },
        ports: {
          ...ports,
          items:
            Number(k.type) === NodeType.CHILLER
              ? [...ports.items, { group: 'right' }, { group: 'right' }, { group: 'left' }, { group: 'left' }]
              : ports.items,
        },
      });
    });

    stencil.load(nodeList as Node<Node.Properties>[]);

    // 节点mouseenter
    graph.current?.on('node:mouseenter', ({ e, node: currentNode }) => {
      e.stopPropagation();

      currentNode.addTools([
        {
          name: 'button-remove',
          args: {
            x: '100%',
            onClick({ cell }: { cell: Cell }) {
              const { name, nodeId, type } = cell.getData();
              Modal.confirm({
                title: `是否删除${name}`,
                onOk() {
                  cell.remove();
                  removeNode(nodeId, type);
                },
              });
            },
          },
        },
      ]);
    });

    // 节点mouseleave
    graph.current?.on('node:mouseleave', ({ e, node }) => {
      e.stopPropagation();

      const nodes = graph.current?.getNodes();
      cancelCellHover(nodes!);
    });

    // 节点node:moved
    graph.current?.on('node:moved', ({ e, node }) => {
      e.stopPropagation();

      const nodes = graph.current?.getNodes();
      cancelCellHover(nodes!);
      saveGraph();
    });

    // 节点click
    graph.current?.on('node:click', ({ e, node }) => {
      e.stopPropagation();
      setOpenModal(true);
      setOperationType(OperationType.EDIT);
      setSelectedNode(node);
    });

    // 节点added
    graph.current?.on('node:added', ({ node }) => {
      setOpenModal(true);
      setOperationType(OperationType.ADD);
      setSelectedNode(node);
    });

    // 边连接
    graph.current?.on('edge:connected', ({ e, currentCell, edge }) => {
      e.stopPropagation();
      // 在做边连接时，上一个节点的leave和边的leave事件会出现在该事件之后，导致节点和边的hover效果被保存下来，所以在连接后要清空hover效果
      const source = edge.getSourceCell();
      cancelCellHover([source!, currentCell!]);
      saveGraph();
    });

    // 边removed
    graph.current?.on('edge:removed', () => {
      saveGraph();
    });

    // 边mouseenter
    graph.current.on('edge:mouseenter', ({ e, cell }) => {
      e.stopPropagation();
      cell.setZIndex(1);
      cell.addTools([
        {
          name: 'button-remove',
          args: {
            distance: 0.5,
            onClick({ cell }: { cell: Cell }) {
              Modal.confirm({
                title: '是否确认删除？',
                onOk() {
                  cell.remove();
                },
              });
            },
          },
        },
      ]);
    });

    // 边mouseleave
    graph.current?.on('edge:mouseleave', ({ e, cell }) => {
      e.stopPropagation();
      cell.setZIndex(0);
      cancelCellHover([cell]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInit) return;

    // 回显
    if (topoData?.topologyDiagram && graph.current) {
      const nodeJson = JSON.parse(topoData?.topologyDiagram);
      graph.current.fromJSON(nodeJson as any);
      const nodes = graph.current?.getNodes();
      nodes.forEach(node => {
        const prevData = node.getData();
        node.updateData({
          ...prevData,
          area: 'right',
          update: true,
        });
      });
      setNodeList(nodes);
      setPortDisplay();
      setIsInit(false);
    }
  }, [isInit, topoData]);

  const showPorts = (ports: NodeListOf<SVGElement>, show: boolean) => {
    for (let i = 0, len = ports.length; i < len; i += 1) {
      ports[i].style.visibility = show ? 'visible' : 'hidden';
    }
  };

  // 设置链接桩显隐
  const setPortDisplay = (key?: string) => {
    const isChilled =
      lineTypeRef.current === LineType.CHILLED_RETURN || lineTypeRef.current === LineType.CHILLED_SUPPLY;
    const currentKey = key ?? activeKey;
    const nodes = graph.current?.getNodes();
    console.log('888');
    nodes?.forEach(node => {
      const { type } = node.getData();
      // 是否隐藏
      const hidden =
        (isChilled && !chilledNodeList.some(k => k === type)) ||
        (!isChilled && !coolingdNodeList.some(k => k === type));

      // const selector = `[data-cell-id="${node.id}"].x6-node .x6-port-body`;
      // const ports = refContainer.current?.querySelectorAll(selector) as NodeListOf<SVGElement>;

      // 修复之前错误数据
      const nodePorts = node.getPorts();

      if (hidden || currentKey === '1') {
        // showPorts(ports, false);
        nodePorts.forEach(item => {
          node.setPortProp(item.id!, 'attrs/rect/magnet', false);
          node.setPortProp(item.id!, 'attrs/rect/style/visibility', 'hidden');
        });
        if (node.hasTool('boundary')) {
          node.removeTool('boundary');
        }
      } else {
        // showPorts(ports, true);
        nodePorts.forEach(item => {
          node.setPortProp(item.id!, 'attrs/rect/magnet', true);
          node.setPortProp(item.id!, 'attrs/rect/style/visibility', 'visible');
        });
        if (!node.hasTool('boundary')) {
          node.addTools([
            {
              name: 'boundary',
              args: {
                padding: 0,
                attrs: {
                  stroke: '#57fb8b',
                  'stroke-dasharray': '0',
                  'stroke-width': 1,
                },
              },
            },
          ]);
        }
      }
    });
  };

  const cancelCellHover = (cells: Cell[]) => {
    cells.forEach(cell => {
      if (cell.hasTool('button-remove')) {
        cell.removeTool('button-remove');
      }
    });
  };

  // 删除节点
  const removeNode = (nodeId: number, type: number) => {
    apiV2HvacNodeDeletePost({ nodeId }).then(() => {
      message.success('删除成功');
      saveGraph();
    });
  };

  const saveGraph = (values?: Record<string, any>, isUpdateNodeData?: boolean) => {
    const nodes = graph.current?.getNodes();
    const edges = graph.current?.getEdges();
    const directionList = edges?.map(edge => {
      const selfId = edge.getSourceCell()?.getData().nodeId;
      const targetId = edge.getSourceCell()?.getData().nodeId;
      return { selfId, targetId };
    });

    const graphJson = graph.current?.toJSON();

    // 只有拓扑无数据时第一次保存画布调用新增接口
    apiV2HvacTopologySavePost({
      id: stationId,
      topologyDiagram: nodes?.length ? JSON.stringify(graphJson) : undefined,
      directionList,
    }).then((res: any) => {
      updateFn();
      if (isUpdateNodeData) {
        setNodeList(nodes!);
        setOpenModal(false);
      }
    });
  };

  const saveNodeData = async (values: Record<string, any>) => {
    if (isNil(selectedNode)) return;
    const { type } = selectedNode.getData();
    selectedNode.updateData({
      name: values.name,
      nodeId: values.nodeId,
      update: true,
      area: 'right',
    });
    selectedNode.resize(...nodeSize[type]);
    setPortDisplay();

    saveGraph(values, true);
  };

  const onCancel = () => {
    const { update } = selectedNode?.getData();
    // 新增时取消不会有update
    if (!update) {
      graph.current?.removeNode(selectedNode?.id!);
    }
    setOpenModal(false);
    setSelectedNode(undefined);
  };

  const items: MXTabsProps['items'] = [
    {
      key: '1',
      label: '节点',
      children: <div className={styles.stencil} ref={dom => (refStencil.current = dom!)}></div>,
    },
    {
      key: '2',
      label: '管道',
      children: (
        <div>
          {Object.entries(LineTypeDisplay).map(([k, v]) => {
            const lineItem = Number(k) as LineType;
            return (
              <div
                key={v}
                className={`${styles.pipeItem} ${Number(lineType) === lineItem && styles.selected}`}
                onClick={() => {
                  setLineType(lineItem);
                  lineTypeRef.current = lineItem;
                  setPortDisplay();
                }}
              >
                <div className={styles.line} style={{ background: LineTypeStyle[lineItem] }}></div>
                <div>{LineTypeDisplay[lineItem]}</div>
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
      <FormTitle
        title="编辑站内拓扑"
        extraContent={
          <div className={styles.extraContent}>
            <div className={styles.ggsTitleInfo}>
              <label>名称：</label>
              {stationName ?? '--'}
            </div>
            <Button onClick={() => navigate(-1)}>返回</Button>
          </div>
        }
      />
      <ResizeObserver
        onResize={({ width, height }) => {
          graph.current?.resize(width - 196, height);
        }}
      >
        <div className={styles.container}>
          <Tabs
            activeKey={activeKey}
            items={items}
            className={styles.leftTab}
            onChange={val => {
              setActiveKey(val);
              setPortDisplay(val);
            }}
          />
          <div className={styles.graphBox}>
            <div className={styles.graph} ref={d => (refContainer.current = d!)}></div>
            {nodeList.length ? null : (
              <div className={styles.noMap}>
                <img
                  className={styles.img}
                  src={theme === Themes.DARK ? gragProcessIcon : grapProcessLightIcon}
                  alt=""
                />
                <div className={styles.desc}>请从左侧拖入设备开始创建拓扑</div>
              </div>
            )}
          </div>
        </div>
      </ResizeObserver>
      {openModal && (
        <NewNode
          operationType={operationType}
          stationId={stationId}
          selectedNode={selectedNode}
          onCancel={onCancel}
          onOk={saveNodeData}
        />
      )}
    </Wrapper>
  );
};

export default HVACTopology;
