import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Cascader, Col, Row, Space, Modal as AntModal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'rc-cascader/lib';
import { useNavigate } from 'react-router-dom';
import {
  useBreadcrumbRoutes,
  Wrapper,
  Button,
  Input,
  Modal,
  Radio,
  Select,
  Form,
  useAsync,
  FormTitle,
  SubContent,
} from '@maxtropy/components';
import { formatOptionData } from '@/shared/components/CascadingMultipleSelector/utils';
import { formatValueFn } from '@/shared/components/FieldItem/util';
import { getBatchDeviceResult } from '@/shared/api/edgeDevice';
import { AttributeTemplate } from '../../../api/template';
import { createBatchDeviceAttribute, updateBatchDeviceAttribute } from '../../../api/batch';
import ErrorMsgModal, { ErrorMsg } from './ErrorMsgModal';
import SelectDevices from './SelectDevices';
import SelectTemplate from './SelectTemplate';
import SelectTemplateVersion from './SelectTemplateVersion';
import AttributeForm, { AttributeFormRef } from './AttributeForm';
import styles from './index.module.scss';
import { getPhysicalModelList, PhysicalModelRes } from '@/api/device';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { getDeviceTypeTree } from '@/api/deviceType';

const routes = [{ name: '批量配置固定属性' }];

export enum ActionType {
  ADD = 0,
  EDIT = 1,
}

const BatchAttribute: React.FC = () => {
  const [form] = Form.useForm();
  const [invalidForm] = Form.useForm();
  const navigate = useNavigate();
  const attributeFormRef: MutableRefObject<AttributeFormRef | null> = useRef(null);

  // const [rootMcid, setRootMcid] = useState<string>();
  const [deviceType, setDeviceType] = useState<Array<number> | undefined>();
  const [deviceTypeName, setDeviceTypeName] = useState<string>();
  const [actionType, setActionType] = useState<ActionType>(ActionType.ADD);
  const [template, setTemplate] = useState<AttributeTemplate>();
  const [templateVersion, setTemplateVersion] = useState<number>();
  const [errorMsg, setErrorMsg] = useState<ErrorMsg[]>();
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [modalApi, modalContextHolder] = AntModal.useModal();

  const deviceTypeData = useAsync(getDeviceTypeTree);
  const physicalModelId = Form.useWatch('physicalModelId', form);

  const makeLoadingModel = () =>
    modalApi.info({
      className: 'loading-modal',
      title: '正在执行',
      content: (
        <div className="loading-modal-content">
          <div className="loading-modal-icon">
            <LoadingOutlined />
          </div>
          <div className="loading-modal-txt">正在执行中</div>
        </div>
      ),
    });

  useEffect(() => {
    form.setFieldsValue({
      template: undefined,
      version: undefined,
      deviceIdList: undefined,
    });
    attributeFormRef.current?.form?.resetFields();
    setTemplate(undefined);
  }, [deviceType, actionType, form]);

  useEffect(() => {
    form.setFieldsValue({
      version: template,
      deviceIdList: undefined,
    });
    attributeFormRef.current?.form?.resetFields();
    setTemplateVersion(template?.version);
  }, [template, form]);

  useEffect(() => {
    form.setFieldsValue({
      deviceIdList: undefined,
    });
    attributeFormRef.current?.form?.resetFields();
  }, [templateVersion, form]);

  const onDeviceTypeSelect = (val: Array<string | number | null>, selectedOptions: DefaultOptionType[]) => {
    setDeviceType(val as number[]);
    const _deviceTypeName = selectedOptions?.map(item => item.label).join('/');
    setDeviceTypeName(_deviceTypeName);
    form.setFieldsValue({
      physicalModelId: undefined,
    });
  };

  const onTemplateSelect = (val?: AttributeTemplate) => {
    setTemplate(val);
  };

  const confirmSubmit = () => {
    Promise.all([form.validateFields(), attributeFormRef.current?.form?.validateFields()]).then(() => {
      Modal.confirm({
        okText: '确定',
        icon: null,
        className: `${styles.passwordConfirmModal}`,
        content: (
          <Form form={invalidForm} isOrigin={true} layout="horizontal">
            <Form.Item label="请输入操作密码" name="password" rules={[{ required: true, message: '请输入操作密码' }]}>
              <Input.Password autoComplete="new-password" placeholder="请输入操作密码" />
            </Form.Item>
          </Form>
        ),
        onOk: () => {
          return invalidForm.validateFields().then(value => onSubmit(value.password));
        },
        afterClose: () => {
          invalidForm.resetFields();
        },
      });
    });
  };

  const handleError = (error: any) => {
    const { errorMessage, status } = error?.cause;
    if (status === 410) {
      const msg = JSON.parse(errorMessage);
      setErrorMsg(msg);
    } else {
      Modal.error({
        title: errorMessage,
        okText: '确定',
      });
    }
  };

  const getFormValues = (password: number) => {
    const { deviceIdList } = form.getFieldsValue();
    const submitValues = {
      password,
      deviceIds: deviceIdList,
      templateId: template?.id,
      templateVersion,
    };
    const values = attributeFormRef.current?.form?.getFieldsValue();
    const templateFlatten = attributeFormRef.current?.templateFlatten;
    const checkedIds = attributeFormRef.current?.checkedIds;
    if (values && templateFlatten) {
      const list = Object.entries(values).map(([fieldId, value]) => {
        const findTemplate = templateFlatten.find(i => i.id === Number(fieldId));
        const formatValue = formatValueFn(value.value, findTemplate);
        return {
          fieldId: Number(fieldId),
          unitId: value.unitId,
          value: formatValue,
        };
      });
      return {
        ...submitValues,
        list: actionType === ActionType.ADD ? list : list.filter(item => checkedIds?.includes(item.fieldId)),
      };
    }
  };

  // 定时请求
  const fetchBatchDeviceResult = (res: { key: string }) =>
    new Promise(resolve => {
      const poll = async () => {
        try {
          const response = await getBatchDeviceResult(res.key).onError(error => {
            Modal.error({
              title: error.cause.errorMessage ?? '未知错误！请联系管理员。',
            });
            throw error;
          });

          if (response.hasResult) {
            resolve(response.hasResult);
          } else {
            setTimeout(poll, 1000); // 继续轮询
          }
        } catch (e) {
          resolve(false);
        }
      };

      poll(); // 立即执行第一次请求
    });

  const onSubmit = async (password: number) => {
    const values = getFormValues(password);
    const modal = makeLoadingModel();
    if (values) {
      const promise =
        actionType === ActionType.ADD ? createBatchDeviceAttribute(values) : updateBatchDeviceAttribute(values);
      promise
        .onError(error => {
          modal.destroy();
          handleError(error);
          throw error;
        })
        .then(res => {
          fetchBatchDeviceResult(res).then(result => {
            modal.destroy();
            if (result) {
              Modal.success({
                title: '批操作成功',
                okText: '确定',
                onOk: () => navigate(-1),
              });
            }
          });
        });
    }
  };

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表
  useEffect(() => {
    if (deviceType && deviceType[2]) {
      // 物模型型号
      getPhysicalModelList({ deviceTypeIds: [deviceType[2]] }).then(res => setPhysicalModelList(res.list));
    }
  }, [deviceType]);
  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
  }, [physicalModelList]);

  return (
    <Wrapper className={styles.wrapper} routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]}>
      <FormTitle title="批量配置固定属性" />
      <SubContent>
        <Form form={form} layout="horizontal" onFinish={confirmSubmit}>
          <Row>
            <Col span={8}>
              <Form.Item name="deviceType" rules={[{ required: true, message: '请选择所属类目' }]} label="所属类目">
                <Cascader
                  options={formatOptionData(deviceTypeData)}
                  allowClear={false}
                  fieldNames={{ children: 'child' }}
                  onChange={onDeviceTypeSelect}
                  placeholder={'请选择所属类目'}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="physicalModelId" label="物模型型号">
                <Select
                  allowClear
                  optionFilterProp="label"
                  showSearch
                  options={objectModalTypeOptions}
                  disabled={!deviceType}
                  placeholder={deviceType ? '请选择' : '请先选择所属类目'}
                  onChange={() => {
                    setTemplate(undefined);
                    form.setFieldsValue({
                      template: undefined,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="type"
            rules={[{ required: true, message: '请选择批操作类型' }]}
            label="批操作类型"
            initialValue={ActionType.ADD}
          >
            <Radio.Group onChange={(e: RadioChangeEvent) => setActionType(e.target.value)}>
              <Radio value={ActionType.ADD}>新增固定属性模板</Radio>
              <Radio value={ActionType.EDIT}>修改模板中属性值</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="template" rules={[{ required: true, message: '请选择固定属性模板' }]} label="固定属性模板">
            <SelectTemplate
              physicalModelId={physicalModelId}
              deviceType={deviceType}
              actionType={actionType}
              onChange={onTemplateSelect}
            />
          </Form.Item>
          {template && (
            <Form.Item name="version" rules={[{ required: true, message: '请选择固定属性模板' }]} label="模板版本号">
              <SelectTemplateVersion
                deviceType={deviceType}
                templateId={template?.id}
                actionType={actionType}
                onChange={value => setTemplateVersion(value?.version)}
              />
            </Form.Item>
          )}
          <Form.Item name="deviceIdList" rules={[{ required: true, message: '请选择设备' }]} label="设备选择">
            <SelectDevices
              deviceType={deviceType}
              templateId={template?.id}
              physicalModelId={physicalModelId}
              templateVersion={templateVersion}
              actionType={actionType}
              deviceTypeName={deviceTypeName}
            />
          </Form.Item>
          {template && (
            <>
              <AttributeForm
                ref={attributeFormRef}
                templateId={template?.id}
                templateVersion={templateVersion}
                actionType={actionType}
              />
            </>
          )}
          <Space className={styles.footer} size={8}>
            <Button htmlType="submit" type={'primary'}>
              完成
            </Button>
            <Button onClick={() => navigate('/device/manage/device')}>取消</Button>
          </Space>
        </Form>
      </SubContent>

      <ErrorMsgModal visible={errorMsg !== undefined} onCancel={() => setErrorMsg(undefined)} errorMsg={errorMsg} />
      {modalContextHolder}
    </Wrapper>
  );
};

export default BatchAttribute;
