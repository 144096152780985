import {
  EnergyMediumTreeFormatType,
  MediumIndicatorDisplayUnit,
  ProcessEntryOrExitStatisticsRes,
} from '@/api/energyMedium';
import { Key } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import { StatisticsPartition } from './components/DateSwitch';

/**
 * 是否是有效的数字
 */
export const isNumber = (value: Key) => !isNaN(+value) && isFinite(+value);

// 校验图非空
export const notEmpty = (arr: any[]) => arr.filter(item => !isNil(item.value)).length > 0;

export const notEmptyForChart = (arr: any[]) => arr.some(i => i.id && notEmpty(i.data));

/**
 * 数组求和
 */
export const sum = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? numbers.reduce((acc, curr) => acc + curr, 0) : NaN;
};

/**
 * 过滤掉非有效数字
 */
export const filterNumber = (dataset: any): number[] =>
  Array.isArray(dataset)
    ? dataset
        .filter((value: any) => !isNil(value))
        .filter((value: Key) => isNumber(value))
        .map(item => +item)
    : [];

/**
 * 求数组中的最大值
 */
export const max = (set: any) => {
  const numbers = filterNumber(set);
  return Math.max(...numbers);
};

/**
 * 求数组中的最小值
 */
export const min = (set: any) => Math.min(...filterNumber(set));

/**
 * 数组求平均数
 */
export const average = (set: any) => {
  const numbers = filterNumber(set);
  return numbers.length ? divide(sum(numbers), numbers.length) : NaN;
};

const divide = (a: Key, b: Key) => validateNumbers((a, b) => (b !== 0 ? a / b : NaN), a, b);

/**
 * 判断参数是否为有效数字
 */
const validateNumbers = (fn: (...values: number[]) => number, ...args: Key[]) => {
  if (!args.every(arg => isNumber(arg))) return NaN;
  return fn(...args.map(item => +item));
};

/**
 * 获取默认时间
 */
export const getDefaultTime = (mode: StatisticsPartition, date: Dayjs, time?: string | null) => {
  switch (mode) {
    case StatisticsPartition.MINUTE_1:
    case StatisticsPartition.MINUTE_15:
      if (time) {
        return {
          from: dayjs(time, 'x').startOf('day').valueOf(),
          to: dayjs(time, 'x').endOf('day').valueOf(),
        };
      }
      return {
        from: dayjs(date, 'x').startOf('day').valueOf(),
        to: dayjs(date, 'x').endOf('day').valueOf(),
      };

    case StatisticsPartition.DAY:
      return {
        from: dayjs(date, 'x').subtract(30, 'days').startOf('day').valueOf(),
        to: dayjs(date, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.MONTH:
      return {
        from: dayjs(date, 'x').subtract(11, 'month').startOf('month').valueOf(),
        to: dayjs(date, 'x').endOf('month').valueOf(),
      };
    default:
      return {
        from: dayjs(date, 'x').startOf('day').valueOf(),
        to: dayjs(date, 'x').endOf('day').valueOf(),
      };
  }
};

export const getTs = (mode: StatisticsPartition, start: Dayjs, end: Dayjs) => {
  switch (mode) {
    case StatisticsPartition.MINUTE_15:
    case StatisticsPartition.DAY:
      return {
        from: dayjs(start, 'x').startOf('day').valueOf(),
        to: dayjs(end, 'x').endOf('day').valueOf(),
      };
    case StatisticsPartition.MONTH:
      return {
        from: dayjs(start, 'x').startOf('month').valueOf(),
        to: dayjs(end, 'x').endOf('month').valueOf(),
      };
    default:
      return {
        from: dayjs(start, 'x').startOf('day').valueOf(),
        to: dayjs(end, 'x').endOf('day').valueOf(),
      };
  }
};

export const formatProcessEntryOrExitCardData = (
  processStatistics?: ProcessEntryOrExitStatisticsRes[],
  type?: EnergyMediumTreeFormatType,
  mediumIndicatorDisplayUnitData?: MediumIndicatorDisplayUnit
) => {
  return (processStatistics ?? []).map(item => {
    return {
      indicatorId: item.indicatorId,
      energyMediumId: item.energyMediumId,
      energyMediumName: item.energyMediumName,
      cardName: item.energyMediumName,
      unit: mediumIndicatorDisplayUnitData?.find(i => i.energyMediumIndicatorId === item.indicatorId)
        ?.displayPhysicalUnitGeneralName,
      value: item.value,
      sceneId: item.sceneId,
      type,
    };
  });
};

export const numberToString = (num?: number) => {
  return typeof num === 'number' ? String(num) : undefined;
};
export const stringToNumber = (str?: string) => {
  return str ? Number(str) : undefined;
};

// 判断是否为输入输出节点内
export const isEntryExit = (type?: number) => {
  if (
    type === EnergyMediumTreeFormatType.ENTRY ||
    type === EnergyMediumTreeFormatType.EXIT ||
    type === EnergyMediumTreeFormatType.NODEINSIDE
  ) {
    return true;
  }
  return false;
};
