import { DataAORIType, DataQuery, StatisticsPartition } from '@/api/energyMediaComparison';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { DataTypeAOrIArr, max, min } from '../../utils';
import { V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse } from '@maxtropy/device-customer-apis-v2';
import { MediumIndicatorDisplayUnit } from '@/api/energyMedium';

export interface ChartOptionProps {
  query: DataQuery;
}

interface LegendProps {
  show: boolean;
  findOriginName: string;
  findComparedName: string;
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'];
}

const xAxis = {
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};

// 设置基本配置项
export const getBasicChartOption = ({ query }: ChartOptionProps) => {
  const colors = ['#1890ff', '#4fc7ec'];
  return {
    backgroundColor: window.getComputedStyle(document.documentElement).getPropertyValue('--component-background'),
    grid: {
      left: 80,
      right: 80,
      top: 95,
      bottom: 80,
    },
    dataZoom: [
      {
        type: 'slider',
        bottom: 15,
        height: 20,
        backgroundColor: 'rgba(226,227,229,0.5)',
        fillerColor: 'rgba(142,177,224,0.3)',
        textStyle: {
          fontSize: 10,
        },
        // startValue: dayjs().startOf('d').format('MM-DD[\n]HH:mm'),
      },
    ],
    color: colors,
    xAxis: getNormalAxis(query),
  };
};

// Tooltip基础配置
export const basicTooltip = {
  trigger: 'axis',
  backgroundColor: 'rgba(0,0,0,0.8)',
  borderColor: 'transparent',
  textStyle: {
    color: '#fff',
  },
};

// Tooltip formatter封装
export const basicStr = (query: DataQuery, axisValue: any) => {
  let str: string;
  switch (query.timeResolution) {
    case StatisticsPartition.MINUTE_1:
    case StatisticsPartition.MINUTE_15:
      str = dayjs(axisValue, 'x').format('MM-DD[\n]HH:mm');
      break;
    case StatisticsPartition.DAY:
      str = dayjs(axisValue, 'x').format('MM-DD');
      break;
    default:
      str = dayjs(axisValue, 'x').format('YYYY-MM');
  }
  return str;
};

// YAxis封装 单Y轴
export const basicSingleYAxis = (originUnit: string) => {
  return {
    type: 'value',
    name: originUnit,
    nameTextStyle: {
      fontSize: 14,
      align: 'right',
    },
    nameGap: 30,
    splitLine: {
      lineStyle: {
        color: '#FFFFFF30',
      },
    },
  };
};

// YAxis封装 双Y轴
export const basicDoubleYAxis = (
  originUnit: string,
  comparedUnit: string,
  originChartmaxValue: number,
  comparedChartmaxValue: number,
  originChartminValue: number,
  comparedChartminValue: number
) => {
  const originChartmaxValueToOne = Number(originChartmaxValue.toFixed(2));
  const comparedChartmaxValueToOne = Number(comparedChartmaxValue.toFixed(2));

  return [
    {
      type: 'value',
      name: originUnit,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
      max: originChartmaxValueToOne,
      min: 0,
      splitNumber: 5,
      interval: Number((originChartmaxValueToOne / 5).toFixed(3)),
    },
    {
      type: 'value',
      name: comparedUnit,
      nameTextStyle: {
        fontSize: 14,
        align: 'right',
      },
      nameGap: 30,
      splitLine: {
        lineStyle: {
          color: '#FFFFFF30',
        },
      },
      max: comparedChartmaxValueToOne,
      min: 0,
      splitNumber: 5,
      interval: Number((comparedChartmaxValueToOne / 5).toFixed(3)),
    },
  ];
};

export const getNormalAxis = (query: DataQuery) => {
  switch (query.timeResolution) {
    case StatisticsPartition.MINUTE_1:
    case StatisticsPartition.MINUTE_15:
      return rangeCatXAxis('Hour');
    case StatisticsPartition.DAY:
      return rangeCatXAxis('Day');
    case StatisticsPartition.MONTH:
      return rangeCatXAxis('Month');
    default:
      return {};
  }
};

export const rangeCatXAxis = (key: string) => {
  return [
    {
      ...xAxis,
      type: 'time',
      minInterval: key === 'Hour' ? 60 * 1000 : 60 * 1000 * 60 * 24,
      axisLabel: {
        formatter: function (e: number) {
          if (key === 'Hour') {
            return dayjs(e, 'x').format('MM-DD[\n]HH:mm');
          } else if (key === 'Day') {
            return dayjs(e, 'x').format('MM-DD');
          } else {
            return dayjs(e, 'x').format('YYYY-MM');
          }
        },
      },
    },
  ];
};

// 获取图例
export const getLegend = ({ show, findOriginName, findComparedName, chartData }: LegendProps) => {
  return {
    show,
    right: 20,
    top: 10,
    textStyle: {
      color: '#AFBCC4',
    },
    data: [
      {
        name: `${chartData?.[0].entryExitName}--${findOriginName}`,
        icon: 'rect',
      },
      {
        name: `${chartData?.[1].entryExitName}--${findComparedName}`,
        icon: 'rect',
      },
    ],
  };
};

// 判断是否为相同指标，生成serious
const isSameIndicator = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  query: DataQuery,
  originUnit: string,
  comparedUnit: string
) => {
  // 指标id相同
  if (chartData?.[0].indicatorId === chartData?.[1].indicatorId) {
    if (
      DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.indicatorType ===
        DataAORIType.INSTANTANEOUS &&
      query.timeResolution === StatisticsPartition.DAY
    ) {
      // 同指标 瞬时量，按日，双箱型图
      return instantaneousSameIndicatorDayBox(chartData, originUnit, query);
    } else if (
      DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.indicatorType ===
        DataAORIType.INSTANTANEOUS &&
      query.timeResolution !== StatisticsPartition.DAY
    ) {
      // 同指标 瞬时量 按1min或15min 双折现图
      return instantaneousSameIndicatorNotDayLine(chartData, originUnit, query);
    } else {
      // 同指标 累积量 任意颗粒度 双柱形图
      return accumulateSameIndicatorBar(chartData, originUnit, query);
    }
  } else {
    // 指标id不同,判断累积量还是瞬时量
    const isOriginAccOrIns = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.indicatorType;
    const isComparedAccOrIns = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[1].indicatorId)?.indicatorType;
    if (isOriginAccOrIns === DataAORIType.ACCUMULATE && isComparedAccOrIns === DataAORIType.ACCUMULATE) {
      // 同为累积量 双Y轴 双柱形图
      return accumulateDifferentIndicatorBar(chartData, originUnit, comparedUnit, query);
    } else if (isOriginAccOrIns === DataAORIType.INSTANTANEOUS && isComparedAccOrIns === DataAORIType.INSTANTANEOUS) {
      if (query.timeResolution === StatisticsPartition.DAY) {
        // 同为瞬时量 按日 双Y轴 双箱型图
        return instantaneousDifferentIndicatorDayBox(chartData, originUnit, comparedUnit, query);
      } else {
        // 同为瞬时量 按1min或15min 双Y轴 双箱型图
        return instantaneousDifferentIndicatorNotDayBox(chartData, originUnit, comparedUnit, query);
      }
    } else {
      // 一个瞬时一个累积
      if (query.timeResolution === StatisticsPartition.DAY) {
        // 按日
        return accumulateAndInstantaneousDifferentIndicatorDayBox(chartData, originUnit, comparedUnit, query);
      } else {
        // 不按日
        return accumulateAndInstantaneousDifferentIndicatorNotDayBox(chartData, originUnit, comparedUnit, query);
      }
    }
  }
};

export const getChartOption = (
  query?: DataQuery,
  chartData?: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  mediumIndicatorDisplayUnitData?: MediumIndicatorDisplayUnit,
  mediumIndicatorDisplayUnitSecondData?: MediumIndicatorDisplayUnit
) => {
  if (chartData && mediumIndicatorDisplayUnitData && query) {
    let originUnit: string | undefined;
    let comparedUnit: string | undefined;
    if (chartData[0].indicatorId === chartData[1].indicatorId) {
      // 通过指标id查找单位
      originUnit = mediumIndicatorDisplayUnitData.find(
        i => i.energyMediumIndicatorId === chartData[0].indicatorId
      )?.displayPhysicalUnitGeneralName;
      comparedUnit = mediumIndicatorDisplayUnitData.find(
        i => i.energyMediumIndicatorId === chartData[1].indicatorId
      )?.displayPhysicalUnitGeneralName;
    } else {
      if (mediumIndicatorDisplayUnitSecondData) {
        // 通过指标id查找单位
        originUnit = mediumIndicatorDisplayUnitData.find(
          i => i.energyMediumIndicatorId === chartData[0].indicatorId
        )?.displayPhysicalUnitGeneralName;
        comparedUnit = mediumIndicatorDisplayUnitSecondData.find(
          i => i.energyMediumIndicatorId === chartData[1].indicatorId
        )?.displayPhysicalUnitGeneralName;
      }
    }
    if (originUnit && comparedUnit) {
      // 获取基本配置项
      const option = getBasicChartOption({
        query,
      });
      // 通过id获取指标名称
      const findOriginName = DataTypeAOrIArr.find(item => item.indicatorId === chartData[0].indicatorId)!.name;
      const findComparedName = DataTypeAOrIArr.find(item => item.indicatorId === chartData[1].indicatorId)!.name;
      // 判断是否为相同指标or不同指标
      return {
        ...option,
        legend: getLegend({
          show: true,
          findOriginName,
          findComparedName,
          chartData,
        }),
        ...isSameIndicator(chartData, query, originUnit, comparedUnit),
      };
    }
  }
};

// 同指标 瞬时量，按日，双箱型图
const instantaneousSameIndicatorDayBox = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  originUnit: string,
  query: DataQuery
) => {
  const name = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.name;
  const originTotalData = chartData?.[0].data?.map(item => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);
  const comparedTotalData = chartData?.[1].data?.map(item => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);
  const color = ['#59DBFF', '#FFCB47'];
  return {
    tooltip: {
      ...basicTooltip,
      formatter(items: any) {
        const { axisValue } = items[0];
        // 根据选择的颗粒度，展示不同的tooltip
        let str: string;
        str = basicStr(query, axisValue);
        items.forEach((item: any, index: number) => {
          const { seriesName, data, marker } = item;
          const valueMax = !isNil(data[1]) ? `${data[1]}${originUnit}` : '--';
          const valueAvg = !isNil(data[2]) ? `${data[2]}${originUnit}` : '--';
          const valueMin = !isNil(data[3]) ? `${data[3]}${originUnit}` : '--';
          str += `<br> ${marker}${seriesName}`;
          str += `<br> 最大: ${valueMax}`;
          str += `<br> 均值: ${valueAvg}`;
          str += `<br> 最小: ${valueMin}`;
        });
        return str;
      },
    },
    yAxis: basicSingleYAxis(originUnit),
    series: [
      {
        name: `${chartData?.[0].entryExitName}--${name}`,
        type: 'custom',
        color: color[0],
        dimensions: ['-', `最大${name}`, `平均${name}`, `最小${name}`],
        itemStyle: {
          borderWidth: 1.5,
        },
        renderItem: function (params: any, api: any) {
          var xValue = api?.value(0);
          var highPoint = api?.coord([xValue, api.value(1)]);
          var avgPoint = api?.coord([xValue, api.value(2)]);
          var lowPoint = api?.coord([xValue, api.value(3)]);
          // var halfWidth = api?.size([0, 1])[1] / 2;
          var halfWidth = 8;
          var style = api?.style({
            stroke: api?.visual('color'),
            fill: undefined,
          });
          return {
            type: 'group',
            children: [
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] - halfWidth,
                  y1: highPoint[1],
                  x2: highPoint[0] + halfWidth,
                  y2: highPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0],
                  y1: highPoint[1],
                  x2: lowPoint[0],
                  y2: lowPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: avgPoint[0] - halfWidth,
                  y1: avgPoint[1],
                  x2: avgPoint[0] + halfWidth,
                  y2: avgPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: lowPoint[0] - halfWidth,
                  y1: lowPoint[1],
                  x2: lowPoint[0] + halfWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
            ],
          };
        },
        encode: {
          x: 0,
          y: [1, 2, 3],
          tooltip: [1, 2, 3],
        },
        data: originTotalData,
      },
      {
        name: `${chartData?.[1].entryExitName}--${name}`,
        type: 'custom',
        color: color[1],
        dimensions: ['-', `最大${name}`, `平均${name}`, `最小${name}`],
        itemStyle: {
          borderWidth: 1.5,
        },
        renderItem: function (params: any, api: any) {
          var xValue = api?.value(0);
          var highPoint = api?.coord([xValue, api.value(1)]);
          var avgPoint = api?.coord([xValue, api.value(2)]);
          var lowPoint = api?.coord([xValue, api.value(3)]);
          // var halfWidth = api?.size([0, 1])[1] / 2;
          var halfWidth = 8;
          var style = api?.style({
            stroke: api?.visual('color'),
            fill: undefined,
          });
          return {
            type: 'group',
            children: [
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] - halfWidth,
                  y1: highPoint[1],
                  x2: highPoint[0] + halfWidth,
                  y2: highPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0],
                  y1: highPoint[1],
                  x2: lowPoint[0],
                  y2: lowPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: avgPoint[0] - halfWidth,
                  y1: avgPoint[1],
                  x2: avgPoint[0] + halfWidth,
                  y2: avgPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: lowPoint[0] - halfWidth,
                  y1: lowPoint[1],
                  x2: lowPoint[0] + halfWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
            ],
          };
        },
        encode: {
          x: 0,
          y: [1, 2, 3],
          tooltip: [1, 2, 3],
        },
        data: comparedTotalData,
      },
    ],
  };
};

// 同指标 瞬时量 按1min或15min 双折线图
const instantaneousSameIndicatorNotDayLine = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  originUnit: string,
  query: DataQuery
) => {
  const name = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.name;
  const color = ['#59DBFF', '#FFCB47'];
  return {
    tooltip: {
      ...basicTooltip,
      formatter(items: any) {
        const { axisValue } = items[0];
        // 根据选择的颗粒度，展示不同的tooltip
        let str: string;
        str = basicStr(query, axisValue);
        items.forEach((item: any, index: number) => {
          const { seriesName, data, marker } = item;
          const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${originUnit}` : '--';
          str += `<br> ${marker}${seriesName}: ${value}`;
        });
        return str;
      },
    },
    yAxis: basicSingleYAxis(originUnit),
    series: [
      {
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: color[0],
        name: `${chartData?.[0].entryExitName}--${name}`,
        data: chartData?.[0].data?.map(a => {
          return [a.ts, a.avg];
        }),
      },
      {
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: color[1],
        name: `${chartData?.[1].entryExitName}--${name}`,
        data: chartData?.[1].data?.map(a => {
          return [a.ts, a.avg];
        }),
      },
    ],
  };
};

// 同指标 累积量 任意颗粒度 双柱形图
const accumulateSameIndicatorBar = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  originUnit: string,
  query: DataQuery
) => {
  const name = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.name;
  const color = ['#59DBFF', '#FFCB47'];
  return {
    tooltip: {
      ...basicTooltip,
      formatter(items: any) {
        const { axisValue } = items[0];
        // 根据选择的颗粒度，展示不同的tooltip
        let str: string;
        str = basicStr(query, axisValue);
        items.forEach((item: any, index: number) => {
          const { seriesName, data, marker } = item;
          const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${originUnit}` : '--';
          str += `<br> ${marker}${seriesName}: ${value}`;
        });
        return str;
      },
    },
    legend: {
      top: 20,
      right: 110,
    },
    toolbox: {
      width: 200,
      height: 100,
      top: 16,
      right: 40,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
      xAxisIndex: 0,
    },
    yAxis: basicSingleYAxis(originUnit),
    series: [
      {
        type: 'bar',
        color: color[0],
        timeResolution: query.timeResolution,
        unit: originUnit,
        name: `${chartData?.[0].entryExitName}--${name}`,
        data: chartData?.[0].data?.map(a => {
          return [a.ts, a.sum];
        }),
      },
      {
        type: 'bar',
        color: color[1],
        timeResolution: query.timeResolution,
        unit: originUnit,
        name: `${chartData?.[1].entryExitName}--${name}`,
        data: chartData?.[1].data?.map(a => {
          return [a.ts, a.sum];
        }),
      },
    ],
  };
};

// 不同指标 累积量 任意颗粒度 双Y轴 柱形图
const accumulateDifferentIndicatorBar = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  originUnit: string,
  comparedUnit: string,
  query: DataQuery
) => {
  const originName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.name;
  const comparedName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[1].indicatorId)?.name;
  const color = ['#59DBFF', '#FFCB47'];
  const units = [originUnit, comparedUnit];
  const originChartData = chartData?.[0].data?.map(i => i.sum);
  const comparedChartData = chartData?.[1].data?.map(i => i.sum);
  const originChartmaxValue = max(originChartData);
  const comparedChartmaxValue = max(comparedChartData);
  const originChartminValue = min(originChartData);
  const comparedChartminValue = min(comparedChartData);
  return {
    tooltip: {
      ...basicTooltip,
      formatter(items: any) {
        const { axisValue } = items[0];
        // 根据选择的颗粒度，展示不同的tooltip
        let str: string;
        str = basicStr(query, axisValue);
        items.forEach((item: any, index: number) => {
          const { seriesName, data, marker } = item;
          const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${units[index]}` : '--';
          str += `<br> ${marker}${seriesName}: ${value}`;
        });
        return str;
      },
    },
    yAxis: basicDoubleYAxis(
      originUnit,
      comparedUnit,
      originChartmaxValue,
      comparedChartmaxValue,
      originChartminValue,
      comparedChartminValue
    ),
    legend: {
      top: 20,
      right: 110,
    },
    toolbox: {
      width: 200,
      height: 100,
      top: 16,
      right: 40,
      itemSize: 20, // 设置图标大小
      feature: {
        brush: {
          type: ['lineX', 'clear'], // 选择工具类型
          title: {
            lineX: '横向选择',
            clear: '清除',
          },
        },
      },
    },
    brush: {
      toolbox: ['lineX', 'clear'],
      xAxisIndex: 0,
    },
    series: [
      {
        type: 'bar',
        color: color[0],
        timeResolution: query.timeResolution,
        unit: originUnit,
        yAxisIndex: 0,
        name: `${chartData?.[0].entryExitName}--${originName}`,
        data: chartData?.[0].data?.map(a => {
          return [a.ts, a.sum];
        }),
      },
      {
        type: 'bar',
        color: color[1],
        yAxisIndex: 1,
        timeResolution: query.timeResolution,
        unit: comparedUnit,
        name: `${chartData?.[1].entryExitName}--${comparedName}`,
        data: chartData?.[1].data?.map(a => {
          return [a.ts, a.sum];
        }),
      },
    ],
  };
};

// 不同指标 瞬时量 按日 双Y轴 箱型图
const instantaneousDifferentIndicatorDayBox = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  originUnit: string,
  comparedUnit: string,
  query: DataQuery
) => {
  const originName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.name;
  const comparedName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[1].indicatorId)?.name;
  const units = [originUnit, comparedUnit];

  const originMaxChartData = chartData?.[0].data?.map(i => i.max);
  const originMinChartData = chartData?.[0].data?.map(i => i.min);
  const comparedMaxChartData = chartData?.[1].data?.map(i => i.max);
  const comparedMinChartData = chartData?.[1].data?.map(i => i.min);
  const originChartmaxValue = max(originMaxChartData);
  const comparedChartmaxValue = max(comparedMaxChartData);
  const originChartminValue = min(originMinChartData);
  const comparedChartminValue = min(comparedMinChartData);

  const originTotalData = chartData?.[0].data?.map(item => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);
  const comparedTotalData = chartData?.[1].data?.map(item => [
    item.ts,
    item.max?.toFixed(2),
    item.avg?.toFixed(2),
    item.min?.toFixed(2),
  ]);
  const color = ['#59DBFF', '#FFCB47'];
  return {
    tooltip: {
      ...basicTooltip,
      formatter(items: any) {
        const { axisValue } = items[0];
        // 根据选择的颗粒度，展示不同的tooltip
        let str: string;
        str = basicStr(query, axisValue);
        items.forEach((item: any, index: number) => {
          const { seriesName, data, marker } = item;
          const valueMax = !isNil(data[1]) ? `${data[1]}${units[index]}` : '--';
          const valueAvg = !isNil(data[2]) ? `${data[2]}${units[index]}` : '--';
          const valueMin = !isNil(data[3]) ? `${data[3]}${units[index]}` : '--';
          str += `<br> ${marker}${seriesName}`;
          str += `<br> 最大: ${valueMax}`;
          str += `<br> 均值: ${valueAvg}`;
          str += `<br> 最小: ${valueMin}`;
        });
        return str;
      },
    },
    yAxis: basicDoubleYAxis(
      originUnit,
      comparedUnit,
      originChartmaxValue,
      comparedChartmaxValue,
      originChartminValue,
      comparedChartminValue
    ),
    series: [
      {
        name: `${chartData?.[0].entryExitName}--${originName}`,
        type: 'custom',
        color: color[0],
        yAxisIndex: 0,
        dimensions: ['-', `最大${originName}`, `平均${originName}`, `最小${originName}`],
        itemStyle: {
          borderWidth: 1.5,
        },
        renderItem: function (params: any, api: any) {
          var xValue = api?.value(0);
          var highPoint = api?.coord([xValue, api.value(1)]);
          var avgPoint = api?.coord([xValue, api.value(2)]);
          var lowPoint = api?.coord([xValue, api.value(3)]);
          // var halfWidth = api?.size([0, 1])[1] / 2;
          var offsetWidth = 8;
          var halfWidth = 8;
          var style = api?.style({
            stroke: api?.visual('color'),
            fill: undefined,
          });
          return {
            type: 'group',
            children: [
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] - halfWidth + offsetWidth,
                  y1: highPoint[1],
                  x2: highPoint[0] + halfWidth + offsetWidth,
                  y2: highPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] + offsetWidth,
                  y1: highPoint[1],
                  x2: lowPoint[0] + offsetWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: avgPoint[0] - halfWidth + offsetWidth,
                  y1: avgPoint[1],
                  x2: avgPoint[0] + halfWidth + offsetWidth,
                  y2: avgPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: lowPoint[0] - halfWidth + offsetWidth,
                  y1: lowPoint[1],
                  x2: lowPoint[0] + halfWidth + offsetWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
            ],
          };
        },
        encode: {
          x: 0,
          y: [1, 2, 3],
          tooltip: [1, 2, 3],
        },
        data: originTotalData,
      },
      {
        name: `${chartData?.[1].entryExitName}--${comparedName}`,
        type: 'custom',
        color: color[1],
        yAxisIndex: 1,
        dimensions: ['-', `最大${comparedName}`, `平均${comparedName}`, `最小${comparedName}`],
        itemStyle: {
          borderWidth: 1.5,
        },
        renderItem: function (params: any, api: any) {
          var xValue = api?.value(0);
          var highPoint = api?.coord([xValue, api.value(1)]);
          var avgPoint = api?.coord([xValue, api.value(2)]);
          var lowPoint = api?.coord([xValue, api.value(3)]);
          // var halfWidth = api?.size([0, 1])[1] / 2;
          var halfWidth = 8;
          var offsetWidth = 8;
          var style = api?.style({
            stroke: api?.visual('color'),
            fill: undefined,
          });
          return {
            type: 'group',
            children: [
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] - halfWidth - offsetWidth,
                  y1: highPoint[1],
                  x2: highPoint[0] + halfWidth - offsetWidth,
                  y2: highPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] - offsetWidth,
                  y1: highPoint[1],
                  x2: lowPoint[0] - offsetWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: avgPoint[0] - halfWidth - offsetWidth,
                  y1: avgPoint[1],
                  x2: avgPoint[0] + halfWidth - offsetWidth,
                  y2: avgPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: lowPoint[0] - halfWidth - offsetWidth,
                  y1: lowPoint[1],
                  x2: lowPoint[0] + halfWidth - offsetWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
            ],
          };
        },
        encode: {
          x: 0,
          y: [1, 2, 3],
          tooltip: [1, 2, 3],
        },
        data: comparedTotalData,
      },
    ],
  };
};

// 不同指标 同为瞬时量 按1min或15min 双Y轴 双折线图
const instantaneousDifferentIndicatorNotDayBox = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  originUnit: string,
  comparedUnit: string,
  query: DataQuery
) => {
  const originName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.name;
  const comparedName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[1].indicatorId)?.name;
  const color = ['#59DBFF', '#FFCB47'];
  const units = [originUnit, comparedUnit];

  const originChartData = chartData?.[0].data?.map(i => i.avg);
  const comparedChartData = chartData?.[1].data?.map(i => i.avg);
  const originChartmaxValue = max(originChartData);
  const comparedChartmaxValue = max(comparedChartData);
  const originChartminValue = min(originChartData);
  const comparedChartminValue = min(comparedChartData);

  return {
    tooltip: {
      ...basicTooltip,
      formatter(items: any) {
        const { axisValue } = items[0];
        // 根据选择的颗粒度，展示不同的tooltip
        let str: string;
        str = basicStr(query, axisValue);
        items.forEach((item: any, index: number) => {
          const { seriesName, data, marker } = item;
          const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${units[index]}` : '--';
          str += `<br> ${marker}${seriesName}: ${value}`;
        });
        return str;
      },
    },
    yAxis: basicDoubleYAxis(
      originUnit,
      comparedUnit,
      originChartmaxValue,
      comparedChartmaxValue,
      originChartminValue,
      comparedChartminValue
    ),
    series: [
      {
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: color[0],
        yAxisIndex: 0,
        name: `${chartData?.[0].entryExitName}--${originName}`,
        data: chartData?.[0].data?.map(a => {
          return [a.ts, a.avg];
        }),
      },
      {
        type: 'line',
        smooth: true,
        lineStyle: { normal: { width: 2 } },
        color: color[1],
        yAxisIndex: 1,
        name: `${chartData?.[1].entryExitName}--${comparedName}`,
        data: chartData?.[1].data?.map(a => {
          return [a.ts, a.avg];
        }),
      },
    ],
  };
};

// 不同指标 一个瞬时一个累积量 按日 一箱型图一柱状图
const accumulateAndInstantaneousDifferentIndicatorDayBox = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  originUnit: string,
  comparedUnit: string,
  query: DataQuery
) => {
  const originName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.name;
  const comparedName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[1].indicatorId)?.name;
  const originType = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.indicatorType;
  const units = [originUnit, comparedUnit];

  if (originType === DataAORIType.INSTANTANEOUS) {
    // 如果第一个为瞬时量(箱型图)，那被对比的为累积量
    const originTotalData = chartData?.[0].data?.map(item => [
      item.ts,
      item.max?.toFixed(2),
      item.avg?.toFixed(2),
      item.min?.toFixed(2),
    ]);
    const color = ['#FFCB47', '#2A6948'];

    const originMaxChartData = chartData?.[0].data?.map(i => i.max);
    const originMinChartData = chartData?.[0].data?.map(i => i.min);
    const comparedChartData = chartData?.[1].data?.map(i => i.sum);
    const originChartmaxValue = max(originMaxChartData);
    const comparedChartmaxValue = max(comparedChartData);
    const originChartminValue = min(originMinChartData);
    const comparedChartminValue = min(comparedChartData);

    return {
      tooltip: {
        ...basicTooltip,
        formatter(items: any) {
          const { axisValue } = items[0];
          // 根据选择的颗粒度，展示不同的tooltip
          let str: string;
          str = basicStr(query, axisValue);
          items.forEach((item: any, index: number) => {
            if (index === 0) {
              // 箱型图
              const { seriesName, data, marker } = item;
              const valueMax = !isNil(data[1]) ? `${data[1]}${units[index]}` : '--';
              const valueAvg = !isNil(data[2]) ? `${data[2]}${units[index]}` : '--';
              const valueMin = !isNil(data[3]) ? `${data[3]}${units[index]}` : '--';
              str += `<br> ${marker}${seriesName}`;
              str += `<br> 最大: ${valueMax}`;
              str += `<br> 均值: ${valueAvg}`;
              str += `<br> 最小: ${valueMin}`;
            } else {
              // 柱形图
              const { seriesName, data, marker } = item;
              const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${units[index]}` : '--';
              str += `<br> ${marker}${seriesName}: ${value}`;
            }
          });
          return str;
        },
      },
      yAxis: basicDoubleYAxis(
        originUnit,
        comparedUnit,
        originChartmaxValue,
        comparedChartmaxValue,
        originChartminValue,
        comparedChartminValue
      ),
      legend: {
        top: 20,
        right: 110,
      },
      toolbox: {
        width: 200,
        height: 100,
        top: 16,
        right: 40,
        itemSize: 20, // 设置图标大小
        feature: {
          brush: {
            type: ['lineX', 'clear'], // 选择工具类型
            title: {
              lineX: '横向选择',
              clear: '清除',
            },
          },
        },
      },
      brush: {
        toolbox: ['lineX', 'clear'],
        xAxisIndex: 0,
      },
      series: [
        {
          name: `${chartData?.[0].entryExitName}--${originName}`,
          type: 'custom',
          zlevel: 99,
          color: color[0],
          yAxisIndex: 0,
          dimensions: ['-', `最大${originName}`, `平均${originName}`, `最小${originName}`],
          itemStyle: {
            borderWidth: 1.5,
          },
          renderItem: function (params: any, api: any) {
            var xValue = api?.value(0);
            var highPoint = api?.coord([xValue, api.value(1)]);
            var avgPoint = api?.coord([xValue, api.value(2)]);
            var lowPoint = api?.coord([xValue, api.value(3)]);
            // var halfWidth = api?.size([0, 1])[1] / 2;
            var halfWidth = 8;
            var style = api?.style({
              stroke: api?.visual('color'),
              fill: undefined,
            });
            return {
              type: 'group',
              children: [
                {
                  type: 'line',
                  transition: ['shape'],
                  shape: {
                    x1: highPoint[0] - halfWidth,
                    y1: highPoint[1],
                    x2: highPoint[0] + halfWidth,
                    y2: highPoint[1],
                  },
                  style: style,
                },
                {
                  type: 'line',
                  transition: ['shape'],
                  shape: {
                    x1: highPoint[0],
                    y1: highPoint[1],
                    x2: lowPoint[0],
                    y2: lowPoint[1],
                  },
                  style: style,
                },
                {
                  type: 'line',
                  transition: ['shape'],
                  shape: {
                    x1: avgPoint[0] - halfWidth,
                    y1: avgPoint[1],
                    x2: avgPoint[0] + halfWidth,
                    y2: avgPoint[1],
                  },
                  style: style,
                },
                {
                  type: 'line',
                  transition: ['shape'],
                  shape: {
                    x1: lowPoint[0] - halfWidth,
                    y1: lowPoint[1],
                    x2: lowPoint[0] + halfWidth,
                    y2: lowPoint[1],
                  },
                  style: style,
                },
              ],
            };
          },
          encode: {
            x: 0,
            y: [1, 2, 3],
            tooltip: [1, 2, 3],
          },
          data: originTotalData,
        },
        {
          type: 'bar',
          color: color[1],
          zlevel: 1,
          yAxisIndex: 1,
          timeResolution: query.timeResolution,
          unit: comparedUnit,
          name: `${chartData?.[1].entryExitName}--${comparedName}`,
          data: chartData?.[1].data?.map(a => {
            return [a.ts, a.sum];
          }),
        },
      ],
    };
  } else {
    // 如果第一个为累积量(柱状图)，那被对比的为瞬时量（箱型图）
    const originTotalData = chartData?.[1].data?.map(item => [
      item.ts,
      item.max?.toFixed(2),
      item.avg?.toFixed(2),
      item.min?.toFixed(2),
    ]);
    const color = ['#2A6948', '#FFCB47'];

    const originChartData = chartData?.[0].data?.map(i => i.sum);
    const comparedMaxChartData = chartData?.[1].data?.map(i => i.max);
    const comparedMinChartData = chartData?.[1].data?.map(i => i.min);
    const originChartmaxValue = max(originChartData);
    const comparedChartmaxValue = max(comparedMaxChartData);
    const originChartminValue = min(originChartData);
    const comparedChartminValue = min(comparedMinChartData);

    return {
      tooltip: {
        ...basicTooltip,
        formatter(items: any) {
          const { axisValue } = items[0];
          // 根据选择的颗粒度，展示不同的tooltip
          let str: string;
          str = basicStr(query, axisValue);
          items.forEach((item: any, index: number) => {
            if (index === 0) {
              // 柱形图
              const { seriesName, data, marker } = item;
              const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${units[index]}` : '--';
              str += `<br> ${marker}${seriesName}: ${value}`;
            } else {
              // 箱型图
              const { seriesName, data, marker } = item;
              const valueMax = !isNil(data[1]) ? `${data[1]}${units[index]}` : '--';
              const valueAvg = !isNil(data[2]) ? `${data[2]}${units[index]}` : '--';
              const valueMin = !isNil(data[3]) ? `${data[3]}${units[index]}` : '--';
              str += `<br> ${marker}${seriesName}`;
              str += `<br> 最大: ${valueMax}`;
              str += `<br> 均值: ${valueAvg}`;
              str += `<br> 最小: ${valueMin}`;
            }
          });
          return str;
        },
      },
      yAxis: basicDoubleYAxis(
        originUnit,
        comparedUnit,
        originChartmaxValue,
        comparedChartmaxValue,
        originChartminValue,
        comparedChartminValue
      ),
      legend: {
        top: 20,
        right: 110,
      },
      toolbox: {
        width: 200,
        height: 100,
        top: 16,
        right: 40,
        itemSize: 20, // 设置图标大小
        feature: {
          brush: {
            type: ['lineX', 'clear'], // 选择工具类型
            title: {
              lineX: '横向选择',
              clear: '清除',
            },
          },
        },
      },
      brush: {
        toolbox: ['lineX', 'clear'],
        xAxisIndex: 0,
      },
      series: [
        {
          type: 'bar',
          color: color[0],
          timeResolution: query.timeResolution,
          unit: originUnit,
          zlevel: 1,
          yAxisIndex: 0,
          name: `${chartData?.[0].entryExitName}--${originName}`,
          data: chartData?.[0].data?.map(a => {
            return [a.ts, a.sum];
          }),
        },
        {
          name: `${chartData?.[1].entryExitName}--${comparedName}`,
          type: 'custom',
          color: color[1],
          zlevel: 99,
          yAxisIndex: 1,
          dimensions: ['-', `最大${comparedName}`, `平均${comparedName}`, `最小${comparedName}`],
          itemStyle: {
            borderWidth: 1.5,
          },
          renderItem: function (params: any, api: any) {
            var xValue = api?.value(0);
            var highPoint = api?.coord([xValue, api.value(1)]);
            var avgPoint = api?.coord([xValue, api.value(2)]);
            var lowPoint = api?.coord([xValue, api.value(3)]);
            // var halfWidth = api?.size([0, 1])[1] / 2;
            var halfWidth = 8;
            var style = api?.style({
              stroke: api?.visual('color'),
              fill: undefined,
            });
            return {
              type: 'group',
              children: [
                {
                  type: 'line',
                  transition: ['shape'],
                  shape: {
                    x1: highPoint[0] - halfWidth,
                    y1: highPoint[1],
                    x2: highPoint[0] + halfWidth,
                    y2: highPoint[1],
                  },
                  style: style,
                },
                {
                  type: 'line',
                  transition: ['shape'],
                  shape: {
                    x1: highPoint[0],
                    y1: highPoint[1],
                    x2: lowPoint[0],
                    y2: lowPoint[1],
                  },
                  style: style,
                },
                {
                  type: 'line',
                  transition: ['shape'],
                  shape: {
                    x1: avgPoint[0] - halfWidth,
                    y1: avgPoint[1],
                    x2: avgPoint[0] + halfWidth,
                    y2: avgPoint[1],
                  },
                  style: style,
                },
                {
                  type: 'line',
                  transition: ['shape'],
                  shape: {
                    x1: lowPoint[0] - halfWidth,
                    y1: lowPoint[1],
                    x2: lowPoint[0] + halfWidth,
                    y2: lowPoint[1],
                  },
                  style: style,
                },
              ],
            };
          },
          encode: {
            x: 0,
            y: [1, 2, 3],
            tooltip: [1, 2, 3],
          },
          data: originTotalData,
        },
      ],
    };
  }
};

// 不同指标 一个瞬时一个累积量 不按日 一折线一柱状图
const accumulateAndInstantaneousDifferentIndicatorNotDayBox = (
  chartData: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'],
  originUnit: string,
  comparedUnit: string,
  query: DataQuery
) => {
  const originName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.name;
  const comparedName = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[1].indicatorId)?.name;
  const originType = DataTypeAOrIArr.find(i => i.indicatorId === chartData?.[0].indicatorId)?.indicatorType;
  const units = [originUnit, comparedUnit];
  const color = ['#FFCB47', '#2A6948'];
  if (originType === DataAORIType.INSTANTANEOUS) {
    // 如果第一个为瞬时量(折线图)，那被对比的为累积量（柱状图）
    const originChartData = chartData?.[0].data?.map(i => i.avg);
    const comparedChartData = chartData?.[1].data?.map(i => i.sum);
    const originChartmaxValue = max(originChartData);
    const comparedChartmaxValue = max(comparedChartData);
    const originChartminValue = min(originChartData);
    const comparedChartminValue = min(comparedChartData);
    return {
      tooltip: {
        ...basicTooltip,
        formatter(items: any) {
          const { axisValue } = items[0];
          // 根据选择的颗粒度，展示不同的tooltip
          let str: string;
          str = basicStr(query, axisValue);
          items.forEach((item: any, index: number) => {
            const { seriesName, data, marker } = item;
            const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${units[index]}` : '--';
            str += `<br> ${marker}${seriesName}: ${value}`;
          });
          return str;
        },
      },
      yAxis: basicDoubleYAxis(
        originUnit,
        comparedUnit,
        originChartmaxValue,
        comparedChartmaxValue,
        originChartminValue,
        comparedChartminValue
      ),
      legend: {
        top: 20,
        right: 110,
      },
      toolbox: {
        width: 200,
        height: 100,
        top: 16,
        right: 40,
        itemSize: 20, // 设置图标大小
        feature: {
          brush: {
            type: ['lineX', 'clear'], // 选择工具类型
            title: {
              lineX: '横向选择',
              clear: '清除',
            },
          },
        },
      },
      brush: {
        toolbox: ['lineX', 'clear'],
        xAxisIndex: 0,
      },
      series: [
        {
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: color[0],
          name: `${chartData?.[0].entryExitName}--${originName}`,
          data: chartData?.[0].data?.map(a => {
            return [a.ts, a.avg];
          }),
        },
        {
          type: 'bar',
          color: color[1],
          timeResolution: query.timeResolution,
          unit: comparedUnit,
          yAxisIndex: 1,
          name: `${chartData?.[1].entryExitName}--${comparedName}`,
          data: chartData?.[1].data?.map(a => {
            return [a.ts, a.sum];
          }),
        },
      ],
    };
  } else {
    const originChartData = chartData?.[0].data?.map(i => i.sum);
    const comparedChartData = chartData?.[1].data?.map(i => i.avg);
    const originChartmaxValue = max(originChartData);
    const comparedChartmaxValue = max(comparedChartData);
    const originChartminValue = min(originChartData);
    const comparedChartminValue = min(comparedChartData);
    return {
      tooltip: {
        ...basicTooltip,
        formatter(items: any) {
          const { axisValue } = items[0];
          // 根据选择的颗粒度，展示不同的tooltip
          let str: string;
          str = basicStr(query, axisValue);
          items.forEach((item: any, index: number) => {
            const { seriesName, data, marker } = item;
            const value = !isNil(data[1]) ? `${data[1].toFixed(2)}${units[index]}` : '--';
            str += `<br> ${marker}${seriesName}: ${value}`;
          });
          return str;
        },
      },
      yAxis: basicDoubleYAxis(
        originUnit,
        comparedUnit,
        originChartmaxValue,
        comparedChartmaxValue,
        originChartminValue,
        comparedChartminValue
      ),
      legend: {
        top: 20,
        right: 110,
      },
      toolbox: {
        width: 200,
        height: 100,
        top: 16,
        right: 40,
        itemSize: 20, // 设置图标大小
        feature: {
          brush: {
            type: ['lineX', 'clear'], // 选择工具类型
            title: {
              lineX: '横向选择',
              clear: '清除',
            },
          },
        },
      },
      brush: {
        toolbox: ['lineX', 'clear'],
        xAxisIndex: 0,
      },
      series: [
        {
          type: 'bar',
          color: color[1],
          timeResolution: query.timeResolution,
          unit: originUnit,
          yAxisIndex: 0,
          name: `${chartData?.[0].entryExitName}--${originName}`,
          data: chartData?.[0].data?.map(a => {
            return [a.ts, a.sum];
          }),
        },
        {
          type: 'line',
          smooth: true,
          lineStyle: { normal: { width: 2 } },
          color: color[0],
          yAxisIndex: 1,
          name: `${chartData?.[1].entryExitName}--${comparedName}`,
          data: chartData?.[1].data?.map(a => {
            return [a.ts, a.avg];
          }),
        },
      ],
    };
  }
};
