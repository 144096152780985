import {
  CompareType,
  CompareTypeDisplay,
  DataQuery,
  ObjectSubmitParams,
  StatisticsPartition,
} from '@/api/energyMediaComparison';
import { useBreadcrumbRoutes, Wrapper, Button, FormTitle, Form, Radio } from '@maxtropy/components';
import { RadioChangeEvent } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState, useMemo } from 'react';
import HistoryCompare from './components/HistoryCompare';
import ObjectCompare from './components/ObjectCompare';
import styles from './index.module.scss';
import Chart from './components/Chart';
import DateSwitch from './components/DateSwitch';
import { getDefaultTime, getTs } from './utils';
import dayjs from 'dayjs';
import { getChartOption } from './components/Chart/chartOptions';
import { getChartOptionByHistory } from './components/Chart/chartOptionsHis';
import { useLocation } from 'react-router-dom';
import {
  apiV2EnergyCompareGetBatchMediumIndicatorProcessPointDataPost,
  apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost,
  V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse,
  apiV2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPost,
  V2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest,
  V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostRequest,
} from '@maxtropy/device-customer-apis-v2';
import { MediumIndicatorDisplayUnit } from '@/api/energyMedium';

const uetUnitType = 5; // 拓扑类型（能源工质）

export type ObjectItemProps = Exclude<
  V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostRequest['requests'],
  undefined
>[number];

const EnergyMediaComparison = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [objectForm] = useForm();
  const [historyForm] = useForm();
  const [chartData, setChartData] =
    useState<V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list']>(); // 图表数据
  const [hisChartData, setHisChartData] =
    useState<V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list']>(); // 历史对比图表数据
  const [compareType, setCompareType] = useState<CompareType>(CompareType.OBJECT);
  const [chartLoading, setChartLoading] = useState(false); // 图表的loading
  const [hisChartLoading, setHisChartLoading] = useState(false); // 历史图表的loading

  const [query, setQuery] = useState<DataQuery>();

  const [searchParams, setSearchParams] = useState<(ObjectItemProps & { emtId?: number })[]>(); // 条件
  const [hisSearchParams, setHisSearchParams] = useState<
    V2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest & { topoId?: number }
  >(); // 历史对比条件

  // 指标相同时调用一个单位接口
  const [mediumIndicatorDisplayUnitData, setMediumIndicatorDisplayUnitData] = useState<MediumIndicatorDisplayUnit>();
  // 指标不同时调用两个单位接口

  const [mediumIndicatorDisplayUnitSecondData, setMediumIndicatorDisplayUnitSecondData] =
    useState<MediumIndicatorDisplayUnit>();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_timeResolution = urlSearchParams.get('timeResolution') || undefined;
  let url_from = urlSearchParams.get('from') || undefined;
  let url_to = urlSearchParams.get('to') || undefined;

  const [objectValues, setObjectValues] = useState<ObjectSubmitParams>();
  const [historyValues, setHistoryValues] = useState<any>();

  // 对象对比单位
  useEffect(() => {
    if (!searchParams) return;
    if (searchParams[0].indicatorId === searchParams[1].indicatorId) {
      apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost({
        uetUnitType: uetUnitType,
        uetUnitId: searchParams[0].emtId,
        entryAndExitId: searchParams[0].processEntryOrExitId,
        // @ts-ignore
        type: searchParams[0].type,
      }).then(res => {
        setMediumIndicatorDisplayUnitData(res.list);
      });
    } else {
      apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost({
        uetUnitType: uetUnitType,
        uetUnitId: searchParams[0].emtId,
        entryAndExitId: searchParams[0].processEntryOrExitId,
        // @ts-ignore
        type: searchParams[0].type,
      }).then(res => {
        setMediumIndicatorDisplayUnitData(res.list);
      });
      apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost({
        uetUnitType: uetUnitType,
        uetUnitId: searchParams[1].emtId,
        entryAndExitId: searchParams[1].processEntryOrExitId,
        // @ts-ignore
        type: searchParams[1].type,
      }).then(res => {
        setMediumIndicatorDisplayUnitSecondData(res.list);
      });
    }
  }, [searchParams]);

  // 历史对比单位
  useEffect(() => {
    if (!hisSearchParams) return;
    apiV2MediumIndicatorDashboardGetIndicatorDisplayUnitPost({
      uetUnitType: uetUnitType,
      uetUnitId: hisSearchParams.topoId,
      entryAndExitId: hisSearchParams.processEntryOrExitId,
      // @ts-ignore
      type: hisSearchParams.type,
    }).then(res => {
      setMediumIndicatorDisplayUnitData(res.list);
    });
  }, [hisSearchParams]);

  // 初始值
  useEffect(() => {
    const defaultTimeResolution = (url_timeResolution as StatisticsPartition) ?? StatisticsPartition.MINUTE_15;
    if (url_from && url_to) {
      setQuery({
        timeResolution: defaultTimeResolution,
        ...getTs(defaultTimeResolution, dayjs(url_from, 'x'), dayjs(url_to, 'x')),
      });
    } else {
      setQuery({
        timeResolution: defaultTimeResolution,
        ...getDefaultTime(StatisticsPartition.MINUTE_15, dayjs()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 点击按时间颗粒度
  useEffect(() => {
    if (query && searchParams && compareType === CompareType.OBJECT) {
      setSearchParams([
        {
          ...searchParams[0],
          timeResolution: query.timeResolution,
          from: query.from!,
          to: query.to!,
        },
        {
          ...searchParams[1],
          timeResolution: query.timeResolution,
          from: query.from!,
          to: query.to!,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, compareType]);

  useEffect(() => {
    if (!mediumIndicatorDisplayUnitData) return;
    if (compareType === CompareType.OBJECT) {
      if (!searchParams) return;
      if (searchParams[0].indicatorId !== searchParams[1].indicatorId && !mediumIndicatorDisplayUnitSecondData) return;
      setChartLoading(true);
      apiV2EnergyCompareGetBatchMediumIndicatorProcessPointDataPost({ requests: searchParams }).then(res => {
        setChartData(res.list);
        setChartLoading(false);
      });
    } else {
      if (!hisSearchParams) return;
      setHisChartLoading(true);
      apiV2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPost(hisSearchParams).then(res => {
        setHisChartData(res.list || []);
        setHisChartLoading(false);
      });
    }
  }, [
    searchParams,
    hisSearchParams,
    compareType,
    mediumIndicatorDisplayUnitData,
    mediumIndicatorDisplayUnitSecondData,
  ]);

  // 点击对象对比按钮
  const onObjectFormFinish = (values: ObjectSubmitParams) => {
    const topEntryOrExit = values.entryAndExitIdTop[1];
    const bottomEntryOrExit = values.entryAndExitIdBottom[1];
    const params = [
      {
        emtId: values.topoIdTop,
        processId: values.entryAndExitIdTop[0],
        processEntryOrExitId: Number(topEntryOrExit.slice(0, topEntryOrExit.length - 1)),
        type: Number(topEntryOrExit.slice(topEntryOrExit.length - 1)),
        indicatorId: values.indicatorIdTop,
        timeResolution: query?.timeResolution,
        from: query?.from!,
        to: query?.to!,
      },
      {
        emtId: values.topoIdBottom,
        processId: values.entryAndExitIdBottom[0],
        processEntryOrExitId: Number(bottomEntryOrExit.slice(0, bottomEntryOrExit.length - 1)),
        type: Number(bottomEntryOrExit.slice(bottomEntryOrExit.length - 1)),
        indicatorId: values.indicatorIdBottom,
        timeResolution: query?.timeResolution,
        from: query?.from!,
        to: query?.to!,
      },
    ];
    setObjectValues(values);
    setSearchParams(params);
  };

  // 历史对比点击对比按钮
  const onHistoryFormFinish = (values: any) => {
    const topEntryOrExit = values.entryAndExitId[1];
    const params = {
      topoId: values.topoId,
      processId: values.entryAndExitId[0],
      processEntryOrExitId: Number(topEntryOrExit.slice(0, topEntryOrExit.length - 1)),
      type: Number(topEntryOrExit.slice(topEntryOrExit.length - 1)),
      indicatorId: values.indicatorId,
      timeResolution: values.timeResolution,
      timeRangeList: values.timeRangeList.map((item: any) => {
        return {
          from: dayjs(item.fromAndTo[0]).startOf('day').valueOf(),
          to: dayjs(item.fromAndTo[1]).endOf('day').valueOf(),
        };
      }),
    };
    setHistoryValues(values);
    setHisSearchParams(params);
  };

  const onCompareTypeChange = (e: RadioChangeEvent) => {
    setCompareType(e.target.value);
    setSearchParams(undefined);
    setHisSearchParams(undefined);
    setChartData(undefined);
    setHisChartData(undefined);
    if (e.target.value === CompareType.HISTORY) {
      objectForm.resetFields();
      setQuery({
        timeResolution: StatisticsPartition.MINUTE_15,
      });
    } else {
      historyForm.resetFields();
      setQuery({
        timeResolution: StatisticsPartition.MINUTE_15,
        ...getDefaultTime(StatisticsPartition.MINUTE_15, dayjs()),
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const objectOption = useMemo(
    () => getChartOption(query, chartData, mediumIndicatorDisplayUnitData, mediumIndicatorDisplayUnitSecondData),
    [chartData]
  );
  const historyOption = useMemo(
    () => getChartOptionByHistory(hisChartData, mediumIndicatorDisplayUnitData, hisSearchParams),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hisChartData]
  );

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
        <FormTitle title="能源介质对比" />

        <div>
          <div className={styles.compareModalBody}>
            <div className={styles.leftCol}>
              <Radio.Group
                options={Object.entries(CompareTypeDisplay).map(([k, v]) => ({
                  label: v,
                  value: k,
                }))}
                onChange={e => {
                  onCompareTypeChange(e);
                }}
                value={compareType}
                optionType="button"
                buttonStyle="solid"
              />
              {compareType === CompareType.OBJECT && (
                <>
                  <Form isOrigin form={objectForm} onFinish={onObjectFormFinish}>
                    <ObjectCompare
                      objectValues={objectValues}
                      objectForm={objectForm}
                      setSearchParams={setSearchParams}
                      setChartData={setChartData}
                      setQuery={setQuery}
                    />
                  </Form>
                </>
              )}
              {compareType === CompareType.HISTORY && (
                <Form isOrigin form={historyForm} onFinish={onHistoryFormFinish}>
                  <HistoryCompare
                    historyValues={historyValues}
                    setHisSearchParams={setHisSearchParams}
                    form={historyForm}
                    setHisChartData={setHisChartData}
                    hisChartData={hisChartData}
                    mediumIndicatorDisplayUnitData={mediumIndicatorDisplayUnitData}
                  />
                </Form>
              )}
              <div className={styles.btnArea}>
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  onClick={() => {
                    compareType === CompareType.OBJECT ? objectForm.submit() : historyForm.submit();
                  }}
                >
                  对比
                </Button>
              </div>
            </div>
            <div className={styles.rightCol}>
              <div className={styles.dateSwitch}>
                {query && compareType === CompareType.OBJECT && (
                  <DateSwitch
                    form={compareType === CompareType.OBJECT ? objectForm : historyForm}
                    compareType={compareType}
                    onQueryChange={value => setQuery(value)}
                    selectBtn={query.timeResolution}
                    value={[dayjs(query.from, 'x'), dayjs(query.to, 'x')]}
                  />
                )}
              </div>

              <div className={styles.chartArea} style={{ marginTop: 20 }}>
                {compareType === CompareType.OBJECT ? (
                  <Chart
                    height={560}
                    searchParams={searchParams}
                    loading={chartLoading}
                    chartData={chartData}
                    option={objectOption}
                  />
                ) : (
                  <Chart
                    height={600}
                    loading={hisChartLoading}
                    hisSearchParams={hisSearchParams}
                    chartData={hisChartData}
                    option={historyOption}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default EnergyMediaComparison;
