import { fetch, Key } from '@maxtropy/components';
import { DataProperty, DeviceModel, DeviceType, OU, OUPageRequest, OUPageResponse, PhysicalUnit } from '../types';

export const getDeviceTypeTreeWithoutGatewayType = () =>
  fetch<{ tree: DeviceType[]; aliasName: Array<string> }>(
    `/api/v2/deviceType/treeWithoutGatewayType`,
    {
      method: 'POST',
    },
    true
  );

export const getDataProperties = (iotProtocol: number, deviceTypeId?: number, physicalModelId?: Key) =>
  fetch<{ list: DataProperty[] }>(
    `/api/v2/dataProperty/list`,
    {
      method: 'POST',
      body: JSON.stringify({
        deviceTypeId: String(deviceTypeId),
        iotProtocol,
        physicalModelId,
      }),
    },
    true
  ).then(res => res.list);
export interface CrossDataPropertiesResponse {
  deviceName: string;
  deviceId: number;
  datapropertyName: string;
  datapropertyId: number;
  hasProperty: boolean;
  pointType: 1 | 2 | 3;
}
export function getCrossDataProperties(data: { id: number | string }) {
  return fetch<{ list: CrossDataPropertiesResponse[] }>(
    `/api/v2/edgeDevice/v2/cross-device/dataproperty`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  ).then(res => res.list);
}

// 临时方法，后续迭代会修改
export const getDataPropertiesTemp = (iotProtocol: number, deviceTypeId?: number, physicalModelId?: Key) =>
  fetch<{ list: DataProperty[] }>(
    `/api/v2/dataProperty/list`,
    {
      method: 'POST',
      body: JSON.stringify({
        deviceTypeId,
        iotProtocol,
        physicalModelId,
      }),
    },
    true
  ).then(res => res.list ?? []);

export const getPhysicalUnits = (dataPropertyId?: number) =>
  fetch<{ list: PhysicalUnit[] }>(
    `/api/v2/physicalUnit/listWithDefault`,
    {
      method: 'POST',
      body: JSON.stringify({ id: dataPropertyId }),
    },
    true
  ).then(res => res.list ?? []);

interface IotProtocol {
  id: number;
  name: string;
}

export const getMockingbirdIotProtocols = () =>
  fetch<{ list: IotProtocol[] }>(
    `/api/v2/iotProtocol/jishang/list`,
    {
      method: 'POST',
    },
    true
  );

export function getOUPage(params: OUPageRequest): Promise<OUPageResponse> {
  return fetch(
    `/api/v2/ou/device/addOu/managePage`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

interface OuListRequest {
  ids: number[];
}

export function getOUList(params: OuListRequest): Promise<{ list: Array<OU> }> {
  return fetch(
    `/api/v2/ou/list`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface OuType {
  id: number;
  name: string;
}

// 获取类型列表
export const getOuTypeList = () =>
  fetch<{ list: OuType[] }>(
    `/api/v2/ou/ouType/list`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);

export const queryGatewayChildren = () =>
  fetch<{ list: number[] }>(
    '/api/v2/deviceType/gateway',
    {
      method: 'POST',
    },
    true
  ).then(res => res.list ?? []);

export interface Tenant {
  id: number;
  mcid: string;
  name: string;
  tenantCode: string;
  address: string;
  adCode: string;
  accountOpenStatus: boolean;
}

export function getRoot(): Promise<Tenant[]> {
  return fetch(`/api/customer/getRoot?check=true`);
}

export interface PhysicalModelRes {
  id: Key;
  modelNo: string; // 型号
  manufacturerId: Key; // 厂商id
  deviceTypeId: Key; // 设备类目id
  createTime: string;
  updateTime: string;
  manufacturerName: string; // 厂商名称
}

export interface PhysicalParams {
  deviceTypeIds?: Key[]; // 设备类目id
  manufacturerId?: Key; // 厂商id
}

// 根据设备类目和厂商查询物模型列表

export function getPhysicalModelList(params: PhysicalParams) {
  return fetch<{ list: PhysicalModelRes[] }>(
    `/api/v2/physical-model/list`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}
