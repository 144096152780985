import { CheckboxOptionType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';
import useMergedState from '../../../hooks/useMergedState';
import { Checkbox } from '@maxtropy/components';
import styles from './index.module.scss';

const CheckboxGroup = Checkbox.Group;

interface CheckGroupWithAllProps {
  options: Array<CheckboxOptionType | string>;
  value?: Array<any>;
  onChange?: (value: Array<any>) => void;
  disabled?: boolean;
}

const CheckGroupWithAll: React.FC<CheckGroupWithAllProps> = props => {
  const { options = [], disabled } = props;
  const [checkedList, setCheckedList] = useMergedState([], { ...props });
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);

  const onChange = (list: any[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checkedList = options.map(option => {
      if (typeof option === 'string') {
        return option;
      } else {
        return option.value;
      }
    });
    setCheckedList(e.target.checked ? checkedList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div className={styles.checkGroupAll}>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} disabled={disabled}>
        全选
      </Checkbox>
      <CheckboxGroup options={options} value={checkedList} onChange={onChange} disabled={disabled} />
    </div>
  );
};

export default CheckGroupWithAll;
