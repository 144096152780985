import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { PageRequest, PageResponse } from './page';

export interface OuItem {
  id: number; // 主键id
  serialNumber: string; // 编号
  name: string; // 名称
  status: number; // 0,禁用;1,启用;2,作废(原本作废用的是0,修改为2,并且OU状态值目前只有启用和作废)
  customerName: string; // 所属组织名称
  isLinked: boolean; // 是否关联 只在查询所有ou列表中有效
  customerMcid: string; // 所属组织
  tenantMcid: string; //
}

// 添加或者移除枚举类型
export enum AddOrRemoveType {
  Add = 0, // 被添加
  Remove = 1, // 被移除
}

// 添加或者移除枚举类型的值
export const AddOrRemoveTypeValue = {
  [AddOrRemoveType.Add]: '被添加',
  [AddOrRemoveType.Remove]: '被移除',
};

interface PVDeviceRequest extends PageRequest {
  pvName?: string; // 光伏名称
  ouId?: number[];
  unitConfGroupId: string;
}

export interface BsaOrPVResponse {
  // bsaId 或者 pv id
  id: string;
  name: string;
  ouName: string;
  ouId: number;
  staffHasOuAuthority: boolean;
}

// 添加或者移除枚举类型
export enum BsaOrPVType {
  BSA = 0,
  PV = 1,
}

export interface mcidItem {
  mcid: string;
  name: string;
}

// 用能单元详情设备类型
export enum UnitConfGroupDeviceType {
  // 能耗设备
  ENERGY_CONSUMPTION = 1,
  // 剔除设备
  REMOVE = 2,
  // 光伏站并网点
  PV_Grid = 3,
  // 储能站
  BSA = 4,
}

// 用能单元详情设备展示
export const UnitConfGroupDeviceTypeDisplay = {
  [UnitConfGroupDeviceType.ENERGY_CONSUMPTION]: '能耗设备',
  [UnitConfGroupDeviceType.REMOVE]: '剔除设备',
  [UnitConfGroupDeviceType.PV_Grid]: '光伏站并网点',
  [UnitConfGroupDeviceType.BSA]: '储能站',
};

// 电能计量类别 id,
export enum MeterType {
  // 电能
  ELECTRICAL_ENERGY = 2030,
}

// 水和天然气计量类别 id
export enum MeterTypeForWaterAndNaturalGas {
  // 水
  WATER = 2,
  // 天然气
  NATURAL_GAS = 3,
}

export type AllMeterType = MeterType | MeterTypeForWaterAndNaturalGas;

// 电能计量类别显示
export const MeterTypeDisplay = {
  [MeterType.ELECTRICAL_ENERGY]: '电能',
};

// 水和天然气计量类别显示
export const MeterTypeDisplayForWaterAndNaturalGas = {
  [MeterTypeForWaterAndNaturalGas.WATER]: '水',
  [MeterTypeForWaterAndNaturalGas.NATURAL_GAS]: '天然气',
};

export const AllMeterTypeDisplay = {
  ...MeterTypeDisplay,
  ...MeterTypeDisplayForWaterAndNaturalGas,
};

// 计量属性 id, 电能
export enum MeterAttribute {
  POSITIVE_ACTIVE_ELECTRIC_ENERGY = 10023,
  REVERSE_ACTIVE_ELECTRIC_ENERGY = 10024,
  TOTAL_ACTIVE_ELECTRIC_ENERGY = 10022,
  // 不计入为 null,
}

export enum MeterAttributeForWaterAndNaturalGas {
  CUMULATIVE_FLOW_Volume = 10437,
  CUMULATIVE_FLOW_QUALITY = 10438,
}

export type AllMeterAttribute = MeterAttribute | MeterAttributeForWaterAndNaturalGas;

export const MeterAttributeForWaterAndNaturalGasDisplay = {
  [MeterAttributeForWaterAndNaturalGas.CUMULATIVE_FLOW_Volume]: '累积流量（体积）',
  [MeterAttributeForWaterAndNaturalGas.CUMULATIVE_FLOW_QUALITY]: '累积流量（质量）',
};

// 计量属性展示, 电能
export const MeterAttributeDisplay = {
  [MeterAttribute.POSITIVE_ACTIVE_ELECTRIC_ENERGY]: '正向有功电能',
  [MeterAttribute.REVERSE_ACTIVE_ELECTRIC_ENERGY]: '反向有功电能',
  [MeterAttribute.TOTAL_ACTIVE_ELECTRIC_ENERGY]: '总有功电能',
};

export const AllMeterAttributeDisplay = {
  ...MeterAttributeForWaterAndNaturalGasDisplay,
  ...MeterAttributeDisplay,
};

// 单个计量记录, 其中计量类别, 计量属性等一定会存在
export interface UnitConfGroupSettingViewRecord {
  id: number;
  // 设备名称
  name: string;
  // 设备 id
  deviceId?: number;
  // 类型 1 能耗设备 2 剔除设备 3光伏站并网点 4储能站
  type: UnitConfGroupDeviceType;
  // 计量类别, 若为非电能的能源介质, 为二级 id
  meterTypeId?: number | null;
  // 计量类别, 若为非电能的能源介质, 为一级 id, 电能为 null
  meterTypeParentId?: number;
  // 计量类别名称
  meterTypeName?: string;
  // 计量类别, 若为非电能的能源介质, 为一级类目的 name, 电能为 null
  meterTypeParentName?: string;
  // 计量属性
  meterAttributeId?: number | null;
  // 计量属性名称
  meterAttributeName?: string;
  // 当前staff是否有设备的ou权限
  staffHasOuAuthority: boolean;
  // 对应一级类目的 id
  firstClassTypeId: number;
}

// ------分析组计量设置----------
// 基础物理量枚举 id
export enum PhysicalQuantity {
  // 体积
  VOLUME = 12,
  // 质量
  MASS = 3,
  // 能量
  ENERGY = 15,
}

// 基础物理量名称展示
export const PhysicalQuantityDisplay = {
  [PhysicalQuantity.VOLUME]: '体积',
  [PhysicalQuantity.MASS]: '质量',
  [PhysicalQuantity.ENERGY]: '能量',
};

// 基础物理量单位展示
export const PhysicalQuantityUnit = {
  [PhysicalQuantity.VOLUME]: 'm³',
  [PhysicalQuantity.MASS]: 'kg',
  [PhysicalQuantity.ENERGY]: 'MJ',
};

export enum DiagnosisLevel {
  EXCELLENT = 0,
  GOOD = 1,
  NORMAL = 2,
  RANGE = 3,
  POOR = 4,
}

// 评分范围
export const scoreRange = [
  { min: 0, max: 60, level: DiagnosisLevel.POOR },
  { min: 60, max: 70, level: DiagnosisLevel.RANGE },
  { min: 70, max: 80, level: DiagnosisLevel.NORMAL },
  { min: 80, max: 90, level: DiagnosisLevel.GOOD },
  { min: 90, max: 100, level: DiagnosisLevel.EXCELLENT },
];

export const levelColors: Record<DiagnosisLevel, string> = {
  [DiagnosisLevel.EXCELLENT]: '73, 170, 25',
  [DiagnosisLevel.GOOD]: '74, 144, 226',
  [DiagnosisLevel.NORMAL]: '216, 150, 20',
  [DiagnosisLevel.RANGE]: '219, 118, 29',
  [DiagnosisLevel.POOR]: '166, 29, 36',
};

export const linearColors: Record<DiagnosisLevel, string> = {
  [DiagnosisLevel.EXCELLENT]: 'linear-gradient(270deg, #48C40A 0%, #49AA19 100%)',
  [DiagnosisLevel.GOOD]: 'linear-gradient(270deg, #3A94FC 0%, #4A90E2 100%)',
  [DiagnosisLevel.NORMAL]: 'linear-gradient(270deg, #FFB219 0%, #D89614 100%)',
  [DiagnosisLevel.RANGE]: 'linear-gradient(270deg, #FF8921 0%, #DB761D 100%)',
  [DiagnosisLevel.POOR]: 'linear-gradient(270deg, #D9252E 0%, #A61D24 100%)',
};
