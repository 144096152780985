import { Key, fetch } from '@maxtropy/components';
import { PageRequest } from './page';

export interface CarbonAccountUnitsSearchParams extends PageRequest {
  name?: string;
}

// 核算指南列表
export interface CarbonAccountGuideListRes {
  id: Key;
  industryId: Key; // 行业id
  industryName: string; // 行业Name
  standard: string; // 指南标准
  issuingInstitution: string; // 颁发机构
  issuingDate: string; // 输入日期
  state: number; // 状态(0:草稿 1:启用 2:禁用)
  createTime: string;
  updateTime: string;
}

export const getAccountGuideList = () =>
  fetch<{ list: CarbonAccountGuideListRes[] }>(
    '/api/v2/carbon/accounting-unit/guide-list',
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);

export interface CarbonAccountUnitsPageRes {
  id: number;
  subjectName: string; // 主体
  unitName: string; // 单元名称
  accountingGuideId: Key; // 核算指南id
  ouIds: Key[]; // ouIds
  startMonth: number; // 财年起始月
  industryName: string; // 行业Name
  standard: string; // 指南标准
  updateTime: string;
}

// 删除
export const delAccountUnit = (id: Key) =>
  fetch(
    `/api/v2/carbon/accounting-unit/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

// 详情
export const getAccountUnitDetailById = (id: Key) =>
  fetch<CarbonAccountUnitsPageRes>(
    `/api/v2/carbon/accounting-unit/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );

// 新增编辑
export interface addAccountUnitBody {
  id?: number;
  subjectName?: string; // 主体
  unitName: string; // 单元名称
  accountingGuideId: number; // 核算指南id
  ouIds: number[];
  startMonth: number; // 财年起始月
}

export interface AccountUnitListItem {
  id: Key;
  unitName: string;
}

// 列表
export const getAccountUnitList = () =>
  fetch<{ list: AccountUnitListItem[] }>(
    `/api/v2/carbon/accounting-unit/list`,
    {
      method: 'POST',
    },
    true
  ).then(res => res.list);

export const getmeterTypeList = (params: getmeterTypeListItem) =>
  fetch(
    `/api/v2/energy-consumption-evaluation-analysis-unit/meterTypeList`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );

export interface getmeterTypeListItem {
  ids: number[];
}

export interface CarbonEmissionFillingDetail {
  id?: number;
  /**
   * 盘查任务id
   */
  inventoryTaskId?: number;
  /**
   * 盘查任务名称
   */
  inventoryTaskName?: string;
  /**
   * 核算年
   */
  emissionYear?: number;
  /**
   * 核算日期(月)
   */
  emissionDate?: string;
  /**
   * 状态(1:未填报 2：已填报)
   */
  state?: number;
  /**
   * 行业id
   */
  industryId?: number;
  /**
   * 行业Name
   */
  industryName?: string;
  /**
   * 指南标准
   */
  standard?: string;
  /**
   * 颁发机构
   */
  issuingInstitution?: string;
  /**
   * 输入日期
   */
  issuingDate?: string;
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 分类
   */
  guideClassificationList?: {
    id?: number;
    /**
     * 范围(1:范围一 2:范围二 3:范围三)
     */
    range?: number;
    /**
     * 分类名称
     */
    classificationName?: string;
    /**
     * 分类顺序(1,2,3...)
     */
    classificationOrder?: number;
    /**
     * 排放过程
     */
    guideClassificationProcessDtos?: {
      id?: number;
      /**
       * 分类id
       */
      classificationId?: number;
      /**
       * 排放过程id
       */
      emissionProcessId?: number;
      /**
       * 排放过程name
       */
      emissionProcessName?: string;
      /**
       * 过程排序(1,2,3...)
       */
      processOrder?: number;
      /**
       * 过程表单列表
       */
      formFieldList?: {
        id?: number;
        /**
         * 排放过程id
         */
        emissionProcessId?: number;
        /**
         * 字段名称
         */
        fieldName?: string;
        /**
         * 字段类型(1:数据关联 2:数值 3:输出)
         */
        fieldType?: number;
        /**
         * 字段属性id
         */
        fieldPropertyId?: number;
        /**
         * 字段属性类型(1燃料 2碳酸盐...)
         */
        fieldPropertyType?: number;
        /**
         * 字段来源(1:排放因子)
         */
        fieldSource?: number;
        /**
         * 字段序号
         */
        fieldOrder?: number;
        /**
         * 小数位数
         */
        decimalDigit?: number;
        /**
         * 数值表达式(例:(0,100])
         */
        numericalExpression?: string;
        /**
         * 筛选表达式
         */
        filterExpression?: string;
        createTime?: {
          /**
           * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
           */
          seconds?: number;
          /**
           * The number of nanoseconds, later along the time-line, from the seconds field.
           * This is always positive, and never exceeds 999,999,999.
           */
          nanos?: number;
        };
        updateTime?: {
          /**
           * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
           */
          seconds?: number;
          /**
           * The number of nanoseconds, later along the time-line, from the seconds field.
           * This is always positive, and never exceeds 999,999,999.
           */
          nanos?: number;
        };
        /**
         * 筛选逻辑
         */
        fieldFilterLogic?: {
          id?: null;
          /**
           * 表单字段id
           */
          formFieldId?: null;
          /**
           * 字段属性id
           */
          fieldPropertyId?: null;
          /**
           * 运算符号(1:等于 2:不等于)
           */
          operateSign?: null;
          /**
           * 匹配类型(1:匹配字段 2:自定义)
           */
          matchType?: null;
          /**
           * 匹配值(字段名称或自定义输入值)
           */
          matchValue?: null;
          createTime?: {
            /**
             * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
             */
            seconds?: number;
            /**
             * The number of nanoseconds, later along the time-line, from the seconds field.
             * This is always positive, and never exceeds 999,999,999.
             */
            nanos?: number;
          };
          updateTime?: {
            /**
             * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
             */
            seconds?: number;
            /**
             * The number of nanoseconds, later along the time-line, from the seconds field.
             * This is always positive, and never exceeds 999,999,999.
             */
            nanos?: number;
          };
        }[];
      }[];
      /**
       * 填报数据
       */
      fillingData?: {
        id?: number;
        /**
         * 排放填报id
         */
        emissionFillingId?: number;
        /**
         * 分类过程id
         */
        classificationProcessId?: number;
        /**
         * 排放过程id
         */
        emissionProcessId?: number;
        /**
         * 表单字段1id
         */
        formField1Id?: number;
        /**
         * 表单字段1值
         */
        formField1Value?: string;
        /**
         * 表单字段2id
         */
        formField2Id?: number;
        /**
         * 表单字段2值
         */
        formField2Value?: string;
        /**
         * 表单字段3id
         */
        formField3Id?: number;
        /**
         * 表单字段3值
         */
        formField3Value?: string;
        /**
         * 表单字段4id
         */
        formField4Id?: number;
        /**
         * 表单字段4值
         */
        formField4Value?: string;
        /**
         * 表单字段5id
         */
        formField5Id?: number;
        /**
         * 表单字段5值
         */
        formField5Value?: string;
        /**
         * 表单字段6id
         */
        formField6Id?: number;
        /**
         * 表单字段6值
         */
        formField6Value?: string;
        /**
         * 表单字段7id
         */
        formField7Id?: number;
        /**
         * 表单字段7值
         */
        formField7Value?: string;
        /**
         * 表单字段8id
         */
        formField8Id?: number;
        /**
         * 表单字段8值
         */
        formField8Value?: string;
        /**
         * 表单字段9id
         */
        formField9Id?: number;
        /**
         * 表单字段9值
         */
        formField9Value?: string;
        /**
         * 表单字段10id
         */
        formField10Id?: number;
        /**
         * 表单字段10值
         */
        formField10Value?: string;
        /**
         * 表单字段11id
         */
        formField11Id?: number;
        /**
         * 表单字段11值
         */
        formField11Value?: string;
        /**
         * 表单字段12id
         */
        formField12Id?: number;
        /**
         * 表单字段12值
         */
        formField12Value?: string;
        /**
         * 表单字段13id
         */
        formField13Id?: number;
        /**
         * 表单字段13值
         */
        formField13Value?: string;
        /**
         * 表单字段14id
         */
        formField14Id?: number;
        /**
         * 表单字段14值
         */
        formField14Value?: string;
        /**
         * 表单字段15id
         */
        formField15Id?: number;
        /**
         * 表单字段15值
         */
        formField15Value?: string;
        /**
         * 自动填报规则id
         */
        autoFillingRuleId?: number;
        /**
         * 作废标志(0:未作废 1:作废)
         */
        obsoleteFlag?: number;
        tenantMcid?: string;
        createTime?: {
          /**
           * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
           */
          seconds?: number;
          /**
           * The number of nanoseconds, later along the time-line, from the seconds field.
           * This is always positive, and never exceeds 999,999,999.
           */
          nanos?: number;
        };
        updateTime?: {
          /**
           * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
           */
          seconds?: number;
          /**
           * The number of nanoseconds, later along the time-line, from the seconds field.
           * This is always positive, and never exceeds 999,999,999.
           */
          nanos?: number;
        };
      }[];
    }[];
  }[];
  /**
   * 核算单元名称
   */
  accountingUnitName?: string;
}
