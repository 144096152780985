import { useState, useEffect, useRef, Key, useMemo, useLayoutEffect } from 'react';
import { Col, Row } from 'antd';
import { useSize } from 'ahooks';
import titleBg from './assets/title@2x.gif';
import SubmitDataOverview from './components/SubmitDataOverview';
import ReportedCarbonEmissionsOfProducts from './components/ReportedCarbonEmissionsOfProducts';
import ModelingOverview from './components/ModelingOverview';
import ModeledProducts from './components/ModeledProducts';
import ProductCarbonFootprintOverview from './components/ProductCarbonFootprintOverview';
import DistributionOfProductCarbonFootprint from './components/DistributionOfProductCarbonFootprint';
import AnalysisOfProductCarbonFootprint from './components/AnalysisOfProductCarbonFootprint';
import styles from './index.module.scss';
import './index.scss';
import { setFitScale } from './utils';
import { Themes, useThemeContext } from '@maxtropy/components';

const CarbonFootprintOverview = () => {
  const outerRef = useRef<HTMLDivElement>();
  const innerRef = useRef<HTMLDivElement>();
  const size = useSize(outerRef);
  const [productionOrderId, setProductionOrderId] = useState<Key>();
  const { setCustomTheme } = useThemeContext();

  useLayoutEffect(() => {
    setCustomTheme?.(Themes.DARK);

    return () => {
      setCustomTheme?.();
    };
  }, []);

  useEffect(() => {
    setFitScale(innerRef.current, size?.width, size?.height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const isOneCol = useMemo(() => {
    return size?.width && size.width <= 1600;
  }, [size?.width]);

  return (
    <div
      ref={dom => {
        if (dom) {
          outerRef.current = dom;
        }
      }}
      className={`${styles.outerContainer}`}
    >
      <div
        ref={dom => {
          if (dom) {
            innerRef.current = dom;
          }
        }}
        className={`${styles.innerContainer} ${!isOneCol && styles.flexContainer}`}
      >
        <div className={styles.title}>
          <img src={titleBg} alt="pic" />
          <span>碳足迹总览</span>
        </div>
        {isOneCol ? (
          <Row>
            <Col span={24} className={styles.module}>
              {/* 填报概览 */}
              <SubmitDataOverview />
              {/* 已填报产品碳排 */}
              <ReportedCarbonEmissionsOfProducts />
              {/* 建模概览 */}
              <ModelingOverview />
              {/* 已建模产品 */}
              <ModeledProducts />
            </Col>
            {/* 模块2 */}
            <Col span={24} className={styles.module}>
              {/* 产品碳足迹概览 */}
              <ProductCarbonFootprintOverview setProductionOrderId={setProductionOrderId} innerRef={innerRef.current} />
              {/* 产品碳足迹分布 */}
              <DistributionOfProductCarbonFootprint id={productionOrderId} />
              {/* 产品碳足迹优化分析 */}
              <AnalysisOfProductCarbonFootprint id={productionOrderId} />
            </Col>
          </Row>
        ) : (
          <div className={styles.content}>
            <div className={styles.module}>
              {/* 填报概览 */}
              <SubmitDataOverview />
              {/* 已填报产品碳排 */}
              <ReportedCarbonEmissionsOfProducts isTwoCol={true} />
              {/* 建模概览 */}
              <ModelingOverview />
              {/* 已建模产品 */}
              <ModeledProducts />
            </div>
            {/* 模块2 */}
            <div className={styles.module}>
              {/* 产品碳足迹概览 */}
              <ProductCarbonFootprintOverview setProductionOrderId={setProductionOrderId} innerRef={innerRef.current} />
              {/* 产品碳足迹分布 */}
              <DistributionOfProductCarbonFootprint id={productionOrderId} />
              {/* 产品碳足迹优化分析 */}
              <AnalysisOfProductCarbonFootprint id={productionOrderId} isTwoCol={true} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarbonFootprintOverview;
