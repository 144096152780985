import * as React from 'react';
import { isNil } from 'lodash-es';
import { Button, EllipsisSpan, MxColumnType } from '@maxtropy/components';
import { getRange } from '../../../../../utils/utils';
import { OBJECT_TYPE, VALUE_TYPE, LENGTH } from '../../../../../types';
import { objectFormatter, valueTypeFormatter, lengthFormatter } from '../entity';
import { getHandleModeMapping, getMode } from './CreateForm';
import { map2Ary, map2Ary2, IEnum } from '../utils';
import { Space } from 'antd';
import { FilterModeDisplay } from '@/shared/const';

export const getColumns = (
  fixed?: boolean,
  dataPropertiesAll?: any[],
  showMappingInfoFn?: (values: IEnum[]) => void,
  insertCols: any[] = []
): MxColumnType<any>[] => {
  return [
    {
      title: '数据属性/标识符',
      dataIndex: 'name',
      showForce: true,
      showComplete: true,
      keyList: ['dataPropertyName', 'identifier'],
      ellipsis: { showTitle: true },
      width: 180,
      fixed: 'left',
      render: (_: undefined, record) => (
        <EllipsisSpan value={!!record.hasProperty ? record.dataPropertyName : record.identifier} />
      ),
    },
    {
      title: '单位',
      dataIndex: 'physicalUnitGeneralName',
      ellipsis: { showTitle: true },
      width: 80,
      fixed: 'left',
      render: (value: string) => <EllipsisSpan value={value} />,
    },
    ...insertCols,
    {
      title: '采集点类型',
      dataIndex: 'hasProperty',
      ellipsis: { showTitle: true },
      width: 120,
      render: (value: boolean) => <EllipsisSpan value={value ? '建模采集点' : '非建模采集点'} />,
    },
    {
      title: '是否可写',
      dataIndex: 'writable',
      ellipsis: { showTitle: true },
      width: 120,
      render: (v: string) => <EllipsisSpan value={v ? '是' : '否'} />,
    },
    {
      title: '功能码',
      width: 120,
      dataIndex: 'objectType',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => (
        <EllipsisSpan value={objectFormatter[record.parameters?.objectType as OBJECT_TYPE]} />
      ),
    },
    {
      title: '开始地址',
      width: 120,
      dataIndex: 'starting',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const value = record.parameters?.starting;
        const starting = !isNil(value) ? (value as Number).toString(16) : value;
        return <EllipsisSpan value={!isNil(value) ? `0x${starting}` : value} />;
      },
    },
    {
      title: '长度',
      width: 100,
      dataIndex: 'length',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={lengthFormatter[record.parameters?.length as LENGTH]} />,
    },
    {
      title: '解码类型',
      width: 140,
      dataIndex: 'valueType',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => (
        <EllipsisSpan value={valueTypeFormatter[record.parameters?.valueType as VALUE_TYPE]} />
      ),
    },
    {
      title: '处理方式',
      dataIndex: 'handleMode',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const { objectType, length, handleMode } = record.parameters ?? {};
        let value;
        const formatter = getHandleModeMapping(objectType, length);
        if (formatter) {
          const target = Object.entries(formatter).find(item => item[0] === `${handleMode}`);
          value = target ? target[1] : '--';
        }
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '枚举映射',
      dataIndex: 'enumMapping',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const { enumMapping } = record.parameters ?? {};
        if (enumMapping) {
          let findEnum = dataPropertiesAll?.find(item => item.id === record.dataPropertyId);
          if (findEnum && findEnum?.enumValue) {
            let map2Arylist = map2Ary(findEnum?.enumValue);

            let list = map2Ary2(enumMapping);
            map2Arylist.forEach(item => {
              let find = list.find(i => String(i.enum) === String(item.enum));
              if (find) {
                item.value = Number(find.value).toString(16);
              }
            });
            if (map2Arylist && map2Arylist.length > 0) {
              return (
                <Space>
                  <Button type="link" onClick={() => showMappingInfoFn?.(map2Arylist)}>
                    查看
                  </Button>
                </Space>
              );
            }
          }
        }
        return '--';
      },
    },
    {
      title: '乘积系数k',
      dataIndex: 'k',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={record.parameters?.k} />,
    },
    {
      title: '偏移系数b',
      dataIndex: 'b',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => <EllipsisSpan value={record.parameters?.b} />,
    },
    {
      title: '位操作模式',
      dataIndex: 'mode',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const { bitMode, bitNum, byteNum } = record.parameters;
        const value = getMode(bitMode, bitNum, byteNum);
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '数据过滤模式',
      dataIndex: 'valueFilterMode',
      ellipsis: { showTitle: true },
      width: 120,
      render: (_: undefined, record) => {
        return (
          <EllipsisSpan
            value={FilterModeDisplay[record.parameters?.valueFilterMode as keyof typeof FilterModeDisplay]}
          />
        );
      },
    },
    {
      title: '数值合理区间',
      dataIndex: 'dataRange',
      ellipsis: { showTitle: true },
      width: 120,
      render: (_: undefined, record) => {
        const value = getRange(record.parameters?.valueFloor, record.parameters?.valueCeiling);
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '云端增长值合理区间',
      dataIndex: 'cloudValueRange',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        const value = getRange(record.parameters?.cloudValueFloor, record.parameters?.cloudValueCeiling);
        return <EllipsisSpan value={value} />;
      },
    },
    {
      title: '云端异常数据过滤个数',
      dataIndex: 'cloudValueFilterCnt',
      ellipsis: { showTitle: true },
      render: (_: undefined, record) => {
        return <EllipsisSpan value={record.parameters.cloudValueFilterCnt} />;
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      ellipsis: { showTitle: true },
      render: (value: string) => <EllipsisSpan value={value} />,
    },
  ];
};
