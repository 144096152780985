import {
  Radio,
  Select,
  Wrapper,
  useBreadcrumbRoutes,
  DatePicker,
  Button,
  Key,
  Empty,
  Form,
  FormTitle,
  useUpdate,
} from '@maxtropy/components';
import { Col, Row, Space, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import MediumSwipper from './components/MediumSwipper';
import UnitSankey from './components/UnitSankey';
import UnitEnergyDetailModal from './components/UnitEnergyDetailModal';
import {
  EnergyFlowChartResponse,
  EnergyUnitGroupListResponseListItem,
  checkEnergyUnitGroupPermission,
  getEnergyFlowChart,
  getEnergyMediumAggregate,
} from '@/api/plustekSankey';
import { useSearchParams } from 'react-router-dom';
import { isNil } from 'lodash';
import React from 'react';
import { useHasPermission } from '@/utils/utils';
import styles from './index.module.scss';
import {
  apiV2EnergyAnalysisListByOuPost,
  apiV2LeanEnergyFlowAnalysisConfigDetailPost,
} from '@maxtropy/device-customer-apis-v2';
import LevelConfigModal from './components/LevelConfigModal';
import { InfoCircleOutlined } from '@ant-design/icons';
import AlModal from './components/AlModal';
import defaultIcon from './assets/defaultIcon.gif';
import hoverIcon from './assets/hoverIcon.gif';
import disabledIcon from './assets/disabledIcon.png';
import { PermissionsType } from '@/common/permissionsConst';

const RangePicker = DatePicker.RangePicker;
type RangeValue = [Dayjs | null, Dayjs | null] | null;

const UnitSankeyMemo = React.memo(UnitSankey);

export enum RadioType {
  DAY = 1,
  MONTH = 2,
}

export interface SwipperListItem {
  id: Key; //能源工质id
  name: string; //能源工质名称
  physicalUnitName: string; //物理单位名称
  energyConsumption: number | null; //能耗用量
  momRatio: number | null; //环比
}

export const getEmpty = (description?: string) => {
  return (
    <div style={{ width: '100%', height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Empty description={description} />
    </div>
  );
};

function formatTime(time: [dayjs.Dayjs, dayjs.Dayjs], radioType: RadioType) {
  if (radioType === RadioType.DAY) {
    return {
      fromTime: time[0].format('YYYY-MM-DD'),
      toTime: time[1].format('YYYY-MM-DD'),
    };
  }
  return {
    fromTime: dayjs(time[0]).startOf('month').format('YYYY-MM-DD'),
    toTime: dayjs(time[1]).endOf('month').format('YYYY-MM-DD'),
  };
}

const PlustekSankey: React.FC = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [form] = Form.useForm();
  const [unitGroup, setUnitGroup] = useState<EnergyUnitGroupListResponseListItem[]>([]); // 用能分析组
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const time = Form.useWatch('time', form);
  const radioType = Form.useWatch('radioType', form);
  const energyUnitGroupId = Form.useWatch('energyUnitGroupId', form);
  const [swipperList, setSwipperList] = useState<SwipperListItem[]>([]); // 中间轮播图数据
  const [selectedMediumId, setSelectedMediumId] = useState<Key>('total'); // 默认选中综合能耗

  const [originChartData, setOriginChartData] = useState<EnergyFlowChartResponse>({
    nodes: [],
    links: [],
  });
  const [energyFlowChartData, setEnergyFlowChartData] = useState<EnergyFlowChartResponse>({
    nodes: [],
    links: [],
  });
  const [physicalUnitName, setPhysicalUnitName] = useState<string>('kgce');
  const [hasUnitPermission, setHasUnitPermission] = useState<boolean>(); // 用能分析组是否拥有所有单元权限
  const [swipperLoading, setSwipperLoading] = useState<boolean>(false);
  const [flowChartLoading, setFlowChartLoading] = useState<boolean>(false);
  const [chartSelectedUnitId, setChartSelectedUnitId] = useState<Key>();
  const [isMedium, setIsMedium] = useState<boolean>(false);

  const [configModalOpen, setConfigModalOpen] = useState<boolean>(false);
  const [fromLevel, setFromLevel] = useState<number>();
  const [toLevel, setToLevel] = useState<number>();
  const [openAlModal, setOpenAlModal] = useState<boolean>(false);

  const [updateState, updateFn] = useUpdate();
  const hasAIAnalysis = useHasPermission(PermissionsType.B_AI_UNIT_ANALYSIS);

  const [urlSearchParams] = useSearchParams();
  let url_radioType = urlSearchParams.get('radioType') ?? undefined;
  let url_time = urlSearchParams.get('time') ?? undefined;
  let url_unitGroupId = urlSearchParams.get('unitGroupId') ?? undefined;

  useEffect(() => {
    if (url_radioType && url_time && url_unitGroupId) {
      form.setFieldsValue({
        radioType: Number(url_radioType),
        time: [dayjs(url_time), dayjs(url_time)],
        energyUnitGroupId: Number(url_unitGroupId),
      });
    }
  }, [url_radioType, url_time, url_unitGroupId, form]);

  useEffect(() => {
    // 获取用能分析组
    apiV2EnergyAnalysisListByOuPost({}).then(res => {
      if (res) {
        setUnitGroup((res.list ?? []) as EnergyUnitGroupListResponseListItem[]);
        if (isNil(url_unitGroupId)) {
          if (res && res.list && res.list.length > 0) {
            form.setFieldsValue({ energyUnitGroupId: res.list[0].id });
          }
        }
      }
    });
  }, [form, url_unitGroupId]);

  useEffect(() => {
    if (energyUnitGroupId) {
      // 校验当前staff是否拥有分析组权限
      checkEnergyUnitGroupPermission(energyUnitGroupId).then(res => {
        setHasUnitPermission(res.hasPermission);
      });
      // 获取层级配置
      apiV2LeanEnergyFlowAnalysisConfigDetailPost({ energyGroupId: energyUnitGroupId }).then(res => {
        setFromLevel(res.fromLevel ?? 1);
        setToLevel(res.toLevel ?? Math.min(3, res.maxLevel ?? 1));
      });
    }
  }, [energyUnitGroupId, updateState]);

  useEffect(() => {
    if (energyUnitGroupId && hasUnitPermission && time?.length === 2) {
      // 获取能流能源工质下聚合数据
      setSwipperLoading(true);
      const t = formatTime(time, radioType);
      getEnergyMediumAggregate({
        ...t,
        energyUnitGroupId,
      })
        .then(res => {
          const list = (res.energyMediumData ?? []).map(i => ({
            id: i.energyMediumId,
            name: i.energyMediumName,
            physicalUnitName: i.physicalUnitName,
            energyConsumption: i.energyConsumption,
            momRatio: i.momRatio,
          }));
          if (list.length > 0) {
            list.unshift({
              id: 'total',
              name: '综合能耗',
              physicalUnitName: 'kgce',
              energyConsumption: res.energyConsumptionStandardCoal,
              momRatio: res.momRatio,
            });
          }
          // 如果当前选中的工质不在列表中，则默认选中综合能耗
          if (selectedMediumId !== 'total' && list.findIndex(i => i.id === selectedMediumId) === -1) {
            setSelectedMediumId('total');
            setIsMedium(false);
          }
          setSwipperList(list);
        })
        .finally(() => {
          setSwipperLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyUnitGroupId, hasUnitPermission, radioType, time]);

  useEffect(() => {
    if (energyUnitGroupId && hasUnitPermission && time?.length === 2 && selectedMediumId) {
      setFlowChartLoading(true);
      const t = formatTime(time, radioType);
      // 获取能流图数据
      getEnergyFlowChart({
        ...t,
        energyUnitGroupId,
        energyMediumId: selectedMediumId === 'total' ? undefined : selectedMediumId,
      })
        .then(res => {
          setOriginChartData({
            nodes: (res.nodes ?? [])
              .map(i => ({
                ...i,
                name: i.key,
              }))
              .filter(i => !isNil(i.value) && i.value !== 0),
            links: res.links.filter(i => !isNil(i.value) && i.value !== 0),
          });
        })
        .finally(() => {
          setFlowChartLoading(false);
        });
    }
  }, [energyUnitGroupId, hasUnitPermission, radioType, selectedMediumId, time]);

  useEffect(() => {
    if (fromLevel && toLevel) {
      setEnergyFlowChartData({
        nodes: originChartData.nodes.filter(i => i.depth + 1 >= fromLevel && i.depth + 1 <= toLevel),
        links: originChartData.links,
      });
    }
  }, [fromLevel, toLevel, originChartData]);

  useEffect(() => {
    if (selectedMediumId) {
      setIsMedium(selectedMediumId !== 'total');
    }
  }, [selectedMediumId]);

  const unitOptions = useMemo(() => {
    return (unitGroup ?? []).map(item => ({ label: item.name, value: item.id }));
  }, [unitGroup]);

  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate =
      radioType === RadioType.DAY
        ? dates[0] && current.diff(dates[0], 'day') >= 90
        : dates[0] && current.diff(dates[0], 'month') >= 23;

    const tooEarly =
      radioType === RadioType.DAY
        ? dates[1] && dates[1].diff(current, 'day') >= 90
        : dates[1] && dates[1].diff(current, 'month') >= 23;
    return !!tooEarly || !!tooLate || current.isAfter(dayjs());
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      form.setFieldsValue({
        time: undefined,
      });
      setDates([null, null]);
    } else {
      if (Object.values(dates ?? {}).every(i => isNil(i))) {
        setDates([dayjs().startOf('month'), dayjs()]);
        form.setFieldsValue({
          time: [dayjs().startOf('month'), dayjs()],
        });
      } else {
        setDates(null);
      }
    }
  };

  return (
    <div id="energyAnalysis">
      <Wrapper routes={[...(breadcrumbRoutes?.routes ?? [])]} className={styles.wrapper}>
        <FormTitle
          title="精益能流图"
          extraContent={
            hasAIAnalysis && (
              <div
                className={`${styles.flowingBtn} ${openAlModal ? styles.disabledBtn : ''}`}
                onClick={() => {
                  if (openAlModal) return;
                  setOpenAlModal(true);
                }}
              >
                {!openAlModal && (
                  <>
                    <img className={styles.aiDefaultIcon} src={defaultIcon} alt="" width={28} />
                    <img className={styles.aiHoverIcon} src={hoverIcon} alt="" width={28} />
                  </>
                )}
                {openAlModal && <img src={disabledIcon} alt="" width={28} />}
                AI分析
              </div>
            )
          }
        ></FormTitle>
        <div>
          <Form
            isOrigin={true}
            form={form}
            initialValues={{
              radioType: RadioType.DAY,
              time: [dayjs().startOf('month'), dayjs()],
            }}
          >
            <Row gutter={24}>
              <Col span={5}>
                <Form.Item label="用能分析组" name="energyUnitGroupId">
                  <Select options={unitOptions} placeholder="请选择" />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Space size={16}>
                  <Form.Item name="radioType">
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={RadioType.DAY}>按日</Radio.Button>
                      <Radio.Button value={RadioType.MONTH}>按月</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="时间选择" name="time">
                    {radioType === 1 ? (
                      <RangePicker
                        value={dates || value}
                        disabledDate={disabledDate}
                        onCalendarChange={val => setDates(val)}
                        onChange={val => setValue(val)}
                        onOpenChange={onOpenChange}
                        allowClear={false}
                      />
                    ) : (
                      <RangePicker
                        value={dates || value}
                        disabledDate={disabledDate}
                        onCalendarChange={val => setDates(val)}
                        onChange={val => setValue(val)}
                        onOpenChange={onOpenChange}
                        picker="month"
                        allowClear={false}
                      />
                    )}
                  </Form.Item>
                </Space>
              </Col>
              <Col span={8}>
                <div style={{ float: 'right' }}>
                  <Space size={8}>
                    <div style={{ color: 'var(--mx-text-desc-color)' }}>
                      <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)', marginRight: 4 }} />
                      当前能流图展示层级：{fromLevel}-{toLevel}级
                    </div>
                    <Button
                      type="primary"
                      onClick={() => {
                        setConfigModalOpen(true);
                      }}
                    >
                      配置
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    >
                      单元能流详情
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>

          {!isNil(hasUnitPermission) ? (
            hasUnitPermission ? (
              swipperList.length > 0 ? (
                <>
                  <Spin spinning={swipperLoading}>
                    <MediumSwipper
                      list={swipperList}
                      setIsMedium={setIsMedium}
                      selectedMediumId={selectedMediumId}
                      setSelectedMediumId={setSelectedMediumId}
                      setPhysicalUnitName={setPhysicalUnitName}
                    />
                  </Spin>
                  <Spin spinning={flowChartLoading}>
                    <UnitSankeyMemo
                      isMedium={isMedium}
                      data={energyFlowChartData}
                      physicalUnitName={physicalUnitName}
                      setModalOpen={setModalOpen}
                      setChartSelectedUnitId={setChartSelectedUnitId}
                    />
                  </Spin>
                </>
              ) : (
                getEmpty()
              )
            ) : (
              getEmpty('当前用能分析组下用能单元权限不完整')
            )
          ) : (
            getEmpty()
          )}
        </div>

        {modalOpen && (
          <UnitEnergyDetailModal
            energyUnitGroupId={energyUnitGroupId}
            time={formatTime(time, radioType)}
            radioType={radioType}
            open={modalOpen}
            setOpen={setModalOpen}
            chartSelectedUnitId={chartSelectedUnitId}
            setChartSelectedUnitId={setChartSelectedUnitId}
            selectedMediumId={selectedMediumId}
            isMedium={isMedium}
          />
        )}

        {configModalOpen && (
          <LevelConfigModal
            energyUnitGroupId={energyUnitGroupId}
            open={configModalOpen}
            setOpen={setConfigModalOpen}
            updateFn={() => updateFn()}
          />
        )}

        {openAlModal && <AlModal setOpen={setOpenAlModal} />}
      </Wrapper>
    </div>
  );
};

export default PlustekSankey;
