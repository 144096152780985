import { Row, Space, Modal as AntModal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BatchEdgeContent } from './contentTypes';
import BatchEdgeForm, { BatchEdgeFormOptions } from './Form';
import useUpdate from '../../hooks/useUpdate';
import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import { Button, Input, Modal, Form, FormTitle, SubContent } from '@maxtropy/components';
import { increment } from './utils';
import BatchEdgePoint from './Mockingbird';
import { EdgeDeviceTemplatePoint } from '../EdgeDevicePointInfo';
import { ActionType, DataPointType } from '../../types';
import { LoadingOutlined } from '@ant-design/icons';
import {
  batchAddPointLock,
  batchDriveTypeFormLock,
  batchUpdatePointLock,
  bathAddPoint,
  bathDeletePoint,
  bathUpdateDriveTypeForm,
  bathUpdatePoint,
  getBatchDeviceResult,
} from '../../api/edgeDevice';
import { UploadFile } from 'antd/es/upload/interface';
import DriveTypeForm, { DriveTypeFormRef, driveTypeLockItems } from './DriveType';
import { OperateType, PointOperateType } from './interface';
import ErrorMsgModal from './ErrorMsgModal';
import Lock from './Lock';

interface DeviceBathEdgeProps {
  isMgmt: boolean;
}

const DeviceBatchEdge: React.FC<DeviceBathEdgeProps> = props => {
  const { isMgmt } = props;
  const [baseForm] = Form.useForm();
  const [invalidForm] = Form.useForm();
  const navigate = useNavigate();
  const update = useUpdate();
  const [locked, setLocked] = useState(false);
  const [points, setPoints] = useState<EdgeDeviceTemplatePoint[]>([]);
  const previousDataPropertyIds = useRef<number[]>([]);
  const [serial, setSerial] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [errorMsg, setErrorMsg] = useState<string[]>();
  const [errorMsgModalVisible, setErrorMsgModalVisible] = useState<boolean>(false);
  const [checkedDriveTypeNames, setCheckedDriveTypeNames] = useState<string[]>([]);
  const driveTypeFormRef: MutableRefObject<DriveTypeFormRef | null> = useRef(null);
  const { operateType, pointOperateType, pointType, hasProperty, dataPropertyIds, parameter } =
    baseForm.getFieldsValue();
  const [modalApi, modalContextHolder] = AntModal.useModal();

  const makeLoadingModel = () =>
    modalApi.info({
      className: 'loading-modal',
      title: '正在校验信息',
      content: (
        <div className="loading-modal-content">
          <div className="loading-modal-icon">
            <LoadingOutlined />
          </div>
          <div className="loading-modal-txt">上传表格信息校验，若信息无误，则开始进行批操作。</div>
        </div>
      ),
    });

  const lockVisible = useMemo(() => {
    return (
      (pointOperateType === PointOperateType.ADD &&
        points.some(point => (point as any).pointType === DataPointType.BASE_POINT)) ||
      (pointOperateType === PointOperateType.UPDATE &&
        pointType === DataPointType.BASE_POINT &&
        hasProperty &&
        dataPropertyIds?.length > 0 &&
        (parameter ?? []).filter((item: string) => item !== 'other').length > 0) ||
      (!hasProperty && (parameter ?? []).filter((item: string) => item !== 'other').length > 0) ||
      (operateType === OperateType.DRIVE_TYPE_PARAMETERS &&
        checkedDriveTypeNames.some(item => driveTypeLockItems.includes(item)))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operateType, pointOperateType, dataPropertyIds, parameter, points, checkedDriveTypeNames]);

  const submitDisabled = useMemo(() => {
    return lockVisible && fileList.length === 0;
  }, [lockVisible, fileList]);

  const resetPoints = () => {
    previousDataPropertyIds.current = [];
    setPoints([]);
  };

  const resetDataPropertyIds = () => {
    previousDataPropertyIds.current = [];
  };

  const resetDriveTypeForm = () => {
    driveTypeFormRef.current?.form?.resetFields();
    driveTypeFormRef.current?.resetCheckbox?.();
  };

  const onValuesChange = (changedValues: any, values: any, options: BatchEdgeFormOptions) => {
    console.log('changedValues', changedValues);
    if (changedValues.hasOwnProperty('tenantMcid')) {
      baseForm.resetFields([
        'deviceIds',
        'pointOperateType',
        'pointType',
        'hasProperty',
        'dataPropertyIds',
        'parameter',
      ]);
      resetPoints();
      resetDataPropertyIds();
      resetDriveTypeForm();
    }
    if (changedValues.hasOwnProperty('deviceType')) {
      baseForm.resetFields([
        'deviceIds',
        'pointOperateType',
        'pointType',
        'hasProperty',
        'dataPropertyIds',
        'parameter',
        'objectModalType',
      ]);
      resetPoints();
      resetDataPropertyIds();
      resetDriveTypeForm();
    }
    if (changedValues.hasOwnProperty('objectModalType')) {
      baseForm.resetFields([
        'deviceIds',
        'pointOperateType',
        'pointType',
        'hasProperty',
        'dataPropertyIds',
        'parameter',
      ]);
      resetPoints();
      resetDataPropertyIds();
      resetDriveTypeForm();
    }
    if (changedValues.hasOwnProperty('iotProtocol')) {
      baseForm.resetFields([
        'driveType',
        'pointOperateType',
        'pointType',
        'hasProperty',
        'dataPropertyIds',
        'parameter',
      ]);
      resetPoints();
      resetDataPropertyIds();
      resetDriveTypeForm();
    }
    if (changedValues.hasOwnProperty('driveType')) {
      baseForm.resetFields([
        'deviceIds',
        'pointOperateType',
        'pointType',
        'hasProperty',
        'dataPropertyIds',
        'parameter',
      ]);
      resetPoints();
      resetDataPropertyIds();
      resetDriveTypeForm();
    }
    if (changedValues.hasOwnProperty('operateType')) {
      baseForm.resetFields(['pointOperateType', 'pointType', 'hasProperty', 'dataPropertyIds', 'parameter']);
      resetPoints();
      resetDataPropertyIds();
      resetDriveTypeForm();
    }
    if (changedValues.hasOwnProperty('pointOperateType')) {
      baseForm.resetFields(['pointType', 'hasProperty', 'dataPropertyIds', 'parameter']);
      resetPoints();
      resetDataPropertyIds();
    }
    if (changedValues.hasOwnProperty('pointType')) {
      baseForm.resetFields(['hasProperty', 'dataPropertyIds', 'parameter']);
      resetPoints();
      resetDataPropertyIds();
    }
    if (changedValues.hasOwnProperty('hasProperty')) {
      baseForm.resetFields(['dataPropertyIds', 'parameter']);
      resetPoints();
      resetDataPropertyIds();
    }
    if (changedValues.hasOwnProperty('dataPropertyIds')) {
      onDataPropertyIdsChange(options);
    }
    if (changedValues.hasOwnProperty('parameter')) {
      onParameterChange();
    }
    setTimeout(() => {
      update();
    });
  };

  const onDataPropertyIdsChange = (options: BatchEdgeFormOptions) => {
    const { dataProperties } = options;
    const { pointType, dataPropertyIds, hasProperty } = baseForm.getFieldsValue();
    const [addSection, deleteSection] = increment(previousDataPropertyIds.current, dataPropertyIds);
    previousDataPropertyIds.current = dataPropertyIds;
    const _points = points
      .filter(point => !deleteSection.includes(point.dataPropertyId))
      .concat(
        addSection.map((dataPropertyId: number) => ({
          pointType: pointType,
          hasProperty,
          dataPropertyId,
          dataPropertyName: dataProperties.find(({ value }) => value === dataPropertyId)?.label,
          parameters: {},
          actionType: ActionType.NONE,
        })) as EdgeDeviceTemplatePoint[]
      );
    setPoints(_points);
  };

  const onParameterChange = () => {
    const { parameter } = baseForm.getFieldsValue();
    let _points;
    if (!parameter.includes('other')) {
      _points = points.map((point: any) => ({
        pointType: point.pointType,
        hasProperty: point.hasProperty,
        ...(point.hasProperty
          ? {
              dataPropertyId: point.dataPropertyId,
              dataPropertyName: point.dataPropertyName,
            }
          : {
              identifier: point.identifier,
            }),
        parameters: {},
        actionType: ActionType.ADD,
      }));
    } else {
      _points = points.map(point => ({
        ...point,
        actionType: ActionType.NONE,
      }));
    }
    setPoints(_points as EdgeDeviceTemplatePoint[]);
  };

  const confirmCompleteInfo = () => {
    const { pointOperateType, parameter, pointType } = baseForm.getFieldsValue();
    if (
      pointOperateType === PointOperateType.UPDATE &&
      ((pointType === DataPointType.BASE_POINT && parameter.includes('other')) ||
        pointType === DataPointType.VIRTUAL_POINT)
    ) {
      const noneList = points.filter(point => point.actionType === ActionType.NONE);
      if (noneList.length > 0) {
        Modal.warning({
          title: '信息填写不完整，请检查后再锁定！',
          okText: '确定',
        });
        return Promise.reject();
      }
    }
    return Promise.resolve();
  };

  const onLockDriveTypeForm = async () => {
    const values = driveTypeFormRef.current?.form?.getFieldsValue();
    const { tenantMcid, deviceType, iotProtocol, deviceIds, driveType } = baseForm.getFieldsValue();
    const data = await batchDriveTypeFormLock(
      {
        tenantMcid,
        iotProtocol,
        deviceIds,
        serial,
        driveType,
        deviceTypeId: deviceType.slice(-1)[0],
        updateItems: checkedDriveTypeNames,
        ...values,
      },
      tenantMcid
    );
    setSerial(data?.serial);
  };

  const onLockPoints = async () => {
    let params = baseForm.getFieldsValue();
    params = {
      ...params,
      deviceTypeId: params.deviceType.slice(-1)[0],
      points: points,
      parameter: params.parameter ?? [],
      physicalModelId: params.objectModalType,
    };
    const length = (params.deviceIds ?? []).length * (points ?? []).length;
    if (length > 100000) {
      Modal.warning({
        title: '数据量过大',
        content: '系统仅支持设备数量*数据点数量≤100000',
        okText: '确定',
      });
      return Promise.reject();
    }
    if (pointOperateType === PointOperateType.ADD) {
      const data = await batchAddPointLock(params);
      setSerial(data?.serial);
    } else {
      const data = await batchUpdatePointLock(params);
      setSerial(data?.serial);
    }
  };

  const onLock = async () => {
    await baseForm.validateFields();
    const { operateType } = baseForm.getFieldsValue();
    if (operateType === OperateType.DRIVE_TYPE_PARAMETERS) {
      await driveTypeFormRef.current?.form?.validateFields();
    }
    await confirmCompleteInfo();
    try {
      if (operateType === OperateType.DRIVE_TYPE_PARAMETERS) {
        await onLockDriveTypeForm();
      } else {
        await onLockPoints();
      }
      setLocked(true);
    } catch (e) {
      console.error(e);
    }
  };

  const onUnLock = () => {
    Modal.confirm({
      title: '确定解除锁定吗？',
      content: '原模版失效，再次锁定后会生成新的模版',
      okText: '确定',
      onOk: () => {
        setLocked(false);
        setSerial('');
        setFileList([]);
        setErrorMsg(undefined);
      },
    });
  };

  const onDownload = async () => {
    setDownloading(true);
    const { operateType, pointOperateType, tenantMcid } = baseForm.getFieldsValue();
    try {
      if (operateType === OperateType.DRIVE_TYPE_PARAMETERS) {
        downloadFile(`${serial}.xlsx`, `/api/v2/batch/edgeDevice/update/download?serial=${serial}`);
      } else {
        if (pointOperateType === PointOperateType.ADD) {
          downloadFile(`${serial}.xlsx`, `/api/v2/batch/edgeDevice/point/add/download?serial=${serial}`);
        } else {
          downloadFile(`${serial}.xlsx`, `/api/v2/batch/edgeDevice/point/update/download?serial=${serial}`);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setDownloading(false);
    }
  };

  const confirmSubmit = async () => {
    await baseForm.validateFields();
    let params = baseForm.getFieldsValue();
    const { operateType } = params;
    if (operateType === OperateType.DRIVE_TYPE_PARAMETERS) {
      await driveTypeFormRef.current?.form?.validateFields();
    }
    await confirmCompleteInfo();
    Modal.confirm({
      title: (
        <>
          <Form form={invalidForm} layout="vertical">
            <Form.Item label="请输入操作密码" name="password" rules={[{ required: true, message: '请输入操作密码' }]}>
              <Input.Password autoComplete="new-password" placeholder="请输入操作密码" />
            </Form.Item>
          </Form>
        </>
      ),
      onOk: () => {
        return invalidForm.validateFields().then(value => onSave(value.password));
      },
      afterClose: () => {
        invalidForm.resetFields();
      },
    });
  };

  const onSaveDriveTypeForm = (password: string) => {
    const { tenantMcid, deviceType, iotProtocol, deviceIds, driveType } = baseForm.getFieldsValue();
    const values = driveTypeFormRef.current?.form?.getFieldsValue();
    const params = {
      tenantMcid,
      iotProtocol,
      deviceIds,
      serial,
      driveType,
      deviceTypeId: deviceType.slice(-1)[0],
      updateItems: checkedDriveTypeNames,
      ...values,
    };
    const formData = new FormData();
    formData.append('param', JSON.stringify(params));
    if (checkedDriveTypeNames.some(item => driveTypeLockItems.includes(item))) {
      formData.append('file', fileList?.[0] as unknown as Blob);
    }
    formData.append('password', password);
    formData.append('tenantMcid', params.tenantMcid);
    return bathUpdateDriveTypeForm(formData);
  };

  const onSavePoints = (password: string) => {
    let params = baseForm.getFieldsValue();
    params = {
      ...params,
      serial,
      parameter: params.parameter ?? [],
      deviceTypeId: params.deviceType.slice(-1)[0],
      points: points,
      physicalModelId: params.objectModalType,
    };
    const formData = new FormData();
    formData.append('param', JSON.stringify(params));
    formData.append('file', fileList?.[0] as unknown as Blob);
    formData.append('password', password);
    formData.append('tenantMcid', params.tenantMcid);
    if (pointOperateType === PointOperateType.ADD) {
      return bathAddPoint(formData);
    } else if (pointOperateType === PointOperateType.UPDATE) {
      return bathUpdatePoint(formData);
    } else {
      return bathDeletePoint(formData);
    }
  };

  const fetchBatchDeviceResult = (res: { key: string }) =>
    new Promise(async resolve => {
      const poll = async () => {
        try {
          const response = await getBatchDeviceResult(res.key).onError(error => {
            Modal.error({
              title: error.cause.errorMessage ?? '未知错误！ 请联系管理员。',
            });
            throw error;
          });

          if (response.hasResult) {
            resolve(response.hasResult);
          } else {
            setTimeout(poll, 1000); // 递归调用，实现轮询
          }
        } catch (e) {
          resolve(false);
        }
      };

      poll(); // 初次调用
    });

  const onSave = async (password: string) => {
    const { operateType } = baseForm.getFieldsValue();
    const modal = makeLoadingModel();
    try {
      const method =
        operateType === OperateType.DRIVE_TYPE_PARAMETERS ? onSaveDriveTypeForm(password) : onSavePoints(password);

      method
        .onError(err => {
          Modal.error({
            title: err.cause.errorMessage ?? '未知错误！ 请联系管理员。',
          });
          modal.destroy();
          throw err;
        })
        .then(async res => {
          const result = await fetchBatchDeviceResult(res);
          modal.destroy();
          if (result) {
            Modal.success({
              title: '批操作成功',
              okText: '确定',
              onOk: () => navigate(-1),
            });
          }
        });
    } catch (e) {
      modal.destroy();
      handleError(e);
    }
  };

  const handleError = (error: any) => {
    const { errorMessage, status } = error;
    if (status === 410) {
      const msg = JSON.parse(errorMessage);
      setErrorMsg(msg);
      setErrorMsgModalVisible(true);
    } else if (status === 411) {
      Modal.error({
        title: '模板错误',
        content: '请在当前页面，下载最新的模版上传',
        okText: '确定',
      });
    } else if (status === 412) {
      Modal.error({
        title: '设备必须保留一个数据点',
        content: `${errorMessage}设备必须保留一个数据点，请修改后再进行批操作。`,
        okText: '确定',
      });
    }
  };

  return (
    <>
      <BatchEdgeContent.Provider
        value={{
          baseForm,
          isMgmt,
        }}
      >
        <FormTitle title="批量修改极熵协议数采" />
        <SubContent style={{ minHeight: '100vh' }}>
          <BatchEdgeForm form={baseForm} onValuesChange={onValuesChange} onFieldsChange={update} disabled={locked} />
          {operateType === OperateType.DATA_POINT && (
            <div style={{ marginBottom: 44 }}>
              <BatchEdgePoint dataSource={points} setDataSource={setPoints} editable={!locked} />
            </div>
          )}
          {operateType === OperateType.DRIVE_TYPE_PARAMETERS && (
            <Row style={{ marginBottom: 44 }}>
              <DriveTypeForm
                ref={driveTypeFormRef}
                driveType={baseForm.getFieldValue('driveType')}
                disabled={locked}
                updateCheckedItems={setCheckedDriveTypeNames}
              />
            </Row>
          )}
          {lockVisible && (
            <Lock
              style={{ marginBottom: 44, background: 'rgba(0, 0, 0, 0.03)', padding: 10 }}
              locked={locked}
              serial={serial}
              downloading={downloading}
              fileList={fileList}
              setFileList={setFileList}
              onLock={onLock}
              onUnLock={onUnLock}
              onDownload={onDownload}
              extra={
                errorMsg && (
                  <Button type="link" onClick={() => setErrorMsgModalVisible(true)}>
                    查看错误信息
                  </Button>
                )
              }
            />
          )}
          <Space size={8} className="sticky-footer-left">
            <Button type="primary" onClick={confirmSubmit} disabled={submitDisabled}>
              确定
            </Button>
            <Button onClick={() => navigate('/device/manage/device')}>取消</Button>
          </Space>
        </SubContent>
        <ErrorMsgModal
          visible={errorMsgModalVisible}
          onCancel={() => setErrorMsgModalVisible(false)}
          errorMsg={errorMsg}
          serial={serial}
        />
        {modalContextHolder}
      </BatchEdgeContent.Provider>
    </>
  );
};

async function downloadFile(name: string, url: string) {
  const res = await window.fetch(url);
  const blob = await res.blob();
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  link.click();
  window.URL.revokeObjectURL(link.href);
}

export default DeviceBatchEdge;
