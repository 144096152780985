import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import './App.scss';

import routers from './routes';
import { CustomApp, useThemeContext } from '@maxtropy/components';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { init } from '@maxtropy/web-monitor';
// 监控sdk
init({
  remoteUrl: `${window.LOGCOLLECT}/api/log-collect`,
  token: 'cIjVVwDJ0UbVXjZ',
  appName: 'dc',
});
const App: React.FC = () => {
  const { setCurrentTheme, ...theme } = useThemeContext();

  useEffect(() => {
    const dataListener = (data: any) => {
      if (data?.theme) {
        setCurrentTheme?.(data.theme.theme || theme.theme); // 更新子应用的主题
      }
    };

    window.microApp?.addDataListener(dataListener, true);

    return () => {
      window.microApp?.removeDataListener(dataListener);
    };
  }, []);

  return (
    <div className="App">
      <ConfigProvider locale={zhCN} theme={theme} space={{ size: 3 }}>
        <CustomApp style={{ height: '100%' }}>
          <RouterProvider router={routers} />
        </CustomApp>
      </ConfigProvider>
    </div>
  );
};

export default App;
