import { BaseInfoContent, Button, DatePicker, Form, Modal, ShowInput } from '@maxtropy/components';
import { Col, Row, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { getChartOption } from './chart';
import { useRequest } from 'ahooks';
import { apiV2ReasoningPredictListPost } from '@maxtropy/device-customer-apis-v2';
import { PredictDataGranularType } from '../../utils';
import styles from './index.module.scss';

export interface IViewPredictionsModal {
  modelId?: number;
  visible: boolean;
  onCancel: () => void;
  predictDataGranular?: PredictDataGranularType;
}

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { RangePicker } = DatePicker;

export type RangeValue = [Dayjs | null, Dayjs | null] | null;

const ViewPredictionsModal: FC<IViewPredictionsModal> = props => {
  const { modelId, visible, onCancel, predictDataGranular } = props;
  const [form] = Form.useForm();
  const chartRef = useRef<ReactEcharts>();
  // 日期选择器默认选择当天
  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>([dayjs(), dayjs()]);
  const [displayValue, setDisplayValue] = useState<any>(dayjs());
  const [rangeTime, setRangeTime] = useState<RangeValue>([dayjs(), dayjs()]);

  // 查看预测曲线
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2ReasoningPredictListPost({
        id: modelId,
        startTs: rangeTime?.[0]?.startOf('day').valueOf(),
        endTs: rangeTime?.[1]?.endOf('day').valueOf(),
      });
    },
    {
      ready: !!rangeTime && !!modelId && visible,
      refreshDeps: [rangeTime, modelId, visible],
    }
  );

  useEffect(() => {
    if (!chartData) return;
    form.setFieldsValue({
      // deviceName: chartData.deviceName,
      modelName: chartData.modelName,
      propertyName: chartData.propertyName,
    });
  }, [chartData]);

  const disabledDate = (current: Dayjs) => {
    // 当天起始时间
    let currentStartTime = dayjs(dayjs().format('YYYY-MM-DD') + ' 0:0:0');
    return current && current < currentStartTime;
  };

  // 时间跨度为31天 这里应该是form的颗粒度决定跨度
  const customDisabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 31;
    const tooEarly = current && current > dayjs().endOf('day');
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  // format startDate endDate
  useEffect(() => {
    if (predictDataGranular !== PredictDataGranularType.MIN) {
      setRangeTime([value?.[0]?.startOf('D') ?? null, value?.[1]?.endOf('D') ?? null]);
    } else {
      const startTime = displayValue?.startOf('day');
      const endTime = displayValue?.endOf('day');
      setRangeTime([startTime, endTime]);
    }
  }, [value, predictDataGranular, displayValue]);

  // 查询图表
  const chartOption = useMemo(() => getChartOption(chartData), [chartData]);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().resize();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal
      contentClassName="modal-form-content"
      size="big"
      destroyOnClose
      open={visible}
      onCancel={onCancel}
      title={'查看预测'}
      footer={<Button onClick={onCancel}>关闭</Button>}
    >
      <Form form={form} {...formLayout} labelAlign="left">
        <BaseInfoContent colNum={3} layout={'horizontal'}>
          <Form.Item name="deviceName" label="预测对象">
            <ShowInput />
          </Form.Item>

          <Form.Item name="modelName" label="模型">
            <ShowInput />
          </Form.Item>

          <Form.Item
            name="propertyName"
            className={styles.long_label}
            label={<span style={{ whiteSpace: 'nowrap' }}>预测数据属性</span>}
          >
            <ShowInput />
          </Form.Item>
        </BaseInfoContent>
        <Row style={{ marginTop: 20 }}>
          <Col span={6}>
            <Form.Item label="预测时间">
              {predictDataGranular === PredictDataGranularType.MIN && (
                <DatePicker
                  style={{ width: 220 }}
                  disabledDate={disabledDate}
                  allowClear={false}
                  onChange={setDisplayValue}
                  value={displayValue}
                />
              )}
              {/* {predictDataGranular === PredictDataGranularType.MONTH && (
                <RangePicker
                  type="month"
                  style={{ width: '250px' }}
                  disabledDate={customDisabledDate}
                  allowClear={false}
                />
              )} */}

              {predictDataGranular === PredictDataGranularType.DAY && (
                <RangePicker
                  type="date"
                  style={{ width: 220 }}
                  disabledDate={customDisabledDate}
                  onCalendarChange={val => {
                    setDates(val);
                  }}
                  onChange={val => {
                    setValue(val);
                  }}
                  onOpenChange={onOpenChange}
                  allowClear={false}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Spin spinning={loading}>
        <ReactEcharts
          ref={d => {
            if (d) {
              chartRef.current = d;
            }
          }}
          style={{ width: 900, height: 450 }}
          option={chartOption}
          notMerge
          lazyUpdate={false}
        />
      </Spin>
    </Modal>
  );
};

export default ViewPredictionsModal;
