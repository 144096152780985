import { Select } from '@maxtropy/components';
import { apiV2ExternalPhysicalUnitListPost } from '@maxtropy/device-customer-apis-v2';
import React, { FC, Key } from 'react';
import { useRequest } from 'ahooks';

export interface IUnitSelect {
  dataPropertyId?: number;
  value?: number;
  onChange?: (value?: Key) => void;
}

const UnitSelect: FC<IUnitSelect> = props => {
  const { dataPropertyId, value, onChange } = props;

  console.log('dataPropertyId', dataPropertyId);

  // 查询单位接口
  const { data: unitList } = useRequest(
    () => {
      return apiV2ExternalPhysicalUnitListPost({
        dataPropertyId,
      });
    },
    {
      ready: !!dataPropertyId,
      refreshDeps: [dataPropertyId],
    }
  );

  return (
    <Select
      options={(unitList?.list ?? []).map(item => ({
        value: item.id,
        label: item.name,
      }))}
      placeholder="请选择"
      showSearch
      optionFilterProp="label"
      value={value}
      onChange={value => onChange?.(value)}
    />
  );
};

export default UnitSelect;
