import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { ReportStrategyStatisticalCycleEnum, ReportStrategyStatisticalTypeEnum, StrategyStatus } from '@/shared/types';
import { PageRequest, PageResponse } from './page';
import { ReportStrategyTime, ReportStrategyRes } from '../pages/ReportStrategy/Create/ExecuteTimePicker';
import { ReportStrategyExecuteCycle, ReportStrategyValues } from '../pages/ReportStrategy/Create';

export interface ReportStrategyDataPropertyListRequest {
  deviceId: number;
  dataPropertyIdList: number[];
}

export interface CreateReportStrategyRequest {
  name: string;
  ouId: number;
  measurementType: ReportStrategyStatisticalTypeEnum;
  statisticalResolution: ReportStrategyStatisticalCycleEnum;
  reportType?: ReportStrategyExecuteCycle;
  computeType?: ReportStrategyValues;
  executionTime?: ReportStrategyTime;
  remark?: string;
  dataPropertyLinkList: ReportStrategyDataPropertyListRequest[];
}

export interface UpdateReportStrategyRequest
  extends Omit<CreateReportStrategyRequest, 'statisticalType' | 'dataPropertyLinkList' | 'ouId'> {
  id: number;
  oldDataPropertyLinkList: ReportStrategyDataPropertyListRequest[];
  newDataPropertyLinkList: ReportStrategyDataPropertyListRequest[];
}

export interface DataProperty {
  id?: number;
  name?: string;
  physicalUnitId?: number;
  physicalUnitName?: string;
  key?: string;
  deviceId?: number;
}

export interface ReportStrategyDataPropertyList {
  deviceId: number;
  deviceCode: string;
  deviceName: string;
  dataPropertyList?: DataProperty[];
}

export interface ReportStrategy
  extends Omit<CreateReportStrategyRequest, 'dataPropertyLinkList' | 'startTime' | 'endTime'> {
  beginTime: string;
  endTime: string;
  id: number;
  code?: string;
  dataPropertyLinkList: ReportStrategyDataPropertyList[];
  ouName: string;
  statisticalBeginTime?: ReportStrategyRes;
  statisticalEndTime?: ReportStrategyRes;
}

export interface ReportStrategyDevicePageRequest extends PageRequest {
  nameOrCode?: string;
  ouId: number;
  measurementType: ReportStrategyStatisticalTypeEnum;
  deviceTypeIds?: Array<number> | undefined;
  tagName?: string;
}

export interface ReportStrategyDevicePageResponse {
  id?: number;
  code?: string;
  name?: string;
  tagList?: string[];
  deviceType?: string;
  customerName?: string;
  ouName?: string;
  dataPropertyList?: DataProperty[];
  checkedPropertyList?: DataProperty[];
}

interface ReportStrategyPageReq extends PageRequest {
  nameOrCode?: string;
  status?: StrategyStatus;
  ouId?: number;
  single: boolean;
}

export interface ReportStrategyPageProps {
  reportId?: number;
  id: number;
  name: string;
  ouId: number;
  code: string;
  measurementType: ReportStrategyStatisticalTypeEnum;
  status: StrategyStatus;
  updateTime: string;
  updateUserName: string;
  remark: string;
  ouName: string;
  statisticalBeginTime: ReportStrategyTime;
  statisticalEndTime: ReportStrategyTime;
  statisticalResolution: number;
}

export function deleteReportStrategy(id: number) {
  return fetch(
    `/api/v2/report-strategy/delete`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function executingReportStrategy(id: number) {
  return fetch(
    `/api/v2/report-strategy/execute`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export function getReportStrategyDeviceDataProperty(
  deviceId: Key,
  measurementType: ReportStrategyStatisticalTypeEnum
): Promise<{ deviceDataPropertyLinkList: DataProperty[] }> {
  return fetch(
    `/api/v2/report-strategy/device-data-property`,
    {
      method: 'POST',
      body: JSON.stringify({ deviceId, measurementType }),
    },
    true
  );
}

export const changeReportStrategyStatus = (id: number, used: boolean) =>
  fetch<boolean>(
    '/api/v2/report-strategy/status',
    {
      method: 'POST',
      body: JSON.stringify({ id, used }),
    },
    true
  );

export const getReportStrategyList = (ouIds?: number[]) => {
  return fetch<{ list: ReportStrategy[] }>(
    `/api/v2/report-strategy/list`,
    {
      method: 'POST',
      body: JSON.stringify({ ouIds }),
    },
    true
  );
};
