import { Key } from '@maxtropy/components';
import { PageRequest } from './page';

export enum IsMapping {
  NO = 0,
  YES = 1,
}

export enum WorkStationState {
  CLOSE = 0,
  OPEN = 1,
}

export enum Sort {
  FRONT = 1,
  BACK = 2,
}

export interface WorkStationPageQuery extends PageRequest {
  queryWord?: string; // 搜索关键词
  status?: WorkStationState[]; // 状态 0->启用 1->禁用
  sort?: Sort; // 排序 1->a,b,c,2->z,y,x
}

export interface WorkStationPageRes {
  id: Key;
  code: string; // 编码
  name: string; // 名称
  state: WorkStationState; // 状态 0:启用，1:禁用
  workProcedureId: Key; //  工序id
  workProcedureName: string; // 工序名称
  workProcedureCode: string; // 工序编码
  isMapping: IsMapping; // 0->没有被映射 1->被映射
}
