export enum ProtocolDataType {
  SELECT = 1,
  INPUT = 2,
}

export const dataTypeList = [
  {
    value: 1,
    label: '遥测值',
  },
  {
    value: 2,
    label: '枚举',
  },
];

// 递归查找 ID 的完整路径
export const findPathById = (options: any, targetValue: any, path = []) => {
  for (const item of options) {
    const newPath = [...path, item.value];

    if (item.value === targetValue) {
      return newPath; // 找到目标值，返回路径
    }

    if (item.child) {
      const foundPath: any = findPathById(item.child, targetValue, newPath as any);
      if (foundPath) return foundPath;
    }
  }
  return null;
};

// 设备类目转换
export const processValue = (input: string | any[]) => {
  if (typeof input === 'number') {
    return [input]; // 直接返回 number
  } else if (Array.isArray(input)) {
    return input.length > 0 ? [input[input.length - 1]] : []; // 返回数组最后一个元素，避免空数组报错
  }
  return []; // 其他情况返回 []
};

// 是否有重复项
export function hasDuplicate(arr: string[]) {
  return new Set(arr).size !== arr.length;
}
