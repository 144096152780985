import { Key, fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import { UetEnergyMediumListResponse } from './uet';

export interface WorkingSubstanceContractSearch extends PageRequest {
  contractName?: string; // 合同名称
  contractNumber?: string; // 合同编号
  accountId?: string; // 关联账户id
  energyMediumSceneIds?: string; // 能源介质ids
}
export interface WorkingSubstanceContractPageRes {
  id: Key;
  contractNumber: string; // 合同编号
  name: string; // 合同名称
  mediumAccountId: Key; // 工质账户id
  mediumAccountName: string; // 工质账户名称
  energyMediumId: Key; // 能源介质id
  energyMediumName: string; // 能源介质名称
  energyMediumIndicatorId: Key; // 工质监测指标id
  energyMediumIndicatorName: string; // 工质监测指标名称
  physicalUnitId: Key; // 物理单位id
  physicalUnitName: string;
  startTime: string; // 最新费率开始时间
  endTime: string; // 最新费率结束时间
  remark: string; // 备注
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  precision: number; // 精度
}

// 获取工质合同分页列表
export function getWorkingSubstanceContractPage(params: WorkingSubstanceContractSearch) {
  return fetch<PageResponse<WorkingSubstanceContractPageRes>>(
    `/api/v2/medium/contract/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface WorkingSubstanceContractCreateParams {
  contractNumber: string; // 合同编号
  name: string; // 合同名称
  mediumAccountId: Key; // 工质账户id
  energyMediumId: Key; // 能源介质id
  energyMediumIndicatorId: Key; // 工质监测指标id
  physicalUnitId: Key; // 物理单位id
  remark?: string; // 备注
  precision: number; // 精度
}

// 新增工质合同
export function addWorkingSubstanceContract(body: WorkingSubstanceContractCreateParams) {
  return fetch(
    '/api/v2/medium/contract/add',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}
export interface WorkingSubstanceContractEditParams {
  id: Key;
  name?: string; // 合同名称
  remark?: string; // 备注
}

// 编辑工质合同
export function updateWorkingSubstanceContract(body: WorkingSubstanceContractEditParams) {
  return fetch(
    '/api/v2/medium/contract/edit',
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
}

export interface EnergyMediumItem {
  id: Key; //能源介质id
  energyName: string; //能源介质名称
  createTime: string; //创建时间
  updateTime: string; //更新时间
}

// 根据关联工质合同账户查询适用的能源介质
export function getEnergyMediumByContractId(id: Key) {
  return fetch<{ list: EnergyMediumItem[] }>(
    `/api/v2/medium/contract/energyMedium/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface IndicatorsItem {
  indicatorId: Key; // 监测指标Id
  indicatorName: string; // 监测指标
  type: number; // 指标类型
  typeName: string; // 指标类型名称
  basePhysicalQuantityId: Key; // 物理量id
  basePhysicalQuantityName: string; // 物理量name
  dataPropertyId: Key; // 数据属性id
  dataPropertyName: string; // 设备的数据属性名称
  deviceId: Key; // 设备id
  deviceName: string; // 设备名称
  importAndExportType: number; // 输入输出类型
  startTime: string; // 生效时间
  importAndExportId: Key; // 输入输出id
}

// 根据能源介质查询对应的监测指标
export async function getIndicatorsByEnergyMediumId(id: Key) {
  const res = await fetch<{ list: IndicatorsItem[] }>(
    `/api/v2/indicator/getIndicators`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
  return res.list;
}

export interface PhysicalUnitItem {
  id: Key; // 主键
  generalName: string; // 单位
  chineseName: string; // 中文单位
  basePhysicalUnitId: Key; // 对应的基本物理单位id
  isBasic: boolean; // 是否是基本物理单位
  k: number; // 转换为标准物理量方程的系数
  b: number; // 转换为标准物理量方程的尾数
  kR: number; // 标准物理量反转方程的系数
  bR: number; // 标准物理量反转方程的尾数
}

// 根据基本物理量id查询物理量单位
export async function getPhysicalUnitByPhysicalQuantityId(id: Key) {
  const res = await fetch<{ list: PhysicalUnitItem[] }>(
    `/api/v2/physicalUnit/physicalUnit`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
  return res.list ?? [];
}

export interface MediumAccountItem {
  id: Key; // id
  accountNumber: string; // 户号
  energyMediumSceneId: Key; // 能源介质场景id
  energyMediumSceneName: string; // 能源介质场景名称
  mcidList: string[]; // 关联组织mcidList
  mcidListStr: string; // 关联组织mcidList
  mcidNameMap: string; // 关联组织名称mcidNameMap
}

//得到staff权限下相关工质账户列表
export function getMediumAccountList() {
  return fetch<{ list: MediumAccountItem[] }>(
    `/api/v2/medium/account/relateMediumAccountList`,
    {
      method: 'POST',
    },
    true
  );
}

export interface WorkingSubstanceAccountCreateParams {
  accountNumber: string; // 户号
  energyMediumSceneId: Key; // 能源介质场景id
  mcidList: Key[]; // 关联组织mcidList
}
// 新建工质账户
export function addWorkingSubstanceAccount(params: WorkingSubstanceAccountCreateParams) {
  return fetch(
    `/api/v2/medium/account/add`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface WorkingSubstanceAccountUpdateParams
  extends Omit<WorkingSubstanceAccountCreateParams, 'energyMediumSceneId' | 'mcidList'> {
  id: Key;
}

// 编辑工质账户
export function updateWorkingSubstanceAccount(params: WorkingSubstanceAccountUpdateParams) {
  return fetch(
    '/api/v2/medium/account/edit',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface WorkingSubstanceAccountPageRes {
  id: Key; //  id
  accountNumber: string; //  户号
  energyMediumSceneId: Key; //  能源介质场景id
  energyMediumSceneName: string; //  能源介质场景名称
  mcidList: string[]; //  关联组织mcidList
  mcidListStr: string; //  关联组织mcidList
  mcidNameMap: string; //  关联组织名称mcidNameMap
}

export interface WorkingSubstanceAccountPageRequest extends PageRequest {
  accountNumber?: string; // 工质账户户号(模糊查询)
  energyMediumSceneId?: string; // 能源介质场景id
}

// 分页查询工质账户分页
export function getWorkingSubstanceAccountPage(params: WorkingSubstanceAccountPageRequest) {
  return fetch<PageResponse<WorkingSubstanceAccountPageRes>>(
    `/api/v2/medium/account/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface AddRateParams {
  mediumContractId: Key; // 工质合同id
  startTime: string; // 开始时间
  price: number; // 价格
}

// 新增费率
export function addRate(params: AddRateParams) {
  return fetch(
    '/api/v2/medium/contract/rate/add',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 删除费率
export function delRate(id: Key) {
  return fetch(
    `/api/v2/medium/contract/rate/del`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

export interface UpdateRateParams {
  id: Key; // 主键
  price: number; // 价格
}

// 编辑费率
export function updateRate(params: UpdateRateParams) {
  return fetch(
    '/api/v2/medium/contract/rate/edit',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface RatePageRequest extends PageRequest {
  mediumContractId: Key; // 工质合同id
}

export interface RatePageRes {
  id: Key;
  mediumContractId: Key; // 工质合同id
  startTime: string; // 开始时间
  endTime: string; // 结束时间
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  price: number; // 价格
}

// 获取费率分页列表
export function getRatePage(params: RatePageRequest) {
  return fetch<PageResponse<RatePageRes>>(
    `/api/v2/medium/contract/rate/page`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface energyMediumSceneToMediumRes {
  id: Key; // 能源介质场景id
  sceneName: string; // 能源介质场景名称
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  energyMediums: UetEnergyMediumListResponse[]; // 能源介质List
}

// 能源介质场景下携带相对应的能源介质列表
export async function getEnergyMediumSceneToMedium() {
  const res = await fetch<{ list: energyMediumSceneToMediumRes[] }>(
    '/api/v2/uet/emt/getEnergyMediumSceneToMediums',
    {
      method: 'POST',
    },
    true
  );
  return res.list;
}

// 查看单条合同信息
export interface WorkingSubstanceContractRes {
  id: Key;
  contractNumber: string; // 合同编号
  name: string; // 合同名称
  mediumAccountId: Key; // 工质账户id
  mediumAccountName: string; // 工质账户名称
  energyMediumId: Key; // 能源介质id
  energyMediumName: string; // 能源介质名称
  energyMediumIndicatorId: Key; // 工质监测指标id
  energyMediumIndicatorName: string; // 工质监测指标名称
  physicalUnitId: Key; // 物理单位id
  physicalUnitName: string; // 物理单位名称
  startTime: string; // 最新费率开始时间
  endTime: string; // 最新费率结束时间
  remark: string; // 备注
  createTime: string; // 创建时间
  updateTime: string; // 更新时间
  precision: number; // 精度
}

export function getMediumContractSingleById(id: Key) {
  return fetch<WorkingSubstanceContractRes>(
    `/api/v2/medium/contract/single/detail`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}
