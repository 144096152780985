import {
  ChargeStationListResponse,
  createUETChargeStation,
  getUetChargeStationList,
  UetInfoProps,
  updateUetChargeStation,
} from '@/api/uet';
import { PermissionsType } from '@/common/permissionsConst';
import FixedBottomButton from '@/components/FixedBottomButton';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  EllipsisSpan,
  message,
  Modal,
  Table,
  Tag,
  Tooltip,
  useTenantPermissions,
  useUpdate,
} from '@maxtropy/components';
import { Space } from 'antd';
import { isNil } from 'lodash-es';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import NewChargeStationModal from './components/NewChargeStationModal';

interface IChargingStation {
  info?: UetInfoProps;
  isDetail?: boolean;
}

export const deviceColumns = [
  {
    title: '充电站编号',
    dataIndex: 'code',
    // width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => (
      <Tooltip placement={'topLeft'} title={v ?? '--'}>
        <Tag border="solid" color="default">
          {v ?? '--'}
        </Tag>
      </Tooltip>
    ),
  },
  {
    title: '充电站名称',
    dataIndex: 'name',
    // width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '装机容量',
    dataIndex: 'installedCapacity',
    // width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={!isNil(v) ? Number(v).toFixed(3) + 'MW' : '--'} />,
  },
  {
    title: '关联回路名称',
    dataIndex: 'circuitName',
    // width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ouName',
    // width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const ChargingStation: FC<IChargingStation> = props => {
  const { info, isDetail } = props;
  const { id } = useParams<{ id: string }>(); // 获取uetid
  // 充电站弹窗标记
  const [currentId, setCurrentId] = useState<number>();
  const [chargeStationVisible, setChargeStationVisible] = useState(false);
  const [chargeStationList, setChargeStationList] = useState<any[]>([]); // TODO
  const [x, forceUpdate] = useUpdate();
  const [edit, setEdit] = useState<boolean>(false);
  const btnDisabled = isDetail || !edit;

  const permission = useTenantPermissions();
  // 新增充电站按钮
  const showAddChargeStationBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_ADDCHARGINGSTATION) && !isDetail;
  }, [permission, isDetail]);
  // 编辑充电站按钮
  const showEditChargeStationBtn = useMemo(() => {
    return permission?.includes(PermissionsType.B_EDITCHARGINGSTATION) && !isDetail;
  }, [permission, isDetail]);

  // 查询充电站列表
  useEffect(() => {
    if (id) {
      getUetChargeStationList(id).then(res => {
        setChargeStationList(res);
      });
    }
  }, [id, x]);

  const chargeStationModalBtn = (id?: number) => {
    setChargeStationVisible(true);
    setCurrentId(id);
  };

  const onSave = () => {
    const unfinished = chargeStationList.some(i => !i.id);
    if (unfinished) {
      message.error('有未保存的充电站！');
    } else {
      setEdit(false);
    }
  };

  // 弹窗保存按钮
  const onChargeStationFinish = (values: any) => {
    console.log(values);
    if (currentId) {
      // 编辑接口
      updateUetChargeStation({ ...values, id: currentId }).then(_ => {
        forceUpdate();
      });
    } else {
      // 新增接口
      createUETChargeStation({ ...values, uetId: id }).then(_ => {
        forceUpdate();
      });
    }
    setChargeStationVisible(false);
    setCurrentId(undefined);
  };

  const columns = {
    title: '操作',
    width: 420,
    fixed: 'right' as const,
    render: (_: undefined, record: ChargeStationListResponse) => (
      <>
        {showEditChargeStationBtn && (
          <Button
            type="link"
            disabled={btnDisabled}
            onClick={() => {
              chargeStationModalBtn(record.id);
            }}
          >
            编辑充电站
          </Button>
        )}
      </>
    ),
  };

  return (
    <>
      {showAddChargeStationBtn && (
        <Button type="primary" disabled={btnDisabled} onClick={() => chargeStationModalBtn(undefined)}>
          <PlusOutlined />
          新建充电站
        </Button>
      )}

      <Table
        style={{ marginTop: 12 }}
        rowKey="id"
        sticky
        scroll={{ x: 1200 }}
        columns={[...deviceColumns, columns]}
        dataSource={chargeStationList}
        pagination={false}
      />

      <FixedBottomButton>
        {edit ? (
          <Space size={8}>
            <Button type="primary" onClick={onSave}>
              保存
            </Button>
            <Button
              onClick={() => {
                Modal.confirm({
                  title: '这样做会造成未保存的信息丢失，确定要这样做吗？',
                  onOk: () => {
                    forceUpdate();
                    setEdit(false);
                  },
                });
              }}
            >
              取消
            </Button>
          </Space>
        ) : (
          <>
            {isDetail ? (
              <Button type="primary">
                <Link to={`/energy/basic/uet`}>返回列表</Link>
              </Button>
            ) : (
              <Button type="primary" onClick={() => setEdit(true)}>
                编辑
              </Button>
            )}
          </>
        )}
      </FixedBottomButton>
      {/* 新建光伏站弹窗 */}
      <NewChargeStationModal
        ouList={info?.ous}
        rowInfo={chargeStationList.find(i => i.id === currentId)}
        visible={chargeStationVisible}
        onCancel={() => {
          setChargeStationVisible(false);
          setCurrentId(undefined);
        }}
        onConfirm={onChargeStationFinish}
      />
    </>
  );
};

export default ChargingStation;
