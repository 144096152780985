// 排班计划 api
import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';

// 列表页用能分析组下拉框
export interface AnalysisUnit {
  // 用能分析组id
  energyGroupId?: number | string;
  // 用能分析组名称
  energyGroupName?: string;
  // 用能单元
  energyUnits?: {
    // 用能单元id
    energyUnitId?: number;
    // 用能单元名称
    energyUnitName?: string;
  }[];
  // 应用单元数
  amount?: number;
}

// 排班计划一条记录字段
export interface Schedule {
  // 用能单元id
  energyUnitId?: number | string;
  // 用能单元名称
  energyUnitName?: string;
  // 所属用能分析组id
  energyGroupId?: string | number;
  // 用能分析组名称
  energyGroupName?: string;
  // 最新排班时间
  workDate?: string;
}

// 获取排班计划列表请求
export interface SchedulePlanListRequest extends PageRequest {
  // 用能分析组id
  energyGroupIds?: string[] | number[];
  // 用能单元名称
  energyUnitName?: string;
}

// 获取排班计划列表
export function getSchedulePlanList(query: SchedulePlanListRequest) {
  return fetch<PageResponse<Schedule>>(
    `/api/v2/energy/unit/work/shift/apply/page`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

// 复制排班拉取的用能单元下拉框
export interface CopyEnergyUnit {
  energyUnitId?: number;
  energyUnitName?: string;
}

export interface CopyEnergyUnitListRequest {
  // 复制日期, e.g. 2022-10
  date: string;
  // 源用能单元 id
  energyUnitId: string;
}

// 获取复制排班用能单元列表
export function getCopyEnergyUnitList(query: CopyEnergyUnitListRequest) {
  return fetch<{ list: CopyEnergyUnit[] }>(
    `/api/v2/energy/unit/work/shift/apply/copyEnergyUnitList`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export interface CopyScheduleParams {
  sourceEnergyUnitId: number;
  targetEnergyUnitIds: number[];
  copyDate: string;
}

// 根据某个用能单元复制排班到另外其他多个用能单元
export function copySchedule(params: CopyScheduleParams) {
  return fetch<boolean>(
    '/api/v2/energy/unit/work/shift/apply/copy/schedule',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

// 班组
export interface WorkTeam {
  // null 为待排班
  id?: number | null;
  // 班组名称
  name?: string;
  // 所属租户
  tenantMcid?: string;
  // 班组负责人id
  leadWorkerId?: number;
  // 负责人名称
  leaderWorkerName?: string;
  // 负责人编号
  leaderWorkerNo?: string;
  // 人员
  workers?: {
    id: number;
    // 工号
    no: string;
    // 名称
    name: string;
  }[];
  // 所属组织
  customers?: any[];
  // 逻辑删除标志
  deleted?: boolean;
  createTime?: string;
  updateTIme?: string;
}

// 获取班组列表, 日历里和快捷排班里的班组都是一样的
export function getWorkTeamList(id: string | number) {
  return fetch<{ list: WorkTeam[] }>(
    `/api/v2/energy/unit/work/shift/apply/teamList`,
    {
      method: 'POST',
      body: JSON.stringify({ id }),
    },
    true
  );
}

// 班次
export interface WorkShift {
  id?: number;
  // 班次id
  workShiftId?: number | null;
  // 班次名称
  workShiftName?: string;
  // 班组id
  workTeamId?: string | null;
  // 班组名称
  workTeamName?: string;
  // 产量
  outputAmount?: number;
  // 产出物 id
  outputProductId?: number;
  customerNames: string[];
}

export interface WorkShiftsPerDay {
  id?: number;
  // 最新工作日日期
  workDate?: string;
  // 用能单元配置id
  configId?: number;
  // 用能单元id
  energyUnitId?: number;
  // 用能单元名称
  energyUnitName?: string;
  // 用能分析组id
  energyGroupId?: number;
  // 用能分析组名称
  energyGroupName?: string;
  // 所属租户
  tenantMcid?: string;
  // 创建时间
  createTime?: string;
  // 更新时间
  updateTime?: string;
  // 是否是工作日
  isWorkingDay?: boolean;
  // null 时无班次配置
  workShifts?: WorkShift[] | null;
}

export interface WorkShiftsPerDayRequest {
  // 用能单元 id
  energyUnitId: number | string;
  // 指定日期: e.g. 2022-09
  date: string;
}

// 获取某个月的排班计划详情, 包含班次和对应班组
export function getWorkShiftsPerDay(query: WorkShiftsPerDayRequest) {
  return fetch<{ list: WorkShiftsPerDay[] }>(
    `/api/v2/energy/unit/work/shift/apply/list`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

// 日历选择框颜色
export const ScheduleColors = ['Purple', 'Brown', 'Blue', 'Green', 'Yellow'];

export interface UpdateSingleScheduleParams {
  // 班次 id, 为当前月下某天具体的 id, 非班次 workShiftId
  energyUnitWorkShiftApplyShiftId: number;
  // 班组 id, 传 null 的时候为待排班
  workTeamId: number | null;
}

// 更新当前月某天下的班次班组
export function updateSingleSchedule(params: UpdateSingleScheduleParams) {
  return fetch<boolean>(
    `/api/v2/energy/unit/work/shift/apply/single/schedule`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface FastScheduleWorkShiftListRequest {
  // 用能单元 id
  energyUnitId: number | number;
  // 开始日期 YYYY-MM-DD
  startDate: string;
  // 结束日期
  endDate: string;
}

// 快捷排班班次
export interface FastScheduleWorkShift {
  id?: number;
  // 班次名称
  name?: string;
  // 班次时间
  timeSeg?: string;
  // 逻辑删除
  deleted?: boolean;
  // 创建时间
  createTime?: string;
  // 修改时间
  updateTime?: string;
}

// 获取快捷排班班次
export function getFastScheduleWorkShiftList(query: FastScheduleWorkShiftListRequest) {
  return fetch<{ list: FastScheduleWorkShift[] }>(
    `/api/v2/energy/unit/work/shift/apply/shiftList`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}

export interface FastScheduleParams {
  // 用能单元 id
  energyUnitId: number;
  // 开始日期
  startDate: string;
  // 结束日期
  endDate: string;
  // 班次班组配对
  shiftTeamPair: {
    // 班次 id
    workShiftId: number;
    // 班组 id
    workTeamId: number | null;
  }[];
}

// 快捷排班
export function fastSchedule(params: FastScheduleParams) {
  return fetch<boolean>(
    '/api/v2/energy/unit/work/shift/apply/quick/schedule',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface AdjustWorkdayParams {
  startDate: string;
  endDate: string;
  // 转为工作日为 true, 转为休息日为 false
  isWorkingDay: boolean;
  energyUnitId: number;
}

// 工作日调整
export function adjustWorkday(params: AdjustWorkdayParams) {
  return fetch<boolean>(
    '/api/v2/energy/unit/work/shift/apply/workday/change',
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface ImportScheduleRequest {
  id: number | string;
  fileKey: string;
}

export interface importScheduleResponse {
  // 200 导入成功, 其他说明有导入失败
  code: number;
  msg: string;
}

// 导入排班, 返回 string 说明存在错误, 否则返回 null, 不存在导入错误
export function importSchedule(query: ImportScheduleRequest) {
  return fetch<importScheduleResponse>(
    `/api/v2/energy/unit/work/shift/apply/import`,
    {
      method: 'POST',
      body: JSON.stringify(query),
    },
    true
  );
}
