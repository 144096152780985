import { DataAORIType, DatePickerTypeDisplay, StatisticsPartition } from '@/api/energyMediaComparison';
import {
  EnergyMediumTreeFormatType,
  getProcessEntryOrExitMonitoringIndicators,
  MediumIndicatorDisplayUnit,
  ProcessEntryOrExitMonitoringIndicatorsRes,
} from '@/api/energyMedium';
import { getTopologySelectList } from '@/api/quality-monitor';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Cascader, FormInstance, Space } from 'antd';
import { Button, DatePicker, Form, message, Radio, Select } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash-es';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { average, DataTypeAOrIArr, sum } from '../../utils';
import { colors } from '../Chart/chartOptionsHis';
import styles from './index.module.scss';
import {
  apiV2EnergyCompareProcessListPost,
  V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse,
  V2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest,
  V2EnergyCompareProcessListPostResponse,
  V2EnergyCompareTopologyListPostResponse,
} from '@maxtropy/device-customer-apis-v2';

const { RangePicker } = DatePicker;

type EnergyCompareItem = Exclude<V2EnergyCompareTopologyListPostResponse['list'], undefined>[number];

export interface IHistoryCompare {
  form: FormInstance<any>;
  hisChartData?: V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'];
  mediumIndicatorDisplayUnitData?: MediumIndicatorDisplayUnit;
  setHisSearchParams: React.Dispatch<
    React.SetStateAction<V2EnergyCompareGetBatchMediumIndicatorProcessPointHisDataPostRequest | undefined>
  >;
  setHisChartData: React.Dispatch<
    React.SetStateAction<V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'] | undefined>
  >;
  historyValues?: any;
}

const HistoryCompare: FC<IHistoryCompare> = props => {
  const { form, setHisSearchParams, hisChartData, mediumIndicatorDisplayUnitData, setHisChartData, historyValues } =
    props;

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_energyMediumTopoId = urlSearchParams.get('energyMediumTopoId') || undefined;
  let url_processId = urlSearchParams.get('processId') || undefined;
  let url_processEntryOrExitId = urlSearchParams.get('processEntryOrExitId') || undefined;
  let url_type = urlSearchParams.get('type') || undefined;
  let url_indicatorId = urlSearchParams.get('indicatorId') || undefined;

  const topoId = Form.useWatch('topoId', form);
  const entryAndExitId = Form.useWatch('entryAndExitId', form);
  const timeResolution = Form.useWatch('timeResolution', form);
  const indicatorId = Form.useWatch('indicatorId', form);
  const timeRangeList = Form.useWatch('timeRangeList', form);

  const [energyCompareTopologyList, setEnergyCompareTopologyList] = useState<EnergyCompareItem[]>();
  const [energyCompareProcessList, setEnergyCompareProcessList] =
    useState<V2EnergyCompareProcessListPostResponse['list']>();
  const [entryOrExitMonitoringIndicatorsList, setEntryOrExitMonitoringIndicatorsList] =
    useState<ProcessEntryOrExitMonitoringIndicatorsRes[]>();

  const topoIdInitValue = useRef<boolean>(false);
  const entryAndExitIdInitValue = useRef<boolean>(false);
  const indicatorIdInitValue = useRef<boolean>(false);

  const firstFetch = useRef<boolean>(false); // 第一次请求

  const [entryAndExitIdDisabled, setEntryAndExitIdDisabled] = useState<boolean>(false);
  const [indicatorIdDisabled, setIndicatorIdDisabled] = useState<boolean>(false);

  const rememberTopoIdRef = useRef<boolean>(false);
  const rememberEntryAndExitIdRef = useRef<boolean>(false);
  const rememberIndicatorIdRef = useRef<boolean>(false);

  // 默认选择第一个
  useEffect(() => {
    if (historyValues) {
      if (!rememberTopoIdRef.current && energyCompareTopologyList && energyCompareTopologyList.length > 0) {
        form.setFieldValue('topoId', historyValues.topoId);
        rememberTopoIdRef.current = true;
      }
      if (!rememberEntryAndExitIdRef.current && energyCompareProcessList && energyCompareProcessList.length > 0) {
        form.setFieldValue('entryAndExitId', historyValues.entryAndExitId);
        rememberEntryAndExitIdRef.current = true;
      }
      if (
        !rememberIndicatorIdRef.current &&
        entryOrExitMonitoringIndicatorsList &&
        entryOrExitMonitoringIndicatorsList.length > 0
      ) {
        form.setFieldValue('indicatorId', historyValues.indicatorId);
        rememberIndicatorIdRef.current = true;
      }
    } else {
      if (!topoIdInitValue.current && energyCompareTopologyList && energyCompareTopologyList.length > 0) {
        form.setFieldValue(
          'topoId',
          url_energyMediumTopoId ? Number(url_energyMediumTopoId) : energyCompareTopologyList[0].id
        );
        topoIdInitValue.current = true;
      }
      if (!entryAndExitIdInitValue.current && energyCompareProcessList && energyCompareProcessList.length > 0) {
        let secondId;
        if (energyCompareProcessList?.[0]?.processEntrySimpleVos?.[0]?.id) {
          secondId =
            energyCompareProcessList?.[0]?.processEntrySimpleVos?.[0]?.id.toString() + EnergyMediumTreeFormatType.ENTRY;
        } else if (energyCompareProcessList?.[0]?.processExitSimpleVos?.[0]?.id) {
          secondId =
            energyCompareProcessList?.[0]?.processExitSimpleVos?.[0]?.id.toString() + EnergyMediumTreeFormatType.EXIT;
        } else if (energyCompareProcessList?.[0]?.processNodeSimpleVo?.id) {
          secondId =
            energyCompareProcessList?.[0]?.processNodeSimpleVo?.id.toString() + EnergyMediumTreeFormatType.NODEINSIDE;
        } else {
          secondId = undefined;
        }
        form.setFieldValue(
          'entryAndExitId',
          url_processId && url_processEntryOrExitId && !isNil(url_type)
            ? [Number(url_processId), url_processEntryOrExitId + url_type]
            : [energyCompareProcessList[0].id, secondId]
        );
        entryAndExitIdInitValue.current = true;
      }
      if (
        !indicatorIdInitValue.current &&
        entryOrExitMonitoringIndicatorsList &&
        entryOrExitMonitoringIndicatorsList.length > 0
      ) {
        form.setFieldValue(
          'indicatorId',
          url_indicatorId ? Number(url_indicatorId) : entryOrExitMonitoringIndicatorsList[0].indicatorId
        );
        indicatorIdInitValue.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyValues, energyCompareTopologyList, energyCompareProcessList, entryOrExitMonitoringIndicatorsList]);

  // 切换form的时候都会清空数据
  useEffect(() => {
    if (firstFetch.current) {
      setHisChartData(undefined);
      setHisSearchParams(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorId, timeResolution, topoId, entryAndExitId, timeRangeList]);

  // 第一次的时候有一个请求
  useEffect(() => {
    if (
      !firstFetch.current &&
      topoId &&
      entryAndExitId &&
      indicatorId &&
      timeResolution &&
      timeRangeList &&
      timeRangeList.length > 0
    ) {
      const values = form.getFieldsValue();
      const topEntryOrExit = values.entryAndExitId[1];
      const params = {
        topoId: values.topoId,
        processId: values.entryAndExitId[0],
        processEntryOrExitId: Number(topEntryOrExit.slice(0, topEntryOrExit.length - 1)),
        type: Number(topEntryOrExit.slice(topEntryOrExit.length - 1)),
        indicatorId: values.indicatorId,
        timeResolution: values.timeResolution,
        timeRangeList: values.timeRangeList.map((item: any) => {
          return {
            from: dayjs(item.fromAndTo[0]).startOf('day').valueOf(),
            to: dayjs(item.fromAndTo[1]).endOf('day').valueOf(),
          };
        }),
      };
      setHisSearchParams(params);
      firstFetch.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoId, entryAndExitId, timeResolution, indicatorId, timeRangeList]);

  // 查询拓扑列表
  useEffect(() => {
    getTopologySelectList().then(setEnergyCompareTopologyList);
  }, []);
  // topo列表options
  const topoListOptions = useMemo(() => {
    return (energyCompareTopologyList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [energyCompareTopologyList]);

  // 上面的 通过topoid查询过程与输入输出
  useEffect(() => {
    if (topoId) {
      setEntryAndExitIdDisabled(true);
      setIndicatorIdDisabled(true);
      // 重新选择拓扑后，后面都置为空
      form.setFieldsValue({
        entryAndExitId: undefined,
        indicatorId: undefined,
      });
      apiV2EnergyCompareProcessListPost({ id: topoId }).then(res => {
        setEnergyCompareProcessList(res.list);
        setEntryAndExitIdDisabled(false);
        setIndicatorIdDisabled(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoId]);

  // 上面输入输出
  const entryAndExitOptions = useMemo(() => {
    return (energyCompareProcessList ?? []).map(i => {
      const obj = {
        label: i.name,
        value: i.id,
        children: (i.processEntrySimpleVos ?? [])
          .map(itemEntry => ({
            label: itemEntry.name,
            value: itemEntry.id!.toString() + EnergyMediumTreeFormatType.ENTRY,
            info: {
              type: EnergyMediumTreeFormatType.ENTRY,
            },
          }))
          .concat(
            (i.processExitSimpleVos ?? []).map(itemExit => ({
              label: itemExit.name,
              value: itemExit.id!.toString() + EnergyMediumTreeFormatType.EXIT,
              info: {
                type: EnergyMediumTreeFormatType.EXIT,
              },
            }))
          ),
      };
      if (i.processNodeSimpleVo) {
        obj.children.push({
          label: i.processNodeSimpleVo.name,
          value: i.processNodeSimpleVo.id!.toString() + EnergyMediumTreeFormatType.NODEINSIDE,
          info: {
            type: EnergyMediumTreeFormatType.NODEINSIDE,
          },
        });
      }
      return obj;
    });
  }, [energyCompareProcessList]);

  // 上面通过输入输出id查询指标
  useEffect(() => {
    if (entryAndExitId && entryAndExitId.length > 1 && !entryAndExitId.includes(undefined)) {
      form.setFieldsValue({
        indicatorId: undefined,
      });
      setIndicatorIdDisabled(true);
      const entryOrExit = entryAndExitId[entryAndExitId.length - 1];
      const entryOrExitType = entryOrExit.slice(entryOrExit.length - 1);
      const entryOrExitId = entryOrExit.slice(0, entryOrExit.length - 1);
      getProcessEntryOrExitMonitoringIndicators({
        processEntryOrExitId: entryOrExitId,
        type: entryOrExitType,
      }).then(res => {
        setEntryOrExitMonitoringIndicatorsList(res.list);
        setIndicatorIdDisabled(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryAndExitId]);
  // 上面指标列表
  const indicatorOptions = useMemo(() => {
    return (entryOrExitMonitoringIndicatorsList ?? []).map(i => ({ label: i.indicatorName, value: i.indicatorId }));
  }, [entryOrExitMonitoringIndicatorsList]);

  const disabledDate = (current: Dayjs) => {
    return current && current >= dayjs().endOf('day');
  };

  const todayDefaltFromTo = [dayjs(dayjs(), 'x').startOf('day'), dayjs(dayjs(), 'x').endOf('day')];
  const dayDefaltFromTo = [dayjs(dayjs(), 'x').subtract(30, 'days').startOf('day'), dayjs(dayjs(), 'x').endOf('day')];

  useEffect(() => {
    if (timeResolution === StatisticsPartition.MINUTE_15) {
      form.setFieldsValue({
        timeRangeList: [
          {
            fromAndTo: todayDefaltFromTo,
          },
        ],
      });
    } else {
      form.setFieldsValue({
        timeRangeList: [
          {
            fromAndTo: dayDefaltFromTo,
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeResolution]);

  const addCpmpareTime = (
    add: (defaultValue?: any, insertIndex?: number | undefined) => void,
    filedsLength: number
  ) => {
    if (filedsLength >= 3) {
      message.warning('最多添加3组对比时间');
    } else {
      add();
    }
  };

  // 均量还是总量文描显示
  const indicatorTypeFormatName = useMemo(() => {
    const indicatorType = DataTypeAOrIArr.find(n => n.indicatorId === indicatorId)?.indicatorType;
    if (indicatorType === DataAORIType.INSTANTANEOUS) {
      return '均量';
    } else {
      return '总量';
    }
  }, [indicatorId]);

  // 单位显示
  const indicatorTypeFormatUnit = useMemo(() => {
    if (mediumIndicatorDisplayUnitData) {
      const originUnit = mediumIndicatorDisplayUnitData.find(
        i => i.energyMediumIndicatorId === indicatorId
      )?.displayPhysicalUnitGeneralName;
      return originUnit;
    }
  }, [mediumIndicatorDisplayUnitData, indicatorId]);

  const totalCount = useMemo(() => {
    if (hisChartData && hisChartData.length > 0) {
      const indicatorType = DataTypeAOrIArr.find(n => n.indicatorId === indicatorId)?.indicatorType;
      if (indicatorType === DataAORIType.ACCUMULATE) {
        const sumCount = hisChartData.map(item => {
          return sum(item.data?.map(i => i.sum));
        });
        return sumCount;
      } else {
        const avgCount = hisChartData.map(item => {
          return average(item.data?.map(i => i.avg));
        });
        return avgCount;
      }
    } else {
      return [];
    }
  }, [hisChartData, indicatorId]);

  return (
    <>
      <div className={styles.compareSection}>
        <Form.Item name="topoId" label="管网拓扑" rules={[{ required: true, message: '请选择管网拓扑' }]}>
          <Select placeholder="请选择管网拓扑" showSearch optionFilterProp="label" options={topoListOptions} />
        </Form.Item>
        <Form.Item
          name="entryAndExitId"
          label="输入/节点内/输出"
          rules={[{ required: true, message: '请选择输入/节点内/输出' }]}
        >
          <Cascader
            showSearch={{
              filter: (inputValue, path) => {
                return path.some(
                  option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                );
              },
            }}
            disabled={entryAndExitIdDisabled}
            allowClear={false}
            style={{ width: '100%' }}
            options={entryAndExitOptions}
            placeholder="请选择输入/节点内/输出"
          />
        </Form.Item>
        <Form.Item name="indicatorId" label="指标" rules={[{ required: true, message: '请选择指标' }]}>
          <Select
            disabled={indicatorIdDisabled}
            placeholder="请选择指标"
            showSearch
            optionFilterProp="label"
            options={indicatorOptions}
          />
        </Form.Item>
        <Form.Item
          name="timeResolution"
          label="颗粒度"
          initialValue={StatisticsPartition.MINUTE_15}
          rules={[{ required: true, message: '请选择颗粒度' }]}
        >
          <Radio.Group>
            <Radio value={StatisticsPartition.MINUTE_15}>{DatePickerTypeDisplay[StatisticsPartition.MINUTE_15]}</Radio>
            <Radio value={StatisticsPartition.DAY}>{DatePickerTypeDisplay[StatisticsPartition.DAY]}</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.List name="timeRangeList">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{
                    marginBottom: 8,
                    padding: 6,
                    borderRadius: 2,
                    backgroundColor: '#ffffff10',
                    borderLeft: `3px solid ${colors[name]}`,
                  }}
                >
                  <Space
                    style={{
                      display: 'flex',
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      label={`时间${name + 1}`}
                      name={[name, 'fromAndTo']}
                      style={{ marginBottom: 12 }}
                      rules={[
                        { required: true, message: '请选择对比时间' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value) {
                              if (getFieldValue('timeResolution')) {
                                const timeResolution = getFieldValue('timeResolution');
                                const [beginDate, endDate] = value;
                                const diff = dayjs(endDate).diff(dayjs(beginDate), 'day');
                                const mostDays = timeResolution === StatisticsPartition.DAY ? 31 : 1;
                                if (diff >= mostDays) {
                                  return Promise.reject(new Error(`最大上限为${mostDays}天`));
                                }
                              }
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <RangePicker disabledDate={disabledDate} allowClear={false} />
                    </Form.Item>

                    <MinusCircleOutlined
                      style={{ color: 'var(--mx-primary-color)', cursor: 'pointer' }}
                      onClick={() => {
                        if (fields.length === 1) {
                          message.warning('至少保留一组对比时间');
                        } else {
                          remove(name);
                        }
                      }}
                    />
                  </Space>
                  <div>
                    {indicatorTypeFormatName}：
                    <span style={{ color: colors[name], fontSize: 16 }}>
                      {!isNaN(totalCount[name])
                        ? totalCount[name].toFixed(2) + (indicatorTypeFormatUnit ?? '--')
                        : '--'}
                    </span>
                  </div>
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  ghost
                  wrapStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  onClick={() => addCpmpareTime(add, fields.length)}
                  block
                  icon={<PlusOutlined />}
                >
                  新增对比时间
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};

export default HistoryCompare;
