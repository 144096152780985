import { DataQuery, ObjectSubmitParams, StatisticsPartition } from '@/api/energyMediaComparison';
import {
  EnergyMediumTreeFormatType,
  getProcessEntryOrExitMonitoringIndicators,
  ProcessEntryOrExitMonitoringIndicatorsRes,
} from '@/api/energyMedium';
import { getTopologySelectList } from '@/api/quality-monitor';
import {
  apiV2EnergyCompareProcessListPost,
  V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostRequest,
  V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse,
  V2EnergyCompareProcessListPostResponse,
  V2EnergyCompareTopologyListPostResponse,
} from '@maxtropy/device-customer-apis-v2';
import { Cascader, Divider, Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDefaultTime } from '../../utils';
import styles from './index.module.scss';

export interface IObjectCompare {
  objectForm: FormInstance<any>;
  setSearchParams: React.Dispatch<
    React.SetStateAction<V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostRequest['requests'] | undefined>
  >;
  setChartData: React.Dispatch<
    React.SetStateAction<V2EnergyCompareGetBatchMediumIndicatorProcessPointDataPostResponse['list'] | undefined>
  >;
  setQuery: React.Dispatch<React.SetStateAction<DataQuery | undefined>>;
  objectValues?: ObjectSubmitParams;
}

type EnergyCompareTopologyItem = Exclude<V2EnergyCompareTopologyListPostResponse['list'], undefined>[number];

const ObjectCompare: FC<IObjectCompare> = props => {
  const { setSearchParams, setChartData, setQuery, objectValues } = props;
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let url_energyMediumTopoId = urlSearchParams.get('energyMediumTopoId') || undefined;
  let url_processId = urlSearchParams.get('processId') || undefined;
  let url_processEntryOrExitId = urlSearchParams.get('processEntryOrExitId') || undefined;
  let url_type = urlSearchParams.get('type') || undefined;
  let url_indicatorId = urlSearchParams.get('indicatorId') || undefined;

  const topoIdTop = Form.useWatch('topoIdTop', props.objectForm);
  const entryAndExitIdTop = Form.useWatch('entryAndExitIdTop', props.objectForm);
  const topoIdBottom = Form.useWatch('topoIdBottom', props.objectForm);
  const entryAndExitIdBottom = Form.useWatch('entryAndExitIdBottom', props.objectForm);
  const indicatorIdTop = Form.useWatch('indicatorIdTop', props.objectForm);
  const indicatorIdBottom = Form.useWatch('indicatorIdBottom', props.objectForm);

  const [energyCompareTopologyList, setEnergyCompareTopologyList] = useState<EnergyCompareTopologyItem[]>();
  const [energyCompareProcessTopList, setEnergyCompareProcessTopList] =
    useState<V2EnergyCompareProcessListPostResponse['list']>();
  const [energyCompareProcessBottomList, setEnergyCompareProcessBottomList] =
    useState<V2EnergyCompareProcessListPostResponse['list']>();
  const [entryOrExitMonitoringIndicatorsTopList, setEntryOrExitMonitoringIndicatorsTopList] =
    useState<ProcessEntryOrExitMonitoringIndicatorsRes[]>();
  const [entryOrExitMonitoringIndicatorsBottomList, setEntryOrExitMonitoringIndicatorsBottomList] =
    useState<ProcessEntryOrExitMonitoringIndicatorsRes[]>();

  const topoIdTopInitValue = useRef<boolean>(false);
  const entryAndExitIdTopInitValue = useRef<boolean>(false);
  const indicatorIdTopInitValue = useRef<boolean>(false);

  const [entryAndExitIdTopDisabled, setEntryAndExitIdTopDisabled] = useState<boolean>(false);
  const [entryAndExitIdBottomDisabled, setEntryAndExitIdBottomDisabled] = useState<boolean>(false);
  const [indicatorIdTopDisabled, setIndicatorIdTopDisabled] = useState<boolean>(false);
  const [indicatorIdBottomDisabled, setIndicatorIdBottomDisabled] = useState<boolean>(false);

  const rememberTopoIdRef = useRef<boolean>(false);
  const rememberEntryAndExitIdRef = useRef<boolean>(false);
  const rememberIndicatorIdRef = useRef<boolean>(false);

  useEffect(() => {
    if (topoIdTopInitValue.current && entryAndExitIdTopInitValue.current && indicatorIdTopInitValue.current) {
      setSearchParams(undefined);
      setChartData(undefined);
      setQuery({
        timeResolution: StatisticsPartition.MINUTE_15,
        ...getDefaultTime(StatisticsPartition.MINUTE_15, dayjs()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoIdTop, entryAndExitIdTop, topoIdBottom, entryAndExitIdBottom, indicatorIdTop, indicatorIdBottom]);

  // 默认选择第一个
  useEffect(() => {
    if (objectValues) {
      if (!rememberTopoIdRef.current && energyCompareTopologyList && energyCompareTopologyList.length > 0) {
        props.objectForm.setFieldValue('topoIdTop', objectValues.topoIdTop);
        props.objectForm.setFieldValue('topoIdBottom', objectValues.topoIdBottom);
        rememberTopoIdRef.current = true;
      }
      if (!rememberEntryAndExitIdRef.current && energyCompareProcessTopList && energyCompareProcessTopList.length > 0) {
        props.objectForm.setFieldValue('entryAndExitIdTop', objectValues.entryAndExitIdTop);
        props.objectForm.setFieldValue('entryAndExitIdBottom', objectValues.entryAndExitIdBottom);
        rememberEntryAndExitIdRef.current = true;
      }
      if (
        !rememberIndicatorIdRef.current &&
        entryOrExitMonitoringIndicatorsTopList &&
        entryOrExitMonitoringIndicatorsTopList.length > 0
      ) {
        props.objectForm.setFieldValue('indicatorIdTop', objectValues.indicatorIdTop);
        props.objectForm.setFieldValue('indicatorIdBottom', objectValues.indicatorIdBottom);
        rememberIndicatorIdRef.current = true;
      }
    } else {
      if (!topoIdTopInitValue.current && energyCompareTopologyList && energyCompareTopologyList.length > 0) {
        props.objectForm.setFieldValue(
          'topoIdTop',
          url_energyMediumTopoId ? Number(url_energyMediumTopoId) : energyCompareTopologyList[0].id
        );
        topoIdTopInitValue.current = true;
      }
      if (
        !entryAndExitIdTopInitValue.current &&
        energyCompareProcessTopList &&
        energyCompareProcessTopList.length > 0
      ) {
        let secondId;
        if (energyCompareProcessTopList?.[0]?.processEntrySimpleVos?.[0]?.id) {
          secondId =
            energyCompareProcessTopList?.[0]?.processEntrySimpleVos?.[0]?.id.toString() +
            EnergyMediumTreeFormatType.ENTRY;
        } else if (energyCompareProcessTopList?.[0]?.processExitSimpleVos?.[0]?.id) {
          secondId =
            energyCompareProcessTopList?.[0]?.processExitSimpleVos?.[0]?.id.toString() +
            EnergyMediumTreeFormatType.EXIT;
        } else if (energyCompareProcessTopList?.[0]?.processNodeSimpleVo?.id) {
          secondId =
            energyCompareProcessTopList?.[0]?.processNodeSimpleVo?.id.toString() +
            EnergyMediumTreeFormatType.NODEINSIDE;
        } else {
          secondId = undefined;
        }
        props.objectForm.setFieldValue(
          'entryAndExitIdTop',
          url_processId && url_processEntryOrExitId && !isNil(url_type)
            ? [Number(url_processId), url_processEntryOrExitId + url_type]
            : [energyCompareProcessTopList[0].id, secondId]
        );
        entryAndExitIdTopInitValue.current = true;
      }
      if (
        !indicatorIdTopInitValue.current &&
        entryOrExitMonitoringIndicatorsTopList &&
        entryOrExitMonitoringIndicatorsTopList.length > 0
      ) {
        props.objectForm.setFieldValue(
          'indicatorIdTop',
          url_indicatorId ? Number(url_indicatorId) : entryOrExitMonitoringIndicatorsTopList[0].indicatorId
        );
        indicatorIdTopInitValue.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectValues, energyCompareTopologyList, energyCompareProcessTopList, entryOrExitMonitoringIndicatorsTopList]);

  // 查询拓扑列表
  useEffect(() => {
    getTopologySelectList().then(setEnergyCompareTopologyList);
  }, []);
  // topo列表options
  const topoListOptions = useMemo(() => {
    return (energyCompareTopologyList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [energyCompareTopologyList]);

  // 上面的 通过topoid查询过程与输入输出
  useEffect(() => {
    if (topoIdTop) {
      setEntryAndExitIdTopDisabled(true);
      setIndicatorIdTopDisabled(true);
      // 重新选择拓扑后，后面都置为空
      props.objectForm.setFieldsValue({
        entryAndExitIdTop: undefined,
        indicatorIdTop: undefined,
      });
      apiV2EnergyCompareProcessListPost({ id: topoIdTop }).then(res => {
        setEnergyCompareProcessTopList(res.list);
        setEntryAndExitIdTopDisabled(false);
        setIndicatorIdTopDisabled(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoIdTop]);

  // 下面的 通过topoid查询过程与输入输出
  useEffect(() => {
    if (topoIdBottom) {
      setEntryAndExitIdBottomDisabled(true);
      props.objectForm.setFieldsValue({
        entryAndExitIdBottom: undefined,
        indicatorIdBottom: undefined,
      });
      apiV2EnergyCompareProcessListPost({ id: topoIdBottom }).then(res => {
        setEnergyCompareProcessBottomList(res.list);
        setEntryAndExitIdBottomDisabled(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topoIdBottom]);

  // 上面输入输出
  const entryAndExitTopOptions = useMemo(() => {
    return (energyCompareProcessTopList ?? []).map(i => {
      const obj = {
        label: i.name,
        value: i.id,
        children: (i.processEntrySimpleVos ?? [])
          .map(itemEntry => ({
            label: itemEntry.name,
            value: itemEntry.id!.toString() + EnergyMediumTreeFormatType.ENTRY,
            info: {
              type: EnergyMediumTreeFormatType.ENTRY,
            },
          }))
          .concat(
            (i.processExitSimpleVos ?? []).map(itemExit => ({
              label: itemExit.name,
              value: itemExit.id!.toString() + EnergyMediumTreeFormatType.EXIT,
              info: {
                type: EnergyMediumTreeFormatType.EXIT,
              },
            }))
          ),
      };
      if (i.processNodeSimpleVo) {
        obj.children.push({
          label: i.processNodeSimpleVo.name,
          value: i.processNodeSimpleVo.id!.toString() + EnergyMediumTreeFormatType.NODEINSIDE,
          info: {
            type: EnergyMediumTreeFormatType.NODEINSIDE,
          },
        });
      }
      return obj;
    });
  }, [energyCompareProcessTopList]);

  // 下面输入输出
  const entryAndExitBottomOptions = useMemo(() => {
    return (energyCompareProcessBottomList ?? []).map(i => {
      const obj = {
        label: i.name,
        value: i.id,
        children: (i.processEntrySimpleVos ?? [])
          .map(itemEntry => ({
            label: itemEntry.name,
            value: itemEntry.id!.toString() + EnergyMediumTreeFormatType.ENTRY,
            info: {
              type: EnergyMediumTreeFormatType.ENTRY,
            },
          }))
          .concat(
            (i.processExitSimpleVos ?? []).map(itemExit => ({
              label: itemExit.name,
              value: itemExit.id!.toString() + EnergyMediumTreeFormatType.EXIT,
              info: {
                type: EnergyMediumTreeFormatType.EXIT,
              },
            }))
          ),
      };
      if (i.processNodeSimpleVo) {
        obj.children.push({
          label: i.processNodeSimpleVo.name,
          value: i.processNodeSimpleVo.id!.toString() + EnergyMediumTreeFormatType.NODEINSIDE,
          info: {
            type: EnergyMediumTreeFormatType.NODEINSIDE,
          },
        });
      }
      return obj;
    });
  }, [energyCompareProcessBottomList]);

  // 上面通过输入输出id查询指标
  useEffect(() => {
    if (entryAndExitIdTop && entryAndExitIdTop.length > 1 && !entryAndExitIdTop.includes(undefined)) {
      props.objectForm.setFieldsValue({
        indicatorIdTop: undefined,
      });
      setIndicatorIdTopDisabled(true);
      const entryOrExit = entryAndExitIdTop[entryAndExitIdTop.length - 1];
      const entryOrExitType = entryOrExit.slice(entryOrExit.length - 1);
      const entryOrExitId = entryOrExit.slice(0, entryOrExit.length - 1);
      getProcessEntryOrExitMonitoringIndicators({
        processEntryOrExitId: entryOrExitId,
        type: entryOrExitType,
      }).then(res => {
        setEntryOrExitMonitoringIndicatorsTopList(res.list);
        setIndicatorIdTopDisabled(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryAndExitIdTop]);
  // 上面指标列表
  const indicatorTopOptions = useMemo(() => {
    return (entryOrExitMonitoringIndicatorsTopList ?? []).map(i => ({ label: i.indicatorName, value: i.indicatorId }));
  }, [entryOrExitMonitoringIndicatorsTopList]);

  // 下面通过输入输出id查询指标
  useEffect(() => {
    if (entryAndExitIdBottom && entryAndExitIdBottom.length > 1 && !entryAndExitIdBottom.includes(undefined)) {
      props.objectForm.setFieldsValue({
        indicatorIdBottom: undefined,
      });
      setIndicatorIdBottomDisabled(true);
      const entryOrExit = entryAndExitIdBottom[entryAndExitIdBottom.length - 1];
      const entryOrExitType = entryOrExit.slice(entryOrExit.length - 1);
      const entryOrExitId = entryOrExit.slice(0, entryOrExit.length - 1);
      getProcessEntryOrExitMonitoringIndicators({
        processEntryOrExitId: entryOrExitId,
        type: entryOrExitType,
      }).then(res => {
        setEntryOrExitMonitoringIndicatorsBottomList(res.list);
        setIndicatorIdBottomDisabled(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entryAndExitIdBottom]);
  // 下面指标列表
  const indicatorBottomOptions = useMemo(() => {
    return (entryOrExitMonitoringIndicatorsBottomList ?? []).map(i => ({
      label: i.indicatorName,
      value: i.indicatorId,
    }));
  }, [entryOrExitMonitoringIndicatorsBottomList]);

  return (
    <>
      <div className={styles.compareSection}>
        <Form.Item name="topoIdTop" label="管网拓扑" rules={[{ required: true, message: '请选择管网拓扑' }]}>
          <Select placeholder="请选择管网拓扑" showSearch optionFilterProp="label" options={topoListOptions} />
        </Form.Item>
        <Form.Item
          name="entryAndExitIdTop"
          label="输入/节点内/输出"
          rules={[{ required: true, message: '请选择输入/节点内/输出' }]}
        >
          <Cascader
            disabled={entryAndExitIdTopDisabled}
            allowClear={false}
            showSearch={{
              filter: (inputValue, path) => {
                return path.some(
                  option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                );
              },
            }}
            style={{ width: '100%' }}
            options={entryAndExitTopOptions}
            placeholder="请选择输入/节点内/输出"
          />
        </Form.Item>
        <Form.Item name="indicatorIdTop" label="指标" rules={[{ required: true, message: '请选择指标' }]}>
          <Select
            disabled={indicatorIdTopDisabled}
            placeholder="请选择指标"
            showSearch
            optionFilterProp="label"
            options={indicatorTopOptions}
          />
        </Form.Item>
      </div>

      <Divider className={styles.dividerSty} dashed>
        VS <i className={styles.circle} />
      </Divider>

      <div className={styles.compareSection}>
        <Form.Item name="topoIdBottom" label="管网拓扑" rules={[{ required: true, message: '请选择管网拓扑' }]}>
          <Select placeholder="请选择管网拓扑" showSearch optionFilterProp="label" options={topoListOptions} />
        </Form.Item>
        <Form.Item
          name="entryAndExitIdBottom"
          label="输入/节点内/输出"
          rules={[{ required: true, message: '请选择输入/节点内/输出' }]}
        >
          <Cascader
            disabled={entryAndExitIdBottomDisabled}
            allowClear={false}
            showSearch={{
              filter: (inputValue, path) => {
                return path.some(
                  option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                );
              },
            }}
            style={{ width: '100%' }}
            options={entryAndExitBottomOptions}
            placeholder="请选择输入/节点内/输出"
          />
        </Form.Item>
        <Form.Item name="indicatorIdBottom" label="指标" rules={[{ required: true, message: '请选择指标' }]}>
          <Select
            placeholder="请选择指标"
            disabled={indicatorIdBottomDisabled}
            showSearch
            optionFilterProp="label"
            options={indicatorBottomOptions}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default ObjectCompare;
