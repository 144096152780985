import React, { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Wrapper,
  Table,
  EllipsisSpan,
  useBreadcrumbRoutes,
  Button,
  usePaging,
  Paging,
  SubContent,
  FormTitle,
} from '@maxtropy/components';
import { IotProtocolType } from '@/shared/types';
import { apiV2GatewayPageEdgeDevicePost } from '@maxtropy/device-customer-apis-v2';
import { fetchGatewayById } from '../../api/gateway';
import { useQuery } from '../../utils/utils';
import GatewayDisplay from './components/GatewayDisplay';
import styles from './index.module.scss';
import { DeviceList } from './type';

interface TableDataType {}

const routes = [{ name: '网关查看' }];

export const deviceColumns = (isDetail?: boolean) => {
  const edgeDeviceId = isDetail
    ? [
        {
          title: '物理设备ID',
          dataIndex: 'id',
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        },
      ]
    : [];
  return [
    {
      title: '设备编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    ...edgeDeviceId,
    {
      title: '设备S/N码',
      dataIndex: 'sn',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属类目',
      dataIndex: 'typeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备标签',
      dataIndex: 'tags',
      ellipsis: { showTitle: true },
      render: (v: string[]) => {
        const tags = v && v.join(', ');
        return <EllipsisSpan value={tags} />;
      },
    },
    {
      title: '所属组织',
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];
};

export const columns = [
  {
    title: '网关中的设备序号',
    dataIndex: 'sequence',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  ...deviceColumns(true),
];

const GatewayDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [devices, setDevices] = useState<DeviceList[]>([]);
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging(100);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const { data: info, isLoading: isInfoLoading } = useQuery(
    useCallback(() => {
      if (id) return fetchGatewayById(id);
      return Promise.resolve(undefined);
    }, [id])
  );

  useEffect(() => {
    if (id) {
      apiV2GatewayPageEdgeDevicePost({
        gatewayId: Number(id),
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setDevices(res?.list ?? []);
        setTotalCount(res?.total ?? 0);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  return (
    <Wrapper routes={[...(breadcrumbRoutes?.routes ?? []), ...routes]} className={styles.wrapper}>
      <Spin spinning={isInfoLoading}>
        <FormTitle title="网关查看" />
        <SubContent title="基础信息">
          <GatewayDisplay row={info} />
        </SubContent>
        <SubContent title="关联设备">
          <Table<TableDataType>
            rowKey="id"
            dataSource={devices}
            columns={info?.iotProtocol === IotProtocolType.MOCKINGBIRD ? columns.slice(1, columns.length) : columns}
            pagination={false}
          />
          <Paging pagingInfo={pagingInfo} />
        </SubContent>
      </Spin>
      <div style={{ padding: '32px 40px' }}>
        <Button onClick={() => navigate(-1)}>返回</Button>
      </div>
    </Wrapper>
  );
};

export default GatewayDetail;
