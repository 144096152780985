import { getTopologySelectList } from '@/api/quality-monitor';
import { Empty, useBreadcrumbRoutes, Wrapper, Radio, Select } from '@maxtropy/components';
import { Col, RadioChangeEvent, Row } from 'antd';
import React, { Key, useEffect, useMemo, useRef, useState } from 'react';
import ProcessingEfficiency from './components/ProcessingEfficiency';
import ViewEnergyMediumList from './components/ViewEnergyMediumList';
import ViewEnergyMediumTopo from './components/ViewEnergyMediumTopo';
import styles from './index.module.scss';
import LossDuringTransAndDisProcess from './components/LossDuringTransAndDisProcess';
import { EnergyWorkingProcessType } from '@/api/uet';
import { V2EnergyCompareTopologyListPostResponse } from '@maxtropy/device-customer-apis-v2';

// 视图类型
export enum viewTypeEnum {
  CONFIGURATION = '1',
  LIST = '2',
}

export const viewTypeDisplay = {
  [viewTypeEnum.CONFIGURATION]: '组态视图',
  [viewTypeEnum.LIST]: '列表视图',
};

type EnergyCompareTopologyItem = Exclude<V2EnergyCompareTopologyListPostResponse['list'], undefined>[number];

const EnergyMediaTopologyAnalysis = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const [viewType, setViewType] = useState<viewTypeEnum>(viewTypeEnum.CONFIGURATION);
  const [energyMediumTopologyList, setEnergyMediumTopologyList] = useState<EnergyCompareTopologyItem[]>();
  const [topoId, setTopoId] = useState<number>();
  const [processId, setProcessId] = useState<Key>();
  const [selectedProcessType, setSelectedProcessType] = useState<EnergyWorkingProcessType>();
  const [selectedTopoSceneId, setSelectedTopoSceneId] = useState<number>();
  const [selectedProcessName, setSelectedProcessName] = useState<string>();

  const processIdRef = useRef<React.Key>(); // 记忆所选

  // 查询拓扑列表
  useEffect(() => {
    getTopologySelectList().then(setEnergyMediumTopologyList);
  }, []);
  // topo列表options
  const topoListOptions = useMemo(() => {
    return (energyMediumTopologyList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [energyMediumTopologyList]);
  useEffect(() => {
    if (energyMediumTopologyList && energyMediumTopologyList.length > 0) {
      setTopoId(energyMediumTopologyList[0].id);
      setSelectedTopoSceneId(energyMediumTopologyList[0].energyMediumSceneId);
    }
  }, [energyMediumTopologyList]);

  const onViewTypeChange = (e: RadioChangeEvent) => {
    setViewType(e.target.value);
  };

  const getProcessId = (key: Key) => {
    processIdRef.current = key;
    setProcessId(key);
  };
  const getProcessType = (type: EnergyWorkingProcessType) => {
    setSelectedProcessType(type);
  };

  const getProcessName = (name: string) => {
    setSelectedProcessName(name);
  };

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} className={styles.wrapper}>
        <div className={styles.contentBody}>
          <div className={styles.leftCol}>
            <div className={styles.leftColTopContent}>
              <Row style={{ width: '100%' }}>
                <Col span={14}>
                  <span>拓扑：</span>
                  <Select
                    style={{ width: '80%' }}
                    placeholder="请选择"
                    showSearch
                    optionFilterProp="label"
                    options={topoListOptions}
                    onChange={value => {
                      processIdRef.current = undefined;
                      const findSceneIdById = energyMediumTopologyList?.find(i => i.id === value)?.energyMediumSceneId;
                      findSceneIdById && setSelectedTopoSceneId(findSceneIdById);
                      setTopoId(value);
                    }}
                    value={topoId}
                  />
                </Col>
                <Col span={10}>
                  <Radio.Group
                    options={Object.entries(viewTypeDisplay).map(([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                    onChange={e => {
                      onViewTypeChange(e);
                    }}
                    value={viewType}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Col>
              </Row>
            </div>
            {viewType === viewTypeEnum.CONFIGURATION ? (
              <div style={{ height: 'calc(100% - 46px)' }}>
                <ViewEnergyMediumTopo
                  energyMediumTopoId={topoId}
                  processIdRef={processIdRef.current}
                  getProcessId={getProcessId}
                  getProcessType={getProcessType}
                  getProcessName={getProcessName}
                />
              </div>
            ) : (
              <div style={{ height: 'calc(100% - 46px)', overflowY: 'auto' }}>
                <ViewEnergyMediumList
                  energyMediumTopoId={topoId}
                  processIdRef={processIdRef.current}
                  getProcessId={getProcessId}
                  getProcessType={getProcessType}
                  getProcessName={getProcessName}
                />
              </div>
            )}
          </div>
          <div className={styles.rightCol}>
            {!topoId ? (
              <Empty style={{ marginTop: '20vh' }} />
            ) : (
              <>
                {selectedProcessType === EnergyWorkingProcessType.PREPARATION_AND_HANDLE && (
                  <ProcessingEfficiency
                    topoId={topoId}
                    processId={processId}
                    selectedTopoSceneId={selectedTopoSceneId}
                    selectedProcessName={selectedProcessName}
                  />
                )}
                {selectedProcessType === EnergyWorkingProcessType.TRANSMISSION_AND_DISTRIBUTION && (
                  <LossDuringTransAndDisProcess
                    topoId={topoId}
                    processId={processId}
                    selectedTopoSceneId={selectedTopoSceneId}
                    selectedProcessName={selectedProcessName}
                  />
                )}
                {selectedProcessType === EnergyWorkingProcessType.STORAGE && <Empty style={{ marginTop: '20vh' }} />}
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default EnergyMediaTopologyAnalysis;
