// 微电网模型状态
export enum ModelStatus {
  DISABLED = 0, // 禁用
  ENABLED = 1, // 启用
}

// 微电网模型状态显示
export const ModelStatusDisplay = {
  [ModelStatus.DISABLED]: '禁用',
  [ModelStatus.ENABLED]: '启用',
};

export const ModelStatusColorDisplay = {
  [ModelStatus.DISABLED]: 'invalid',
  [ModelStatus.ENABLED]: 'success',
};

// 模型类型
export enum ModelType {
  PV = 0, // 光伏预测模型
  WIND_POWER = 1, // 风电预测模型
  LOAD = 2, // 负载预测模型
}

// 模型类型显示
export const ModelTypeDisplay = {
  [ModelType.PV]: '光伏预测模型',
  [ModelType.WIND_POWER]: '风电预测模型',
  [ModelType.LOAD]: '负载预测模型',
};

// 文件上传tip显示
export const ModelTypeTipDisplay = {
  [ModelType.PV]: '光伏站',
  [ModelType.WIND_POWER]: '风电站',
  [ModelType.LOAD]: '负载电表',
};

// 训练状态
export enum TrainStatus {
  TRAINING = 0,
  SUCCESS = 1,
  FAILED = 2,
}

export const TrainStatusDisplay = {
  [TrainStatus.TRAINING]: '训练中',
  [TrainStatus.SUCCESS]: '成功',
  [TrainStatus.FAILED]: '失败',
};

export const TrainStatusColorDisplay = {
  [TrainStatus.TRAINING]: '#4A90E2',
  [TrainStatus.SUCCESS]: 'success',
  [TrainStatus.FAILED]: 'error',
};

// 预测数据颗粒度
export enum PredictDataGranularType {
  MIN = '1M', // 1分钟
  FIFTEEN = '15M', // 15分钟
  THIRTY = '30M', // 30分钟
  HOUR = 'H', // 1小时
  DAY = 'D', // 1天
}
// 预测数据颗粒度展示
export const PredictDataGranularTypeDisplay = {
  [PredictDataGranularType.MIN]: '1分钟',
  [PredictDataGranularType.FIFTEEN]: '15分钟',
  [PredictDataGranularType.THIRTY]: '30分钟',
  [PredictDataGranularType.HOUR]: '1小时',
  [PredictDataGranularType.DAY]: '1天',
};

// 训练数据窗口粒度 H,D,M
export enum InWindowType {
  HOUR = 'H', // 小时
  DAY = 'D', // 天
  MIN = '1M', // 1分钟
}
// 训练数据窗口粒度展示
export const InWindowDisplay = {
  [InWindowType.DAY]: '天',
  [InWindowType.HOUR]: '小时',
  [InWindowType.MIN]: '1分钟',
};

// 推理数据定时任务 D,H,15M
export enum InferenceTimeType {
  DAY = 'D', // 天
  HOUR = 'H', // 小时
  FIFTEEN = '15M', // 15分钟
}
// 推理数据定时任务展示
export const InferenceTimeTypeDisplay = {
  [InferenceTimeType.DAY]: '天',
  [InferenceTimeType.HOUR]: '小时',
  [InferenceTimeType.FIFTEEN]: '15分钟',
};
