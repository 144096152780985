import { DatePicker, Radio, Tabs, Tooltip } from '@maxtropy/components';
import React, { FC, Key, useEffect, useMemo, useState } from 'react';
import { CurrentItemType, DatePickerType, datePickerTypeDisplay } from '../../type';
import { DatePickerProps, RadioChangeEvent, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';
import { useRequest } from 'ahooks';
import {
  apiV2StationSignboardBaselineConfigGetPost,
  apiV2StationSignboardStationDetailIndicatorChartValuePost,
  apiV2StationSignboardStationDetailIndicatorListPost,
  apiV2StationSignboardStationDetailIndicatorValuePost,
} from '@maxtropy/device-customer-apis-v2';
import Charts from '../Charts';
import { indicatorBtns } from '../../config';
import { getDefaultTime } from '../../utils';

const { RangePicker } = DatePicker;

export interface ChartsDataReq {
  indicatorId?: Key; //指标id
  granularityType?: DatePickerType; //时间颗粒度
  startDate?: string; //开始时间
  endDate?: string; //结束时间
}

interface ICenterDataCompartion {
  stationType?: string;
  stationId?: string;
}

const BottomSingleDevice: FC<ICenterDataCompartion> = props => {
  const { stationType, stationId } = props;
  // const chartRef = useRef<ReactEcharts>();
  const [activeKey, setActiveKey] = useState<string>(); // Tab选择
  // const [selectBtn, setSelectBtn] = useState<DatePickerType>(); // 时间颗粒度
  // const [dates, setDates] = useState<[Dayjs, Dayjs]>(); // 日期选择

  const [query, setQuery] = useState<ChartsDataReq>();

  // tabs请求
  const { data: tabData } = useRequest(
    async () => {
      const res = await apiV2StationSignboardStationDetailIndicatorListPost(
        {
          stationType: Number(stationType),
          stationId: Number(stationId),
        }
        // // { server: 'mock' }
      );
      return (res?.list ?? []).map(item => {
        return {
          key: item.id!.toString(),
          label: item.name,
        };
      });
    },
    {
      ready: !!stationId && !!stationType,
      refreshDeps: [stationId, stationType],
    }
  );

  // 指标配置值获取
  const { data: indicatorValueData, loading: indicatorValueLoading } = useRequest(
    () => {
      return apiV2StationSignboardStationDetailIndicatorValuePost(
        {
          stationType: Number(stationType),
          stationId: Number(stationId),
          startDate: query?.startDate,
          endDate: query?.endDate,
          granularityType: query?.granularityType,
          indicatorId: query?.indicatorId,
        }
        // { server: 'mock' }
      );
    },
    {
      ready: !!stationId && !!stationType && !!query,
      refreshDeps: [stationId, stationType, query],
    }
  );

  // 图表请求
  const { data: chartData, loading } = useRequest(
    () => {
      return apiV2StationSignboardStationDetailIndicatorChartValuePost(
        {
          stationType: Number(stationType),
          stationId: Number(stationId),
          startDate: query?.startDate,
          endDate: query?.endDate,
          granularityType: query?.granularityType,
          indicatorId: query?.indicatorId,
        }
        // { server: 'mock' }
      );
    },
    {
      ready: !!stationId && !!stationType && !!query,
      refreshDeps: [stationId, stationType, query],
    }
  );

  // 查询基准，渗透每个图表
  const { data: baselineConfigData } = useRequest(
    async () => {
      const res = await apiV2StationSignboardBaselineConfigGetPost(
        {
          stationType: Number(stationType),
          stationId: Number(stationId),
        }
        // { server: 'mock' }
      );
      if (res?.list) {
        return res?.list;
      }
    },
    {
      ready: !!stationId && !!stationType,
      refreshDeps: [stationId, stationType],
    }
  );

  const [currentItem, setCurrentItem] = useState<CurrentItemType>(); // 保存每一个选择的信息
  // 默认选择第一个指标
  useEffect(() => {
    if (tabData && tabData.length) {
      setActiveKey(tabData[0].key);
    }
  }, [tabData]);

  useEffect(() => {
    if (activeKey) {
      const target = indicatorBtns()?.find(item => item.id.toString() === activeKey);
      const defaultAggrby = target?.defaultSelectBtn?.aggrby;
      setCurrentItem(target);
      setQuery({
        ...query,
        indicatorId: Number(activeKey),
        granularityType: defaultAggrby,
        startDate: getDefaultTime(defaultAggrby, dayjs()).from.format('YYYY-MM-DD'),
        endDate: getDefaultTime(defaultAggrby, dayjs()).to.format('YYYY-MM-DD'),
      });
    }
  }, [activeKey]);

  const onChangeRangePicker = (range: [Dayjs | null, Dayjs | null] | null) => {
    if (range) {
      const [beginDate, endDate] = range;
      if (!isNil(beginDate) && !isNil(endDate)) {
        // 查询的state
        setQuery({
          ...query,
          startDate: beginDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
        });
      }
    }
  };

  // 禁用时间范围
  const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    if (current > dayjs().endOf('day')) return true;
    const mostDays =
      query?.granularityType === DatePickerType.DAY ? 31 : query?.granularityType === DatePickerType.HOUR ? 7 : 7;
    let timeType: 'days' | 'M' = 'days';
    if (query?.granularityType === DatePickerType.DAY || query?.granularityType === DatePickerType.HOUR) {
      timeType = 'days';
    }
    if (query?.granularityType === DatePickerType.MONTH) {
      timeType = 'M' as const;
    }
    let rangeGap = timeType === 'days' ? mostDays : 12;

    if (from) {
      return Math.abs(current.diff(from, timeType)) >= rangeGap;
    }
    return false;
  };

  // 查询图表
  const chartOption = useMemo(
    () =>
      Object.assign(
        {},
        currentItem?.getChartOption?.({
          chartData,
          query,
          baselineConfigData: baselineConfigData?.find(i => i.indicatorId.toString() === activeKey)?.granularityValues,
        })
      ),
    [chartData]
  );

  // 日期切换
  const handleGranularityType = (e: RadioChangeEvent) => {
    const selectGranularityType = e.target.value;
    const defaultStartDate = getDefaultTime(selectGranularityType, dayjs()).from;
    const defaultEndDate = getDefaultTime(selectGranularityType, dayjs()).to;
    setQuery({
      ...query,
      granularityType: selectGranularityType,
      startDate: defaultStartDate.format('YYYY-MM-DD'),
      endDate: defaultEndDate.format('YYYY-MM-DD'),
    });
  };

  return (
    <>
      <Tabs onChange={v => setActiveKey(v)} activeKey={query?.indicatorId?.toString()} items={tabData} />

      {query?.indicatorId && (
        <>
          <Radio.Group
            onChange={handleGranularityType}
            value={query?.granularityType}
            buttonStyle="solid"
            style={{ marginRight: 8 }}
          >
            {(currentItem?.dateBtn ?? []).map(btn => (
              <Radio.Button key={btn.aggrby} value={btn.aggrby}>
                {datePickerTypeDisplay[btn.aggrby as DatePickerType]}
              </Radio.Button>
            ))}
          </Radio.Group>
          <RangePicker
            value={[dayjs(query.startDate), dayjs(query.endDate)]}
            onChange={onChangeRangePicker}
            picker={query?.granularityType === DatePickerType.MONTH ? 'month' : 'date'}
            disabledDate={disabledDate}
            allowClear={false}
          />
          <Spin spinning={indicatorValueLoading}>
            <div className={styles.indicatorValue_card_wrapper}>
              {indicatorValueData?.list?.map(item => (
                <div className={styles.itemcard_wrapper} key={Math.random()}>
                  <Tooltip title={`${item?.subName ?? '--'}(${item?.physicalUnitName ?? '--'})`}>
                    <span className={styles.itemcard_wrapper_top_label}>{`${item?.subName ?? '--'}(${
                      item?.physicalUnitName ?? '--'
                    })`}</span>
                  </Tooltip>
                  <Tooltip title={!isNil(item?.value) ? item?.value.toFixed(2) : '--'}>
                    <span className={styles.itemcard_wrapper_top_value}>
                      {!isNil(item?.value) ? item?.value.toFixed(2) : '--'}
                    </span>
                  </Tooltip>
                </div>
              ))}
            </div>
          </Spin>

          <div className={styles.chart_box_inner}>
            <Charts loading={loading} chartData={chartData} option={chartOption} />
          </div>
        </>
      )}
    </>
  );
};

export default BottomSingleDevice;
