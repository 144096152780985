import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  CustomFilter,
  EllipsisSpan,
  Form,
  Input,
  Modal,
  Paging,
  PopConfirm,
  Select,
  Table,
  useAsync,
  useBreadcrumbRoutes,
  usePaging,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import { Cascader, Space } from 'antd';
import styles from './index.module.scss';
import React, { useState } from 'react';
import { getDeviceTypeTree } from '@/api/deviceType';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { DefaultOptionType } from 'antd/es/cascader';
import CreateOrEditDeviceModal, { formSubmitProps } from './components/CreateOrEditDeviceModal';
import { useRequest } from 'ahooks';
import {
  apiV2ExternalBoundDeviceEditPost,
  apiV2ExternalBoundDevicePagePost,
  apiV2ExternalDeviceBindPost,
  apiV2ExternalDeviceUnbindPost,
  apiV2ExternalProtocolListPost,
} from '@maxtropy/device-customer-apis-v2';
import { PermissionsType } from '@/common/permissionsConst';
import { useHasPermission } from '@/utils/utils';
import { hasDuplicate } from './utils';

export interface FilterParams {
  protocol?: string;
  deviceNameOrCode?: string;
  thirdPartyUniqueIdentifier?: string;
  deviceTypeId?: number[][] | number[];
}

const columns = [
  {
    title: '三方设备编码',
    dataIndex: 'thirdPartyUniqueIdentifier',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '协议',
    dataIndex: 'protocol',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '平台设备',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '平台设备编码',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const ThreePartyDeviceDocking = () => {
  const breadcrumbRoutes = useBreadcrumbRoutes();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<FilterParams>({});

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>();

  const [update, updateFn] = useUpdate();
  const hasAddThreePartyPermission = useHasPermission(PermissionsType.B_ADDTHREE_PARTYDEVICERELATIONSHIP);

  const deviceTypeData = useAsync(getDeviceTypeTree);

  // 查询分页列表
  const { data: pageList, loading } = useRequest(
    async () => {
      const res = await apiV2ExternalBoundDevicePagePost({
        ...searchParams,
        deviceTypeIds: searchParams.deviceTypeId as number[],
        page: pageOffset,
        size: pageSize,
      });
      setTotalCount(res.total!);
      return res.list;
    },
    {
      refreshDeps: [pageOffset, pageSize, searchParams, update],
    }
  );

  // 协议列表
  const { data: protocolList } = useRequest(async () => {
    const res = await apiV2ExternalProtocolListPost({});
    return res.list;
  });

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const onFinish = (val: FilterParams) => {
    const typeId = getChildNodesByParentIds(val.deviceTypeId as number[][], deviceTypeData?.tree);
    setPageOffset(1);
    setSearchParams({
      ...val,
      deviceTypeId: typeId as number[],
    });
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  // 删除
  const onDelete = (id: number) => {
    apiV2ExternalDeviceUnbindPost({
      id,
    }).then(_ => {
      updateFn();
    });
  };

  const filters = (
    <>
      <CustomFilter form={form} onFinish={(v: FilterParams) => onFinish(v)} onReset={onReset}>
        <Form.Item name="thirdPartyUniqueIdentifier" label="三方设备编码">
          <Input style={{ width: '100%' }} placeholder="请输入" />
        </Form.Item>

        <Form.Item name="deviceTypeId" label="所属类目">
          <Cascader
            options={formatOptionData(deviceTypeData)}
            allowClear={false}
            multiple
            maxTagCount="responsive"
            fieldNames={{ children: 'child' }}
            showSearch={{ filter }}
            placeholder={'请选择所属类目'}
          />
        </Form.Item>
        <Form.Item name="deviceNameOrCode" label="平台设备名称/编码">
          <Input style={{ width: '100%' }} placeholder="请输入" />
        </Form.Item>
        <Form.Item name="protocol" label="协议">
          <Select
            allowClear
            placeholder="请选择协议"
            showSearch
            optionFilterProp="label"
            style={{ width: '100%' }}
            options={(protocolList ?? []).map(v => ({
              value: v.name,
              label: v.name,
            }))}
          />
        </Form.Item>
      </CustomFilter>
    </>
  );

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (_: any, record: any) => {
        return (
          <Space size={10}>
            <Button
              type="link"
              onClick={() => {
                setEditModalOpen(true);
                setEditId(record.id);
              }}
            >
              编辑
            </Button>
            <PopConfirm
              title="删除后无法恢复该数据，确定删除该行数据？"
              onConfirm={() => {
                onDelete(record.id);
              }}
            >
              <Button type="link">删除</Button>
            </PopConfirm>
          </Space>
        );
      },
    },
  ];

  const onCancel = () => {
    setEditModalOpen(false);
    setEditId(undefined);
  };

  const onSubmit = async (values: formSubmitProps) => {
    const typeId = values.deviceTypeId[values.deviceTypeId?.length - 1];
    const params = {
      ...values,
      deviceTypeId: typeId,
      propertyBindItems: values.propertyBindItems.map(item => ({
        ...item,
        valueMaps: item.valueMaps ? convertToArray(item.valueMaps) : undefined,
      })),
    };
    // 判断编码是否有重复
    const codeArr = (values.propertyBindItems ?? []).map(item => item.thirdPartyPropertyCode);
    if (hasDuplicate(codeArr)) {
      Modal.error({
        title: '三方设备数采点编码有重复',
      });
      return;
    }

    if (editId) {
      await apiV2ExternalBoundDeviceEditPost({
        ...params,
        deviceTypeId: typeId,
        id: editId,
      });
      onCancel();
      updateFn();
    } else {
      await apiV2ExternalDeviceBindPost({
        ...params,
        deviceTypeId: typeId,
      });
      onCancel();
      setPageOffset(1);
      updateFn();
    }
  };

  // 转换
  const convertToArray = (str: string) => {
    return str
      .split(';')
      .map(pair => {
        const [key, value] = pair.split(':');
        if (!key || !value) return null; // 处理无效数据
        return { originalValue: key.trim(), convertedValue: value.trim() };
      })
      .filter(Boolean); // 过滤掉 `null` 值，防止错误数据
  };

  return (
    <>
      <Wrapper routes={breadcrumbRoutes?.routes} filters={filters} className={styles.wrapper}>
        <Space size={10} direction="vertical" className={styles.btnBlank}>
          {hasAddThreePartyPermission && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setEditModalOpen(true);
                setEditId(undefined);
              }}
            >
              新建
            </Button>
          )}
        </Space>
        <Table
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={pageList ?? []}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
      {/* 编辑 */}
      {editModalOpen && (
        <CreateOrEditDeviceModal editId={editId} visible={editModalOpen} onCancel={onCancel} onSubmit={onSubmit} />
      )}
    </>
  );
};

export default ThreePartyDeviceDocking;
